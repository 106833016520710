import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import { HiExclamationCircle, HiOutlineCheckCircle } from 'react-icons/hi'
import { useState, useEffect } from 'react'
import { NavLink, useNavigate, Link } from 'react-router-dom'
import { showMessage, makeAjax, url, respStatus, dateFormat } from "../../../helpers/global_helper"
import Switch from "react-switch"
import Popup from "../../popup/Popup"
import LoginPinInner from "../../authentication/loginPin/LoginPinInner"
import { useDispatch, useSelector } from "react-redux"
import { getPlayerProfileDetail } from "../../../store/actions";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ActionLoader from "../../../pages/ActionLoader"
const ProfileInner = (props) => {
    const [loaderStatus, setLoader] = useState(false);
    const dispatch = useDispatch();
    const OffSymbol = () => {
        return (
            <div className="toggle-btn">
                {" "}

                Off
            </div>
        );
    };


    const OnSymbol = () => {
        return (
            <div className="toggle-btn">
                {" "}
                On
            </div>
        );
    };

    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        opassword: "",
        password: "",
        password_confirmation: ""
    })

    const [formError, setFormError] = useState({});
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data
    }));

    const [valuesOld, setValuesOld] = useState({
        password: "",
        showOldPassword: false,
    });
    const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfirm, setValuesConfirm] = useState({
        password: "",
        showConfirmPassword: false,
    });

    const [pinActive, setPinActive] = useState(false);
    const [onhoverConfirmPassword, setOnhoverConfirmPassword] = useState(false);
    const [onhoverOldPassword, setOnhoverOldPassword] = useState(false);
    const [onhoverNewPassword, setOnhoverNewPassword] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true);
        makeAjax(
            url.USER_API.changePassword,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setLoader(false);
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                setLoader(false);
                setFormError({});
                setFormData({ ...formData, ...{ opassword: '', password: '', password_confirmation: '' } });
                showMessage(response);
                logout();
                navigate('/')

            });
    }

    const logout = () => {
        makeAjax(
            url.USER_API.logout, [], url.USER_MS_EXT).then(response => {

                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
            });
        window.localStorage.removeItem("user_token")
        navigate('/')
        setPinActive(true)
    }

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
        setFormError({ ...formError, [event.target.name]: '' })
    }

    // Check player email verified
    useEffect(() => {
        dispatch(getPlayerProfileDetail());
    }, []);

    const [pinProtectionChange, setPinProtectionChange] = useState(false)
    const [pinClassChange, setPinClassChange] = useState(true)
    const handleChangeCheck = (event) => {
        setPinClassChange(curr => !curr)
        let tempData = JSON.parse(JSON.stringify(userProfile));
        tempData['enabled_active_pin'] = !tempData['enabled_active_pin'];
        // setUserProfile(tempData);
        dispatch(getPlayerProfileDetail());
        if (userProfile.enabled_active_pin === true) {
            setPinProtectionChange(true)
            return;
        } else {
            navigate('/pin-on');
            return;
        }
    }

    const handlePopup = () => {
        setPinProtectionChange(false)
        let tempData = JSON.parse(JSON.stringify(userProfile));
        if (pinProtectionChange === true) {

            tempData['enabled_active_pin'] = !tempData['enabled_active_pin'];
        } else {

            tempData['enabled_active_pin'] = true;
        }
        dispatch(getPlayerProfileDetail());
        // setUserProfile(tempData);
    }

    //#wl2-5252
    const onHoverHandel = (event) => {
        if (event.target.name == 'password') {
            setOnhoverNewPassword(true);
        }
        if (event.target.name == 'opassword') {
            setOnhoverOldPassword(true);
        }
        if (event.target.name == 'password_confirmation') {
            setOnhoverConfirmPassword(true);
        }
    }


    const onLeaveHandel = () => {
        setOnhoverNewPassword(false);
        setOnhoverOldPassword(false);
        setOnhoverConfirmPassword(false);
    }

    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        const checkIsMobile = () => {
            const isMobile = window.innerWidth <= 768;
            setIsDesktop(!isMobile);
        };

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    const handleMouseOverOld = () => {
        if (isDesktop) {
            setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
        }
    };

    const handleClickOld = () => {
        if (!isDesktop) {
            setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
        }
    };

    const handleMouseOver = () => {
        if (isDesktop) {
            setValues({ ...values, showPassword: !values.showPassword });
        }
    };

    const handleClick = () => {
        if (!isDesktop) {
            setValues({ ...values, showPassword: !values.showPassword });
        }
    };

    const handleMouseOverConf = () => {
        if (isDesktop) {
            setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
        }
    };

    const handleClickConf = () => {
        if (!isDesktop) {
            setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
        }
    };


    return (
        <div className='profile'>
            <div className="container">
                {/* <h2 className="title">{props.t('profile_upper')}</h2> */}
                <div className="profile-box">
                    <h4 className="sub-title">{props.t('profile')}</h4>
                    <div className="res-cus-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>{props.t('username')} :</td>
                                    <td className="data">{userProfile.username}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('email')} :</td>
                                    <td className="data  d-flex flex-column flex-sm-row">
                                        <div className="break">
                                            {userProfile.email_verified_at ? (
                                                <a data-toggle="tooltip" title="add to cart"><HiOutlineCheckCircle style={{ 'fontSize': '18px', 'color': 'green' }} /></a>
                                            ) : <HiExclamationCircle />}

                                            {userProfile.email_verified_at ? (
                                                <Link to="/email-verification" style={{ 'color': 'green' }}> {userProfile.email_id}</Link>
                                            ) : userProfile.email_id}
                                            {!userProfile.email_verified_at && <NavLink to="/email-verification" className="unverfied-email verify-link ms-2">{props.t('verification')}</NavLink>}
                                        </div>
                                        <div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{props.t('bank')} / {props.t('e_wallet')} :</td>
                                    <td className="data">{userProfile.player_bank_detail.bank_detail.bank_name}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('account_e_wallet_name')} :</td>
                                    <td className="data">{userProfile.player_bank_detail.account_name}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('account')} / {props.t('e_wallet')} :</td>
                                    <td className="data">{userProfile.player_bank_detail.account_number}</td>
                                </tr>
                                <tr>
                                    <td> {props.t('last_login')} :</td>
                                    <td className="data">{dateFormat(userProfile.last_login_at)}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
                <div className="pin-protection">
                    <span>{props.t('pin_protection')}</span>

                    <Switch
                        className={`me-1 mb-sm-8 ${(userProfile.tenant_active_pin && userProfile.enabled_active_pin) == true ? 'cusCheck' : 'cusCheck2'}`}
                        onColor="#626ed4"
                        disabled={!userProfile.tenant_active_pin}
                        onChange={handleChangeCheck}
                        checked={(userProfile.tenant_active_pin && userProfile.enabled_active_pin) ? true : false}
                        offColor={'#FF0000'}
                    />


                </div>

            </div>
            {pinProtectionChange && <Popup handlePoup={handlePopup} data={pinProtectionChange} pinProtection />}
            {pinActive && <LoginPinInner data={pinActive} />}

        </div>
    )
}

ProfileInner.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ProfileInner);