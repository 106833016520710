import React from 'react'
import { Spinner } from 'react-bootstrap'
import { withTranslation } from 'react-i18next';
const ActionLoader = (props) => {
  return (
    <>
      <button className={props.loaderClass + " loaderButton"} onClick={props.onClick} disabled={typeof props.disabled != 'undefined' ? props.disabled : false}>
        {!props.showLoader && props.t(props.label)}
        {props.showLoader &&
          <span id='loadingBtn'>
            <Spinner className='actionloader' animation="border" size="sm" />
          </span>
        }
      </button >

    </>
  )
}

export default withTranslation()(ActionLoader);
