import { useEffect, useState } from "react"
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper"
import PaymentGrid from "./payment-grid/PaymentGrid"
import PaymentSlider from "./payment-slider/PaymentSlider"
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const initSetting = {
  payment_grid: null,
  class: null,
  default_class: null,
};
const Payment = (props) => {
  const [fnSetting, setFnSetting] = useState(initSetting);
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  const [payment, setPayment] = useState([])
  const getBankList = () => {
    makeAjax(url.USER_API.listTenantBank, {})
      .then(response => {
        if (response.status !== respStatus['SUCCESS']) {
          showMessage(response);
          return;
        }
        setPayment(response.data)
      })
  }
  useEffect(() => {
    getBankList();
  }, []);

  useEffect(() => {
    let temp = { ...fnSetting };
    temp['payment_grid'] = componentSetting?.["payment-method"]?.["default-value"];
    temp['default_class'] = componentSetting?.["payment-method"]?.["default-value"];
    setFnSetting(temp);

  }, [componentSetting?.["payment-method"]]);


  return (
    <>
      {
        fnSetting['payment_grid'] && < div className="payment-method-wrapper">

          <div className={fnSetting['default_class']}>
            {
              fnSetting['payment_grid'] == 'payment-grid-full' && < PaymentGrid />
            }
            {
              fnSetting['payment_grid'] == 'payment-grid-half' && < PaymentGrid />
            }
            {
              fnSetting['payment_grid'] == 'payment-slider' && <PaymentSlider />
            }

          </div>
        </div >


      }
    </>
  )
}

Payment.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Payment);