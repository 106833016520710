import { useSelector } from 'react-redux'
import CommonSec from '../components/commonSection/CommonSec'
import FloatingComponents from '../components/floatingComponents/FloatingComponents'
import ReferralsInner from '../components/referrals-inner/ReferralsInner'
import ReferralsInnerNonLogin from '../components/referrals-inner/ReferralsInnerNonLogin'
const Referral = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    let loggedIn = window.localStorage.getItem("user_token")
    return (
        <>
            {loggedIn &&
                <ReferralsInner />
            }
            {!loggedIn &&
                <ReferralsInnerNonLogin />
            }
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
        </>
    )
}

export default Referral