import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../helpers/global_helper";
import Search from "../games/search/Search";
import BlogItem from "./blogItem/BlogItem";
import { GrSearch } from "react-icons/gr";
import { GoDotFill } from "react-icons/go";
import { CSS_CLASSES } from "../../css-classes";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

const BlogsInner = (props) => {
  const [readmore, setReadmore] = useState(false);

  const { category } = useParams();
  const { page } = useParams();
  const [blogs, setBlog] = useState({
    data: [],
    current_page: 1,
    last_page: 1,
  });
  const [file_path, setFilePath] = useState("");
  const [filterForm, setFilterForm] = useState({
    category: [],
    keyword: "",
    page: 1,
    // per_page: 1
  });

  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));

  const navigate = useNavigate();
  const [blogCategory, setBlogCategory] = useState([]);
  const listPlayerBlogCategory = () => {
    makeAjax(url.USER_API.listPlayerBlogCategory, []).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        setBlogCategory([]);
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setBlogCategory(response.data);
      let t = JSON.parse(JSON.stringify(filterForm));
      t["category"] = [];
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].code == category) {
          t["category"].push(response.data[i]["name"]);
          t["page"] = 1;
          setFilterForm(t);
          break;
        }
      }
    });
  };

  const listBlog = () => {
    makeAjax(url.USER_API.listBlog, filterForm).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setBlog(response.data.data);
      setFilePath(response.data.file_path);

    });
  };

  useEffect(() => {
    listBlog();
  }, [filterForm]);

  useEffect(() => {
    listPlayerBlogCategory();
  }, [category]);

  useEffect(() => {
    if (page != filterForm.page) {
      let t = JSON.parse(JSON.stringify(filterForm));
      t["page"] = page;
      setFilterForm(t);
    }
  }, [page]);

  useEffect(() => {

  }, [blogs]);

  const pushCategory = (v, type = "cat") => {

    let t = JSON.parse(JSON.stringify(filterForm));
    if (type == "cat") {
      // if (t['category'].indexOf(v.name) != -1) {
      //     t['category'].splice(t['category'].indexOf(v.name), 1);
      // } else {
      //     t['category'].push(v.name);
      // }
    } else {
      t.keyword = v.target.value;
      t.page = 1;
    }
    setFilterForm(t);
  };

  const changeCategory = (e, code) => {
    e.preventDefault();

    if (code == category) {
      let t = JSON.parse(JSON.stringify(filterForm));
      t["category"] = [];
      setFilterForm(t);
      navigate("/blogs");
    } else navigate("/blogs/category/1/" + code);
  };

  // Get device type
  let deviceType = localStorage.getItem("device_type");

  // Fetch other media
  const { otherMedia } = useSelector((state) => ({
    otherMedia: state.othermediaInformationListR.data,
  }));

  const initAssetConfig = {
    width: "300px",
    height: "200px",
    alt: "defautl alt",
    description: "default description",
    image: "",
    link: window.location.origin,
  };

  const initBannerAssetConfig = {
    width: 300,
    height: 200,
    alt: "defautl alt",
    description: "default description",
    image: "/assets/images/gohjiyongzz.gif",
    link: window.location.origin,
  };

  const initStyle = {
    width: "",
    height: "",
    backgroundImage: "",
  };

  const [assets, setAssets] = useState(initAssetConfig);
  const [assetsBanner, setAssetsBanner] = useState(initBannerAssetConfig);

  const [style, setStyle] = useState(initStyle);
  // Effect call for manupulate data
  useEffect(() => {
    // Width
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["width"] != "undefined"
    ) {
      assets["width"] = otherMedia[deviceType + "_" + "background"]["width"];
      style["width"] =
        otherMedia[deviceType + "_" + "background"]["width"] + "px";
    }
    // Heigth
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["height"] !=
      "undefined"
    ) {
      assets["height"] = otherMedia[deviceType + "_" + "background"]["height"];
      style["height"] =
        otherMedia[deviceType + "_" + "background"]["height"] + "px";
    }

    // Image path
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["path"] != "undefined"
    ) {
      assets["image"] = otherMedia[deviceType + "_" + "background"]["path"];
      style["backgroundImage"] = `url(${otherMedia[deviceType + "_" + "background"]["path"]
        })`;
    }

    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["link"] != "undefined"
    ) {
      assets["link"] = otherMedia[deviceType + "_" + "background"]["link"];
    }

    setAssetsBanner(assetsBanner);
    setAssets(assets);
    setStyle(style);
  }, [assets, assetsBanner, style]);

  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div
      className="blogs"
      style={
        style["backgroundImage"] != ""
          ? {
            backgroundImage: style["backgroundImage"],
            width: style["width"],
            height: style["height"],
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }
          : {}
      }
    >
      <div className="container">
        <div className="blog-header d-flex justify-content-between">
          {
            componentSetting?.page_title?.options?.section_title && <div className="title">{props.t("blogs")}</div>
          }
          <div className="search-section">
            <form className={CSS_CLASSES.searchBar}>
              <input
                type="text"
                placeholder={props.t("title_blogs")}
                value={filterForm.keyword}
                onChange={(e) => {
                  pushCategory(e, "keyword");
                }}
              />
              <GrSearch />
            </form>
          </div>
        </div>


        <div className="categories-main">
          {blogCategory.map((v, i) => (
            <NavLink
              to={"/blogs/category/1/" + v.code}
              onClick={(e) => {
                changeCategory(e, v.code);
              }}
              key={i}
              className={
                "category-item " +
                (category == v.code ? "selected-category" : "")
              }
            >
              {v.name}
            </NavLink>
          ))}
        </div>

        <div className="blog-items">
          {blogs.data.map((item, index) => (
            <div className="blog-item">
              <div className="blog-img">
                <img alt="Blog" src={file_path + item.system_file_name} />
              </div>
              <div className="blog-content">
                <h4 className="blog-title">{item.title}</h4>
                <div className="posted-on">
                  <span className="date"> {item.post_date.slice(0, 10)}</span>
                  <span className="author">
                    {" "}
                    <GoDotFill /> {item.author}
                  </span>
                  {item.post_tags != null && (
                    <span className="tag"> # {item.post_tags} </span>
                  )}
                </div>
                <div className="blog-text short-desc">
                  <p>{item.text}</p>
                  {item.short_content && (
                    <p
                      dangerouslySetInnerHTML={{ __html: item.short_content }}
                    />
                  )}
                </div>
                <NavLink
                  to={"/blogs/post/" + item.slug}
                  className="read-more-btn read-more-blog"
                >
                  {props.t("read_more")}
                </NavLink>

              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

BlogsInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(BlogsInner);
