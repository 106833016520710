import {
  FETCH_META_INFO,
  FETCH_META_INFO_SUCCESS
} from "./actionTypes"


export const fetchMetaInfo = () => {
  return {
    type: FETCH_META_INFO,
    payload: {},
  }
}

export const fetchMetaInfoSuccess = (response) => {
  return {
    type: FETCH_META_INFO_SUCCESS,
    payload: response,
  }
}