import {
  FETCH_FOOTER_INFO,
  FETCH_FOOTER_INFO_SUCCESS
} from "./actionTypes"


export const fetchFooterInfo = () => {
  return {
    type: FETCH_FOOTER_INFO,
    payload: {},
  }
}

export const fetchFooterInfoSuccess = (response) => {
  return {
    type: FETCH_FOOTER_INFO_SUCCESS,
    payload: response,
  }
}