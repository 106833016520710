import {
  LIST_LANGUAGE_DATA,
  LIST_LANGUAGE_DATA_SUCCESS
} from "./actionTypes"

export const listLanguageData = (selected_language_code) => {
  return {
    type: LIST_LANGUAGE_DATA,
    payload: { selected_language_code },
  }
}

export const listLanguageDataSuccess = (response) => {
  return {
    type: LIST_LANGUAGE_DATA_SUCCESS,
    payload: response,
  }
}