import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { CSS_CLASSES } from "../../../../../css-classes"
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from 'react-router-dom';
import Popup from "../../../../popup/Popup";
import { makeAjax, url, showMessage, respStatus } from "../../../../../helpers/global_helper";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Slider from "react-slick";

const initSetting = {
    provider_grid: null,
    class: null,
    default_class: null,
};

const GameProvider = (props) => {

    // Set & get states
    const [fnSetting, setFnSetting] = useState(initSetting);

    // Device type`
    let deviceType = localStorage.getItem('device_type');

    // set & get state
    const [maintainanceError, setMaintainanceError] = useState(false);
    const [commingSoonPopup, setCommingSoonPopup] = useState(false);
    const [loginforLunchLobby, setLoginforLunchLobby] = useState(false);
    const [hotGameList, setHotGameList] = useState([]);
    const [gameId, setGameId] = useState('');
    const [limitedProvider, setLimitedProvider] = useState([]);
    const [allProviderList, setAllProviderList] = useState([]);
    const [specificClass, setClass] = useState(null);

    // Get code from url
    const code = window.location.pathname.substring(1,);

    const [activeIndex, setActiveIndex] = useState(0);
    const [prevIndex, setPrevIndex] = useState(null);

    // Fetch provider sub menu from redux
    const { providerSubMenuList } = useSelector(state => ({
        providerSubMenuList: state.submenuListR.data
    }));

    // Component Setting 
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    // Use navigate
    const navigate = useNavigate();

    // Hot game list
    const getAllHotGame = () => {
        let payload = {
            category_code: code,
            provider_name: '',
            search_term: '',
        };
        makeAjax(
            url.USER_API.listProviderGame, payload, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus["SUCCESS"]) {
                    if (response.code != "validation_error") {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                }
                setHotGameList(response.data);
            });
    };

    useEffect(() => {
        if (code == 'games') {
            getAllHotGame();
        }
    }, [code]);

    useEffect(() => {
        const status = localStorage.getItem('all_game_click');
        if (status) {
            window.scrollTo(0, 350);
        }
    }, []);

    // Ajax call for game details
    const handleGameClick = (game) => {
        if (game.maintainance) {
            setMaintainanceError(true);
            return;
        }

        const payload = {
            game_id: game._id
        };
        setGameId(
            { ...gameId, game_id: game._id }
        );
        makeAjax(
            url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(response => {
                if (response.status != respStatus["SUCCESS"]) {
                    if (response.code == "game_under_maintenance") {
                        setMaintainanceError(true);
                        return;
                    }

                    if (response.code == "game_not_active") {
                        setCommingSoonPopup(true);
                        return;
                    }

                    if (response.code == "validation.invalid_token_error") {
                        navigate('/register');
                        return;
                    }

                    if (response.code != "validation_error") {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                    return;
                }
                //pg soft game data 7th feb 2024
                if (response?.data?.code === "bd200") {
                    const newTab = window.open();
                    newTab.document.write(response.data.data);
                } else {
                    window.open(response.data, "_blank");
                }
            }
            )
    };

    // Close maintaninace popup
    const handlePopup = () => {
        setMaintainanceError(false);
        setCommingSoonPopup(false);
        setLoginforLunchLobby(false);
    }

    useEffect(() => {
        if (typeof componentSetting?.["game-provider"] != 'undefined') {
            setFnSetting({
                ...fnSetting,
                ['provider_grid']: componentSetting?.["game-provider"]?.["default-value"],
                ['default_class']: componentSetting?.["game-provider"]?.["default-value"],
                ['game_provider_handle']: componentSetting?.custom_loading?.options?.game_provider_handle,
            })
        }
    }, [componentSetting]);

    const showAllProvider = () => {
        navigate('/game-provider');
    }

    const settings = {
        arrows: true,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <IoIosArrowForward />,
        prevArrow: <IoIosArrowBack />,
        beforeChange: (current, next) => {
            setPrevIndex(current);
        },
        afterChange: (current) => {
            setActiveIndex(current);
        },

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false,

                },
            },
        ],
    };

    useEffect(() => {
        if (fnSetting['game_provider_handle'] && Object.values(providerSubMenuList).length > 0) {
            let count = 1;
            let arr = [];
            let allProvider = [];
            let track = [];
            let trackAllProvider = [];
            for (const gameProvider of Object.values(providerSubMenuList)) {
                for (const provider of Object.values(gameProvider)) {
                    if (provider?.sub_provider_id == null) {
                        if (deviceType == 'desktop' && count <= 8) {
                            if (!track.includes(provider?.provider?._id)) {
                                arr.push(provider);
                                track.push(provider?.provider?._id);
                                count++;
                            }
                        }
                        if (deviceType == 'mobile' && count <= 12) {
                            if (!track.includes(provider?.provider?._id)) {
                                arr.push(provider);
                                track.push(provider?.provider?._id);
                                count++;
                            }
                        }
                        if (!trackAllProvider.includes(provider?.provider?._id)) {
                            allProvider.push(provider);
                            trackAllProvider.push(provider?.provider?._id);
                        }
                    }
                }
            };
            setLimitedProvider(arr);
            setAllProviderList(allProvider);
        }

        if (code == 'game-provider')
            document.body.classList.add('see-all-game-provider');
    }, [providerSubMenuList, code, window.location.pathname, fnSetting]);

    return (
        <>
            <div className="game-provider">
                <div className="container">
                    {
                        fnSetting['game_provider_handle'] && <div className="game-header">
                            <div className="title">{props.t('providers')}</div>
                            {
                                code != 'game-provider' && <div className="sub-header" onClick={showAllProvider}>{props.t('see_all')}</div>
                            }
                        </div>
                    }
                    {
                        fnSetting['provider_grid'] && < div className="game-provider-wrapper">
                            <div className={fnSetting['default_class']}>
                                {
                                    fnSetting['provider_grid'] == 'game-provider-grid'
                                    && <div className={CSS_CLASSES.menuItemsHover}>
                                        {code != 'games' && code != '' && Object.values(providerSubMenuList).length > 0 && Object.values(providerSubMenuList).map((v) =>
                                            Object.values(v).length > 0 && Object.values(v).map((obj) =>
                                                (obj.menu_code == code) && <>
                                                    <div className="under-games">
                                                        <NavLink key={obj} to={obj.tenant_provider_reco.provider_name}>
                                                            {obj.maintainance_mode && <div className="undermaintenance">
                                                                {props.t('under_maintanance')}
                                                            </div>}
                                                            <div key={obj} className="item">
                                                                {obj.provider_media != null && obj.provider_media != undefined ? <img className="img-fluid" src={obj.ftp_path + obj.provider_media.base_path + obj.provider_media.system_file_name} alt={obj.tenant_provider_reco.provider_name} /> : <img className="img-fluid" src={'/assets/images/default-images/provider_' + deviceType + ".png"} alt={obj.tenant_provider_reco.provider_name} />}
                                                                {
                                                                    obj.tenant_provider_reco.provider_name.split('').length > 15 ?
                                                                        <marquee><h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6></marquee>
                                                                        : <h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6>
                                                                }
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </>
                                            )
                                        )}

                                        {code != 'games' && code == '' && Object.values(providerSubMenuList).length > 0 && Object.values(providerSubMenuList).map((v) =>
                                            Object.values(v).length > 0 && Object.values(v).map((obj) =>
                                                <>
                                                    <div className="under-games">
                                                        <NavLink key={obj} to={obj.menu_code == 'lottery' ? 'lotteryHome/' + obj.tenant_provider_reco.provider_name : obj.menu_code + '/' + obj.tenant_provider_reco.provider_name}>
                                                            {obj.maintainance_mode && <div className="undermaintenance">
                                                                {props.t('under_maintanance')}
                                                            </div>}
                                                            <div key={obj} className="item">
                                                                {obj.provider_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.provider_media.base_path + obj.provider_media.system_file_name} alt={obj.tenant_provider_reco.provider_name} /> : <img className="img-fluid" src={'/assets/images/default-images/provider_' + deviceType + ".png"} alt={obj.tenant_provider_reco.provider_name} />}
                                                                {
                                                                    obj.tenant_provider_reco.provider_name.split('').length > 15 ?
                                                                        <marquee><h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6></marquee>
                                                                        : <h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6>
                                                                }
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </>
                                            )
                                        )}

                                        {code == 'games' && hotGameList && typeof hotGameList.gameInfo != 'undefined' && hotGameList.gameInfo.length > 0 && hotGameList.gameInfo.map((obj) =>
                                            <>
                                                <div onClick={() => { handleGameClick(obj) }} className="under-games">
                                                    <NavLink key={obj}>
                                                        {obj.maintainance_mode && <div className="undermaintenance">
                                                            {props.t('under_maintanance')}
                                                        </div>}
                                                        <div key={obj} className={`item ${obj.is_hot_game ? 'overlay-image' : ''}`} >
                                                            <span className="promo">{'Hot'}</span>
                                                            {obj.game_assets_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.game_assets_media.base_path + obj.game_assets_media.system_file_name} alt="Cverlay image" /> : <img className="img-fluid" src={'/assets/images/default-images/game_' + deviceType + ".png"} alt={obj.name} />}
                                                            {
                                                                obj.name.split('').length > 15 ?
                                                                    <marquee><h6 key={obj} className="item-name">{obj.name}</h6></marquee>
                                                                    : <h6 key={obj} className="item-name">{obj.name}</h6>
                                                            }
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                }
                                {
                                    fnSetting['provider_grid'] == 'game-provider-carousel'
                                    && code != 'game-provider'
                                    && <div className="provider-list">
                                        <Slider {...settings}>
                                            {limitedProvider.length > 0 && limitedProvider.map((obj) =>
                                                <>
                                                    {
                                                        obj.sub_provider_id == null && <div className="under-games">
                                                            <NavLink key={obj} to={obj.menu_code == 'lottery' ? 'lotteryHome/' + obj.tenant_provider_reco.provider_name : '/' + obj.tenant_provider_reco.provider_name}>
                                                                <div key={obj} className="item">
                                                                    {obj.provider_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.provider_media.base_path + obj.provider_media.system_file_name} alt={obj.tenant_provider_reco.provider_name} /> : <img className="img-fluid" src={'/assets/images/default-images/providersm_' + deviceType + ".png"} alt={obj.tenant_provider_reco.provider_name} />}
                                                                    {code !== '' && (
                                                                        <>
                                                                            {
                                                                                obj.tenant_provider_reco.provider_name.split('').length > 15 ?
                                                                                    <marquee><h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6></marquee>
                                                                                    : <h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    }
                                                </>
                                            )}
                                        </Slider>
                                    </div>
                                }
                                {
                                    fnSetting['game_provider_handle']
                                    && code == 'game-provider'
                                    && <div className={`game-provider-wrapper ${specificClass}`}>
                                        <div className="game-provider-grid"  >
                                            <div className={CSS_CLASSES.menuItemsHover}>
                                                {
                                                    allProviderList.length > 0 && allProviderList.map((obj) =>
                                                        <>
                                                            {
                                                                <div className="under-games">
                                                                    <NavLink key={obj} to={obj.menu_code == 'lottery' ? 'lotteryHome/' + obj.tenant_provider_reco.provider_name : '/' + obj.tenant_provider_reco.provider_name}>
                                                                        <div key={obj} className="item">
                                                                            {obj.provider_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.provider_media.base_path + obj.provider_media.system_file_name} alt={obj.tenant_provider_reco.provider_name} /> : <img className="img-fluid" src={'/assets/images/default-images/providersm_' + deviceType + ".png"} alt={obj.tenant_provider_reco.provider_name} />}
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div >
                    }
                </div>
            </div>
            {maintainanceError && <Popup handlePoup={handlePopup} providerMaintainance />}
            {commingSoonPopup && <Popup handlePoup={handlePopup} commingSoonGame />}
        </>
    )
}

GameProvider.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(GameProvider);


