import {
  GET_LOGOUT,
  GET_LOGOUT_SUCCESS
} from "./actionTypes"


export const getLogout = () => {
  return {
    type: GET_LOGOUT,
    payload: {},
  }
}

export const getLogoutSuccess = (response) => {
  return {
    type: GET_LOGOUT_SUCCESS,
    payload: response,
  }
}