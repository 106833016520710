import { useState, useEffect } from 'react'
import { CgMenuGridR } from 'react-icons/cg'
import { FaBackspace, FaArrowCircleLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom';
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper";
import Popup from "../../popup/Popup";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerProfileDetail } from '../../../store/actions';
import { checkPinStatus } from '../../../store/check-pin-status/actions';
import ActionLoader from '../../../pages/ActionLoader';
const LoginPinInner = (props) => {

    const navigate = useNavigate();
    const [loaderProcessStatus, setProcessLoader] = useState(false);
    const [loaderOutStatus, setOutLoader] = useState(false);

    const InitFormData = {
        pin: '',
        pin_confirmation: '',
        type: 'login-pin'
    }

    const location = useLocation();
    const dispatch = useDispatch();

    const { userProfile, pinStatus, redirect } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
        pinStatus: state.checkPinStatusR.data,
        redirect: state.checkPinStatusR.redirect
    }));



    const redirectMe = (pinStatus, path, component_location) => {

        if (Object.keys(pinStatus).length) {
            let config_status = pinStatus.get_tenant_config?.value;
            if (config_status === true && pinStatus.enabled_active_pin === true && pinStatus.active_pin === false && component_location == path) {
                return;
            } else if (config_status === true && pinStatus.enabled_active_pin === true && pinStatus.is_pin_verified === false) {
                navigate("/security-validation");
                return;
            }
            else if ((pinStatus.is_security_question_set === false || pinStatus.is_security_question_set === null)) {
                navigate("/security-question");
                return;
            }
            else if (pinStatus.is_password_active === false) {
                navigate("/reminder-password");
                return;
            } else if (config_status === true && ((pinStatus.enabled_active_pin === true && pinStatus.active_pin === true) || pinStatus.enabled_active_pin === false) && component_location == path) {
                navigate("/");
                return;
            } else if (!config_status) {
                navigate("/");
                return;
            }
        }
    }

    useEffect(() => {
        redirectMe(pinStatus, location.pathname, '/login-pin')
    }, [pinStatus]);

    const [formData, setFormData] = useState(InitFormData)
    const [formError, setFormError] = useState({});
    const [pinBox1, setPinBox1] = useState(false)
    const [pinBox2, setPinBox2] = useState(false)
    const [pinProtectionChange, setPinProtectionChange] = useState(false)

    const [disabledNum1, setDisabledNum1] = useState(true)
    const [disabledNum2, setDisabledNum2] = useState(true)

    const numPad1 = (e) => {
        const numMain1 = formData.pin + e.target.id
        if (numMain1.length > 6) {
            return false;
        }
        if (numMain1.length == 6) {
            setDisabledNum1(false)
        }
        setFormData({ ...formData, ...{ pin: numMain1 } })
    }

    const numPad2 = (e) => {
        const numMain2 = formData.pin_confirmation + e.target.id
        if (numMain2.length > 6) {
            return false;
        }
        if (numMain2.length == 6) {
            setDisabledNum2(false)
        }
        setFormData({ ...formData, ...{ pin_confirmation: numMain2 } })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessLoader(true);
        makeAjax(
            url.USER_API.setUserNewPin,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setProcessLoader(false);
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setPinProtectionChange(true)
                    setFormError(response.messages);
                    return;
                }
                setFormError({});
                setFormData({ ...formData, ...{ pin: '', pin_confirmation: '' } });
                dispatch(checkPinStatus());
                // navigate("/");
                // navigate(0);
                showMessage(response);
                setProcessLoader(false);
            });
    }

    const numPadClear1 = (e) => {
        setFormData({ ...formData, ...{ pin: formData.pin.slice(0, -1) } })
        setDisabledNum1(true)
    }
    const numPadClear2 = () => {
        setFormData({ ...formData, ...{ pin_confirmation: formData.pin_confirmation.slice(0, -1) } })
        setDisabledNum2(true)
    }

    const handlePopup = () => {
        setFormData(InitFormData)
        setPinProtectionChange(false)
    }
    // Logout api call
    const logout = () => {
        setOutLoader(true);
        if (userProfile.is_pin_verified === true) {
            setOutLoader(false);
            navigate("/profile");
            return;
        } else {
            makeAjax(
                url.USER_API.logout, [], url.USER_MS_EXT).then(response => {
                    if (response.status != respStatus['SUCCESS']) {
                        setOutLoader(false);
                        showMessage(response);
                        return;
                    }
                });
        }
        window.localStorage.removeItem("user_token");
        setOutLoader(false);
        navigate('/')
    }

    const [numbers, setNumbers] = useState([]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    useEffect(() => {
        const shuffledNumbers = shuffleArray([...Array(10).keys()]);
        setNumbers(shuffledNumbers);
        dispatch(getPlayerProfileDetail());
    }, []);

    const closeBox1 = () => {
        setPinBox1(false);
        setFormData({ ...formData, pin: '' })
        const shuffledNumbers = shuffleArray([...Array(10).keys()]);
        setNumbers(shuffledNumbers);
        setDisabledNum1(true);
    }
    const closeBox2 = () => {
        setPinBox2(false);
        setFormData({ ...formData, pin_confirmation: '' })
        const shuffledNumbers = shuffleArray([...Array(10).keys()]);
        setNumbers(shuffledNumbers);
        setDisabledNum2(true);
    }
    return (
        <div className='new-auth login-pin'>
            <div className="new-pass-box mt-5">
                <h4 className="title">{props.t('welcome')}, {userProfile.username}</h4>
                <div className="new-pass-box-inner">
                    <p className="desc">{props.t('this_system_is_to_increase_security_and_privacy_of_your_account')}</p>
                    <form>
                        <div className="password-icon">
                            <input type="password" placeholder={props.t('enter_your_new_pin')} value={formData.pin} onClick={() => (setPinBox1(curr => !curr), setPinBox2(false))} />
                            <CgMenuGridR onClick={() => (setPinBox1(curr => !curr), setPinBox2(false))} />
                            {pinBox1 &&
                                <div className="pin-box">
                                    <input type="password" placeholder={props.t('pin')} name="pin" value={formData.pin} onChange={() => { }} maxLength={6} pattern="[^\s]+" />
                                    <div className="enter-pin">
                                        <div className="num-box">
                                            {numbers.map(number => (
                                                <span key={number} className="num" id={number} onClick={numPad1}>{number}</span>
                                            ))}
                                        </div>
                                        <div className="button-box">
                                            <button className='okay' onClick={() => setPinBox1(false)} disabled={disabledNum1}>{props.t('okay')}</button>
                                            <span className="back" onClick={numPadClear1}><FaBackspace /></span>
                                            <span className='close' onClick={closeBox1}>{props.t('close')}</span>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="password-icon">
                            <input type="password" placeholder={props.t('confirm_new_pin')} value={formData.pin_confirmation} onClick={() => (setPinBox2(curr => !curr), setPinBox1(false))} />
                            <CgMenuGridR onClick={() => (setPinBox2(curr => !curr), setPinBox1(false))} />
                            {pinBox2 &&
                                <div className="pin-box">
                                    <input type="password" placeholder={props.t('pin')} name="pin_confirmation" value={formData.pin_confirmation} onChange={() => { }} maxLength={6} pattern="[^\s]+" />
                                    <div className="enter-pin">
                                        <div className="num-box">
                                            {numbers.map(number => (
                                                <span key={number} className="num" id={number} onClick={numPad2}>{number}</span>
                                            ))}
                                        </div>
                                        <div className="button-box">
                                            <button className='okay' onClick={() => setPinBox2(false)} disabled={disabledNum2}>{props.t('okay')}</button>
                                            <span className="back" onClick={numPadClear2}><FaBackspace /></span>
                                            <span className='close' onClick={closeBox2}>{props.t('close')}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <ActionLoader label={'log_out'} loaderClass={'cancel login-pin-cancel'} showLoader={loaderOutStatus} onClick={logout} />
                        <ActionLoader label={'process'} loaderClass={'login-pin-process'} showLoader={loaderProcessStatus} onClick={(e) => { handleSubmit(e) }} />

                    </form>
                    <span className="note">{props.t('this_pin_will_be_used_every_time_you_enter_the_game')}</span>
                </div>
            </div>
            {pinProtectionChange && <Popup handlePoup={handlePopup} data={[formError]} setNewPin />}
        </div>
    )
}

LoginPinInner.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(LoginPinInner);