import { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { MdDeleteOutline, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { RiInboxFill } from "react-icons/ri";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  dateFormat,
} from "../../../helpers/global_helper";
import ReplyMessage from "./reply-message/ReplyMessage";
import Popup from "../../popup/Popup";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ActionLoader from "../../../pages/ActionLoader";
import { useDispatch } from "react-redux";
import { getMemoCount } from "../../../store/actions";

const MemoInner = (props) => {
  const dispatch = useDispatch();
  // Form initialization
  const initForm = {
    subject: "",
    message: "",
    username: "",
  };

  const InitFilterPage = {
    per_page: 5,
    page: 1,
  };

  const InitFilterPageOutbox = {
    per_page: 5,
    page: 1,
  };

  // Set & get data
  const [sendMsg, setSendMsg] = useState(false);
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState(initForm);
  const [clientDetails, setClientDetails] = useState([]);
  const [playerDetails, setPalyerDetails] = useState([]);
  const [playerInboxMessage, setPlayerInboxMessage] = useState([]);
  const [playerOutboxMessage, setPlayerOutboxMessage] = useState([]);
  const [playerOutboxPagination, setPlayerOutboxPagination] =
    useState(InitFilterPageOutbox);
  const [playerInboxPagination, setPlayerInboxPagination] =
    useState(InitFilterPage);
  const [singleFieldData, setSingleFieldData] = useState(InitFilterPageOutbox);
  const [singleFieldDataInbox, setSingleFieldInboxData] =
    useState(InitFilterPage);
  const [replyMessage, setReplyMessage] = useState(false);
  const [inMsg, setInMsg] = useState(true);
  const [outMsg, setOutMsg] = useState(false);
  const [rowData, setRowData] = useState({});
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [sendMessage, setSendMessage] = useState(false);
  const [durationPopup, setDurationPopup] = useState(false);
  const [newMessageCount, setNewMessageCount] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteID] = useState([]);
  const [clickReadStatus, setClickReadStatus] = useState(true);
  const [latestMessage, setLatestMessage] = useState({});
  const [delete_popup, setDeletePopup] = useState(false);
  const [waitMessage, setWaitMessage] = useState({});
  const [loaderStatus, setLoader] = useState(false);
  const [select_all, setSelectAll] = useState(false);
  const [select_all_outbox, setSelectAllOutbox] = useState(false);
  const [multiCheck, setMultiCheck] = useState({});
  const [multiCheckOutbox, setMultiCheckOutbox] = useState({});
  const [showWarning, setWarning] = useState(false);
  const [showSuccess, setSuccess] = useState(false);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Tenannt or client profile details based on domain
  const getClientDetails = (event) => {
    makeAjax(url.ADMIN_API.getClientDetails, formData, url.ADMIN_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
          }
          setFormError(response.messages);
          return;
        }
        setClientDetails(response.data);
      }
    );
  };

  // Get player unread message count
  const getUnreadMessageCount = (event) => {
    makeAjax(
      url.MEMO_API.getPlayerUnreadMessageCount,
      [],
      url.MEMO_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        setFormError(response.messages);
        return;
      }
      setUnreadMessageCount(response.data);
    });
  };

  // Player inbox message details
  const getPlayerInboxMessageList = (event) => {
    makeAjax(url.MEMO_API.listPlayerInboxMessage, [], url.MEMO_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
          }
          showMessage(response);
          return;
        }

        setNewMessageCount(true);

        if (event == "deleteMemo") setNewMessageCount(false);

        setClickReadStatus(false);
        if (clickReadStatus) {
          setClickReadStatus(true);
        }

        setSelectAll(false);
        setMultiCheck({});
        setPlayerInboxMessage(response.data);
      }
    );
  };

  // Player outbox message list
  const getPlayerOutMessageList = (event) => {
    makeAjax(url.MEMO_API.listPlayerOutboxMessage, [], url.MEMO_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
          }
          showMessage(response);
          return;
        }

        if (!inMsg) {
          setNewMessageCount(true);
        }
        if (event == "deleteMemo") setNewMessageCount(false);

        setSelectAllOutbox(false);
        setMultiCheckOutbox({});
        setPlayerOutboxMessage(response.data);
      }
    );
  };

  // Player profile details
  const getPlayerDetails = (event) => {
    makeAjax(
      url.USER_API.getPlayerProfileDetail,
      formData,
      url.USER_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        setFormError(response.messages);
        return;
      }
      setPalyerDetails(response.data);
    });
  };

  // Send message
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    makeAjax(url.MEMO_API.playerComposeMessage, formData, url.MEMO_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          setLoader(false);
          if (response.status != "send_email_duration_error") {
            setDurationPopup(true);
            return;
          }
          if (response.code != "validation_error") {
            showMessage(response);
          }
          setFormError(response.messages);
          return;
        }
        setLoader(false);
        setFormData(initForm);
        setFormError({});
        showMessage(response);
      }
    );
  };

  // Effect List
  useEffect(() => {
    getClientDetails();
    getPlayerDetails();
    getPlayerInboxMessageList();
    getPlayerOutMessageList();
    getUnreadMessageCount();
    setClickReadStatus(true);
  }, []);

  useEffect(() => {
    getUnreadMessageCount();
  }, [unreadMessageCount]);

  useEffect(() => {
    getUnreadMessageCount();
    getPlayerInboxMessageList();
    const interval1 = setInterval(() => getUnreadMessageCount(), 60000);
    const interval2 = setInterval(() => getPlayerInboxMessageList(), 60000);
    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      setClickReadStatus(true);
    };
  }, []);

  // Input event
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      ["username"]: playerDetails["username"],
    });
  };

  // On click reply open
  const replyMsg = (event) => {
    makeAjax(
      url.MEMO_API.playerUpdateMessageStatus,
      { _id: event._id },
      url.MEMO_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        setFormError(response.messages);
        return;
      }
      getUnreadMessageCount();
      dispatch(getMemoCount());
    });

    setReplyMessage(true);
    setRowData(event);
    setWaitMessage(
      `${props.t(
        "you_have_just_sended_the_memo_please_wait_for_the_two_minutes_to_send_another_memo"
      )}.${props.t("thank_you")}`
    );
  };

  // On click reply close
  const replyMsgClose = () => {
    setReplyMessage(false);
    setRowData({});
    setSendMessage(true);
    setInMsg(true);
    setOutMsg(false);
    setClickReadStatus(true);
    getPlayerInboxMessageList();
  };

  // Outbox message goto filter
  const gotoPageOutbox = (page = 1) => {
    let payload = singleFieldData;
    payload["page"] = page;
    setPlayerOutboxPagination(payload);
    makeAjax(
      url.MEMO_API.listPlayerOutboxMessage,
      payload,
      url.MEMO_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        showMessage(response);
        return;
      }
      setPlayerOutboxMessage(response.data);
    });
  };

  // Inbox message goto filter
  const gotoPageInbox = (page = 1) => {
    let payload = singleFieldDataInbox;
    payload["page"] = page;
    setPlayerInboxPagination(payload);
    makeAjax(
      url.MEMO_API.listPlayerInboxMessage,
      payload,
      url.MEMO_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        showMessage(response);
        return;
      }
      setPlayerInboxMessage(response.data);
    });
  };

  // Outbox filter pagination
  const filterChangeOutbox = (event) => {

    let val = event.target.value;
    if (isNaN(val)) {
      val = "";
    } else {
      if (val != "") val = parseInt(val);
    }

    if (event.target.name == "page" && val > playerOutboxMessage.last_page)
      val = parseInt(playerOutboxMessage.last_page);

    if (event.target.name == "per_page") {
      singleFieldData["per_page"] = val;
    } else {
      singleFieldData["page"] = val;
    }
    setPlayerOutboxPagination(singleFieldData);
    makeAjax(
      url.MEMO_API.listPlayerOutboxMessage,
      singleFieldData,
      url.MEMO_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        showMessage(response);
        return;
      }
      setPlayerOutboxMessage(response.data);
    });
  };

  // Inbox filter pagination
  const filterChangeInbox = (event) => {

    let val = event.target.value;
    if (isNaN(val)) {
      val = "";
    } else {
      if (val != "") val = parseInt(val);
    }

    if (event.target.name == "page" && val > playerInboxMessage.last_page)
      val = parseInt(playerInboxMessage.last_page);

    if (event.target.name == "per_page") {
      singleFieldDataInbox["per_page"] = val;
    } else {
      singleFieldDataInbox["page"] = val;
    }
    setPlayerInboxPagination(singleFieldDataInbox);
    makeAjax(
      url.MEMO_API.listPlayerInboxMessage,
      singleFieldDataInbox,
      url.MEMO_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        showMessage(response);
        return;
      }
      setPlayerInboxMessage(response.data);
    });
  };

  // Onclick incoming mesage tab
  const updateIncommingMessage = (event) => {
    setDeleteID([]);
    setSingleFieldInboxData(InitFilterPage);
    setPlayerInboxPagination(InitFilterPage);
    getPlayerInboxMessageList();
    setInMsg(true);
    setOutMsg(false);
    setSendMsg(false);
    setReplyMessage(false);
    setRowData({});
    setClickReadStatus(true);
  };

  // Onclick outgoing mesage tab
  const updateOutgoingMessage = (event) => {
    setDeleteID([]);
    setSingleFieldData(InitFilterPageOutbox);
    setPlayerOutboxPagination(InitFilterPageOutbox);
    setSendMessage(false);
    setDurationPopup(false);
    setNewMessageCount(false);
    getPlayerOutMessageList();
    setInMsg(false);
    setOutMsg(true);
    setSendMsg(false);
    setReplyMessage(false);
    setRowData({});
    setClickReadStatus(true);
  };

  // Onclick create new mesage
  const composeMessage = (event) => {
    setDeleteID([]);
    setSendMessage(false);
    setDurationPopup(false);
    setNewMessageCount(false);
    setFormData({});
    setInMsg(false);
    setOutMsg(false);
    setSendMsg(true);
    setReplyMessage(false);
    setRowData({});
    setClickReadStatus(true);
    setWaitMessage(
      `${props.t(
        "you_have_just_sended_the_memo_please_wait_for_a_reply_from_our_operator"
      )}.${props.t("thank_you")}`
    );
  };

  // Set false popup
  const handlePopup = () => {
    setSendMessage(false);
    setDurationPopup(false);
    setNewMessageCount(false);
    setShowDelete(false);
    setWarning(false);
    setSuccess(false);
  };

  // Set true email duaration validation popup
  const emailDuration = () => {
    setDurationPopup(true);
  };

  // Set true email duaration validation popup
  const handleEmailDurationPopupOn = () => {
    setDurationPopup(false);
  };

  // Handle new message count
  const handleNewMessageCount = () => {
    setNewMessageCount(false);
    setDurationPopup(true);
  };

  // Close delete popup
  const deleteMessageConfirm = () => {
    setDeleteID([]);
    setShowDelete(false);
  };

  // Refresh inbox listing
  const refreshInboxList = () => {
    setInMsg(true);
    setOutMsg(false);
    setSendMsg(false);
    setReplyMessage(false);
    setNewMessageCount(false);
    setUnreadMessageCount(0);
    setClickReadStatus(false);
  };

  // Pagination
  const gotoInboxListing = () => {
    setReplyMessage(false);
    setInMsg(true);
    setOutMsg(false);
    setSendMsg(false);
    getPlayerInboxMessageList();
  };

  // Multiselect inbox array
  let selectedEntryObj = {};
  let allSiteData = {};
  useEffect(() => {
    selectedEntryObj = {};
    allSiteData = {};

    if (typeof playerInboxMessage.data != "undefined") {
      for (let i = 0; i < playerInboxMessage.data.length; i++) {
        selectedEntryObj[playerInboxMessage.data[i]["_id"]] = false;
        allSiteData[playerInboxMessage.data[i]["_id"]] = JSON.parse(
          JSON.stringify(playerInboxMessage.data[i])
        );
      }
      setMultiCheck(selectedEntryObj);
    }
  }, [playerInboxMessage]);

  // Multiselect outbox array
  let selectedOutboxEntryObj = {};
  let allSiteOutboxData = {};
  useEffect(() => {
    selectedOutboxEntryObj = {};
    allSiteOutboxData = {};

    if (typeof playerOutboxMessage.data != "undefined") {
      for (let i = 0; i < playerOutboxMessage.data.length; i++) {
        selectedOutboxEntryObj[playerOutboxMessage.data[i]["_id"]] = false;
        allSiteOutboxData[playerOutboxMessage.data[i]["_id"]] = JSON.parse(
          JSON.stringify(playerOutboxMessage.data[i])
        );
      }
      setMultiCheckOutbox(selectedOutboxEntryObj);
    }
  }, [playerOutboxMessage]);

  // Check / uncheck records
  const checkUnCheckMe = (_id, type) => {
    let check = {};
    if (type == "inbox") {
      check[_id] = !multiCheck[_id];
      setMultiCheck({ ...multiCheck, ...check });
    }
    if (type == "outbox") {
      check[_id] = !multiCheckOutbox[_id];
      setMultiCheckOutbox({ ...multiCheckOutbox, ...check });
    }
  };

  // Select all records
  const selectAll = (type) => {
    if (type == "inbox") {
      for (let i = 0; i < playerInboxMessage.data.length; i++) {
        selectedEntryObj[playerInboxMessage.data[i]["_id"]] = !select_all;
      }
      setMultiCheck(selectedEntryObj);
      setSelectAll(!select_all);
    }

    if (type == "outbox") {
      for (let i = 0; i < playerOutboxMessage.data.length; i++) {
        selectedOutboxEntryObj[playerOutboxMessage.data[i]["_id"]] =
          !select_all_outbox;
      }
      setMultiCheckOutbox(selectedOutboxEntryObj);
      setSelectAllOutbox(!select_all_outbox);
    }
  };

  // Click on delete button
  const deleteMe = (v) => {
    let memoId = [];
    if (v == "inbox") {
      let keys = Object.keys(multiCheck);
      for (let i = 0; i < keys.length; i++) {
        if (multiCheck[keys[i]]) {
          memoId.push(keys[i]);
        }
      }
    }

    if (v == "outbox") {
      let keys = Object.keys(multiCheckOutbox);
      for (let i = 0; i < keys.length; i++) {
        if (multiCheckOutbox[keys[i]]) {
          memoId.push(keys[i]);
        }
      }
    }

    if (typeof v._id != "undefined") memoId.push(v._id);

    if (!memoId.length) {
      setWarning(true);
      setFormError({});
      return;
    }
    setDeleteID(memoId);
    setShowDelete(true);
  };

  // Confirm deleting records
  const deleteConfirmMessage = () => {
    let payload = {
      _id: deleteId,
    };
    makeAjax(url.MEMO_API.deletePlayerMessage, payload, url.MEMO_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
          }
          showMessage(response);
          return;
        }
        setNewMessageCount(false);
        setShowDelete(false);
        setSuccess(true);
        setDeleteID([]);
        getUnreadMessageCount();
        dispatch(getMemoCount());
        getPlayerInboxMessageList("deleteMemo");
        getPlayerOutMessageList("deleteMemo");
      }
    );
  };
  return (
    <>
      <div className="memo">
        <div className="container">
          <div className="memo-bg">
            <div className="memo-box">
              <div className="user-div">

                <div className="user-profile">
                  <FaUser className="user-face" />
                  <div className="user-details">
                    <span className="username">{playerDetails.username}</span>
                    <span className="email">{playerDetails.email_id}</span>
                  </div>
                </div>
                <div className="title-box title-box-color">
                  {sendMsg && (
                    <div className="title-box title-box-color">
                      <h3 className="text-create-message uppercase">
                        {props.t("create_message")}
                      </h3>
                    </div>
                  )}

                  {!replyMessage && inMsg && (
                    <div className="title-box title-box-color">
                      <h3 className="text-create-message uppercase">
                        {props.t("notification")}
                      </h3>
                    </div>
                  )}

                  {!replyMessage && outMsg && (
                    <div className="title-box title-box-color">
                      <h3 className="text-create-message uppercase">
                        {props.t("outbox")}
                      </h3>
                    </div>
                  )}

                  {replyMessage && (
                    <div className="title-box title-box-color">
                      <h3 className="text-create-message uppercase">
                        {props.t("read_message")}
                      </h3>
                    </div>
                  )}
                </div>
              </div>
              <div className="indox-div">

                <div className="message-box">
                  <div
                    className={`message-option ${inMsg && "active"}`}
                    onClick={updateIncommingMessage}
                  >
                    <p>
                      <RiInboxFill /> {props.t("notification")}
                    </p>
                    <span className="count">{unreadMessageCount}</span>
                  </div>
                </div>



                <div className="table-div">
                  {sendMsg === true ? (
                    <form className="create-message">
                      <div className="to-box">
                        <label for="recipient" className="w-1/12 to-text">
                          To
                        </label>
                        <input
                          className="domain-to"
                          type="text"
                          value={clientDetails.domain_name}
                          readOnly
                        />
                      </div>
                      <div className="to-box">
                        <label for="title" className="w-1/12 to-text">
                          {props.t("subject")}
                        </label>
                        <input
                          className="domain-to"
                          type="text"
                          placeholder="Subject"
                          value={formData.subject}
                          name="subject"
                          onChange={handleChange}
                        />
                        {formError.subject ? (
                          <span className="text-danger-red">
                            {props.t("hello")}
                            {formError.subject[0]}
                          </span>
                        ) : null}
                      </div>
                      <div className="to-box">
                        <label for="title" className="w-1/12 to-text">
                          {props.t("message")}
                        </label>
                        <textarea
                          className="msg-text1"
                          placeholder="Message"
                          value={formData.message}
                          name="message"
                          onChange={handleChange}
                        ></textarea>
                        {formError.message ? (
                          <span className="text-danger-red1">
                            {formError.message[0]}
                          </span>
                        ) : null}
                      </div>
                      <div className="back-send-btn-box">
                        <BsFillArrowLeftCircleFill
                          onClick={gotoInboxListing}
                          className="arrow-message-icon"
                        />
                        {/* <Button className='send-button' onClick={handleSubmit} >{props.t('send')}</Button> */}
                        <ActionLoader
                          label={"send"}
                          loaderClass={"send-button"}
                          showLoader={loaderStatus}
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  ) : replyMessage === true ? (
                    <>
                      <ReplyMessage
                        replyMsgClose={replyMsgClose}
                        gotoInboxListing={gotoInboxListing}
                        emailDuration={emailDuration}
                        rowData={rowData}
                        type={inMsg}
                      />
                    </>
                  ) : (
                    <>
                      <table>
                        <thead>
                          {inMsg && (
                            <>
                              <tr>
                                <th
                                  style={{ fontSize: "50px", width: "100px" }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={select_all}
                                    onChange={() => { }}
                                    onClick={() => {
                                      selectAll("inbox");
                                    }}
                                  />
                                </th>
                                <th className="cusWidth1">
                                  {props.t("operator")}
                                </th>
                                <th className="cusWidth">
                                  {props.t("subject")}
                                </th>
                                <th className="cusWidth1">
                                  {props.t("date")}/{props.t("time")}
                                </th>
                                <th className="cusWidth1">
                                  <p
                                    className="mass-delete"
                                    onClick={() => {
                                      deleteMe("inbox");
                                    }}
                                  >
                                    {props.t("delete_all")}
                                    <MdDeleteOutline />
                                  </p>
                                </th>
                              </tr>
                            </>
                          )}


                        </thead>
                        {inMsg && (
                          <tbody>
                            {typeof playerInboxMessage.data != "undefined" &&
                              playerInboxMessage.data.length > 0 &&
                              playerInboxMessage.data.map((v, i) => (
                                <tr key={i}>
                                  <td style={{ fontSize: "10px" }}>
                                    <input
                                      type="checkbox"
                                      id="checkbor_arr"
                                      name="checkbor_arr"
                                      checked={multiCheck[v._id]}
                                      onChange={() => { }}
                                      onClick={() => {
                                        checkUnCheckMe(v._id, "inbox");
                                      }}
                                    />
                                  </td>
                                  <td
                                    className="zero"
                                    onClick={() => replyMsg(v)}
                                  >
                                    {v.domain_name}
                                  </td>
                                  {!v.status && (
                                    <td
                                      onClick={() => replyMsg(v)}
                                      className="subject-len zero"
                                    >
                                      {v.subject}
                                    </td>
                                  )}

                                  {v.status && (
                                    <td
                                      onClick={() => replyMsg(v)}
                                      className="subject-len zero"
                                    >
                                      {v.subject}
                                    </td>
                                  )}

                                  <td
                                    className="memodate"
                                    style={{ color: "var(--vs-gray1)" }}
                                    onClick={() => replyMsg(v)}
                                  >
                                    {v.created_at}
                                  </td>
                                  <td
                                    className="memodate"
                                    onClick={() => deleteMe(v)}
                                  >
                                    <MdDeleteOutline />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        )}


                      </table>
                    </>
                  )}
                  {inMsg && (
                    <table className="pagenation-table">
                      <tbody>
                        <tr>
                          <td className="col-md-auto">
                            <select
                              className="form-select"
                              name="per_page"
                              value={playerInboxPagination.per_page}
                              onChange={filterChangeInbox}
                              style={{
                                appearance: "none",
                                paddingRight: "20px",
                              }}
                            >
                              {[5, 10, 15, 50, 100, 200, 500].map(
                                (pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {"Show"} {pageSize}
                                  </option>
                                )
                              )}
                            </select>

                          </td>
                          <td className="col-md-auto">
                            <div className="d-flex gap-1 button-left">
                              <button
                                color="primary"
                                onClick={() => gotoPageInbox(1)}
                                disabled={playerInboxMessage.current_page == 1}
                              >
                                {"<<"}
                              </button>
                              <button
                                color="primary"
                                onClick={() =>
                                  gotoPageInbox(
                                    playerInboxMessage.current_page - 1
                                  )
                                }
                                disabled={playerInboxMessage.current_page == 1}
                              >
                                {"<"}
                              </button>
                            </div>
                          </td>
                          <td className="col-md-auto d-md-block">
                            {props.t("page")}{" "}
                            <strong>
                              {playerInboxMessage.current_page} {"of"}{" "}
                              {Math.ceil(
                                playerInboxMessage.total /
                                playerInboxMessage.per_page
                              )}
                            </strong>
                          </td>
                          <td className="col-md-auto">
                            <input
                              type="text"
                              min={1}
                              max={playerInboxMessage.last_page}
                              value={playerInboxPagination.page}
                              name="page"
                              className="input-no"
                              onChange={filterChangeInbox}
                            />
                          </td>
                          <td className="col-md-auto">
                            <div className="d-flex gap-1 button-right">
                              <button
                                color="primary"
                                onClick={() =>
                                  gotoPageInbox(
                                    playerInboxMessage.current_page + 1
                                  )
                                }
                                disabled={
                                  playerInboxMessage.current_page ==
                                  playerInboxMessage.last_page
                                }
                              >
                                {">"}
                              </button>
                              <button
                                color="primary"
                                onClick={() =>
                                  gotoPageInbox(playerInboxMessage.last_page)
                                }
                                disabled={
                                  playerInboxMessage.current_page ==
                                  playerInboxMessage.last_page
                                }
                              >
                                {">>"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {sendMessage && <Popup handlePoup={handlePopup} sendEmail />}
        {durationPopup && (
          <Popup
            handlePoup={handlePopup}
            handleEmailDurationPopupOn={handleEmailDurationPopupOn}
            message={waitMessage}
            sendEmailDuration
          />
        )}
        {unreadMessageCount > 0 && newMessageCount && (
          <Popup
            handleNewMessageCount={handleNewMessageCount}
            count={unreadMessageCount}
            refreshInboxList={refreshInboxList}
            handlePoup={handlePopup}
            newMessageCount
          />
        )}
        {showDelete && (
          <Popup
            handlePoup={handlePopup}
            deleteMessageConfirm={deleteMessageConfirm}
            deleteConfirmMessage={deleteConfirmMessage}
            deleteMessagePopup
          />
        )}
        {showWarning && <Popup handlePoup={handlePopup} warning />}
        {showSuccess && <Popup handlePoup={handlePopup} success />}
      </div>
    </>
  );
};

MemoInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(MemoInner);
