import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineNoteAlt } from "react-icons/md";
import { TbLogin, TbEdit } from "react-icons/tb";
// import Popup from "../../../../components/popup/Popup";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getPlayerProfileDetail } from "../../../store/actions";
import { checkPinStatus } from "../../../store/check-pin-status/actions";
import ActionLoader from "../../../pages/ActionLoader";
import Popup from "../Popup";
const LoginMobile = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pinStatus, redirect } = useSelector((state) => ({
    pinStatus: state.checkPinStatusR.data,
    redirect: state.checkPinStatusR.redirect,
  }));

  let loggedIn = window.localStorage.getItem("user_token");

  const [isPopup, setIsPopup] = useState(false);
  const [globalMaintenance, setGlobalMaintenance] = useState(false);
  const [showRegisterpopup, setShowRegisterpopup] = useState(false);
  const [userDataHandleClick, setUserDataHandleClick] = useState(false);


  const [loaderStatus, setLoader] = useState(false);

  const HandleRegisterPopup = () => {
    setShowRegisterpopup(!showRegisterpopup);
  };

  const userDataHandle = () => {
    setUserDataHandleClick(true);
  };

  const handlePopup = () => {
    setIsPopup(false);
  };

  const [formError, setFormError] = useState({});

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    key: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((values) => ({ ...values, [name]: value }));
    setErrors((values) => ({ ...values, [name]: null }));
  };

  const redirectMe = (pinStatus, path, component_location) => {

    let config_status = pinStatus.get_tenant_config?.value;
    let url = "";
    if (
      config_status === true &&
      pinStatus.enabled_active_pin === true &&
      pinStatus.active_pin === false
    ) {
      url = "/login-pin";
    } else if (
      config_status === true &&
      pinStatus.enabled_active_pin === true &&
      pinStatus.is_pin_verified === false
    ) {
      url = "/security-validation";
    } else if (
      pinStatus.is_security_question_set === false ||
      pinStatus.is_security_question_set === null
    ) {
      url = "/security-question";
    } else if (pinStatus.is_password_active === false) {
      url = "/reminder-password";
    } else {
      if (loggedIn != null && loggedIn != '')
        dispatch(getPlayerProfileDetail());
      props.handle();
      props.userData();
      return;
    }
    navigate(url);
    props.handle();
    props.userData();
  };

  useEffect(() => {
    if (loggedIn != null && loggedIn != '' && Object.keys(pinStatus).length)
      redirectMe(pinStatus, location.pathname, "/");
  }, [pinStatus]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    makeAjax(url.USER_API.login, formData, url.USER_MS_EXT).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        setLoader(false);
        if (response.code == "api_message.loginMaintenanceOn") {
          let msg = response.messages;
          if (
            typeof response.data != "undefined" &&
            typeof response.data.tenant != "undefined" &&
            response.data.tenant != null
          ) {
            msg = response.data.tenant;
          }
          setGlobalMaintenance(msg);
          return;
        }
        if (response.code !== "validation_error") {
          showMessage(response);
        }
        setFormError(response.messages);
        return;
      }
      localStorage.setItem("user_token", response.data.token);
      window.localStorage.setItem("isLoggedIn", true);
      dispatch(getPlayerProfileDetail());
      makeAjax(url.USER_API.listPlayerAlternativeSite, {})
        .then((response) => {

          if (
            response.status !== respStatus["SUCCESS"] ||
            (response.data &&
              response.data.data &&
              response.data.data.length == 0)
          ) {
            dispatch(checkPinStatus());
            // navigate(0);
          }
          let sites = response.data.data;

          for (let i = 0; i < sites.length; i++) {
            if (sites[i]["is_login"]) {
              if (window.location.host == sites[i]["domain"]) {

                dispatch(checkPinStatus());
                return;
              } else {
                window.location.assign(
                  (sites[i]["is_secure"] ? "https" : "http") +
                  "://" +
                  sites[i]["domain"] +
                  "/update_token/web/" +
                  localStorage.getItem("user_token")
                );
                return;
              }
            }
          }
          dispatch(checkPinStatus());
          // navigate("/");
          // navigate(0);
          setLoader(false);
        })
        .catch((ex) => {
          // navigate("/");
          // navigate(0);
          setLoader(false);
          dispatch(checkPinStatus());
        });
    });
  };

  const handleClose = () => {
    props.handle();
  };

  const [inputType, setInputType] = useState(false);

  const toggleEye = () => {
    setInputType((curr) => !curr);
  };

  const { playerMenu } = useSelector((state) => ({
    playerMenu: state.menuListR.data,
  }));

  return (
    <div className="login-mobile">
      <h2 className="title">{props.t("login")}</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-control">
          <label className="form-label">{props.t("username")}</label>
          <input
            type="text"
            placeholder={props.t("username")}
            name="username"
            onChange={handleChange}
          />
          {formError.username ? (
            <span className="text-danger error-msg">
              {formError.username[0]}
            </span>
          ) : null}
        </div>
        <div className="input-control pass-input">
          <label className="form-label">{props.t("password")}</label>
          <div className="password-icon">
            <input
              type={inputType == true ? "text" : "password"}
              placeholder={props.t("password")}
              name="password"
              onChange={handleChange}
            />
            {inputType == true ? (
              <AiOutlineEye onClick={toggleEye} ref={props.btnRefLoginEye} />
            ) : (
              <AiOutlineEyeInvisible onClick={toggleEye} />
            )}
            {formError.password ? (
              <span className="text-danger error-msg">
                {formError.password[0]}
              </span>
            ) : null}
          </div>
        </div>
        <div className="login-links">
          <div>
            <span
              className="customer-complaint"
              onClick={() => setIsPopup((curr) => !curr)}
            >
              {props.t("customer_complaints")}
            </span>
          </div>
          <div>
            <NavLink
              to="forgot-password"
              className="forgot-pass pt-1"
              onClick={handleClose}
            >
              {props.t("forgot_password")} ?
            </NavLink>
          </div>
        </div>

        <div className="login-btns">

          {playerMenu.before &&
            playerMenu.before.register &&
            playerMenu.before.register.status ? (

            <NavLink className="register" onClick={HandleRegisterPopup}>
              {props.t(`${playerMenu.before.register.code}`)}
            </NavLink>
          ) : null}

          <ActionLoader
            label={"login"}
            loaderClass={"login-mobile-loader"}
            showLoader={loaderStatus}
            onClick={handleSubmit}
          />

          {showRegisterpopup && (
            <div className="register-popup">
              <Popup
                handlePoup={HandleRegisterPopup}
                registerpopup
                userDataHandle={userDataHandle}
              />
            </div>
          )}

          {isPopup && <Popup handlePoup={handlePopup} custComplaint />}
        </div>
      </form>
      {globalMaintenance && (
        <Popup
          handlePoup={handlePopup}
          globalMaintenanceCondition
          message={globalMaintenance}
          onChange={handleSubmit}
        />
      )}
    </div>
  );
};

LoginMobile.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(LoginMobile);
