import { useState, useEffect } from "react";
import Popup from "../../popup/Popup";
import Select from "react-select";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlayerProfileDetail,
  resetPlayerProfile,
} from "../../../store/actions";
import {
  checkPinStatus,
  resetPinStatus,
} from "../../../store/check-pin-status/actions";

const SecurityQuestionInner = (props) => {
  const [formData, setFormData] = useState({
    security_question_id: "",
    security_answer: "",
  });

  const emptySecurityDrop = {
    _id: null,
    question: null,
  };

  const [isSecurityQuestionChange, setIsSecurityQuestionChange] =
    useState(false);
  const [securityDD, setSecurityList] = useState([]);
  const [refreshSecurity, setRefreshSecurity] = useState(null);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const dispatch = useDispatch();
  const { userProfile, pinStatus, redirect } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
    pinStatus: state.checkPinStatusR.data,
    redirect: state.checkPinStatusR.redirect,
  }));

  const redirectMe = (pinStatus, path, component_location) => {

    if (Object.keys(pinStatus).length) {
      let config_status = pinStatus.get_tenant_config?.value;
      if (
        config_status === true &&
        pinStatus.enabled_active_pin === true &&
        pinStatus.active_pin === false
      ) {
        navigate("/login-pin");
        return;
      } else if (
        config_status === true &&
        pinStatus.enabled_active_pin === true &&
        pinStatus.is_pin_verified === false
      ) {
        navigate("/security-validation");
        return;
      } else if (
        (pinStatus.is_security_question_set === false ||
          pinStatus.is_security_question_set === null) &&
        component_location == path
      ) {
        return;
      } else if (pinStatus.is_password_active === false) {
        navigate("/reminder-password");
        return;
      } else if (
        pinStatus.is_security_question_set === true &&
        path == component_location
      ) {
        navigate("/");
        return;
      } else if (!config_status) {
        navigate("/");
        return;
      }
    }
  };

  useEffect(() => {
    redirectMe(pinStatus, location.pathname, "/security-question");
  }, [pinStatus]);

  useEffect(() => {
    dispatch(getPlayerProfileDetail());
    getSecurityList();
  }, []);

  const getSecurityList = () => {
    makeAjax(url.USER_API.securityQuestionDD, [], url.USER_MS_EXT).then(
      (response) => {

        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setSecurityList(response.data);
      }
    );
  };

  // On change security question
  const onChangeSecQue = (event) => {
    setRefreshSecurity(null);
    setFormError({ ...formError, ["security_question_id"]: "" });
    if (!event) {
      setFormData({ ...formData, ["security_question_id"]: "" });
    } else {
      let t = emptySecurityDrop;
      t._id = event._id;
      t.question = event.question;
      setRefreshSecurity(t);
      setFormData({ ...formData, ["security_question_id"]: event._id });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    makeAjax(
      url.USER_API.addUpdatePlayerSecurityQuestion,
      formData,
      url.USER_MS_EXT
    ).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        setFormError(response.data);
        setIsSecurityQuestionChange(false);
        return;
      }

      formData["security_question_id"] = "";
      formData["security_answer"] = "";
      setFormData({
        ...formData,
      });

      setRefreshSecurity(null);
      setIsSecurityQuestionChange(true);
      showMessage(response);
      dispatch(checkPinStatus());
      // navigate("/");
      // navigate(0);
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    formError.security_answer = {};
    setFormError({
      ...formError,
    });
  };

  const handlePopup = () => {
    setIsSecurityQuestionChange(false);
  };

  const cancelSubmit = (e) => {
    e.preventDefault();
    makeAjax(url.USER_API.logout, [], url.USER_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      window.localStorage.removeItem("isLoggedIn");
      window.localStorage.removeItem("user_token");
      dispatch(resetPlayerProfile());
      dispatch(resetPinStatus());
      navigate("/");
      // navigate('/');
      // navigate(0);
    });
  };

  return (
    <div className="new-auth security-question">
      <div className="new-pass-box">
        <h4 className="title">
          {props.t("welcome")}, {userProfile.username}
        </h4>
        <div className="new-pass-box-inner">
          <p className="desc">{props.t("security_questions_and_answers")}</p>
          <ul className="list">
            <li>
              {props.t(
                "please_select_a_question_and_enter_the_answer_that_best_suits_you"
              )}
              .{" "}
            </li>
            <li>
              {props.t(
                "the_security_answer_column_cannot_be_empty_must_be_filled_in_with_at_least_three_characters"
              )}
            </li>
            <li>
              {props.t(
                "this_security_answer_will_be_needed_when_using_the_forgot_password_menu"
              )}
              .
            </li>
          </ul>
          <form>
            <div className="input-control">
              <label>{props.t("security_question")} :</label>
              <Select
                name="security_question_id"
                onChange={onChangeSecQue}
                value={refreshSecurity}
                isClearable={true}
                placeholder={props.t("select_security_question")}
                getOptionLabel={(option) => option.question}
                getOptionValue={(option) => option._id}
                options={securityDD}
                className="cusReactSelect"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#0e0e0e",
                    primary: "#000",
                  },
                })}
              />
              {formError.security_question_id ? (
                <span className="text-danger error-msg">
                  {formError.security_question_id[0]}
                </span>
              ) : null}
            </div>
            <div className="input-control">
              <label> {props.t("security_answer")} : </label>
              <input
                type="text"
                placeholder={props.t("security_answer")}
                name="security_answer"
                value={formData.security_answer}
                onChange={handleChange}
              />
              {formError.security_answer ? (
                <span className="text-danger error-msg">
                  {formError.security_answer[0]}
                </span>
              ) : null}
            </div>
            <span className="note">
              *
              {props.t(
                "please_remember_the_answer_to_your_security_question_a_security_question_will_be_asked_when_you_forget_your_password"
              )}
            </span>
            <div className="button-div justify-content-center">
              <button
                className="cancel mt-auto"
                type="button"
                onClick={(e) => {
                  cancelSubmit(e);
                }}
              >
                {props.t("log_out")}
              </button>
              <button
                type="button"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {props.t("process")}
              </button>
            </div>
          </form>
        </div>
      </div>
      {isSecurityQuestionChange && (
        <Popup handlePoup={handlePopup} securityQuestionChange />
      )}
    </div>
  );
};

SecurityQuestionInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(SecurityQuestionInner);
