import React, { useEffect, useState } from "react";
import CommonSec from "../commonSection/CommonSec";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  makeAjax,
  url,
  respStatus,
  showMessage,
  currencyFormat,
  dateFormat,
} from "../../helpers/global_helper";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineChevronLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineChevronRight,
} from "react-icons/md";

const TurnoverProviders = (props) => {
  // Pagination init
  const InitFilterPage = {
    per_page: 5,
    page: 1,
  };

  // State declaration
  const [turnoverInfo, setTurnoverInfo] = useState([]);
  const [turnoverPagination, setTurnoverPagination] = useState(InitFilterPage);
  const [singleFieldData, setSingleFieldInboxData] = useState(InitFilterPage);
  const [listData, setListData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Get turnover info
  const getTurnoverInfo = () => {
    makeAjax(url.USER_API.listInfoTurnover, [], url.USER_MS_EXT).then(
      (response) => {
        console.log(">>>>getTurnoverInfo Success<<<<", response);
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setTurnoverInfo(response.data);
      }
    );
  };

  // Goto filter
  const gotoPage = (page = 1) => {
    let payload = singleFieldData;
    payload["page"] = page;
    setTurnoverPagination(payload);
    makeAjax(url.USER_API.listInfoTurnover, payload, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
          }
          showMessage(response);
          return;
        }
        setTurnoverInfo(response.data);
      }
    );
  };

  // filter pagination
  const filterChangeInbox = (event) => {
    console.log("<<<<<per page value>>>>>>", event.target.value);
    let val = event.target.value;
    if (isNaN(val)) {
      val = "";
    } else {
      if (val != "") val = parseInt(val);
    }

    if (event.target.name == "page" && val > turnoverInfo.last_page)
      val = parseInt(turnoverInfo.last_page);

    if (event.target.name == "per_page") {
      singleFieldData["per_page"] = val;
    } else {
      singleFieldData["page"] = val;
    }
    setTurnoverPagination(singleFieldData);
    makeAjax(
      url.USER_API.listInfoTurnover,
      singleFieldData,
      url.USER_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        showMessage(response);
        return;
      }
      setTurnoverInfo(response.data);
    });
  };

  useEffect(() => {
    getTurnoverInfo();
  }, []);

  if (typeof turnoverInfo.data != "undefined") {
    console.log(Object.values(turnoverInfo.data));
  }

  const data = [
    {
      title: "TurnOver",
      date: "01-01-2023 0:11:12",
      data: "655defe9f9883456bb0d7517",
      bonustitle: "Rollover Commision Bonus",
      bonus: "-",
    },
    {
      title: "TurnOver",
      date: "01-01-2023 0:11:12",
      data: "655defe9f9883456bb0d7517",
      bonustitle: "Rollover Commision Bonus",
      bonus: "-",
    },
  ];

  return (
    <>
      <div className="table-div">
        <div className={!props.showCommanSection && "turnover"}>
          <div className="container">
            {!props.showCommanSection && (
              <h3 className="title">{props.t("turnover")}</h3>
            )}
            {windowWidth >= 991 ? (
              // Desktop div
              <div>
                <div className="turnover-data">
                  <table>
                    <thead>
                      <tr>
                        <th>{props.t("date")}</th>
                        <th>{props.t("turnover")}</th>
                        <th>{props.t("rollover_commision_bonus")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeof turnoverInfo.data != "undefined" &&
                      Object.values(turnoverInfo.data).length > 0 ? (
                        <>
                          {Object.values(turnoverInfo.data).map((v, i) => (
                            <tr>
                              <td>{dateFormat(v.date)}</td>
                              <td>{currencyFormat(v.cashback_turnover)}</td>
                              <td>{currencyFormat(v.roll_bonus)}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <td colSpan={3} className="text-center no-record">{props.t('no_record_found')} </td>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="turnovertable-data">
                {typeof turnoverInfo.data != "undefined" &&
                  Object.values(turnoverInfo.data).length > 0 ?(<>
                 { Object.values(turnoverInfo.data).map((v, i) => (
                    <table className="turnover-table">
                      <tbody className="turnover-bg">
                        <tr>
                          <td>
                            <span className="data-one ">
                              {props.t("turnover")}:
                            </span>
                          </td>
                          <td className="date-form">
                            <span className="data-one ">
                              {props.t("date")}:
                            </span>
                            <span className="date">{dateFormat(v.date)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="turnover-id">
                              {currencyFormat(v.cashback_turnover)}
                            </span>
                          </td>
                        </tr>
                        <tr className="all-data">
                          <td className="data-one">
                            <span>{props.t("rollover_commision_bonus")}:</span>{" "}
                            <span className="turnover-id">
                              {currencyFormat(v.roll_bonus)}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                  </>)
                  :(
                    <div className="text-center no-record">{props.t('no_record_found')}</div>
                  )}
                  
              </div>
            )}
          </div>
        </div>
      </div>

      {!props.showCommanSection && <CommonSec />}
    </>
  );
};

TurnoverProviders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(TurnoverProviders);
