import { useState, useEffect } from "react"
import { AiOutlineStop } from "react-icons/ai"
import { HiOutlineSpeakerphone } from "react-icons/hi"
import { CgMenuGridR, CgPhone } from "react-icons/cg"
import { FaArrowCircleLeft, FaRandom, FaBackspace } from "react-icons/fa"
import { NavLink, useLocation } from "react-router-dom"
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper"
import Popup from "../../popup/Popup"
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { getPlayerProfileDetail, resetPlayerProfile } from "../../../store/actions"
import { checkPinStatus, resetPinStatus } from "../../../store/check-pin-status/actions"
import ActionLoader from "../../../pages/ActionLoader"

const SecurityValidationInner = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const [loaderStatus, setLoader] = useState(false);
    const [loaderOkStatus, setOkLoader] = useState(false);

    const InitFormData = {
        active_pin: '',
        type: 'security-login'
    }

    const { userProfile, pinStatus, redirect } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
        pinStatus: state.checkPinStatusR.data,
        redirect: state.checkPinStatusR.redirect
    }));

    const redirectMe = (pinStatus, path, component_location) => {

        if (Object.keys(pinStatus).length) {
            let config_status = pinStatus.get_tenant_config?.value;
            if (config_status === true && pinStatus.enabled_active_pin === true && pinStatus.active_pin === false) {
                navigate("/login-pin");
                return;
            } else if (config_status === true && pinStatus.enabled_active_pin === true && pinStatus.is_pin_verified === false && component_location == path) {
                return;
            }
            else if ((pinStatus.is_security_question_set === false || pinStatus.is_security_question_set === null)) {
                navigate("/security-question");
                return;
            }
            else if (pinStatus.is_password_active === false) {
                navigate("/reminder-password");
                return;
            } else if (config_status === true && pinStatus.enabled_active_pin === true && pinStatus.is_pin_verified === true && path == component_location) {
                navigate("/");
                return;
            } else if (!config_status) {
                navigate("/");
                return;
            }
        }
    }

    useEffect(() => {
        redirectMe(pinStatus, location.pathname, '/security-validation')
    }, [pinStatus]);

    const [formData, setFormData] = useState(InitFormData);
    const [pinBox1, setPinBox1] = useState(false)
    const [isChecked, setIsChecked] = useState(true);
    const [isSecurityValidation, setIsSecurityValidation] = useState(false)
    const [isValidationPopup, setIsValidationPopup] = useState(false)
    const [isBlock, setIsBlock] = useState(false);
    const [isBlockPopUp, setIsBlockPopUp] = useState(false);

    const [disabledNum1, setDisabledNum1] = useState(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        setOkLoader(true);
        setPinBox1(false)
        if (isChecked === false) {
            setOkLoader(false);
            setIsSecurityValidation(true)
            setFormError({});
            return false;
        }

        makeAjax(
            url.USER_API.checkValidatePin, formData, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setOkLoader(false);
                    setIsValidationPopup(true)
                    setFormError(response.messages);
                    setFormData(InitFormData);
                    setDisabledNum1(true)
                    return;
                }
                setOkLoader(false);
                setFormError({});
                setFormData({ ...formData, ...{ active_pin: '' } });
                dispatch(checkPinStatus());
                // navigate('/');
                // navigate(0);
            });

    }


    const handleErr = (err) => {
        setIsBlock(err)
    };

    const numPadClear1 = () => {
        setFormError({});
        setFormData({ ...formData, ...{ active_pin: formData.active_pin.slice(0, -1) } })
    }
    const numPad1 = (e) => {
        const numMain1 = formData.active_pin + e.target.id
        if (numMain1.length > 6) {
            return false;
        }
        if (numMain1.length == 6) {
            setDisabledNum1(false)
        }
        setFormData({ ...formData, ...{ active_pin: numMain1 } })
    }

    // Check if agree checkbox is checked
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        // pop up if disagree
        if (isChecked === true) {
            setIsSecurityValidation(true)
            return false;
        }
    };

    // Handle pop close
    const handlePopup = () => {
        setIsSecurityValidation(false)
        setIsValidationPopup(false);

        if (isBlock === true) {
            setIsBlockPopUp(true)
        }
    }

    const popUpClosed = () => {
        setIsBlockPopUp(false);
        logout();
    }

    const [numbers, setNumbers] = useState([]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    useEffect(() => {
        const shuffledNumbers = shuffleArray([...Array(10).keys()]);
        setNumbers(shuffledNumbers);
        dispatch(getPlayerProfileDetail());
        dispatch(checkPinStatus());
    }, []);

    const closeBox = () => {
        setPinBox1(false);
        setFormData(InitFormData)
        const shuffledNumbers = shuffleArray([...Array(10).keys()]);
        setNumbers(shuffledNumbers);
        setDisabledNum1(true);
    }

    // Logout api call
    const logout = () => {
        setLoader(true);
        makeAjax(
            url.USER_API.logout, [], url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setLoader(false);
                    showMessage(response);
                    return;
                }
            });
        window.localStorage.removeItem("isLoggedIn");
        window.localStorage.removeItem("user_token");
        setLoader(false);
        dispatch(resetPlayerProfile());
        dispatch(resetPinStatus());
        navigate('/');
        // navigate(0)
    }

    const changePinBoxVal = () => {

        setPinBox1(!pinBox1);
    }

    return (
        <div className='new-auth login-pin security-validation'>
            <div className="new-pass-box">
                <h4 className="title">{props.t('security_validation')}</h4>
                <div className="new-pass-box-inner">
                    <h5 className="sub-title">{props.t('check_and_make_sure_your_data_is_correct')}</h5>
                    <div className="user-validation-details res-cus-tab">
                        <table>
                            <tr>
                                <td>{props.t('username')} :</td>
                                <td className="data">{userProfile.username}</td>
                            </tr>
                            <tr>
                                <td>{props.t('nickname')} :</td>
                                <td className="data">{userProfile.nickname}</td>
                            </tr>
                            <tr>
                                <td>{`${props.t('bank')} / ${props.t('e_wallet')}`} : </td>
                                <td className="data">{userProfile.player_bank_detail.bank_detail.bank_name}</td>
                            </tr>
                            <tr>
                                <td>{`${props.t('account')} / ${props.t('e_wallet_name')}`} :</td>
                                <td className="data">{userProfile.player_bank_detail.account_name}</td>
                            </tr>
                            <tr>
                                <td>{`${props.t('account')} / ${props.t('phone_number')}`} : &nbsp;</td>
                                <td className="data">{userProfile.player_bank_detail.account_number}</td>
                            </tr>
                            <tr>
                                <td>{props.t('last_login')} :</td>
                                <td className="data">{userProfile.last_login_at}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="checkBox">
                        <input type="checkbox" id="agree" checked={isChecked} onChange={handleCheckboxChange} />

                        <label htmlFor="agree">{props.t('agree_about')} <NavLink to="/rules-of-play">{props.t('rules_of_play')}</NavLink></label>
                    </div>
                    <h5 className="sub-title">{props.t('please_check_personal_data_before_entering_the_pin')}</h5>
                    <div className="icons-section">
                        <div className="icon-item">
                            <AiOutlineStop />
                            <p>{props.t('user_id_will_be_blocked_if_you_experience_five_x_errors')}</p>
                        </div>
                        <div className="icon-item">
                            <FaRandom />
                            <p>{props.t('buttons_are_generated_randomly_please_pay_attention_to_the_number_you_input')}</p>
                        </div>
                        <div className="icon-item">
                            <CgPhone />
                            <p>{props.t('if_you_forget_your_pin_please_click_contact_our_operator')}</p>
                        </div>
                        <div className="icon-item">
                            <HiOutlineSpeakerphone />
                            <p>{props.t('do_not_share_your_pin_with_others')}</p>
                        </div>
                    </div>
                    <div className="user-validation-details">
                        <form>
                            <div className="password-icon">
                                <input type="password" placeholder={props.t('enter_your_pin')} value={formData.active_pin} onClick={() => { changePinBoxVal(); }} />
                                <div className='cg-pin'><CgMenuGridR onClick={() => { changePinBoxVal(); }} /></div>
                                {pinBox1 &&
                                    <div className="pin-box">
                                        <input type="password" placeholder={props.t('pin')} value={formData.active_pin} onChange={() => { }} maxLength={6} pattern="[^\s]+" />
                                        <div className="enter-pin">
                                            <div className="num-box">
                                                {numbers.map(number => (
                                                    <span key={number} className="num" id={number} onClick={numPad1}>{number}</span>
                                                ))}
                                            </div>
                                            <div className="button-box">
                                                <ActionLoader label={'okay'} disabled={disabledNum1} loaderClass={'okay security-ok'} showLoader={loaderOkStatus} onClick={(e) => { handleSubmit(e) }} />

                                                <span className="back" onClick={numPadClear1}><FaBackspace /></span>
                                                <span className='close' onClick={closeBox}>{props.t('close')}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <ActionLoader label={'log_out'} loaderClass={'cancel security-pin-logout'} showLoader={loaderStatus} onClick={logout} />

                        </form>
                        <span className="note">{props.t('forget_your_pin')} ? {' '} <a href="#">{props.t('contact_our_operator')}</a></span>
                    </div>
                </div>
            </div>
            {isSecurityValidation && <Popup handlePoup={handlePopup} agreeMessage />}
            {isValidationPopup && <Popup handleErr={handleErr} handlePoup={handlePopup} data={formError} securityValidationMessage />}
            {isBlockPopUp && <Popup handlePoup={popUpClosed} blockPopUpMessage />}

        </div>
    )
}

SecurityValidationInner.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(SecurityValidationInner);