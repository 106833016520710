const Language = {
  id: {
    label: "Indonesia",
    flag: window.location.origin + "/assets/images/flags/inflag.jpg"
  },
  en: {
    label: "English",
    flag: window.location.origin + "/assets/images/flags/us.jpg"
  },
}

export default Language
