import React from 'react'
import NewPasswordInner from '../../components/authentication/newPassword/NewPasswordInner'
import FloatingComponents from '../../components/floatingComponents/FloatingComponents'
import { useSelector } from 'react-redux'
// import { componentSetting } from '../../template-settings'
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const NewPassword = () => {
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  return (
    <>
      <NewPasswordInner />
      {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
    </>
  )
}

NewPassword.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(NewPassword);