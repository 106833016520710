import {
    GET_PLAYER_BALANCE,
    GET_PLAYER_BALANCE_SUCCESS,
  } from "./actionTypes"
  
  export const getPlayerBalance = () => {
    return {
      type: GET_PLAYER_BALANCE,
      payload: {  },
    }
  }
  
  export const getPlayerBalanceSuccess = (response) => {
    return {
      type: GET_PLAYER_BALANCE_SUCCESS,
      payload: response,
    }
  }
  