import React from 'react'


const ComingSoon = () => {
  return (
    <>
    <div className='comingsoon'>
        <div className='container' style={{paddingBottom: '1rem'}}>
        <h3 className='status-text fs-2'>Coming Soon</h3>
        </div>
    </div>
    </>
  )
}

export default ComingSoon
