import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CommonSec from "../../components/commonSection/CommonSec";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
} from "../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const TransactionQueue = (props) => {
  const navigate = useNavigate();
  const [viewData, setViewData] = useState({});
  const [pendingCount, setPendingCount] = useState(null);
  const [timeTaken, setTimeTaken] = useState(null);
  const [bankStatus, setBankStatus] = useState(null);

  const checkPendingTransaction = () => {
    makeAjax(url.DPWD_API.checkPendingTransaction, {}, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          return;
        }
        if (response.data && !response.data.pending_status) {
          navigate("/withdraw");
          return;
        }
        setViewData(response.data);
      }
    );
  };

  const checkWithdrawPendingRequest = () => {
    makeAjax(
      url.DPWD_API.checkWithdrawPendingRequest,
      [],
      url.DPWD_MS_EXT
    ).then((response) => {
      
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }

      if (response.data.count > 0) {
        setTimeTaken(response.data.count * 4);
      }
      setPendingCount(response.data.count);
      setBankStatus(response.data.bank_status);
    });

    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 1000);
  };

  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    checkPendingTransaction();
    checkWithdrawPendingRequest();
  }, []);
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));
  return (
    <>
      <section className="depo-message">
        <div className="container" style={{ paddingBottom: "1rem" }}>
          <h3 className="status-text">
            {props.t("withdraw_process")}
          </h3>
          <div className="depo-message-box">
            <h3 className="depo-text">
              {props.t("we_have_received_your_withdraw_request")}:
            </h3>
            <div className=" depo-amount">
              {currencyFormat(viewData.amount)} {userProfile.currency}
            </div>
            <table className="w-2/4 depo-message-table text-center">
              <thead>
                <tr>
                  <td>
                    {props.t("bank")} / {props.t("e_wallet")}:
                  </td>

                  <td className="data">{viewData.bank_name}</td>
                </tr>
                <tr>
                  <td>
                    {props.t("account_e_wallet_name")}:
                  </td>

                  <td className="data">{viewData.user_account_name}</td>
                </tr>
                <tr>
                  <td>
                    {props.t("account")} / {props.t("phone_number")}:
                  </td>

                  <td className="data">{viewData.user_account_number}</td>
                </tr>
              </thead>
            </table>

            <div className="d-flex justify-content-center time-dur">
              <div className="time-num">
                <input
                  type="text"
                  defaultValue={pendingCount}
                  disabled
                  className="form-control text-center fw-bold"
                  aria-label="Number"
                />
                <label for="number" className="form-label">
                  {props.t("number")}
                </label>
              </div>

              <div className="time-line">
                <input
                  type="text"
                  className="form-control text-center fw-bold"
                  defaultValue={viewData.withdraw_group}
                  disabled
                  aria-label="Line"
                />
                <label for="line" className="form-label">
                  {props.t("line")}
                </label>
              </div>

              {bankStatus && bankStatus == "Online" ? (
                <div className="time-min">
                  <input
                    type="text"
                    className="form-control text-center fw-bold"
                    defaultValue={timeTaken +" "+ props.t("minutes")}
                    aria-label="Duration"
                    disabled
                  />
                  <label for="duration" className="form-label">
                    {props.t("duration")}
                  </label>
                </div>
              ) : (
                bankStatus && (
                  <div className="time-min">
                    <input
                      type="text"
                      className="form-control text-center fw-bold"
                      defaultValue={"Bank" + " " + bankStatus}
                      aria-label="Duration"
                      disabled
                    />
                    <label for="duration" className="form-label">
                      {props.t("duration")}
                    </label>
                  </div>
                )
              )}

              <div>
                <div onClick={checkWithdrawPendingRequest} className="spin-refresh-btn">
                <MdRefresh
                    className={`spin-button ${spinning ? "spin" : ""}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CommonSec />
    </>
  );
};

TransactionQueue.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(TransactionQueue);
