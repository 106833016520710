import { HiPuzzle } from 'react-icons/hi'
import GameCategoryMenu from './game-category-ver-menu/GameCategoryMenu'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { FaFireAlt, FaGamepad, FaDice, FaMoneyCheckAlt } from 'react-icons/fa'
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import GameCategoryCarousel from './gamecategorycarousel/GameCategoryCarousel';
import ProviderGames from './game-category-ver-menu/main-games/ProviderGames';

const initSlug = {
  provider_slug: null,
  category_slug: null,
}

const GameCategory = (props) => {
  const [slugInfo, setSlugInfo] = useState(initSlug);
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  const tabs = [
    { id: 0, icon: <HiPuzzle color='white' />, name: 'All Games' },
    { id: 1, icon: <FaFireAlt color='white' />, name: 'Hot Games' },
    { id: 2, icon: <FaMoneyCheckAlt color='white' />, name: 'Slots' },
    { id: 3, icon: <FaGamepad color='white' />, name: 'Arcades' },
    { id: 4, icon: <FaDice color='white' />, name: 'Casino' }
  ]

  const { playerMenu } = useSelector(state => ({
    playerMenu: state.menuListR.data
  }));

  const urlData = window.location.pathname.split('/');

  useEffect(() => {
    let arr = urlData.filter(e => String(e).trim());
    let tmpSlug = slugInfo;
    if (arr.length > 0) {
      tmpSlug['category_slug'] = arr?.[0];
      if (arr.length > 1) {
        tmpSlug['category_slug'] = arr?.[0];
        tmpSlug['provider_slug'] = arr?.[1];
      }
      setSlugInfo({ ...slugInfo });
    }
  }, [props.categorySlug, props.providerSlug]);

  return (
    <>
      {
        componentSetting?.['category-nav-bar']?.['default-value']
        && < div className="category-nav-bar">
          {
            !componentSetting?.custom_loading?.options?.game_category_handle
            && <div>
              {
                componentSetting?.['category-nav-bar']?.['default-value'] == 'horizonatal'
                && < GameCategoryCarousel tabs={props.tabs} categorySlug={props.categorySlug} providerSlug={props.providerSlug} />
              }
              {
                componentSetting?.['category-nav-bar']?.['default-value'] == 'vertical'
                && <GameCategoryMenu tabs={tabs} categorySlug={props.categorySlug} providerSlug={props.providerSlug} playerMenu={playerMenu} />
              }
            </div>
          }
          {
            componentSetting?.custom_loading?.options?.game_category_handle
            && <div>
              {
                (props.providerSlug == null  &&  props.categorySlug == null)
                && componentSetting?.['category-nav-bar']?.['default-value'] == 'horizonatal'
                && <GameCategoryCarousel tabs={props.tabs} />
              }
              {
                (props.providerSlug == null &&  props.categorySlug == null)
                && componentSetting?.['category-nav-bar']?.['default-value'] == 'vertical'
                && <GameCategoryMenu categorySlug={props.categorySlug} providerSlug={props.providerSlug} tabs={tabs} playerMenu={playerMenu} />
              }
              {
                (props.providerSlug != null || props.categorySlug != null) 
                && <ProviderGames categorySlug={props.categorySlug} providerSlug={props.providerSlug} tabs={props.tabs} />
              }
            </div>
          }
        </div >
      }
    </>
  )
}

GameCategory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(GameCategory);