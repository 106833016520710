import React from 'react'
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { makeAjax, showMessage, url, respStatus } from '../../../helpers/global_helper';
import { useState, useEffect } from 'react';
import JackpotGrid from './jackpotgrid/JackpotGrid';
import JackpotSlider from './jackpotslider/JackpotSlider';
import { useSelector } from 'react-redux';
const initSetting = {
  jackpot_grid: null,
  default_class: null,
};

const Jackpot = (props) => {
  const [fnSetting, setFnSetting] = useState(initSetting);
  const [jackpotResult, setJackpotResult] = useState([]);
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  const getJackpotResult = () => {
    makeAjax(url.REPORT_API.getLatestJackpot, [], url.REPORT_MS_EXT).then(response => {
      if (response.status != respStatus['SUCCESS']) {
        // showMessage(response);
        console.error("getLatestJackpot", response)
        return;
      }
      setJackpotResult(response.data);
    });
  }

  useEffect(() => {
    getJackpotResult();
  }, []);

  useEffect(() => {
    let temp = { ...fnSetting };
    temp['jackpot_grid'] = componentSetting?.["jackpot"]?.["default-value"];
    setFnSetting(temp);
  }, [componentSetting]);
  return (
    <>
      {
        fnSetting['jackpot_grid'] && <div className="jackpot">
          <div className={fnSetting['jackpot_grid']}>
            <h2 className='title'>{props.t('progressive_jackpot')}</h2>
            {
              fnSetting['jackpot_grid'] == 'grid' && < JackpotGrid />
            }
            {
              fnSetting['jackpot_grid'] == 'slider' && <JackpotSlider />
            }
          </div>
        </div>
      }
    </>

  )
}

Jackpot.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Jackpot);