import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const PromotionsInner = (props) => {
  // Get device type
  let deviceType = localStorage.getItem("device_type");
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));
  // Init promo image
  const PromoImg = [
    {
      img: "/assets/images/default-images/banner_" + deviceType + ".png",
      link: "",
      width: "auto",
      height: 300,
      alt: "Promo img",
    },
  ];

  // Fetch other media
  const { otherMedia } = useSelector((state) => ({
    otherMedia: state.othermediaInformationListR.data,
  }));

  const [assets, setAssets] = useState(PromoImg);

  // Effect call for manupulate data
  useEffect(() => {
    setAssets(PromoImg);
    if (
      typeof otherMedia[deviceType + "_" + "promo_image"] != "undefined" &&
      otherMedia[deviceType + "_" + "promo_image"].length > 0
    ) {
      let sliderList = otherMedia[deviceType + "_" + "promo_image"];
      let sliderArr = [];
      for (const slider of sliderList) {
        let obj = {};
        obj["width"] = slider["width"];
        obj["height"] = slider["height"];
        obj["alt"] = slider["alternate"];
        obj["img"] = slider["path"];
        obj["link"] = slider["link"];
        obj["description"] = slider["description"];
        sliderArr.push(obj);
      }
      setAssets(sliderArr);
    }
  }, [otherMedia]);

  const initAssetConfig = {
    width: "300px",
    height: "auto",
    alt: "defautl alt",
    description: "default description",
    image: "",
    link: window.location.origin,
  };

  const initStyle = {
    width: "",
    height: "",
    backgroundImage: "",
  };

  const [assetsBg, setAssetsBg] = useState(initAssetConfig);

  const [style, setStyle] = useState(initStyle);
  // Effect call for manupulate data
  useEffect(() => {
    setAssetsBg(initAssetConfig);
    // Width
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["width"] != "undefined"
    ) {
      assetsBg["width"] = otherMedia[deviceType + "_" + "background"]["width"];
      style["width"] =
        otherMedia[deviceType + "_" + "background"]["width"] + "px";
      setStyle({
        ...style,
      });

      setAssetsBg({
        ...assetsBg,
      });
    }
    // Heigth
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["height"] !=
      "undefined"
    ) {
      assetsBg["height"] =
        otherMedia[deviceType + "_" + "background"]["height"];
      style["height"] =
        otherMedia[deviceType + "_" + "background"]["height"] + "px";
      setStyle({
        ...style,
      });
      setAssetsBg({
        ...assetsBg,
      });
    }

    // Image path
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["path"] != "undefined"
    ) {
      assetsBg["image"] = otherMedia[deviceType + "_" + "background"]["path"];
      style["backgroundImage"] = `url(${otherMedia[deviceType + "_" + "background"]["path"]
        })`;
      setStyle({
        ...style,
      });
      setAssetsBg({
        ...assetsBg,
      });
    }

    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["link"] != "undefined"
    ) {
      assetsBg["link"] = otherMedia[deviceType + "_" + "background"]["link"];
      setAssetsBg({
        ...assetsBg,
      });
    }
  }, [otherMedia]);

  return (
    <>
      <div
        className="promotions"
        style={
          style["backgroundImage"] != ""
            ? {
              backgroundImage: style["backgroundImage"],
              width: style["width"],
              height: style["height"],
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
            : {}
        }
      >
        <div className="container">
          <div className="promotion-header">

            {
              componentSetting?.page_title?.options?.section_title && <div className="title">{props.t("promos")}</div>
            }
          </div>
          <div className="promotion-main">
            {assets.map((v, i) => (
              <div className="imgspace" key={i}>
                <a target="_blank" href={v.link}>
                  <img
                    src={v.img}
                    width={v.width}
                    height={v.height}
                    alt={v.alt}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

PromotionsInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PromotionsInner);
