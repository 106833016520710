import { useSelector } from "react-redux"
import MainHeader from "./main-header/MainHeader"
import News from "./news/News"
import { Helmet } from 'react-helmet'
import { useEffect, useState } from "react"
import { makeAjax, url, respStatus, showMessage } from "../../helpers/global_helper"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import VerticalHeader from "./main-header/VerticalHeader";
import { Container } from "react-bootstrap"
import Navbar from "./navbar/Navbar"


const extractScriptAttributes = (scriptString) => {
  const srcRegex = /src=["'](.*?)["']/;
  const idRegex = /id=["'](.*?)["']/;
  const srcMatch = scriptString.match(srcRegex);
  const idMatch = scriptString.match(idRegex);
  const src = srcMatch ? srcMatch[1] : null;
  const id = idMatch ? idMatch[1] : null;
  if (src == null && id == null) {
    let doc = new DOMParser().parseFromString(scriptString, "text/xml");
    let src = doc.firstChild.textContent;
    return { src, id };
  }
  return { src, id };
};

const getLiveChatScript = () => {
  return new Promise((resolve, reject) => {
    makeAjax(url.MASTER_API.getLiveChatConfig, [], url.MASTER_MS_EXT)
      .then(response => {
        if (response.status !== respStatus['SUCCESS']) {
          if (response.code !== 'validation_error') {
            showMessage(response);
            reject(response);
            return;
          }
          showMessage(response);
          reject(response);
          return;
        }
        if (response.data && response.data.script) {
          const { src, id } = extractScriptAttributes(response.data.script);
          resolve({ scriptSrc: src, scriptId: id });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

const Header = (props) => {
  let location = useLocation();
  // useEffect(() => {
  //   localStorage.setItem('device_type', 'desktop');
  // }, [])

  const [codeData, setPrms] = useState({});
  const [seoData, setSeo] = useState({
    title: 'Gaming App',
    keyword: 'Default keyword',
    description: 'Default description',
  });

  // Fetch component setting
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  // Get code from url
  let params = window.location.pathname.substring(1,);

  // Fetch meta info
  const { metaInfo } = useSelector(state => ({
    metaInfo: state.metaInformationListR.data
  }));

  const restrictedUrl = window.location.pathname;
  let loggedIn = window.localStorage.getItem("user_token");

  const getAlternativeSite = () => {
    makeAjax(url.USER_API.isLoggedIn, {}, url.USER_MS_EXT)
      .then(response => {
        if (response.status !== respStatus['SUCCESS'] || !response.data) {
          localStorage.removeItem('user_token');
        }
        loggedIn = window.localStorage.getItem("user_token");
        makeAjax(url.USER_API.listPlayerAlternativeSite, {})
          .then(response => {
            if (response.status !== respStatus['SUCCESS'] || (response.data && response.data.data && response.data.data.length == 0)) {
              return;
            }
            let sites = response.data.data;
            for (let i = 0; i < sites.length; i++) {
              if (loggedIn != null) {
                if (sites[i]['is_login']) {
                  if (window.location.host == sites[i]['domain']) {
                    return;
                  } else {
                    window.location.assign((sites[i]['is_secure'] ? 'https' : 'http') + '://' + sites[i]['domain'] + '/update_token/web/' + localStorage.getItem('user_token'));
                    return;
                  }
                }
              } else {
                if ((sites[i]['is_login'] || sites[i]['is_redirect']) && window.location.host == sites[i]['domain']) {
                  let nonPrimary = {};
                  for (let j = 0; j < sites.length; j++) {
                    if (sites[j]['is_primary']) {
                      window.location.assign((sites[j]['is_secure'] ? 'https' : 'http') + '://' + sites[j]['domain']);
                      return;
                    } else if (!sites[j]['is_login'] && !sites[j]['is_logout'] && !sites[j]['is_redirect']) {
                      nonPrimary = sites[j];
                    }
                  }
                  if (nonPrimary.domain) {
                    window.location.assign((nonPrimary.is_secure ? 'https' : 'http') + '://' + nonPrimary.domain);
                    return;
                  }
                }
              }
            }
          });
      });
  }

  let redirectArr = ['/login-pin', '/security-question', '/security-validation'];

  const fetchSeoInformation = (event) => {
    let payload = {
      code: 'seo',
    };
    makeAjax(url.USER_API.getTextInfo,
      payload,
      url.USER_MS_EXT).then(response => {
        if (response.status != respStatus['SUCCESS']) {
          showMessage(response);
          return;
        }
        if (response && response.data && response.data.value) {
          let element = document.getElementById("title");
          element.before(response.data.value);
        }
      })
  }

  useEffect(() => {
    let url_split = window.location.pathname.split("/");
    if ((url_split[1] && url_split[1] != 'update_token') || !redirectArr.includes(location.pathname))
      getAlternativeSite();
    fetchSeoInformation();
    let scriptElement;

    getLiveChatScript()
      .then(scriptAttributes => {
        const { scriptSrc, scriptId } = scriptAttributes;
        let element = document.getElementById("title");
        scriptElement = document.createElement("script");
        if (scriptId == null) {
          scriptElement.innerHTML = scriptSrc;
        } else {
          scriptElement.src = scriptSrc;
          scriptElement.id = scriptId;
        }
        element.before(scriptElement);
      })
      .catch(error => {
        console.error(error);
      });


    return () => {

      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (params == '')
      params = 'home';
    if (params == 'games')
      params = 'hot_games';
    if (metaInfo && metaInfo.length > 0) {
      for (const itr of metaInfo) {
        let seo = {
          title: itr.title,
          keyword: itr.keyword,
          description: itr.description,
        };
        if (itr.menu.code == params) {
          setSeo(seo);
          break;
        } else {
          setSeo({
            title: 'Gaming App',
            keyword: 'Default keyword',
            description: 'Default description',
          });
        }
      }
    }
  }, [params]);


  return (
    <>
      <header className="header">
        <Container>
          <div>
            {
              <>
                <Helmet>
                  <title>{seoData.title}</title>
                  <meta name={seoData.keyword} content={seoData.description} />
                </Helmet>
              </>
            }
          </div>

          {typeof componentSetting?.["top-nav-menus"] != 'undefined' && componentSetting?.["top-nav-menus"]?.["default-value"] && (componentSetting?.["nav-bar"]?.["default-value"] == 'horizonatal' || componentSetting?.["nav-bar"]?.["default-value"] == 'nav-carozal') &&
            <MainHeader />
          }
          {componentSetting?.["nav-bar"]?.["default-value"] == 'vertical' &&
            <VerticalHeader sideMenuClickProp={props.sideMenuClickProp} />
          }

          {typeof componentSetting?.["news"] != 'undefined' && componentSetting?.["news"]?.["default-value"] && <News />}

          {componentSetting?.["nav-bar"]?.["default-value"] == 'horizonatal' && componentSetting?.custom_loading?.options?.navbar_handle &&
            <Navbar />
          }

        </Container>
      </header>
    </>
  )
}

Header.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Header);