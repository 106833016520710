import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CSS_CLASSES } from "../../../../css-classes";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const Logo = (props) => {

  const initAssetConfig = {
    width: 30,
    height: 30,
    alt: 'defautl alt',
    description: 'default description',
    image: '/assets/images/default-images/logo.svg',
    link: '/'
  }
  // Set & get state
  const [assets, setAssets] = useState(initAssetConfig);

  // Fetch other media
  const { otherMedia } = useSelector(state => ({
    otherMedia: state.othermediaInformationListR.data
  }));

  // Get device type
  let deviceType = localStorage.getItem('device_type');

  // Effect call for manupulate data
  useEffect(() => {
    setAssets(initAssetConfig);
    // Width
    if (typeof otherMedia[deviceType + '_' + 'logo'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'logo']['width'] != 'undefined') {
      assets['width'] = otherMedia[deviceType + '_' + 'logo']['width'];
      setAssets({
        ...assets
      });
    }

    // Heigth
    if (typeof otherMedia[deviceType + '_' + 'logo'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'logo']['height'] != 'undefined') {
      assets['height'] = otherMedia[deviceType + '_' + 'logo']['height'];
      setAssets({
        ...assets
      });
    }

    // Description
    if (typeof otherMedia[deviceType + '_' + 'logo'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'logo']['description'] != 'undefined') {
      assets['description'] = otherMedia[deviceType + '_' + 'logo']['description'];
      setAssets({
        ...assets
      });
    }

    // alternative
    if (typeof otherMedia[deviceType + '_' + 'logo'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'logo']['alternate'] != 'undefined') {
      assets['alt'] = otherMedia[deviceType + '_' + 'logo']['alternate'];
      setAssets({
        ...assets
      });
    }

    // Image path
    if (typeof otherMedia[deviceType + '_' + 'logo'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'logo']['path'] != 'undefined') {
      assets['image'] = otherMedia[deviceType + '_' + 'logo']['path'];
      setAssets({
        ...assets
      });
    }

    if (typeof otherMedia[deviceType + '_' + 'logo'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'logo']['link'] != 'undefined') {
      assets['link'] = otherMedia[deviceType + '_' + 'logo']['link'];
      setAssets({
        ...assets
      });
    }
  }, [otherMedia]);

  return (
    <>
      {
        assets['link'] != '' ? <a href={assets['link']}>
          <div className="header-logo">
            <span><img className={`img-fluid ${CSS_CLASSES.logo}`} src={assets['image']} alt={assets['alt']} width={assets['width']} height={assets['height']} /></span>
          </div>
        </a> : <div className="">
          <span><img className={`img-fluid ${CSS_CLASSES.logo}`} src={assets['image']} alt={assets['alt']} width={assets['width']} height={assets['height']} /></span>
        </div>
      }

    </>
  )
}

Logo.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Logo);



