import { useEffect, useState } from 'react'
import CommonSec from '../components/commonSection/CommonSec'
import { useSelector } from "react-redux";

const Mobile = () => {

   // Fetch other media
   const { otherMedia } = useSelector(state => ({
      otherMedia: state.othermediaInformationListR.data
   }));

   // Get device type
   let deviceType = localStorage.getItem('device_type');

   const initAssetConfig = {
      width: '300px',
      height: '200px',
      alt: 'defautl alt',
      description: 'default description',
      image: '',
      link: window.location.origin
   }

   const initBannerAssetConfig = {
      width: 300,
      height: 200,
      alt: 'defautl alt',
      description: 'default description',
      image: "/assets/images/gohjiyongzz.gif",
      link: window.location.origin
   }

   const initStyle = {
      width: '',
      height: '',
      backgroundImage: ''
   };

   const [assetsBg, setAssetsBg] = useState(initAssetConfig);
   const [assetsBanner, setAssetsBanner] = useState(initBannerAssetConfig);

   const [style, setStyle] = useState(initStyle);
   // Effect call for manupulate data
   useEffect(() => {

      // Width
      if (typeof otherMedia[deviceType + '_' + 'background'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'background']['width'] != 'undefined') {
         assetsBg['width'] = otherMedia[deviceType + '_' + 'background']['width'];
         style['width'] = otherMedia[deviceType + '_' + 'background']['width'] + 'px';
      }
      // Heigth
      if (typeof otherMedia[deviceType + '_' + 'background'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'background']['height'] != 'undefined') {
         assetsBg['height'] = otherMedia[deviceType + '_' + 'background']['height'];
         style['height'] = otherMedia[deviceType + '_' + 'background']['height'] + 'px';
      }

      // Image path
      if (typeof otherMedia[deviceType + '_' + 'background'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'background']['path'] != 'undefined') {
         assetsBg['image'] = otherMedia[deviceType + '_' + 'background']['path'];
         style['backgroundImage'] = `url(${otherMedia[deviceType + '_' + 'background']['path']})`;
      }

      if (typeof otherMedia[deviceType + '_' + 'background'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'background']['link'] != 'undefined') {
         assetsBg['link'] = otherMedia[deviceType + '_' + 'background']['link'];
      }


      setAssetsBanner(assetsBanner);
      setAssetsBg(assetsBg);
      setStyle(style);

   }, [assetsBg, assetsBanner, style]);

   return (
      <>
         <section className='mobile-section' style={style['backgroundImage'] != '' ? {
            backgroundImage: style['backgroundImage'],
            width: style['width'],
            height: style['height'],
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
         } : {}}>
            <div className='container'>
               <img src='../assets/images/mobile-banner-img.jpg' alt='mobile banner image' className='mobile-banner-img' />
               <div className='versions'>
                  <div className='version-box'>
                     <a href='#'>
                        <img alt='mobile banner image' src='https://brotogel.wl.hkb-gaming1.com/images/apk/android.jpg?v=1.8.0' />
                     </a>
                     <a href='#' className='version-btn'>
                        How To Install
                     </a>
                  </div>
                  <div className='version-box'>
                     <a href='#'>
                        <img alt='mobile banner image' src='http://wigobet.wl.hkb-gaming1.com/images/apk/html5.jpg?v=1.8.0' />
                     </a>
                     <a href='#' className='version-btn'>
                        Mobile Version
                     </a>
                  </div>
                  <div className='version-box'>
                     <a href='#'>
                        <img alt='mobile banner image' src='http://wigobet.wl.hkb-gaming1.com/images/apk/ios.jpg?v=1.8.0' />
                     </a>
                     <a href='#' className='version-btn'>
                        Coming Soon
                     </a>
                  </div>
               </div>
            </div>
         </section>
         <CommonSec />
      </>
   )
}

export default Mobile
