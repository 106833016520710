import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ShowSuccess = (props) => {
    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>
            <p> {props.t('memo_deleted_successfully')}</p>
        </div>
    )
}

ShowSuccess.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ShowSuccess);