import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
  dateFormat,
} from "../../../helpers/global_helper";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { MdRefresh } from "react-icons/md";
import {
  getPlayerBalance,
  getPlayerProfileDetail,
} from "../../../store/actions";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DepositWithdrawMessage from "../../popup/popup-components/DepositWithdrawMessage";
import CInput from "../../commonSection/currency-input/CInput";
import { Col, Row, Table } from "react-bootstrap";
import ActionLoader from "../../../pages/ActionLoader";

const WithdrawInner = (props) => {
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [formData, setFormData] = useState({ amount: "" });
  const [ruleData, setRuleData] = useState({ value: "" });
  const [tenantConfig, setTenantConfig] = useState({});
  const [formError, setFormError] = useState({ amount: [] });
  const [loaderStatus, setLoaderStatus] = useState(false);
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));
  const [playerBalance, setPlayerBalance] = useState([]);
  const [validationStatus, setValidationStatus] = useState(false);
  const [notWithdrawPending, setNotWithdrawPending] = useState(false);
  const [depositWithdrawMessage, setDepositWithdrawMessage] = useState([]);
  const [
    depositWithdrawNotificationClosed,
    setDepositWithdrawNotificationClosed,
  ] = useState(false);
  const [dwMessageId, setDwMessageId] = useState(null);
  const [isPopup, setIsPopup] = useState(false);
  const [spinning, setSpinning] = useState(false);

  const dispatch = useDispatch();

  //get balance data from redux
  const { balanceAmount } = useSelector((state) => ({
    balanceAmount: state.getPlayerBalanceR, //checking redux
  }));

  useEffect(() => {
    if (balanceAmount != undefined) {

      setPlayerBalance(balanceAmount.data);
    }
  }, [balanceAmount]);

  const getRule = () => {
    makeAjax(
      url.ADMIN_API.getRule,
      { code: "withdraw_rule" },
      url.ADMIN_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response.messages);
        return;
      }
      if (response.data) setRuleData(response.data);

    });
  };

  const getValidationStatus = () => {
    makeAjax(url.USER_API.getValidationStatus, [], url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          listLastTenWithdrawRequest();
          return;
        }

        if (response.data.validation_status != "valid") {
          setValidationStatus(false);
          navigate("/validation");
          return;
        } else {
          setValidationStatus(true);
        }
        checkPendingTransaction();
      }
    );
  };

  const checkPendingTransaction = () => {
    makeAjax(url.DPWD_API.checkPendingTransaction, {}, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          listLastTenWithdrawRequest();
          return;
        }
        if (
          response.data &&
          response.data.status == "Pending" &&
          response.data.transaction_type == "withdraw"
        ) {
          setNotWithdrawPending(false);
          navigate("/transaction-queue");
          return;
        } else {
          setNotWithdrawPending(true);
        }
        listLastTenWithdrawRequest();
      }
    );
  };

  const listLastTenWithdrawRequest = () => {
    makeAjax(url.DPWD_API.listLastTenWithdrawRequest, [], url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          return;
        }
        setListData(response.data);

      }
    );
  };

  const formChange = (value, name) => {
    let t = JSON.parse(JSON.stringify(formData));
    t["amount"] = value;
    setFormData(t);
    setFormError({});
  };

  const addWithdrawRequest = () => {
    setLoaderStatus(true);
    setFormError({});
    makeAjax(url.DPWD_API.addWithdrawRequest, formData, url.DPWD_MS_EXT).then(
      (response) => {
        togglePopUp();
        if (response.status != respStatus["SUCCESS"]) {
          setLoaderStatus(false);
          getTenantWithdrawConfig();
          if (response.code == "validation_error") {
            setFormError(response.messages);
            return;
          }
          showMessage(response);
          return;
        }
        setLoaderStatus(false);
        showMessage(response);
        listLastTenWithdrawRequest();
        navigate("/transaction-queue");
      }
    );
  };

  const getTenantWithdrawConfig = () => {
    makeAjax(url.DPWD_API.getTenantWithdrawConfig, null, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          return;
        }
        setTenantConfig(response.data);

      }
    );
  };

  useEffect(() => {
    getValidationStatus();
    getRule();
    getTenantWithdrawConfig();
    fetchPlayerBalance();
    depositWithdrawNotificationMessage();
  }, []);

  const togglePopUp = () => {
    setIsPopup(!isPopup);
  };

  const submitWithdraw = () => {
    if (formData.amount > 0) {
      togglePopUp();
      return;
    }
    showMessage("Withdraw amount is required.");
  };

  const handleRefreshBalance = () => {
    let orderBy = { withdraw: true };

    makeAjax(url.GP_API.transferIn, orderBy, url.GP_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
    });
    dispatch(getPlayerBalance());
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 2000);
  };

  const fetchPlayerBalance = () => {
    makeAjax(url.GP_API.getBalance, [], url.GP_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setPlayerBalance(response.data);
    });
  };

  //withdraw notification pop up

  const handleNotificationPopup = () => {
    messageRead();
    setDepositWithdrawNotificationClosed(false);
  };

  const messageRead = () => {
    if (messageRead != null) {
      let payload = {
        _id: dwMessageId,
      };
      makeAjax(
        url.DPWD_API.depositWithdrawReadMessage,
        payload,
        url.DPWD_MS_EXT
      ).then((response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        fetchPlayerBalance();
      });
    }
  };

  const depositWithdrawNotificationMessage = () => {
    makeAjax(
      url.DPWD_API.depositWithdrawNotificationMessage,
      [],
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setDepositWithdrawMessage(response.data);
      if (response.data.length > 0) {
        setDwMessageId(response.data[0]._id);
        setDepositWithdrawNotificationClosed(true);
      }
    });
  };

  return (
    <>
      {validationStatus && notWithdrawPending && (
        <div className="deposit">
          <div className="container">
            {/* <h4 className="title">{props.t('withdraw')}</h4> */}
            <div className="deposit-box">
              <div className="deposit-box-l">
                <div className="title-box">
                  <h4 className="sub-title mb-lg-3">{props.t("recipient")}</h4>
                  <div className="table-res">
                    <table>
                      <thead>
                        <tr>
                          <td>
                            {props.t("bank")} / {props.t("e_wallet")}:
                          </td>

                          <td className="data">
                            {userProfile && userProfile.player_bank_detail
                              ? userProfile.player_bank_detail.bank_detail
                                ? userProfile.player_bank_detail.bank_detail
                                  .bank_name
                                : ""
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {props.t("account_e_wallet_name")}:
                          </td>

                          <td className="data">
                            {userProfile && userProfile.player_bank_detail
                              ? userProfile.player_bank_detail.account_name
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {props.t("account")} /{" "}
                            {props.t("phone_number")}:
                          </td>

                          <td className="data">
                            {userProfile && userProfile.player_bank_detail
                              ? userProfile.player_bank_detail.account_number
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{props.t("min_withdraw")}:</td>
                          <td className="data">
                            {tenantConfig
                              ? currencyFormat(tenantConfig.min_withdraw)
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{props.t("max_withdraw")}:</td>
                          <td className="data">
                            {tenantConfig
                              ? currencyFormat(tenantConfig.max_withdraw)
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{props.t("max_withdraw_queue")}:</td>
                          <td className="data">
                            {tenantConfig
                              ? tenantConfig.withdraw_queue_max
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{props.t("current_queue_number")}:</td>

                          <td className="data">
                            {tenantConfig
                              ? tenantConfig.current_withdraw_queue_count
                              : ""}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{ __html: ruleData.value }}
                  ></div>
                </div>
              </div>
              <div className="deposit-box-r">
                <div className="title-box">
                  <h4 className="sub-title mb-lg-3">{props.t("confirm_withdraw")}</h4>
                  <div className="table-res">
                    <table>
                      <thead>
                        <tr>
                          <td>{props.t("username")}:</td>

                          <td className="data">
                            {userProfile ? userProfile.username : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{props.t("balance")}:</td>

                          <td className="data d-flex align-items-center" style={{ wordBreak: "unset" }}>
                            {playerBalance ? currencyFormat(playerBalance?.main_balance) : ""}
                            &nbsp;&nbsp;
                            <div
                              className="header-btn refresh"
                              onClick={() => {
                                handleRefreshBalance();
                              }}
                            >
                              <MdRefresh
                                className={`spin-button ${spinning ? "spin" : ""
                                  }`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>{props.t("withdraw_amount")}:</td>

                          <td className="data">
                            <CInput
                              type="number"
                              value={formData.amount}
                              placeholder={props.t("amount")}
                              maxLength={15}
                              onValueChange={(value, name) =>
                                formChange(value, name)
                              }
                            />
                            <span className="text-danger">
                              {formError.amount ? formError.amount[0] : ""}
                            </span>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <button onClick={submitWithdraw} className="cus-process">
                    {props.t("process")}
                  </button>
                </div>
                <div className="title-box2">
                  <h4 className="sub-title">{props.t("last_ten_withdraw")}</h4>
                  <div className="table-res1">
                    <Table>
                      <thead>
                        <tr>
                          <th>{props.t("date")}</th>
                          <th className="deposit-data">{props.t("amount")}</th>
                          <th className="deposit-data">{props.t("status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listData.map((item, i) => (
                          <tr key={i}>
                            <td>{dateFormat(item.requested_at)}</td>
                            {/* .toLocaleString()} */}
                            <td className="deposit-data">
                              {" "}
                              {currencyFormat(item.amount)}{" "}
                            </td>
                            <td className="deposit-data">{item.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPopup && (
        <div className={"popup-modal"} id="confirmWithdrawId">
          <div className="modal-cus">
            <span className="close" onClick={togglePopUp}>
              <GrFormClose />
            </span>
            <div className="popup-box">
              <h4 className="title">{props.t("confirm_withdraw")}</h4>
              <p className="text-center">
                {props.t("your_withdrawal_request_will_be_process_immediately")}
              </p>

              <div className="text-center">
                <ActionLoader
                  label={props.t("confirm")}
                  loaderClass={"confirm-widthdraw"}
                  showLoader={loaderStatus}
                  onClick={addWithdrawRequest}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WithdrawInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(WithdrawInner);
