import { useEffect, useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { makeAjax, respStatus, url } from '../../helpers/global_helper';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const initSetting = {
    floating_link_pf: null,
    class: null,
    default_class: null,
};

const FloatingComponents = (props) => {
    const [fnSetting, setFnSetting] = useState(initSetting);
    const [showTopBtn, setShowTopBtn] = useState(false);
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400)
                setShowTopBtn(true)
            else
                setShowTopBtn(false)
        })
    }, [])
    const scrollTop = () => {
        window.scrollTo({
            top: 0
        })
    }
    const [showLink, setShowLink] = useState(false);
    const [alternativeSite, setAlternativeSite] = useState([]);
    const getAlternativeSite = () => {
        makeAjax(url.USER_API.listPlayerAlternativeSite, {})
            .then(response => {
                if (response.status !== respStatus['SUCCESS']) {
                    setAlternativeSite([]);
                    return;
                }
                setAlternativeSite(response.data.data)
            })
    }
    useEffect(() => {
        getAlternativeSite();
    }, []);

    const toggleLink = (e) => {
        e.preventDefault();
        let t = showLink;
        setShowLink(!t);
    }

    useEffect(() => {
        let temp = { ...fnSetting };
        temp['floating_link_pf'] = componentSetting?.["floating-components"]?.["default-value"];
        temp['default_class'] = componentSetting?.["floating-components"]?.["default-value"];
        setFnSetting(temp);
    }, [componentSetting?.["floating-components"]]);


    return (
        <>
            {
                fnSetting['floating_link_pf'] && <div className="floating-link">
                    <div className={fnSetting['default_class']}>
                        <li ><FiExternalLink /><p className='alter'>{props.t('alternative_links')}</p> </li>
                        <ol className="link_ol">
                            {
                                alternativeSite.map((v, i) =>
                                    <li key={i}>
                                        <a target="_blank" href={v.is_secured ? 'https://' + v.domain : 'http://' + v.domain}>{v.domain}</a>
                                    </li>
                                )
                            }
                        </ol>
                    </div>
                </div>
            }

        </>
    )
}

FloatingComponents.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(FloatingComponents);