import React from 'react'
import { FiChevronDown, FiRefreshCw } from 'react-icons/fi'
import { MdNotificationsNone, MdRefresh } from 'react-icons/md'
import { CgPassword } from 'react-icons/cg'
import { HiOutlineMail, HiOutlineLogout, HiGift, HiOutlineCheckCircle } from 'react-icons/hi'
import { IoIosArrowDown } from 'react-icons/io'
import { RiInformationLine } from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import { BiUserCircle, BiTimeFive, BiLogOut, BiUser } from 'react-icons/bi'
import { PiCoins } from 'react-icons/pi'
import { FaRegEnvelope, FaRegUser } from 'react-icons/fa'
import { GiTakeMyMoney, GiBookPile } from 'react-icons/gi'
import { BsFillKeyFill, BsCashCoin, BsBarChartLineFill } from 'react-icons/bs'
import { IoIosArrowForward } from 'react-icons/io'
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom'
import { RiErrorWarningLine } from 'react-icons/ri'
import Popup from '../../../../components/popup/Popup'
import { useState, useEffect, useRef } from 'react'
import { showMessage, makeAjax, url, respStatus } from '../../../../helpers/global_helper'
import { useSelector } from 'react-redux'
import { getLogout, getPlayerBalance, getPlayerProfileDetail, resetPlayerProfile } from '../../../../store/actions'
import { useDispatch } from "react-redux"
// import { Dropdown } from 'rsuite';
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DepositWithdrawMessage from '../../../../components/popup/popup-components/DepositWithdrawMessage'
import { thisTypeAnnotation } from '@babel/types'
import { resetPinStatus } from '../../../../store/check-pin-status/actions'
import { currencyFormat } from '../../../../helpers/global_helper';
import LanguageDropdown from '../../../../components/languageDropdown/LanguageDropdown'

const AfterUserLogin = (props) => {
    // set & get data
    const [subMenu, setSubMenu] = useState(false)
    const [subMenu2, setSubMenu2] = useState(false)
    const [playerBalance, setPlayerBalance] = useState([]);
    const [depositWithdrawMessage, setDepositWithdrawMessage] = useState([]);
    const [rejectValidationPopup, setRejectValidationPopup] = useState(false);
    const [tenantLotteryProviderDD, settenantLotteryProviderDD] = useState([]);
    const [isNotification, setNotification] = useState(false);
    const [isPopupReset, setIsPopupReset] = useState(false);
    const [notificationCount, setNoticationCount] = useState(0)
    const [isClicked, setIsClicked] = useState(false);
    const [promotionCategory, setPromotionCategory] = useState([]);
    const { logoutInfo } = useSelector(state => ({
        logoutInfo: state.logoutR.data
    }));
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
    }));

    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    const dispatch = useDispatch();

    const [unreadMessage, setUnreadMessageCount] = useState(false);
    const navigate = useNavigate()

    const handleIconClick = () => {
        makeAjax(
            url.ADMIN_API.promotionCategoryNameDD, [], url.ADMIN_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    // showMessage(response);
                    return;
                }
                setPromotionCategory(response.data);
            });
        setIsClicked(!isClicked);
    };

    let redirectArr = ['/login-pin', '/security-question', '/security-validation', '/reminder-password'];
    const getPinStatus = () => {
        makeAjax(
            url.USER_API.checkIfPinAlreadySet, [], url.USER_MS_EXT).then(response => {
                let resp = response.data;
                if (response.status != respStatus['SUCCESS']) {

                    return;
                }
                if (resp) {
                    let config_status = resp.get_tenant_config?.value;
                    if (config_status === true) {
                        if (resp.enabled_active_pin === true && resp.active_pin === false && !redirectArr.includes(location.pathname)) {
                            navigate("/login-pin");
                            return;
                        } else if (resp.enabled_active_pin === true && resp.is_pin_verified === false && !redirectArr.includes(location.pathname)) {
                            navigate("/security-validation");
                            return;
                        }
                        else if ((resp.is_security_question_set === false || resp.is_security_question_set === null) && !redirectArr.includes(location.pathname)) {
                            navigate("/security-question");
                            return;
                        }
                        else if (resp.is_password_active === false && !redirectArr.includes(location.pathname)) {
                            navigate("/reminder-password");
                            return;
                        }
                    }
                }
            }).catch(ex => {

            });
    }

    useEffect(() => {
        getPinStatus();
        dispatch(getPlayerBalance());
    }, []);

    useEffect(() => {
        if (userProfile && userProfile.validation_status == "invalid") {
            setRejectValidationPopup(true);
        };
    }, [userProfile]);

    let loggedIn = window.localStorage.getItem('isLoggedIn')
    // Logout api call
    const logout = () => {
        dispatch(getLogout());
        window.localStorage.removeItem("user_token");
        window.localStorage.removeItem("isLoggedIn");
        window.localStorage.removeItem("desktop_pop_up_after_login");  // added 29th jan 2024
        window.localStorage.removeItem("mobile_pop_up_after_login");
        dispatch(resetPlayerProfile());
        dispatch(resetPinStatus());
        makeAjax(url.USER_API.listPlayerAlternativeSite, {})
            .then(response => {
                if (response.status !== respStatus['SUCCESS'] || (response.data && response.data.data && response.data.data.length == 0)) {
                    navigate('/');
                    return;
                }
                let sites = response.data.data;
                for (let i = 0; i < sites.length; i++) {
                    if (sites[i]['is_logout'] && window.location.host != sites[i]['domain']) {
                        window.location.assign((sites[i]['is_secure'] ? 'https' : 'http') + '://' + sites[i]['domain']);
                        return;
                    }
                }
                let nonPrimary = {};
                for (let j = 0; j < sites.length; j++) {
                    if (sites[j]['is_primary'] && window.location.host != sites[j]['domain']) {
                        window.location.assign((sites[j]['is_secure'] ? 'https' : 'http') + '://' + sites[j]['domain']);
                        return;
                    } else if (!sites[j]['is_login'] && !sites[j]['is_logout'] && !sites[j]['is_redirect'] && window.location.host != sites[j]['domain']) {
                        nonPrimary = sites[j];
                    }
                }
                if (nonPrimary.domain) {
                    window.location.assign((nonPrimary.is_secure ? 'https' : 'http') + '://' + nonPrimary.domain);
                    return;
                } else {
                    navigate('/');
                }
            }).catch(ex => {
                navigate('/');
            });
    }

    // Effect List
    useEffect(() => {
        dispatch(getPlayerProfileDetail());
    }, []);

    const [isPopup, setIsPopup] = useState(false)

    // Close popup
    const handlePopup = () => {
        setIsPopup(false);
        setNotification(false);
        setIsPopupReset(false);
    }




    const updateLastLoginAt = () => {
        makeAjax(
            url.USER_API.lastActiveAt, [], url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
            });
    };

    const [emailVer, setEmailVer] = useState(false)
    useEffect(() => {
        const timeout = setInterval(() => {
            setEmailVer(false);
        }, 5000);
        return () => {
            clearInterval(timeout);
        };
    }, []);

    const handleEmailVer = () => {
        setEmailVer(!emailVer);
    }

    const goToMemoPage = (event) => {
        navigate('/memo')
    }

    useEffect(() => {
        updateLastLoginAt();
        const interval1 = setInterval(() => updateLastLoginAt(), 60000)
        return () => {
            clearInterval(interval1);
        }
    }, []);

    const playerUnreadMessage = (event) => {
        makeAjax(url.MEMO_API.getPlayerUnreadMessageCount, [], url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    return;
                }
                setUnreadMessageCount(response.data);
            })
    }

    useEffect(() => {
        playerUnreadMessage();
        fetchPlayerBalance();
        const interval1 = setInterval(() => playerUnreadMessage(), 60000)
        return () => {
            clearInterval(interval1);
        }
    }, []);


    const handleRefreshBalance = () => {
        makeAjax(url.GP_API.getBalance, '', url.GP_MS_EXT).then(response => {
            if (response.status != respStatus["SUCCESS"]) {
                if (response.code != "validation_error") {
                    showMessage(response);
                    return;
                }
                showMessage(response);
                return;
            }
        })

        fetchPlayerBalance();

    };

    const transferIn = () => {
        let orderBy = { withdraw: true };

        makeAjax(url.GP_API.transferIn, orderBy, url.GP_MS_EXT).then(response => {
            if (response.status != respStatus["SUCCESS"]) {
                if (response.code != "validation_error") {
                    showMessage(response);
                    return;
                }
                showMessage(response);
                return;
            }
        })

        dispatch(getPlayerBalance());

        setSpinning(true);
        setTimeout(() => {
            setSpinning(false);
        }, 2000);

    };

    const fetchPlayerBalance = () => {
        makeAjax(
            url.GP_API.getBalance, [], url.GP_MS_EXT).then(response => {

                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                setPlayerBalance(response.data);
            });
    };

    // Effect List
    useEffect(() => {
        fetchPlayerBalance();
    }, []);

    useEffect(() => {
        document.addEventListener("onBlur", closeVerificationPOPUP, true);

    }, [])

    // const refOne = useRef()

    const closeVerificationPOPUP = (e) => {
        setEmailVer(false);
    }

    const { playerMenu } = useSelector(state => ({
        playerMenu: state.menuListR.data
    }));


    const location = useLocation();


    const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
        <Link ref={ref} to={href} {...rest} >
            {children}
        </Link>
    ));

    //# notificcation deposit withdraw

    const rejectedPophandel = () => {
        setRejectValidationPopup(false);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            handleRefreshBalance();
        }, 100000); // 60000 milliseconds = 1 minute

        return () => {
            clearInterval(interval);
        };
    }, []);

    const [spinning, setSpinning] = useState(false);

    const { memoCount } = useSelector(state => ({

        memoCount: state.getMemoCountR //checking redux
    }));

    useEffect(() => {
        if (memoCount != undefined) {
            setUnreadMessageCount(memoCount.data);
        }
    }, [memoCount]);


    //get balance data from redux
    const { balanceAmount } = useSelector(state => ({
        balanceAmount: state.getPlayerBalanceR //checking redux
    }));

    useEffect(() => {
        if (balanceAmount != undefined) {
            setPlayerBalance(balanceAmount.data);
        }
    }, [balanceAmount]);

    const gettenantLotteryProviderDD = () => {
        makeAjax(
            url.GP_API.tenantLotteryProviderDD, [], url.GP_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                settenantLotteryProviderDD(response.data);
            });
    }

    const getNotificationCount = () => {
        makeAjax(url.MEMO_API.getPlayerUnreadMessageCount, [], url.MEMO_MS_EXT).then((response) => {

            if (response.status != respStatus["SUCCESS"]) {
                showMessage(response);
                return;
            }
            setNoticationCount(response.data);
        })
    }
    useEffect(() => {
        getNotificationCount();
        gettenantLotteryProviderDD();
    }, [])

    const resetHandlePopup = () => {
        setIsPopupReset(false);
    }

    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            // Clicked outside the dropdown, so close it
            setIsClicked(false);
        }
    };

    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [isActive, setIsActive] = useState(false);

    const handleItemClick = () => {
        // Toggle the isActive state on click
        setIsActive(!isActive);
    };

    const [isArrowUp, setIsArrowUp] = useState(false);

  const toggleArrowDirection = () => {
    setIsArrowUp((prevState) => !prevState);
  };

    return (
        <>
            <div className='after-login-header'>

                {
                    !componentSetting?.custom_loading?.options?.profile_handle &&
                    <div className='wigo-1'>
                        <Dropdown data-bs-theme="dark">
                            <Dropdown.Toggle className="user-icon rs-dropdown-toggle">
                                <span className="usr-profle">
                                    {props.t('menu')}
                                </span>
                                <IoIosArrowDown className="down-arrow" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={NavLink} href="/profile">
                                    <BiUserCircle /> {props.t('profile')}
                                </Dropdown.Item>
                                {(playerMenu.after && playerMenu.after.memo && playerMenu.after.memo.status) &&
                                    <Dropdown.Item as={NavLink} href="/memo">
                                        <FaRegEnvelope /> {playerMenu.after.memo.name}
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item><a onClick={() => setIsPopup(curr => !curr)}>
                                    <BsFillKeyFill /> {props.t('change_password')}
                                </a></Dropdown.Item>
                                {(playerMenu.after && playerMenu.after.deposit && playerMenu.after.deposit.status) &&
                                    <Dropdown.Item as={NavLink} href="/deposit">
                                        <BsCashCoin /> {props.t(`${playerMenu.after.deposit.code}`)}
                                    </Dropdown.Item>
                                }
                                {(playerMenu.after && playerMenu.after.withdraw && playerMenu.after.withdraw.status) &&
                                    <Dropdown.Item as={NavLink} href="/withdraw">
                                        <GiTakeMyMoney />{props.t(`${playerMenu.after.withdraw.code}`)}
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item as={NavLink} href="/trans-history">
                                    <GiBookPile /> {props.t('transaction_history')}
                                </Dropdown.Item>
                                {(playerMenu.after && playerMenu.after.info_promo && playerMenu.after.info_promo.status) &&
                                    <Dropdown.Item as={NavLink} href="/promo-info">
                                        <HiGift /> {playerMenu.after.info_promo.name}
                                    </Dropdown.Item>
                                }
                                {(playerMenu.after && playerMenu.after.info_turnover && playerMenu.after.info_turnover.status) &&
                                    <Dropdown.Item onMouseOver={() => setSubMenu2(true)} onMouseLeave={() => setSubMenu2(false)}>
                                        <span className="sub-menu-dropdown">
                                            <BsBarChartLineFill /> {props.t(`${playerMenu.after.info_turnover.code}`)}
                                            <IoIosArrowForward />
                                            {subMenu2 &&
                                                <div className="sub-menu">
                                                    <Dropdown.Item as={NavLink} href="/comingsoon" onClick={() => setSubMenu2(false)}>
                                                        <HiGift /> HKB Gaming
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={NavLink} href="../turnover" onClick={() => setSubMenu2(false)}>
                                                        <HiGift />{props.t('other_providers')}
                                                    </Dropdown.Item>
                                                </div>
                                            }
                                        </span>
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item onMouseOver={() => setSubMenu(true)} onMouseLeave={() => setSubMenu(false)}>
                                    <span className="sub-menu-dropdown">
                                        <BiTimeFive /> {props.t('outstanding_bet')}
                                        <IoIosArrowForward />
                                        {subMenu &&
                                            <div className="sub-menu">
                                                {tenantLotteryProviderDD?.map((data, index) => {
                                                    return (
                                                        <Dropdown.Item as={NavLink} href={`/outstanding-bet/${data?._id}`} onClick={() => setSubMenu(false)}>
                                                            <HiGift /> {data?.name}
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item><a onClick={logout}>
                                    <HiOutlineLogout /> {props.t('log_out')}
                                </a></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }
                {
                    componentSetting?.custom_loading?.options?.profile_handle && (
                        <div className='wigo-4'>
                            <div className='promo-chip-bg'>
                                <PiCoins className="coins" />
                                <div className="idr-div">
                                    <div className='iconhoverdiv' ref={dropdownRef}>
                                        <div className="icon d-flex" onClick={handleIconClick}>
                                            <p>{currencyFormat(playerBalance.promo_balance)}</p>
                                            <RiInformationLine />
                                        </div>
                                        {isClicked && (
                                            <div className="click-content">
                                                <p>{props.t('following_categories_are_applicable_for_promos')}</p>
                                                <ul>
                                                    {promotionCategory && promotionCategory !== null && promotionCategory.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <span className="idr-num">{userProfile ? userProfile.currency : ''} <>{currencyFormat(playerBalance.main_balance)}</></span>
                                </div>

                                <div className="refresh" onClick={() => { transferIn() }}>
                                    <MdRefresh className={`spin-button ${spinning ? 'spin' : ''}`} />
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !componentSetting?.custom_loading?.options?.profile_handle &&
                    <div className='wigo-5'>
                        <div className='promo-chip-bg'>
                            <div className="idr-div">
                                <PiCoins className="coins" />
                                <span className="idr-num">{userProfile ? userProfile.currency : ''} <>{currencyFormat(playerBalance?.main_balance)}</></span>
                                <div className="header-btn refresh" onClick={() => { transferIn() }}>
                                    <FiRefreshCw className={`spin-button ${spinning ? 'spin' : ''}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    playerMenu.after && playerMenu.after.memo && playerMenu.after.memo.status && (
                        <div className='wigo-2'>
                            {
                                componentSetting?.custom_loading?.options?.profile_handle &&
                                <div>
                                    <div className="notifications" onClick={() => setNotification(true)}>
                                        {notificationCount > 0 ? <div className="red-dot"></div> : null}
                                        <MdNotificationsNone />
                                    </div>
                                </div>
                            }
                            {
                                !componentSetting?.custom_loading?.options?.profile_handle &&
                                <div className="notifications" onClick={goToMemoPage}>
                                    <span className="num">{unreadMessage}</span>
                                    <HiOutlineMail />
                                </div>
                            }
                        </div>
                    )
                }

                {
                    !componentSetting?.custom_loading?.options?.profile_handle &&
                    <div className='wigo-3'>
                        <div className="profile-tab">
                            <BiUserCircle />
                            {userProfile.email_verified_at ? (
                                <HiOutlineCheckCircle onClick={handleEmailVer} style={{ 'fontSize': '18px', 'color': 'green' }} />
                            ) :
                                <RiErrorWarningLine className='error' onClick={handleEmailVer} />
                            }

                            {userProfile.email_verified_at && emailVer ?
                                (<span className="unverfied-email verified-email"> {props.t('verified_email')}</span>)
                                : ""}

                            {!userProfile.email_verified_at && emailVer ?
                                (<NavLink to="email-verification" className="unverfied-email"> {userProfile.email_verified_at} {props.t('unverified_email')}</NavLink>)
                                : ""}


                            <span className="profile-id">{userProfile.username}</span>
                        </div>
                    </div>
                }

                {
                    componentSetting?.custom_loading?.options?.profile_handle &&
                    <div className='wigo-8'>
                        <Dropdown data-bs-theme="dark">
                            <Dropdown.Toggle className="user-icon">
                                <span className="usr-profle">
                                    {userProfile.username
                                        ? userProfile.username[0].toUpperCase()
                                        : "U"}
                                </span><IoIosArrowDown className={`arrow ${isArrowUp ? 'up-arrow' : 'down-arrow'}`} onClick={toggleArrowDirection} />
                                {!userProfile.email_verified_at && <div className="red-dot2"></div>}
                            </Dropdown.Toggle>
                            {!userProfile.email_verified_at && <div className="red-dot2"></div>}
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    as={NavLink}
                                    href="/profile"
                                    active={isActive}
                                    onClick={handleItemClick}
                                >

                                    <BiUser className="header-proicons" />
                                    <span>
                                        {props.t('profile')}
                                        {!userProfile.email_verified_at && <div className="red-dot2"></div>}
                                    </span>
                                </Dropdown.Item>

                                <Dropdown.Item>
                                    <div onClick={() => setIsPopupReset(!isPopupReset)}>
                                        <CgPassword className="header-proicons" />
                                        {' '}<span>{props.t('reset_password')}</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={logout}>
                                    {" "}
                                    <TbLogout className="header-proicons" />
                                    <span>{props.t('log_out')}</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }


                {(playerMenu.after && playerMenu.after.deposit && playerMenu.after.deposit.status) &&
                    <div className='wigo-5'>
                        <div className="res-align deposit-btn-bg">
                            <NavLink to={'deposit'} className="dropdown-item"> {props.t(`${playerMenu.after.deposit.name.toLowerCase()}`)}</NavLink>
                        </div>
                    </div>
                }

                {(playerMenu.after && playerMenu.after.deposit && playerMenu.after.withdraw.status) &&
                    <div className='wigo-6'>
                        <div className="header-btn res-align">
                            <NavLink to={'withdraw'} className="dropdown-item"> {props.t(`${playerMenu.after.withdraw.name.toLowerCase()}`)} </NavLink>
                        </div>
                    </div>
                }


                {
                    !componentSetting?.custom_loading?.options?.profile_handle &&
                    <div className='wigo-7'>
                        <div className="header-btn res-align">
                            <span onClick={logout}>{props.t('log_out')} <HiOutlineLogout /></span>
                        </div>
                    </div>
                }
                <LanguageDropdown />
            </div>
            {isPopup && <Popup handlePoup={handlePopup} changePass />}
            {rejectValidationPopup && <Popup handlePoup={rejectedPophandel} playerRejected />}
            {isNotification &&
                <Popup handlePoup={handlePopup} length={notificationCount} getNotificationCount={getNotificationCount} readNotification />
            }
            {isPopupReset && (<Popup handlePoup={resetHandlePopup} changePass />)}
        </>
    )
}

AfterUserLogin.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(AfterUserLogin);