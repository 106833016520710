import LotteryBoxSlider from "./lottery-box-slider/LotteryBoxSlider";
import LotteryFullBoxSlider from "./lottery-full-box-slider/LotteryFullBoxSlider";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";
import { useEffect, useState } from "react";
import LotteryGrid from "./lotterygrid/LotteryGrid";

const initSetting = {
  lottery_result_ty: null,
  class: null,
  default_class: null,
};

const LotteryResults = () => {
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));


  const [lotteryResult, setLotteryResult] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [fnSetting, setFnSetting] = useState(initSetting);

  const getLotteryResult = (e = null) => {
    if (e)
      e.preventDefault();
    makeAjax(url.GP_API.getLotteryResult, [], url.GP_MS_EXT).then(response => {
      if (response.status != respStatus['SUCCESS']) {
        showMessage(response);
        return;
      }
      setLotteryResult(response.data);
      if (response.data.length == 2) {
        const firstData = response.data[0];
        setLotteryResult(prevData => {
          const newData = [...prevData];
          newData.push(firstData);
          return newData;
        });
      } else if (response.data.length === 1) {
        const firstData = response.data[0];
        setLotteryResult(prevData => {
          const newData = [...prevData];
          newData.push(firstData);
          newData.push(firstData);
          return newData;
        });
      }
      setSpinning(true);
      setTimeout(() => {
        setSpinning(false);
      }, 1000);
    });
  }

  useEffect(() => {
    getLotteryResult();
  }, []);

  const lottery = [
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 1, num2: 6, num3: 7, num4: 2 },
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 1, num2: 6, num3: 7, num4: 2 },
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 1, num2: 6, num3: 7, num4: 2 },
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 4, num2: 3, num3: 2, num4: 1 },
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 1, num2: 6, num3: 7, num4: 2 },
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 1, num2: 6, num3: 7, num4: 2 },
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 1, num2: 6, num3: 7, num4: 2 },
    { counImg: '/assets/images/lottery/gp.png', counName: 'Hong Kong', counDate: '20/12/2022', num1: 1, num2: 6, num3: 7, num4: 2 },
  ]

  useEffect(() => {
    let temp = { ...fnSetting };
    temp['lottery_result_ty'] = componentSetting?.["lottery-result-type"]?.["default-value"];
    temp['default_class'] = componentSetting?.["lottery-result-type"]?.["default-value"];
    setFnSetting(temp);
  }, [componentSetting]);

  return (
    <>

      {
        fnSetting['lottery_result_ty'] && < div className="lottery-result">
          <div className="container">
            <div className={fnSetting['default_class']}>
              {
                fnSetting['lottery_result_ty'] == 'full-slider' && <LotteryFullBoxSlider spinning={spinning} lotteryResult={lotteryResult} lottery={lottery} getLotteryResult={getLotteryResult} />
              }
              {
                fnSetting['lottery_result_ty'] == 'half-slider' && <LotteryFullBoxSlider spinning={spinning} lotteryResult={lotteryResult} lottery={lottery} getLotteryResult={getLotteryResult} />

              }
              {
                fnSetting['lottery_result_ty'] == 'grid' && <LotteryGrid spinning={spinning} lotteryResult={lotteryResult} lottery={lottery} getLotteryResult={getLotteryResult} />
              }
            </div>
          </div >
        </div>
      }
    </>
  )
}

LotteryResults.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(LotteryResults);