import React, { useState, useEffect } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../helpers/global_helper";
import { useNavigate } from "react-router-dom";
import CommonSec from "../commonSection/CommonSec";
import { CSS_CLASSES } from "../../css-classes";
import { fetchReferalInfo } from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ReferralsInnerNonLogin = (props) => {
  const navigate = useNavigate();
  const [readmore, setReadmore] = useState(false);

  const accordian = [
    {
      id: 1,
      title: "YOU WANT PASSIVE INCOME EVERY DAY?",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum aliquid iure dicta provident? Et dolor doloremque animi culpa minima laudantium maiores unde excepturi suscipit maxime sed perspiciatis, expedita quo nesciunt.",
    },
    {
      id: 2,
      title: "BONUS REFERRAL CARD GAMES DI WIGOBET",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum aliquid iure dicta provident? Et dolor doloremque animi culpa minima laudantium maiores unde excepturi suscipit maxime sed perspiciatis, expedita quo nesciunt.",
    },
    {
      id: 3,
      title: "LOTTERY REFERRAL BONUS AT WIGOBET",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum aliquid iure dicta provident? Et dolor doloremque animi culpa minima laudantium maiores unde excepturi suscipit maxime sed perspiciatis, expedita quo nesciunt.",
    },
    {
      id: 4,
      title: "DINGDONG REFERRAL BONUS AT WIGOBET",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum aliquid iure dicta provident? Et dolor doloremque animi culpa minima laudantium maiores unde excepturi suscipit maxime sed perspiciatis, expedita quo nesciunt.",
    },
  ];

  const [active, setActive] = useState(1);
  const [showAnimate, setShowAnimate] = useState(false);
  const handleAccordian = (id) => {
    setTimeout(() => {
      setShowAnimate(true);
    }, 1000);
    setActive(id);
    if (id === active) {
      setActive(0);
    }
  };

  const [page_content, setPageContent] = useState("");
  const readFile = () => {
    makeAjax(
      url.ADMIN_API.readFile,
      { entity_type: "referral_" + localStorage.getItem("device_type") },
      url.ADMIN_MS_EXT
    ).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setPageContent(response.data);
    });
  };

  useEffect(() => {
    localStorage.getItem("device_type");
    readFile();
  }, []);

  // Fetch other media
  const { otherMedia } = useSelector((state) => ({
    otherMedia: state.othermediaInformationListR.data,
  }));

  // Get device type
  let deviceType = localStorage.getItem("device_type");

  const initAssetConfig = {
    width: "300px",
    height: "200px",
    alt: "defautl alt",
    description: "default description",
    image: "",
    link: window.location.origin,
  };

  const initBannerAssetConfig = {
    width: 300,
    height: 200,
    alt: "defautl alt",
    description: "default description",
    image: "/assets/images/gohjiyongzz.gif",
    link: window.location.origin,
  };

  const initStyle = {
    width: "",
    height: "",
    backgroundImage: "",
  };

  const [assetsBg, setAssetsBg] = useState(initAssetConfig);
  const [assetsBanner, setAssetsBanner] = useState(initBannerAssetConfig);

  const [style, setStyle] = useState(initStyle);
  // Effect call for manupulate data
  useEffect(() => {
    // Width
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["width"] != "undefined"
    ) {
      assetsBg["width"] = otherMedia[deviceType + "_" + "background"]["width"];
      style["width"] =
        otherMedia[deviceType + "_" + "background"]["width"] + "px";
    }
    // Heigth
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["height"] !=
      "undefined"
    ) {
      assetsBg["height"] =
        otherMedia[deviceType + "_" + "background"]["height"];
      style["height"] =
        otherMedia[deviceType + "_" + "background"]["height"] + "px";
    }

    // Image path
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["path"] != "undefined"
    ) {
      assetsBg["image"] = otherMedia[deviceType + "_" + "background"]["path"];
      style["backgroundImage"] = `url(${otherMedia[deviceType + "_" + "background"]["path"]
        })`;
    }

    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["link"] != "undefined"
    ) {
      assetsBg["link"] = otherMedia[deviceType + "_" + "background"]["link"];
    }

    setAssetsBanner(assetsBanner);
    setAssetsBg(assetsBg);
    setStyle(style);
  }, [assetsBg, assetsBanner, style]);

  return (
    <div
      className="referrals"
      style={
        style["backgroundImage"] != ""
          ? {
            backgroundImage: style["backgroundImage"],
            width: style["width"],
            height: style["height"],
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }
          : {}
      }
    >
      <div
        className="container"
        dangerouslySetInnerHTML={{ __html: page_content }}
      >

      </div>

    </div>
  );
};

ReferralsInnerNonLogin.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReferralsInnerNonLogin);
