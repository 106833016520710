import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LazyLoading = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(); // Fetch initial data when the component mounts
    }, []);

    const fetchData = async () => {
        setLoading(true);

        try {
            // Simulate fetching data from an API
            const response = await axios.get(`https://jsonplaceholder.typicode.com/photos?_page=${page}`);
            const newData = response.data;

            setData((prevData) => [...prevData, ...newData]);
            setPage((prevPage) => prevPage + 1);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            fetchData(); // Load more data when scrolled to the bottom
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Ensure this effect runs only once
    const datame = [
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/live_casinos",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        }, {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/live_casinos",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
        {
            cmlable: "Gates of Olympus",
            cmsublabel: "Pragmatic Play",
            imgpath: "../assets/images/hotgame-images/hotgame-1.png",
            link: "/register",
        },
    ];
    return (
        <div>
            <ul>
                {data.map((item) => (
                    <li key={item.id}>
                        <img src={item.thumbnailUrl} alt={item.title} />
                        <p>{item.title}</p>
                    </li>
                ))}
            </ul>
            <div className='loading-center'>
                {loading && <p>Loading...</p>}
            </div>
        </div>
    );
};

export default LazyLoading
