import { useState, useEffect } from 'react'
import Favicon from '../../../components/favicon/Favicon'
import AfterUserLogin from './userlogin/AfterUserLogin'
import { useNavigate } from 'react-router-dom'
import { makeAjax, showMessage, url, respStatus } from '../../../helpers/global_helper'
import UserLogin from './userlogin/UserLogin'
import { useSelector } from 'react-redux'
import Popup from '../../../components/popup/Popup'
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import HomeScreenPopup from '../../../components/popup/popup-components/HomeScreenPopup'
import { FiMenu } from 'react-icons/fi'

const VerticalHeader = (props) => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    let loggedIn = window.localStorage.getItem("user_token");
    const [popup, setPopup] = useState(false);
    const [popupUnreadMessage, setPopupUnreadMessage] = useState(undefined);
    const [popupNotificationClosed, setPopupNotificationClosed] = useState(false);
    const [popupMessageId, setPopupMessageId] = useState(null);
    const navigate = useNavigate();

    const handlePopup = () => {
        setPopup(false);
    };
    let urlData = window.location.pathname.split('/').pop();

    useEffect(() => {
        if (urlData == '') {
            setPopup(true);
        }
    }, [urlData])

    useEffect(() => {
        if (loggedIn != '' && loggedIn != null) {
            unreadPopUpMessages();
            const interval = setInterval(() => unreadPopUpMessages(), 6000)
            return () => {
                clearInterval(interval);
            }
        }
    }, [loggedIn]);

    const unreadPopUpMessages = () => {
        makeAjax(
            url.MEMO_API.getUnreadPopUpMessage, [], url.MEMO_MS_EXT).then(response => {

                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }

                setPopupUnreadMessage(response.data);
                if (response.data.length > 0 && response.data[0] != null) {
                    setPopupMessageId(response.data[0]._id);
                    setPopupNotificationClosed(true);
                }
            });
    };

    const handleNotificationPopup = () => {
        messageRead();
        // setDepositWithdrawNotificationClosed(false);
        setPopupNotificationClosed(false);
    }

    const messageRead = () => {
        if (messageRead != null) {
            let payload = {
                _id: popupMessageId
            }
            makeAjax(
                url.MEMO_API.updatePopupMessageStatus, payload, url.MEMO_MS_EXT).then(response => {

                    if (response.status != respStatus['SUCCESS']) {
                        showMessage(response);
                        return;
                    }
                    // fetchPlayerBalance();
                    navigate('/memo');
                });
        }
    }

    const sideMenuClick = () => {
        props.sideMenuClickProp();
    }

    return (
        <div className="header-components">
            <div className='menu-trig-ver' onClick={sideMenuClick}>
                <FiMenu />
            </div>
            {loggedIn ? <>{componentSetting != null && typeof componentSetting?.["device-login-info"]?.["options"]?.["user-login"] != 'undefined' && componentSetting?.["device-login-info"]?.["options"]?.["user-login"] && <AfterUserLogin />}</> : <>{componentSetting != null && typeof componentSetting?.["device-login-info"]?.["options"]?.["user-login"] != 'undefined' && componentSetting?.["device-login-info"]?.["options"]?.["user-login"] && <UserLogin />}</>}

            <Favicon />
            {loggedIn ? <>{componentSetting != null && typeof componentSetting.userLogin != 'undefined' && componentSetting.userLogin && popupUnreadMessage != undefined && popupUnreadMessage != 'undefined' && popupNotificationClosed && <HomeScreenPopup handlePopup={handleNotificationPopup} popupNotificationClosed={popupNotificationClosed} data={popupUnreadMessage} />}</> : null}

            {loggedIn ? <>{componentSetting != null && typeof componentSetting.userLogin != 'undefined' && componentSetting.userLogin && <AfterUserLogin />}</> : <>{componentSetting != null && typeof componentSetting.userLogin != 'undefined' && componentSetting.userLogin && <UserLogin />}</>}

            {
                popup && loggedIn != null && loggedIn != '' && <Popup handlePoup={handlePopup} afterLogin />
            }

            {
                popup && loggedIn == null && <Popup handlePoup={handlePopup} beforeLogin />
            }

        </div>

    )
}

VerticalHeader.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(VerticalHeader);