import { useSelector } from 'react-redux'
import CommonSec from '../components/commonSection/CommonSec'
import FloatingComponents from '../components/floatingComponents/FloatingComponents'
import Games from '../components/games/Games';
import SliderSec from '../components/slider/SliderSec'
import { useEffect } from 'react';
import { useDispatch } from "react-redux"
import { getPlayerProfileDetail } from '../store/actions';
const Home = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    let loggedIn = window.localStorage.getItem("user_token");

    const dispatch = useDispatch();
    useEffect(() => {
        if (loggedIn != null && loggedIn != '')
            dispatch(getPlayerProfileDetail());
    }, []);
    return (
        <>
            <SliderSec />
            <Games categorySlug={null} providerSlug={null} />
            <CommonSec />
            {typeof componentSetting?.["floating-components"] != 'undefined' && componentSetting?.["floating-components"]?.["default-value"] && <FloatingComponents />}
        </>
    )
}

export default Home