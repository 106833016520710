import React, { useState } from 'react'
import Popup from '../Popup'
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const ChangeEmail = (props) => {
    const InitFormData = {
        email_id: '',
        password: '',
    }

    const [formData, setFormData] = useState(InitFormData)
    const [formError, setFormError] = useState({})
    const [formSuccess, setFormSuccess] = useState({});
    const [isSuccessMessagePopUp, setIsSuccessMessagePopUp] = useState(false);


    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        makeAjax(
            url.USER_API.changeEmail,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    setIsWrongPassword(true);
                    return;
                }
                setFormData(InitFormData);
                setFormSuccess(response.messages);
                props.handleData(response.messages, response.data);
                setIsSuccessMessagePopUp(true)
            });
    }

    const handle = (e) => {
        setIsSuccessMessagePopUp(false)
        e.preventDefault()
        props.changeEmailConfirm()

    }




    const [isEmailAlreadyUsed, setIsEmailAlreadyUsed] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isWrongPassword, setIsWrongPassword] = useState(false)

    const emailAlreadyUsed = (e) => {
        e.preventDefault()
        setIsEmailAlreadyUsed(true)
    }

    const emailInvalid = (e) => {
        e.preventDefault()
        setIsEmailInvalid(true)
    }

    const wrongPassword = (e) => {
        e.preventDefault()
        setIsWrongPassword(true)
    }

    const handlePopup = () => {
        setIsEmailAlreadyUsed(false)
        setIsEmailInvalid(false)
        setIsWrongPassword(false)
    }

    const successHandle = () => {
        setIsSuccessMessagePopUp(false);
        props.changeEmailConfirm();
    }

    return (
        <div className='popup-box change-email'>
            <h4 className="title">{props.t('change_e_mail')}</h4>
            <form onSubmit={handleSubmit}>
                <div className="input-control">
                    <input type="text" name="email_id" placeholder={props.t('new_e_mail')} onChange={handleChange} />
                </div>
                <div className="input-control">
                    <input type="password" name="password" placeholder={props.t('password')} onChange={handleChange} />
                </div>
                <button type="submit">{props.t('process')}</button>
            </form>

            {isWrongPassword && <Popup handlePoup={handlePopup} data={[formError]} wrongPassword />}

            {/* Success Message Pop Up */}
            {isSuccessMessagePopUp && <Popup handlePoup={successHandle} data={[formSuccess]} successMessages />}
        </div>
    )
}

ChangeEmail.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ChangeEmail);