import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  FETCH_TEXT_INFO
} from "./actionTypes"
import {
  fetchTextInfoSuccess
} from "./actions";

import { makeAjax, url, showMessage } from "../../helpers/global_helper";
import { USER_MS_EXT } from "../../helpers/url_helper";

function* fetchTextInfo(data) {
  try {
    
    const response = yield call(
      makeAjax,
      url.USER_API.getTextInfo,
      data.payload,
      USER_MS_EXT
    );
    if (response.status == 'SUCCESS') {
      yield all([
        put(fetchTextInfoSuccess({ response: response.data }))
      ]);
    }
    else {
      showMessage(response);
    }
  } catch (error) {
    showMessage(error);
    
    // alert
  }
}

function* textInfomationSaga() {
  yield takeLatest(FETCH_TEXT_INFO, fetchTextInfo);
}

export default textInfomationSaga;
