import React from 'react'
import CommonSec from '../components/commonSection/CommonSec'

const Status = () => {
  return (
    <>
    <div className='status'>
        <div className='container' style={{paddingBottom: '1rem'}}>
        <h3 className='status-text'>Your account failed validation.!</h3>
        <div className='status-box'>
            <p className='status-para'>Please contact your operator!<br/> Thank you</p>
        </div>
        </div>
    </div>
    <CommonSec />
    </>
  )
}

export default Status
