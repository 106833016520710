import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ConfirmDelete = (props) => {
  const handleButtonClick = (e) => {
    e.preventDefault();
    props.deleteConfirmMessage();
  };

  return (
    <div className="popup-box">
      <h4 className="title">{props.t("delete_confirmation")}</h4>
      <p className="popup-error-msg">{props.t("are_you_sure_to_delete")} ?</p>
      <div className="text-center pt-1">
        <button
          onClick={handleButtonClick}
          onTouchStart={handleButtonClick}
          onTouchEnd={(e) => e.preventDefault()}
        >
          {props.t("delete")}
        </button>
      </div>
    </div>
  );
};

ConfirmDelete.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

ConfirmDelete.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ConfirmDelete);
