import { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { CSS_CLASSES } from '../../../css-classes';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { makeAjax, showMessage, url, respStatus } from "../../../helpers/global_helper";
const PartnersSlider = () => {
  const [providerIcon, setProviderIcon] = useState([]);

  // Get provider list
  const getPartnerIconList = (e) => {
    makeAjax(
      url.USER_API.getProviderIcon, [], url.USER_MS_EXT).then(response => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
        }
        setProviderIcon(response.data);
      });
  }

  // Use effect call
  useEffect(() => {
    getPartnerIconList();
  }, []);


  // Get device type
  let deviceType = window.localStorage.getItem("device_type");
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
    ],
  };

  const data = [
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {typeof providerIcon[deviceType + '_footer_provider_icon'] != 'undefined' && providerIcon[deviceType + '_footer_provider_icon'].map((v, i) =>
          <a href={v.link} key={i}>
            <img src={v.path} alt={v.alternate} width={v.width} height={v.height} className="img-fluid img-item" />
          </a>
        )}
      </Slider>

    </>
  )
}

export default PartnersSlider
