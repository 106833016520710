import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdRefresh } from "react-icons/md";
import { BiShowAlt } from "react-icons/bi";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import Select from "react-select";
import Popup from "../../popup/Popup";
import { useLocation, useNavigate, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getPlayerProfileDetail } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ActionLoader from "../../../pages/ActionLoader";
import { checkPinStatus } from "../../../store/check-pin-status/actions";

const RegisterInner = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pinStatus } = useSelector((state) => ({
    pinStatus: state.checkPinStatusR.data,
  }));

  // FormInit
  const InitForm = {
    username: "",
    password: "",
    password_confirmation: "",
    security_question_id: "",
    security_answer: "",
    contact: "",
    email: "",
    bank_id: "",
    account_name: "",
    account_number: "",
    referrals: "",
    captcha: "",
    key: "",
    validation_length: "",
  };

  // Refresh dd
  const emptyBankDrop = {
    _id: null,
    bank_name: null,
  };

  // Refresh dd
  const emptySecurityDrop = {
    _id: null,
    question: null,
  };

  // Set &get form data
  const [formData, setFormData] = useState(InitForm);

  // Form Error
  const [formError, setFormError] = useState({});

  // Sshow Password
  const [inputType, setInputType] = useState("password");
  const [inputConfirmType, setConfirmInputType] = useState("password");

  // Refresh dd
  const [refreshBank, setRefreshBank] = useState(null);
  const [refreshSecurity, setRefreshSecurity] = useState(null);

  // Loader
  const [loaderStatus, setLoader] = useState(false);
  const [username, setUserName] = useState("");

  // DD
  const [securityDD, setSecurityList] = useState([]);
  const [bankDD, setBankListData] = useState([]);
  const [captcha, setCaptcha] = useState([]);
  const [registerWithLoginClosed, setRegisterWithLoginClosed] = useState(false);
  const [referralName, setReferralName] = useState("");
  const [colorUsername, setColorUsername] = useState(null);
  const [onhoverMessage, setOnhoverMessage] = useState(false);
  const [onhoverPasswordMsg, setOnhoverPasswordMsg] = useState(false);
  const [onhoverConfirmPasswordMsg, setOnhoverConfirmPasswordMsg] =
    useState(false);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    if (formData.username != "") {
      let payload = {
        username: formData.username,
      };
      makeAjax(
        url.USER_API.checkDuplicateUsername,
        payload,
        url.USER_MS_EXT
      ).then((response) => {

        if (response.status != respStatus["SUCCESS"]) {
          setFormError(response.messages);
          setColorUsername("red");
          return;
        }
        setColorUsername(null);
        setFormError({});
      });
    } else {
      setColorUsername(null);
      setFormError({});
    }
  }, [formData.username]);

  // Get client list
  const getSecurityList = () => {
    makeAjax(url.USER_API.securityQuestionDD, [], url.USER_MS_EXT).then(
      (response) => {

        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setSecurityList(response.data);
      }
    );
  };

  // Bank list
  const getBankList = () => {
    makeAjax(url.USER_API.bankDD, [], url.USER_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setBankListData(response.data);
    });
  };

  // Load captcha
  const getCaptcha = (event) => {
    makeAjax(url.USER_API.loadCaptcha, [], url.USER_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setCaptcha(response.data);
    });

    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 5000);
  };

  // Effect List
  useEffect(() => {
    getSecurityList();
    getBankList();
    getCaptcha();
    setInputType("password");
  }, []);

  // On change form fields
  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    if (name === "account_number") {
      // Remove decimal point if present
      val = val.replace(/\./g, "");
      // Validate if the entered value is a valid integer
      if (!/^\d+$/.test(val)) {
        val = ""; // If not a valid integer, set the value to an empty string
      }
      // Limit the length based on the validation_length from the formData
      if (
        formData["bank_id"] !== "" &&
        val.length > formData["validation_length"]
      ) {
        val = val.substring(0, formData["validation_length"]);
      }
    }
    if (name == 'contact') {
      const validInput = val.replace(/[^0-9+-]/g, '');
      val = validInput;
    }
    setFormError({ ...formError, [name]: "" });

    setFormData({ ...formData, [name]: val });
  };

  // On change bank
  const onChangeBank = (event) => {
    setRefreshBank(null);
    setFormError({ ...formError, ["bank_id"]: "" });
    if (!event) {
      setFormData({
        ...formData,
        ["bank_id"]: "",
        ["validation_length"]: "",
        ["account_number"]: "",
      });
    } else {
      let t = emptyBankDrop;
      t._id = event._id;
      t.bank_name = event.bank_name;
      setRefreshBank(t);
      setFormData({
        ...formData,
        ["bank_id"]: event._id,
        ["validation_length"]: event.validation_length,
        ["account_number"]: "",
      });
    }
  };

  // On change security question
  const onChangeSecQue = (event) => {
    setRefreshSecurity(null);
    setFormError({ ...formError, ["security_question_id"]: "" });
    if (!event) {
      setFormData({ ...formData, ["security_question_id"]: "" });
    } else {
      let t = emptySecurityDrop;
      t._id = event._id;
      t.question = event.question;
      setRefreshSecurity(t);
      setFormData({ ...formData, ["security_question_id"]: event._id });
    }
  };

  // User register

  const redirectMe = (pinStatus, path, component_location) => {

    let config_status = pinStatus.get_tenant_config?.value;
    let url = "";
    if (
      config_status === true &&
      pinStatus.enabled_active_pin === true &&
      pinStatus.active_pin === false
    ) {
      url = "/login-pin";
    } else if (
      config_status === true &&
      pinStatus.enabled_active_pin === true &&
      pinStatus.is_pin_verified === false
    ) {
      url = "/security-validation";
    } else if (
      pinStatus.is_security_question_set === false ||
      pinStatus.is_security_question_set === null
    ) {
      url = "/security-question";
    } else if (pinStatus.is_password_active === false) {
      url = "/reminder-password";
    } else {
      dispatch(getPlayerProfileDetail());
      return;
    }
    navigate(url);
  };

  useEffect(() => {
    if (Object.keys(pinStatus).length)
      redirectMe(pinStatus, location.pathname, "/");
  }, [pinStatus]);

  const registerUser = (event) => {
    event.preventDefault();
    setFormError({});
    setLoader(true);
    let tFData = JSON.parse(JSON.stringify(formData));
    setUserName(tFData["username"]);
    tFData["key"] = !captcha.key ? "" : captcha.key;
    if (referralName != "") {
      tFData["referrals"] = referralName;
    }
    makeAjax(url.USER_API.userRegistration, tFData, url.USER_MS_EXT).then(
      (response) => {

        if (response.status != respStatus["SUCCESS"]) {
          setLoader(false);
          if (
            response.code != "validation_error" &&
            response.code != "validation.credential_error"
          ) {
            showMessage(response);
            return;
          }
          tFData["key"] = "";
          setFormData(tFData);
          setCaptcha([]);
          getCaptcha();
          setFormError(response.messages);
          return;
        }
        localStorage.setItem("user_token", response.data.token);
        window.localStorage.setItem("isLoggedIn", true);
        setTimeout(() => {
          makeAjax(url.USER_API.listPlayerAlternativeSite, {})
            .then((response) => {

              if (
                response.status !== respStatus["SUCCESS"] ||
                (response.data &&
                  response.data.data &&
                  response.data.data.length == 0)
              ) {
                dispatch(checkPinStatus());
              }
              let sites = response.data.data;

              for (let i = 0; i < sites.length; i++) {
                if (sites[i]["is_login"]) {
                  if (window.location.host == sites[i]["domain"]) {
                    dispatch(checkPinStatus());
                    return;
                  } else {
                    window.location.assign(
                      (sites[i]["is_secure"] ? "https" : "http") +
                      "://" +
                      sites[i]["domain"] +
                      "/update_token/web/" +
                      localStorage.getItem("user_token")
                    );
                    return;
                  }
                }
              }
              dispatch(checkPinStatus());
            })
            .catch((ex) => {
              dispatch(checkPinStatus());
            });
        }, 5000);
        setCaptcha([]);
        getCaptcha();
        setRefreshBank(null);
        setRefreshSecurity(null);
        setFormData(InitForm);
        showMessage(response);
        setInputType("password");
        setConfirmInputType("password");
        setRegisterWithLoginClosed(true);
        localStorage.removeItem("referral_slug");
        setLoader(false);
      }
    );
  };

  const handlePopup = () => {
    setRegisterWithLoginClosed(false);
  };

  useEffect(() => {
    let referralSlug = localStorage.getItem("referral_slug");
    if (referralSlug != null) {
      formData["referrals"] = referralSlug;
      setFormData(formData);
      setReferralName(referralSlug);
    }
  }, [formData, referralName]);

  const handleUsernameChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setFormData({ ...formData, [name]: val });
  };

  const onHoverHandel = (event) => {
    if (event.target.name == "username") {
      setOnhoverMessage(true);
    }
    if (event.target.name == "password") {
      setOnhoverPasswordMsg(true);
    }
    if (event.target.name == "password_confirmation") {
      setOnhoverConfirmPasswordMsg(true);
    }
  };

  const onLeaveHandel = () => {
    setOnhoverMessage(false);
    setOnhoverPasswordMsg(false);
    setOnhoverConfirmPasswordMsg(false);
  };

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobile = window.innerWidth <= 768;
      setIsDesktop(!isMobile);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const handleMouseOver = () => {
    if (isDesktop) {
      if (inputType == "password") setInputType("text");
      if (inputType == "text") setInputType("password");
    }
  };

  const handleClick = () => {
    if (!isDesktop) {
      if (inputType == "password") setInputType("text");
      if (inputType == "text") setInputType("password");
    }
  };

  const handleMouseOverConf = () => {
    if (isDesktop) {
      if (inputConfirmType == "password") setConfirmInputType("text");
      if (inputConfirmType == "text") setConfirmInputType("password");
    }
  };

  const handleClickConf = () => {
    if (!isDesktop) {
      if (inputConfirmType == "password") setConfirmInputType("text");
      if (inputConfirmType == "text") setConfirmInputType("password");
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "37px",
      height: "37px",
      boxShadow: state.isFocused ? null : null,
      cursor: "pointer",
      fontSize: "14px",

      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
        minHeight: "32px",
        height: "32px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
      backgroundColor: state.isSelected ? "transparent" : "transparent", // Change background color for selected option
      color: state.isSelected ? "#D47DFF" : "#BAAEC1",
      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#D47DFF" : "#D47DFF", // Change text color for selected value
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    menu: (provided) => ({
      ...provided,
      // For WebKit browsers (Chrome, Safari)
      "&::-webkit-scrollbar": {
        width: "3px",
        height: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#3498db", // Set the color of the scrollbar thumb
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
      },
      // For Firefox
      scrollbarColor: " #201E21",
      scrollbarWidth: "3px",
      scrollbarheight: "5px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  return (
    <div className="registration">
      <div className="container">

        <div className="registration_inner_cont">
          <h3 className="title ">{props.t('register')}</h3>
          <div className="registerouter-main">
            <form>
              <h3 className="sub-title">{props.t('personal_details')}</h3>
              <div className="form-div">
                <div className="input-control">
                  <label className="form-label">{props.t('username')}</label>
                  <input
                    type="text"
                    placeholder={props.t('minimum_3_maximum_13_characters')}
                    value={formData.username}
                    name="username"
                    style={{ backgroundColor: colorUsername }}
                    onChange={handleUsernameChange}
                    onMouseEnter={onHoverHandel}
                    onMouseLeave={onLeaveHandel}
                  />
                  {formError.username ? (
                    <span
                      style={{ color: "white" }}
                      className="text-danger error-msg"
                    >
                      {formError.username[0]}
                    </span>
                  ) : null}
                </div>
                <div className="input-control">
                  <label className="form-label">{props.t('password')}</label>
                  <div className="password-icon">
                    <input
                      type={inputType}
                      placeholder={props.t('min_8_one_uppercasse_one_number_and_special_characters')}
                      value={formData.password}
                      name="password"
                      onChange={handleChange}
                      onMouseEnter={onHoverHandel}
                      onMouseLeave={onLeaveHandel}
                    />
                    {inputType == "password" && (
                      <AiOutlineEyeInvisible
                        onMouseEnter={handleMouseOver}
                        onClick={handleClick}
                        className="hideicon"
                      />
                    )}
                    {inputType == "text" && (
                      <BiShowAlt
                        onMouseLeave={handleMouseOver}
                        onClick={handleClick}
                        className="showicon"
                      />
                    )}
                  </div>
                  {formError.password ? (
                    <span
                      style={{ color: "white" }}
                      className="text-danger error-msg"
                    >
                      {formError.password[0]}
                    </span>
                  ) : null}
                </div>
                <div className="input-control">
                  <label className="form-label">{props.t('confirm_password')}</label>
                  <div className="password-icon">
                    <input
                      type={inputConfirmType}
                      value={formData.password_confirmation}
                      placeholder={props.t('min_8_one_uppercasse_one_number_and_special_characters')}
                      name="password_confirmation"
                      onChange={handleChange}
                      onMouseEnter={onHoverHandel}
                      onMouseLeave={onLeaveHandel}
                    />
                    {inputConfirmType == "password" && (
                      <AiOutlineEyeInvisible
                        onMouseEnter={handleMouseOverConf}
                        onClick={handleClickConf}
                        className="hideicon"
                      />
                    )}
                    {inputConfirmType == "text" && (
                      <BiShowAlt
                        onMouseLeave={handleMouseOverConf}
                        onClick={handleClickConf}
                        className="showicon"
                      />
                    )}
                  </div>
                  {formError.password_confirmation ? (
                    <span className="text-danger error-msg">
                      {formError.password_confirmation[0]}
                    </span>
                  ) : null}
                </div>


                <div className="input-control">
                  <label className="form-label">{props.t("phone_number")}</label>
                  <input
                    onChange={handleChange}
                    name="contact"
                    value={formData.contact}
                    type="tel"
                    placeholder={props.t("enter_phone_number")}
                  />
                  {formError.contact ? (
                    <span className="text-danger error-msg">
                      {formError.contact[0]}
                    </span>
                  ) : null}
                </div>

                <div className="input-control">
                  <label className="form-label">{props.t("email")}</label>
                  <input
                    type="email"
                    placeholder={props.t("enter_email_id")}
                    value={formData.email}
                    onChange={handleChange}
                    name="email"
                  />
                  {formError.email ? (
                    <span className="text-danger error-msg">
                      {formError.email[0]}
                    </span>
                  ) : null}
                </div>
              </div>
            </form>
          </div>

          <form>
            <h3 className="sub-title">{props.t("bank_details")}</h3>
            <div className="form-div">
              <div className="input-control">
                <label className="form-label">{props.t("bank")} / {props.t("e_wallet")}</label>
                <Select
                  name="bank_id"
                  value={refreshBank}
                  onChange={onChangeBank}
                  isClearable={true}
                  placeholder={`${props.t("select_bank")} / ${props.t(
                    "e_wallet"
                  )}`}
                  getOptionLabel={(option) => option.bank_name}
                  getOptionValue={(option) => option._id}
                  options={bankDD}
                  className="cusReactSelect"
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    fontSize: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "#181618",
                      primary: "#181618",
                    },
                  })}
                />

                {formError.bank_id ? (
                  <span className="text-danger error-msg">
                    {formError.bank_id[0]}
                  </span>
                ) : null}
              </div>
              <div className="input-control">
                <label for="exampleInputEmail1" className="form-label">

                  {props.t("account_e_wallet_name")}
                </label>
                <input
                  onChange={handleChange}
                  value={formData.account_name}
                  name="account_name"
                  type="text"
                  placeholder={props.t("enter_account_e_wallet_name")}
                />
                {formError.account_name ? (
                  <span className="text-danger error-msg">
                    {formError.account_name[0]}
                  </span>
                ) : null}
              </div>
              <div className="input-control">
                <label for="exampleInputEmail1" className="form-label">
                  {props.t("account")} / {props.t("phone_number")}
                </label>
                <input
                  onChange={handleChange}
                  value={formData.account_number}
                  name="account_number"
                  type="text"
                  placeholder={`${props.t("enter_account")} / ${props.t(
                    "phone_number"
                  )}`}
                />
                {formError.account_number ? (
                  <span className="text-danger error-msg">
                    {formError.account_number[0]}
                  </span>
                ) : null}
              </div>


            </div>
          </form>
          <form>
            {/* referal */}
            <div className="form-div">
              <div className="input-control">
                <label for="exampleInputEmail1" className="form-label">
                  {props.t("referral")}
                </label>
                <input
                  onChange={handleChange}
                  name="referrals"
                  value={formData.referrals}
                  type="text"
                  placeholder={props.t("enter_referral")}
                />
              </div>
            </div>
            {/* referal */}
          </form>
          <div className="text-center mt-4">
            <ActionLoader
              label={props.t('register')}
              loaderClass={"btn register-loader reg-sub"}
              showLoader={loaderStatus}
              onClick={registerUser}
            />
          </div>
        </div>
      </div>

      {registerWithLoginClosed && (
        <div className="register-withlogin">
          <Popup
            handlePoup={handlePopup}
            data={{ username: username }}
            RegisterWithLogin
          />
        </div>
      )}
    </div>
  );
};

RegisterInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(RegisterInner);