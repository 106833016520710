import { FETCH_DEPOSIT_PENDING_SUCCESS } from "./actionTypes";

const initialState = {
    data: [],
};

const DepositPendingRequestR = (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_DEPOSIT_PENDING_SUCCESS:
            state = { ...state, data: action.payload.response };
            return state;
    }
    return state;
}

export default DepositPendingRequestR