import { useEffect, useState } from "react";
import { GrSearch } from "react-icons/gr";
import { CSS_CLASSES } from "../../../css-classes";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import FindGames from "../FindGames/FindGames";
import Loading from "../Loading/Loading";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { useSelector } from "react-redux";


const initSetting = {
  search_sm: null,
  default_class: null,
};

const Search = (props) => {
  // Set & get state
  const [gameList, setGameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [fnSetting, setFnSetting] = useState(initSetting);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);


  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  // Fetch game data
  const fetchGameListData = (searchWord, nextPage) => {
    return new Promise((resolve, reject) => {

      let payload = {
        all_games: true,
        search_term: searchWord,
        category_code: "",
        provider_name: "",
        page: nextPage,
        per_page: 90
      };
      let urlArr = window.location.pathname.split("/");
      let arr = urlArr.filter((e) => String(e).trim());
      if (typeof props.providerSlug == "undefined" && arr.length == 1) {
        if (arr[0] != "") {
          payload["category_code"] = arr[0];
        }
      } else if (typeof props.providerSlug != "undefined" && arr.length > 1) {
        if (arr[0] != "") {
          payload["category_code"] = arr[0];
        }
        if (arr[1] != "") {
          payload["provider_name"] = arr[1];
        }
      } else {
        if (typeof props.providerSlug != "undefined" && arr.length == 1) {
          payload["provider_name"] = arr[0];
        }
      }

      if (props.all_games) {
        payload.category_code = "";
        payload.provider_name = "";
      }

      if (payload.search_term != "") {
        setLoading(true);
        makeAjax(
          url.USER_API.listProviderGame,
          payload,
          url.USER_MS_EXT
        ).then(response => {
          setLoading(false);
          if (response.status != respStatus["SUCCESS"]) {
            showMessage(response);
            return;
          }
          if (response?.data?.gameInfo?.data && response?.data?.gameInfo?.data.length > 0) {
            resolve({
              games: response?.data?.gameInfo?.data,
              currentPage: response?.data?.gameInfo?.current_page,
              lastPage: response?.data?.gameInfo?.last_page,
            });
          }
        }).catch(error => {
          reject(error);
        });
      } else {
        setLoading(false);
        setGameList([]);
        setSearchTerm("");
        setLastPage(null);
        setPage(1);
      }
    });
  };

  // Get game list
  const getGameList = async (search_term, nextPage, type) => {

    try {
      let text = '';
      if (type == 'autoloading') {
        text = search_term;
      } else {
        text = search_term.target.value;
        setSearchTerm(text);
        setGameList([]);
        nextPage = 1;
      }
      if (lastPage == null || nextPage <= lastPage) {
        let response = '';
        response = await fetchGameListData(text, nextPage);
        setGameList((prevRecords) => [...prevRecords, ...response.games]);
        setPage(nextPage + 1);
        setLastPage(response.lastPage);
      }

    } catch (error) {
      console.error('Error fetching records:', error);
    } finally {
      //
    }
  };

  let debounceTimer;
  const isPopupScrollableAtBottom = () => {
    const popupContent = document.getElementById('popup-content');
    if (popupContent) {
      const popupScrollHeight = popupContent.scrollHeight;
      const popupScrollTop = popupContent.scrollTop;
      const popupClientHeight = popupContent.clientHeight;
      return popupScrollTop + popupClientHeight >= popupScrollHeight * 0.8;
    }

    return false;
  };

  const handlePopupScroll = () => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      if (isPopupScrollableAtBottom()) {
        getGameList(searchTerm, page, 'autoloading');
      }
    }, 200);
  };

  useEffect(() => {
    const popupContent = document.getElementById('popup-content');
    if (popupContent) {
      popupContent.addEventListener('scroll', handlePopupScroll);
    }

    return () => {
      if (popupContent) {
        popupContent.removeEventListener('scroll', handlePopupScroll);
      }
    };
  }, [page]);

  useEffect(() => {
    if (props.showFilter)
      setSearchTerm('');
  }, [props.showFilter])

  useEffect(() => {
    let temp = { ...fnSetting };
    temp['search_sm'] = componentSetting?.["search"]?.["default-value"];
    temp['default_class'] = componentSetting?.["search"]?.["default-value"];

    setFnSetting(temp);
  }, [componentSetting]);

  return (
    <>
      {
        fnSetting['search_sm'] &&
        <div className='search-section'>
          <div className='container'>
            < div className={fnSetting['default_class']}>
              <form className={CSS_CLASSES.searchBar}>
                <input type="text" name="search_box" value={searchTerm} placeholder={props.placeholder} onChange={(e) => getGameList(e, page)} />
                <GrSearch />
              </form>

              {
                gameList && searchTerm != '' && (
                  <>
                    <div className="search-game-result" >
                      <div className="findYourGames">
                        <div className="findgames games" id="popup-content">

                          <FindGames findGames={gameList} loader={loading} />
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>

          </div>
        </div>

      }
    </>

  );
};

Search.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Search);
