import {
    GET_MEMO_COUNT,
    GET_MEMO_COUNT_SUCCESS,
  } from "./actionTypes"
  
  export const getMemoCount = () => {
    return {
      type: GET_MEMO_COUNT,
      payload: {  },
    }
  }
  
  export const getMemoCountSuccess = (response) => {
    return {
      type: GET_MEMO_COUNT_SUCCESS,
      payload: response,
    }
  }
  