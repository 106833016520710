import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PiPaperclipBold } from 'react-icons/pi'
import { MdClose, MdSend, MdMinimize } from 'react-icons/md'
import Faq from './faq/Faq'
import Chats from './chats/Chats'
import { useDispatch } from 'react-redux'
import { getPlayerProfileDetail } from '../../store/actions'
const LiveChat = (props) => {
    const [isActive, setIsActive] = useState(true);

    let loggedIn = window.localStorage.getItem("user_token");

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        // Cleanup the interval to avoid memory leaks
        return () => clearInterval(interval);
    }, []);

    const hours = time.getHours();
    const minutes = time.getMinutes();

    const [visible, setVisible] = useState(false);
    const handleToggle = () => {
        setVisible(!visible);
        setIsActive(!isActive);
    };


    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    const [isChatVisible, setIsChatVisible] = useState(false);

    const handleOthersTabClick = () => {
        // Toggle the visibility of the chat input block
        setIsChatVisible(!isChatVisible);
    };

    const [isDivVisible, setDivVisibility] = useState(false);

    const handleHeaderClick = () => {
        setDivVisibility(!isDivVisible);
    };


    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVisible(false);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);



    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);

    const sendMessage = () => {
        if (message.trim() !== '') {
            setMessages([...messages, { text: message, sender: 'user' }]);
            setMessage('');
        }
    };

    const [isShow, setIsShow] = useState(false);
    const toggleChat = () => {
        setIsShow(!isShow);
    };

    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsShow(true);
        } else {
            setIsShow(false);
        }
    }, []);
    const dispatch = useDispatch();
    useEffect(() => {
        if (loggedIn != null && loggedIn != '')
            dispatch(getPlayerProfileDetail());
    }, []);

    // Define your accordion data
    const accordionData = [
        {

            label: "Login/Register",
            subtabs: [
                { key: "subtab-0", label: "Already registered, but received error message for validation?" },
                { key: "subtab-1", label: "How do you register friends on your site so they can get bonuses?" },
                // Add more subtabs as needed
            ],
        },
        {

            label: "Deposit",
            subtabs: [
                { key: "subtab-0", label: "Option 1" },
                { key: "subtab-1", label: "Option 2" },
                // Add more subtabs as needed
            ],
        },
        {

            label: "Others"
            // No subtabs for "Others" in this example
        },
    ];




    return (
        <>
            <div className={`chat-bar-collapsible ${isShow ? 'open' : ''}`}>
                <div id="chat-button" className='collapsible' onClick={toggleChat} >
                    <p>Chat with us!</p>
                    <div className='chat-icons d-flex gap-2'>
                        <MdMinimize className='minimize' style={{ marginTop: '-4px' }} />
                        <MdClose className='minimize' />
                    </div>
                </div>
                {isShow &&
                    <div className="content">

                        <div className="full-chat-block">
                            <div className="outer-container">
                                <div className="chat-container">

                                    <ul className="chatbox">
                                        <div className='category'>
                                            <p className='chat-day'>Today</p>
                                            <div className='chat-text fadeInLeft'>
                                                <p className='chat-label'>Hi, welcome to live chat, please select the category of your question.</p>
                                                <div className='chat-time-in'>{`${hours}:${minutes < 10 ? '0' : ''}${minutes}`}</div>
                                            </div>
                                            {isVisible && (
                                                <div className="three-dot-ani">
                                                    <div className="c-three-dots-loader"></div>
                                                </div>
                                            )}
                                            <Faq accordionData={accordionData} messages={messages} setMessages={setMessages} />
                                        </div>

                                        <Chats messages={messages} />
                                    </ul>

                                    <>
                                        <div className="chat-bar-input-block">
                                            <div className="chat-bar-icons">
                                                <input className="form-control" type="file" id="formFile" />
                                                <label for='formFile'>
                                                    <PiPaperclipBold className="attachicon" />
                                                </label>

                                            </div>
                                            <div id="userInput">
                                                <input id="textInput"
                                                    className="input-box"
                                                    type="text"
                                                    name="msg"
                                                    placeholder="Type a message..."
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)} />
                                                <div className='chatsendicon' onClick={() => sendMessage()}><MdSend /></div>

                                            </div>
                                        </div>
                                    </>

                                    <div id="chat-bar-bottom">
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </>
    )
}
export default LiveChat