import React, { useState } from "react";
import { BsSliders } from "react-icons/bs";
import { Button } from "react-bootstrap";
import CategoryFilter from "./CategoryFilter";
import CommonSec from "../../components/commonSection/CommonSec";
import Popup from "../../components/popup/Popup";
import { useNavigate } from "react-router-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../helpers/global_helper";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import Loading from "../../components/games/Loading/Loading";
import Search from "../../components/games/search/Search";
import { GrClose, GrClosedCaption, GrSearch } from "react-icons/gr";
import { CSS_CLASSES } from "../../css-classes";
import { IoArrowUpSharp } from "react-icons/io5";
import ScrollToTop from "../../helpers/ScrollToTop";
import { FaArrowUp } from "react-icons/fa";

const AllGames = (props) => {
  const [page, setPage] = useState(1);
  let initFormData = {
    search_term: "",
    provider_name: "",
    category_code: "",
    all_games: true,
    dropdown_filter: false,
    per_page: 100,
  };
  const [show, setShow] = useState(false);
  const [gameId, setGameId] = useState("");
  const [maintainanceError, setMaintainanceError] = useState(false);
  const [commingSoonPopup, setCommingSoonPopup] = useState(false);
  const [records, setRecords] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedProviderDropDown, setselectedProviderDropDown] =
    useState(null);
  const [selectedCategoryDropDown, setselectedCategoryDropDown] =
    useState(null);
  const [providerList, setProviderList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [formData, setFormData] = useState(initFormData);

  const navigate = useNavigate();

  // Scroll init
  let oldScrollY = 0;
  let user_token = window.localStorage.getItem("user_token");
  // Device type
  let deviceType = localStorage.getItem("device_type");

  // Launch game
  const handleGameClick = (game) => {

    if (game.name.toLowerCase() == 'dummy game')
      return;

    if (!user_token) {
      navigate("/register");
      return;
    }
    if (game.maintainance_mode) {
      gameUnderMaintennace(game);
      return;
    }
    const payload = {
      game_id: game._id,
    };
    setGameId({ ...gameId, game_id: game._id });
    makeAjax(url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == "game_under_maintenance") {
            setMaintainanceError(true);
            return;
          }

          if (response.code == "game_not_active") {
            setCommingSoonPopup(true);
            return;
          }

          if (response.code == "validation.invalid_token_error") {
            navigate("/register");
            return;
          }

          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }
        //pg soft game data 7th feb 2024
        if (response?.data?.code === "bd200") {
          const newTab = window.open();
          newTab.document.write(response.data.data);
        } else {
          window.open(response.data, "_blank");
        }
      }
    );
  };

  // If game is under maintennace
  const gameUnderMaintennace = (event) => {
    setMaintainanceError(true);
  };

  // Close maintaninace popup
  const handlePopup = () => {
    setMaintainanceError(false);
    setCommingSoonPopup(false);
  };

  // Fetch game data
  const fetchGameListData = (nextPage, onChangePayload) => {
    return new Promise((resolve, reject) => {
      let payload = { ...formData };
      payload.category_code = onChangePayload
        ? onChangePayload?.category_code
        : "";
      payload.provider_name = onChangePayload
        ? onChangePayload.provider_name
        : "";
      payload.search_term = onChangePayload ? onChangePayload.search_term : "";
      payload.page = nextPage;
      setFormData(payload);

      setLoading(true);
      makeAjax(url.USER_API.listProviderGame, payload, url.USER_MS_EXT)
        .then((response) => {
          setLoading(false);
          if (response.status != respStatus["SUCCESS"]) {
            showMessage(response);
            return;
          }
          if (
            response?.data?.gameInfo?.data &&
            response?.data?.gameInfo?.data.length > 0
          ) {
            resolve({
              games: response?.data?.gameInfo?.data,
              currentPage: response?.data?.gameInfo?.current_page,
              lastPage: response?.data?.gameInfo?.last_page,
            });
          } else {
            resolve({
              games: [],
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Get game list
  const getGameList = async (nextPage, onChangePayload) => {
    let dummyLast = lastPage;
    try {
      if (nextPage <= lastPage) {
        let response = "";

        if (onChangePayload) {
          response = await fetchGameListData(nextPage, onChangePayload);
        } else {
          response = await fetchGameListData(nextPage);
        }
        if (nextPage == 1) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setRecords([]);
        }
        setRecords((prevRecords) => [...prevRecords, ...response.games]);
        setPage(nextPage + 1);
        setLastPage(response.lastPage);
        if (!response.lastPage) setLastPage(dummyLast);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      //
    }
  };

  // On scroll game load
  let debounceTimer;
  const handleScroll = () => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      const scrolledFromTop = window.scrollY + window.innerHeight;

      const totalHeight = document.documentElement.scrollHeight;

      const threshold = 0.8;

      if (scrolledFromTop >= threshold * totalHeight) {
        getGameList(page, formData);
      }
    }, 450); // Adjust the delay (in milliseconds)
  };

  const providerWiseCategoryDD = (code) => {
    makeAjax(
      url.GP_API.providerWiseCategoryDD,
      { provider_id: code },
      url.GP_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setCategoryList(response.data);
    });
  };

  const getProviderList = () => {
    makeAjax(url.GP_API.tenantProviderDD, {}, url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }
        setProviderList(response.data);
      }
    );
  };

  const onChangeCategory = (event) => {
    let payload = { ...formData };

    if (event) {
      setselectedCategoryDropDown(event);
      payload["category_code"] = event.menu_code;
    } else {
      payload["category_code"] = "";
      payload["dropdown_filter"] = false;
      setselectedCategoryDropDown(null);
    }
    setFormData(payload);
    getGameList(1, payload);
  };

  const onChangeProvider = (event) => {
    let payload = formData;
    if (event) {
      payload["provider_name"] = event._id;
      payload["dropdown_filter"] = true;
      setselectedProviderDropDown(event);
      providerWiseCategoryDD(event._id);
    } else {
      payload["provider_name"] = "";
      setCategoryList([]);
      setRecords([]);
      payload["dropdown_filter"] = false;
      setselectedProviderDropDown(null);
    }
    payload["category_code"] = "";
    getGameList(1, payload);
    setFormData({ ...payload });
    setselectedCategoryDropDown(null);
  };

  const onChangeSearch = (val) => {
    let payload = formData;
    payload["search_term"] = val;
    payload["dropdown_filter"] = false;
    if (val != "") {
      setShow(false);
    }
    setFormData({ ...payload });
    getGameList(1, payload);
  };
  const onOpenFilter = () => {
    if (formData["search_term"] != "") {
      onChangeSearch("");
    }
    setShow(!show);
  };

  // Initial game load
  useEffect(() => {
    setRecords([]);
    getProviderList();
    setselectedProviderDropDown(null);
    setselectedCategoryDropDown(null);
    getGameList(page);
  }, []);

  // On scroll game load
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, formData, lastPage]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "37px",
      height: "37px",
      boxShadow: state.isFocused ? null : null,
      cursor: "pointer",
      fontSize: "14px",

      "@media (max-width: 540px)": {
        fontSize: "12px", // Font size for screens up to 540px wide
        minHeight: "32px",
        height: "32px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
      backgroundColor: state.isSelected ? "transparent" : "transparent", // Change background color for selected option
      color: state.isSelected ? "#D47DFF" : "#BAAEC1",
      "@media (max-width: 540px)": {
        fontSize: "12px", // Font size for screens up to 540px wide
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#D47DFF" : "#D47DFF", // Change text color for selected value
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    menu: (provided) => ({
      ...provided,
      // For WebKit browsers (Chrome, Safari)
      "&::-webkit-scrollbar": {
        width: "3px",
        height: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#3498db", // Set the color of the scrollbar thumb
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
      },
      // For Firefox
      scrollbarColor: " #201E21",
      scrollbarWidth: "3px",
      scrollbarheight: "5px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) setShowTopBtn(true);
      else setShowTopBtn(false);
    });
  }, []);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="slotgame-outer">
        <div className="container">
          <div className="hot-game-header">
            <div className="header-wrap">
              <div className="title">{props.t("all_games")}</div>
              <div className="d-flex justify-content-between searchfill">
                <form className={CSS_CLASSES.searchBar}>
                  <input
                    type="text"
                    name="search_box"
                    value={formData["search_term"]}
                    placeholder={props.t("find_your_games")}
                    onChange={(e) => onChangeSearch(e.target.value)}
                  />
                  {formData["search_term"] == "" ? (
                    <GrSearch />
                  ) : (
                    <span
                      onClick={() => {
                        onChangeSearch("");
                      }}
                    >
                      <GrClose />
                    </span>
                  )}
                </form>
                <div className="bt-fil">
                  <Button
                    onClick={() => {
                      onOpenFilter();
                    }}
                  >
                    {" "}
                    <BsSliders />
                  </Button>
                </div>
              </div>
            </div>

            {show && (
              // <CategoryFilter />
              <div className="ca-category">
                <div className="form-div">
                  <div className="input-control">
                    <label className="form-label">{props.t("providers")}</label>
                    <Select
                      name="provider"
                      isClearable={true}
                      className="cusReactSelect"
                      styles={customStyles}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={onChangeProvider}
                      options={providerList}
                      value={selectedProviderDropDown}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#181618",
                          primary: "#181618",
                        },
                      })}
                    />
                  </div>
                  <div className="input-control">
                    <label className="form-label">{props.t("category")}</label>
                    <Select
                      name="category"
                      isClearable={true}
                      className="cusReactSelect"
                      styles={customStyles}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={onChangeCategory}
                      options={categoryList}
                      value={selectedCategoryDropDown}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#181618",
                          primary: "#181618",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="slotgame-list">
            {records && records.length > 0 ? (
              <>
                {records.map((v, index) => (
                  <div
                    key={index}
                    className="slotgame-item"
                    onClick={() => {
                      handleGameClick(v);
                    }}
                  >
                    {v.maintainance_mode && (
                      <div className="undermaintenance">
                        {props.t("under_maintanance")}
                      </div>
                    )}
                    <div
                      className={`hotgame-img ${
                        v.status === "Coming Soon" || v.is_hot_game || v.is_new
                          ? "overlay-image"
                          : ""
                      }`}
                    >
                      {v.status === "Coming Soon" ? (
                        <span className="coming">{props.t("coming_soon")}</span>
                      ) : v.is_hot_game ? (
                        <span className="promo">{props.t("Hot")}</span>
                      ) : v.is_new ? (
                        <span className="new">{props.t("new")}</span>
                      ) : (
                        ""
                      )}
                      {v.game_assets_media != null ? (
                        <img
                          className="img-fluid"
                          src={
                            v.ftp_path +
                            v.game_assets_media.base_path +
                            v.game_assets_media.system_file_name
                          }
                          alt={v.name}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={
                            "/assets/images/default-images/game_" +
                            deviceType +
                            ".png"
                          }
                          alt={v.name}
                        />
                      )}
                      {/* <span className="promos">Promo</span> */}
                      <div className="hotgame-content pt-2">
                        {v.name.split("").length > 21 ? (
                          <p className="game-title hovering">{v.name}</p>
                        ) : (
                          <p className="game-title">{v.name}</p>
                        )}
                        <p className="game-subtitle">
                          {v?.tenant_provider_reco?.provider_name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {!loading && (
                  <span className="title">{props.t("no_game_found")}</span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showTopBtn && (
        <div className="top-bg">
          <button className={CSS_CLASSES.scrollTop} onClick={scrollTop}>
            <FaArrowUp />
          </button>
        </div>
      )}

      {loading && <Loading />}
      <CommonSec />

      {maintainanceError && (
        <Popup handlePoup={handlePopup} providerMaintainance />
      )}
      {commingSoonPopup && <Popup handlePoup={handlePopup} commingSoonGame />}
    </div>
  );
};

AllGames.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(AllGames);
