
import { useState, useEffect } from 'react';
import { makeAjax, showMessage, url, respStatus } from '../../../../helpers/global_helper';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const JackpotGrid = (props) => {
    const [jackpotResult, setJackpotResult] = useState([]);

    const getJackpotResult = () => {
        makeAjax(url.REPORT_API.getLatestJackpot, [], url.REPORT_MS_EXT).then(response => {
            if (response.status != respStatus['SUCCESS']) {
                // showMessage(response);
                console.error("getLatestJackpot",response)
                return;
            }
            setJackpotResult(response.data);
        });
    }

    useEffect(() => {
        getJackpotResult();
    }, []);
    return (
        <>
            <div className='jackpot-bg'>
                <h2 className='sub-title'>{props.t('progressive_jackpot')}</h2>
                <p className="desc">{props.t('the_total_jackpot_that_can_be_brought_home_by_players')}</p>
                <div className="idr"><span>IDR</span> <span>{jackpotResult && typeof jackpotResult.jackpot_win_amount != 'undefined' ? jackpotResult.jackpot_win_amount : 0}</span></div>
                <img alt='Jackpot' src='\assets\images\Progressive-Jackpot-Mobile-Banner-2.gif' className='img-fluid treasure' />
            </div>
        </>
    )
}

JackpotGrid.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(JackpotGrid);
