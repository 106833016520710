import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import HttpApi from 'i18next-http-backend';
import { makeAjax, url, showMessage } from "./helpers/global_helper";
import Backend from "i18next-http-backend";
import tokenPrefix from "./helpers/jwt-token-access/token-prefix";
const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "en")
}
let base_url = window.location.origin;
let ext = url.API_PREFIX + url.APPLICATION_EXT + url.USER_MS_EXT + '/listLanguageUserData?lang=';
if (process.env.REACT_APP_ENABLE_LOCAL_API == 2) {
    ext = url.APPLICATION_EXT + url.USER_MS_EXT + '/listLanguageUserData?lang=';

}
const backendOptions = {
    loadPath: ((process.env.REACT_APP_ENABLE_LOCAL_API == 1) ? process.env.REACT_APP_API_URL : base_url) + ext + '{{lng}}',
    allowMultiLoading: true,
    customHeaders: {
        Origin: window.location.origin,
        Authorization: tokenPrefix + localStorage.getItem(url.TOKEN_KEY),
    },
    crossDomain: true
};

i18n
    .use(HttpApi)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: false,
        backend: backendOptions,
        lng: localStorage.getItem("I18N_LANGUAGE"),
        fallbackLng: localStorage.getItem("I18N_LANGUAGE"),
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            wait: true,
            useSuspense: false
        }
    })

export default i18n
