import {
    GET_PLAYER_BALANCE_SUCCESS,
  } from "./actionTypes"
  
  const initialState = {
    data:[],
  }
  
  const getPlayerBalanceR = (state = initialState, action) => {
    switch (action.type) {
      case GET_PLAYER_BALANCE_SUCCESS:
        state = { ...state, data: action.payload.response };
        return state;
    }
    return state
  }
  
  export default getPlayerBalanceR
  