import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper"
import CommonSec from "../../commonSection/CommonSec";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ValidationInner = (props) => {
    const navigate = useNavigate();
    const [textData, setTextData] = useState({});

    const getValidationStaus = (event) => {
        makeAjax(url.USER_API.getValidationStatus, [], url.USER_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response.messages);
                }
                if (typeof response.data != null && response.data != '') {
                    setTextData(response.data);
                }

                if (response.data.validation_status == 'valid') {
                    navigate("/");
                }
            })
    }
    useEffect(() => {
        getValidationStaus();
    }, []);

    return (
        <>
            <div className='status'>
                <div className='container' style={{ paddingBottom: '1rem' }}>
                    <h3 className='status-text'>{textData ? textData.heading : ""}</h3>
                    <div className='status-box'>
                        <div className='status-para' dangerouslySetInnerHTML={{ __html: textData ? textData.message : "" }}></div>
                    </div>
                </div>
            </div>
            <CommonSec />
        </>
    )
}

ValidationInner.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ValidationInner);