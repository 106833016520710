import React from 'react'

const PreLoader = () => {
  return (
   <>
  <div className="preloader">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
   </>
  )
}

export default PreLoader
