import { useState } from 'react'
import { BiHomeAlt } from 'react-icons/bi'
import { FaFireAlt, FaGamepad, FaDice, FaGifts, FaBlog, FaMoneyCheckAlt } from 'react-icons/fa'
import { MdPeopleAlt, MdOutlineHelp, MdSportsBasketball } from 'react-icons/md'
import { CgCardSpades } from 'react-icons/cg'
import { CSS_CLASSES } from '../../../css-classes'
import HoverNav from '../../../layout/header/navbar/hover/HoverNav'
import { NavLink } from 'react-router-dom'
import { AiFillBell } from 'react-icons/ai'
import { GiPodiumWinner } from 'react-icons/gi'
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ExtraMenu = (props) => {
    const [isItem, setIsItem] = useState();

    const { playerMenu } = useSelector(state => ({
        playerMenu: state.menuListR.data
    }));

    const mouseOver = (code) => {
        setIsItem(code);
    }

    const hoverGames = [
        { code: "home", menu: 'Home Page', icon: <BiHomeAlt />, link: '/' },
        { code: "hot_games", menu: 'Hot Games', icon: <FaFireAlt />, link: '/games' },
        { code: "slots", menu: 'Slots', icon: <FaMoneyCheckAlt />, link: '/slots' },
        { code: "arcades", menu: 'Arcades', icon: <FaGamepad />, link: '/arcades' },
        { code: "jackpot_bar", menu: 'Jackpot Bar', icon: <FaGamepad />, link: '/jackpot_bar' },
        { code: "live_casino", menu: 'Casino', icon: <FaDice />, link: '/live_casino' },
        { code: "sportsbook", menu: 'Sports', icon: <MdSportsBasketball />, link: '/sportsbook' },
        { code: "card_games", menu: 'Card Games', icon: <CgCardSpades />, link: '/card_games' },
        { code: "lottery", menu: 'Lottery', icon: <GiPodiumWinner />, link: '/lottery' },
        { code: "dingdong", menu: 'Ding Dong', icon: <AiFillBell />, link: '/dingdong' },
        { code: "blogs", menu: 'Blogs', icon: <FaBlog />, link: '/blogs' },
        { code: "promotion", menu: 'Promotions', icon: <FaGifts />, link: '/promotion' },
        { code: "referral", menu: 'Referrals', icon: <MdPeopleAlt />, link: '/referrals' },
        { code: "help", menu: 'Help', icon: <MdOutlineHelp />, link: '/help' },
        { code: "mobile", menu: 'Mobile', icon: <BiHomeAlt />, link: '/mobile' }
    ]
    const [hovered, setHovered] = useState(false);

    const handleOver = () => {
        setHovered(true)
    }
    const handleOut = () => {
        setHovered(false)
    }

    return (
        <div className={hovered ? 'hover pos-r' : 'pos-r'} onMouseOver={handleOver} onMouseLeave={handleOut}>
            <div className="container">
                <ul className={CSS_CLASSES.navbar}>
                    {
                        hoverGames.map((v, i) => {
                            return (playerMenu['before'][v.code] && playerMenu['before'][v.code]['status']) ?
                                <li key={playerMenu['before'][v.code]['_id']}> {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon} {playerMenu['before'][v.code]['name']}</NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} {playerMenu['before'][v.code]['name']}</a>}</li>
                                : null
                        })
                    }
                </ul>
            </div>
            <HoverNav code={isItem} />
        </div>
    )
}

ExtraMenu.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ExtraMenu);