import { CSS_CLASSES } from "../../../../css-classes";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Partners from "../../partners/Partners";
import Slider from "react-slick";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import {
  makeAjax,
  showMessage,
  url,
  respStatus,
} from "../../../../helpers/global_helper";
import Jackpot from "../../../games/jackpot/Jackpot";

const PaymentGrid = (props) => {
  const [bankDD, setBankDD] = useState([]);

  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  const bankList = () => {
    makeAjax(url.USER_API.listTenantBank, [], url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response.messages);
            return;
          }
          showMessage(response);
          return;
        }
        setBankDD(response.data);
      }
    );
  };
  useEffect(() => {
    bankList();
  }, []);

  return (
    <div className="container">
      <div className="main-payment">
        <div className={CSS_CLASSES.paymentMethod}>
          <h2 className="title">{props.t("payment_methods")}</h2>
          <div className="payment-items">
            <div className="bank">
              {bankDD.length > 0 &&
                bankDD.map((v, i) => {
                  {
                    return (
                      <>
                        {v.status == "Offline" && (
                          <div className="bank-item">
                            <span class="tooltiptext">{props.t("offline")}</span>
                            <div className="bank-status offline">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}

                        {v.status == "Problem" && (
                          <div className="bank-item">
                            <span class="tooltiptext">{props.t("problem")}</span>
                            <div className="bank-status problem">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}

                        {v.status == "Coming_Soon" && (
                          <div className="bank-item">
                            <span class="tooltiptext">{props.t("coming_soon")}</span>
                            <div className="bank-status comings">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}

                        {v.status == "Online" && (
                          <div className="bank-item">
                            <span class="tooltiptext">{props.t("online")}</span>
                            <div className="bank-status online">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        {
          componentSetting?.custom_loading?.options?.payment_grid_handle && <Jackpot />
        }
        {
          !componentSetting?.custom_loading?.options?.payment_grid_handle && <Partners />
        }
      </div>
    </div>
  );
};

PaymentGrid.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PaymentGrid);
