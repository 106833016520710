import { useSelector } from 'react-redux'
import CommonSec from '../../components/commonSection/CommonSec'
import FloatingComponents from '../../components/floatingComponents/FloatingComponents'
import WithdrawInner from '../../components/user-pages/withdraw/WithdrawInner'
// import { componentSetting } from '../../template-settings'

const Withdraw = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    return (
        <div className='withdraw-com'>
            <WithdrawInner />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
        </div>
    )
}

export default Withdraw