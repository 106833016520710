import React, { useEffect, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineBanknotes } from "react-icons/hi2";
import Link from "react-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import Popup from "../../popup/Popup";
import ForgotPassword from "../../popup/popup-components/ForgotPassword";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ActionLoader from "../../../pages/ActionLoader";

const ForgotPasswordInner = (props) => {
  const [formDataEmail, setFormDataEmail] = useState({
    email_id: "",
  });

  const [formDataBank, setFormDataBank] = useState({
    username: "",
    bank_id: "",
    account_name: "",
    account_number: "",
    valcode: "",
    key: "",
    captcha: "",
  });

  const [loaderStatus, setLoader] = useState(false);
  const [loaderBankStatus, setBankLoader] = useState(false);
  const [loaderEmailStatus, setEmailLoader] = useState(false);
  const [formError, setFormError] = useState({});
  const [formErrorBank, setFormErrorBank] = useState({});
  const [formErrorAnswer, setFormErrorAnswer] = useState({});
  const [popupError, setPopupError] = useState("");
  const [bankDD, setBankListData] = useState([]);
  const [captcha, setCaptcha] = useState([]);
  const [bankValidationLength, setBankValidationLength] = useState([]);
  const [isEmail, setIsEmail] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const [securityQuestion, setSecurityQuestion] = useState({
    security_question_id: "",
    question: "",
    answer: "",
    username: "",
  });

  // Email Section
  const handleEmailChange = (event) => {
    setFormDataEmail({
      ...formDataEmail,
      [event.target.name]: event.target.value,
    });
  };

  const [isPopup, setIsPopup] = useState(false);

  const handlePopup = () => {
    setIsPopup(false);
  };
  const navigate = useNavigate();

  function verifyOnEmailSubmit(event) {
    setEmailLoader(true);
    event.preventDefault();
    makeAjax(url.USER_API.verifyWithEmail, formDataEmail, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          setEmailLoader(false);
          if (response.code != "validation_error") showMessage(response);
          setFormError(response.messages);
          setPopupError(response.messages.email_id);
          setIsPopup(true);
          return;
        }
        setEmailLoader(false);
        setFormError({});
        setFormDataEmail({ ...formDataEmail, ...{ email_id: "" } });
        showMessage(response);
      }
    );
  }
  // Email section End

  // Load captcha
  const getCaptcha = (event) => {
    makeAjax(url.USER_API.loadCaptcha, [], url.USER_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setCaptcha(response.data);
      setFormDataBank(formDataBank);
    });
  };

  const onChangeBank = (event) => {
    let init = JSON.parse(JSON.stringify(formDataBank));
    init["account_number"] = "";
    if (!event) {
      init["bank_id"] = "";
    } else {
      makeAjax(
        url.USER_API.getBankValidationCode,
        { bank_id: event._id },
        url.USER_MS_EXT
      ).then((response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setBankValidationLength(response.data[0].validation_length);
      });

      init["bank_id"] = event._id;
    }

    setFormDataBank(init);
  };

  const handleChange = (event) => {
    let tData = JSON.parse(JSON.stringify(formDataBank));
    const re = /^[0-9\b]+$/;
    const name = event.target.name;
    const value = event.target.value;

    if (name == "account_number") {
      if (value != "" && !re.test(value)) {
        return true;
      } else if (
        value.length > bankValidationLength ||
        value < 0 ||
        value.startsWith(".") ||
        value.endsWith(".") ||
        value == "."
      ) {
        return false;
      }
    }

    tData[event.target.name] = event.target.value;

    if (event.target.name == "username") {
      tData["username"] = event.target.value.toUpperCase();
    }

    if (event.target.name == "bank_id") tData["account_number"] = "";
    setFormDataBank(tData);


  };

  const [secQ, setSecQ] = useState(false);

  function verifyOnBankSubmit(event) {
    event.preventDefault();
    setLoader(true);
    formDataBank["key"] = !captcha.key ? "" : captcha.key;

    makeAjax(
      url.USER_API.validateBankDetails,
      formDataBank,
      url.USER_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        setLoader(false);
        if (response.code != "validation_error") {
          showMessage(response);
        }
        if (response.code == "securityQuestionErr") {
          setPopupError(response.messages[0]);
          setIsPopup(true);
        }
        getCaptcha();
        setFormErrorBank(response.messages);
        return;
      }
      setLoader(false);
      setFormErrorBank({});
      setSecurityQuestion(response.data);
      setSecQ(true);
    });
  }

  const getBankList = () => {
    makeAjax(url.USER_API.bankDD, [], url.USER_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setBankListData(response.data);
    });
  };

  useEffect(() => {
    getBankList();
    getCaptcha();
  }, []);

  const handleQuestionChange = (event) => {
    let uData = JSON.parse(JSON.stringify(securityQuestion));
    if (event) {
      uData[event.target.name] = event.target.value;
      uData["username"] = formDataBank.username;
    }
    setSecurityQuestion(uData);
    //setSecurityQuestion({ ...securityQuestion, ...{ [event.target.name]: event.target.value } })
  };

  const getVerificationLink = (event) => {
    setBankLoader(true);
    event.preventDefault();
    makeAjax(
      url.USER_API.verifyWithBankDetails,
      securityQuestion,
      url.USER_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        setBankLoader(false);
        if (response.code != "validation_error") showMessage(response);
        setFormErrorAnswer(response.messages);
        return;
      }
      setBankLoader(false);
      setSecurityQuestion({ ...securityQuestion, ...{ answer: "" } });
      setFormErrorAnswer({});
      //navigate(response.data);
      window.location.href = response.data;
    });
  };

  const triggerEmail = () => {
    setIsEmail(true);
    setIsBank(false);
    setFormErrorBank({});
    setFormDataEmail({ ...formDataEmail, ...{ email_id: "" } });
  };

  const triggerBank = () => {
    setIsBank(true);
    setIsEmail(false);
    setFormError({});
    setFormDataBank(formDataBank);
  };

  const data = "Hello Everyone";

  const [spinning, setSpinning] = useState(false);
  const handleRefreshBalance = () => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 1000);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "37px",
      height: "37px",
      boxShadow: state.isFocused ? null : null,
      cursor: "pointer",
      fontSize: "14px",

      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
        minHeight: "32px",
        height: "32px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
      backgroundColor: state.isSelected ? "transparent" : "transparent", // Change background color for selected option
      color: state.isSelected ? "#D47DFF" : "#BAAEC1",
      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#D47DFF" : "#D47DFF", // Change text color for selected value
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    menu: (provided) => ({
      ...provided,
      // For WebKit browsers (Chrome, Safari)
      "&::-webkit-scrollbar": {
        width: "3px",
        height: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#3498db", // Set the color of the scrollbar thumb
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
      },
      // For Firefox
      scrollbarColor: " #201E21",
      scrollbarWidth: "3px",
      scrollbarheight: "5px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  return (
    <div className="forgot-password">
      <div className="container">
        <div className="forgot-bg">
          <h4 className="title">{props.t("forgot_password")}</h4>
          {!secQ ? (
            <>

              <div className="form-div email-div">
                <form onSubmit={verifyOnEmailSubmit}>
                  <div className="input-control mb-3">
                    <input
                      type="email"
                      name="email_id"
                      value={formDataEmail.email_id}
                      onChange={handleEmailChange}
                      placeholder={props.t("enter_your_email")}
                    />
                  </div>
                  <ActionLoader
                    label={"process"}
                    loaderClass={"forgot-email for-sub"}
                    showLoader={loaderEmailStatus}
                  />
                </form>
              </div>

              {isBank && (
                <div className="form-div bank-div">
                  <form>
                    <div className="input-control">
                      <input
                        type="text"
                        placeholder={props.t("username")}
                        value={formDataBank.username}
                        name="username"
                        onChange={handleChange}
                      />
                      {formErrorBank.username ? (
                        <span className="text-danger error-msg">
                          {formErrorBank.username[0]}
                        </span>
                      ) : null}
                    </div>
                    <div className="input-control">
                      <Select
                        name="bank_id"
                        onChange={onChangeBank}
                        isClearable={true}
                        placeholder={`${props.t("select_bank")} / ${props.t(
                          "e_wallet_service"
                        )}`}
                        getOptionLabel={(option) => option.bank_name}
                        getOptionValue={(option) => option._id}
                        options={bankDD}
                        className="cusReactSelect"
                        styles={customStyles}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#181618",
                            primary: "#181618",
                          },
                        })}
                      />

                      {formErrorBank.bank_id ? (
                        <span className="text-danger error-msg">
                          {formErrorBank.bank_id[0]}
                        </span>
                      ) : null}
                    </div>
                    <div className="input-control">
                      <input
                        type="text"
                        placeholder={`${props.t("account_name")} / ${props.t(
                          "e_wallet"
                        )}`}
                        name="account_name"
                        value={formDataBank.account_name}
                        onChange={handleChange}
                      />
                      {formErrorBank.account_name ? (
                        <span className="text-danger error-msg">
                          {formErrorBank.account_name[0]}
                        </span>
                      ) : null}
                    </div>
                    <div className="input-control">
                      <input
                        type="text"
                        name="account_number"
                        placeholder={props.t("bank_account_number")}
                        value={formDataBank.account_number}
                        onChange={handleChange}
                      />
                      {formErrorBank.account_number ? (
                        <span className="text-danger error-msg">
                          {formErrorBank.account_number[0]}
                        </span>
                      ) : null}
                    </div>
                    <div className="input-control">
                      <div className="captcha-div-input">
                        <input
                          onChange={handleChange}
                          name="captcha"
                          maxLength={5}
                          type="text"
                          placeholder={props.t("enter_captcha")}
                        />
                        {captcha ? <img alt="Captcha" src={captcha.img} /> : null}
                        <button
                          type="button"
                          onClick={getCaptcha}
                          className="btn m-0 forgot-btn"
                        >
                          <MdRefresh
                            onClick={() => {
                              handleRefreshBalance();
                            }}
                            className={`spin-button ${spinning ? "spin" : ""}`}
                          />
                        </button>
                      </div>
                      {formErrorBank.captcha ? (
                        <span className="text-danger error-msg">
                          {formErrorBank.captcha[0]}
                        </span>
                      ) : null}
                    </div>
                    <ActionLoader
                      label={"process"}
                      loaderClass={"forget-password-email for-sub"}
                      showLoader={loaderStatus}
                      onClick={verifyOnBankSubmit}
                    />
                  </form>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="form-div security-que">
                <h5 className="ques">{securityQuestion.question}</h5>
                <form>
                  <input
                    type="text"
                    name="answer"
                    value={securityQuestion.answer}
                    onChange={handleQuestionChange}
                    placeholder={props.t("secret_answer")}
                  />
                  {formErrorAnswer.answer ? (
                    <span className="text-danger">
                      {formErrorAnswer.answer[0]}
                    </span>
                  ) : null}
                  <span className="note">
                    *
                    {props.t(
                      "please_remember_the_answer_to_your_security_question_a_security_question_will_be_asked_when_you_forget_your_password"
                    )}{" "}
                  </span>
                  <ActionLoader
                    label={"process"}
                    loaderClass={"bank-forget-password-email"}
                    showLoader={loaderBankStatus}
                    onClick={getVerificationLink}
                  />
                </form>
              </div>
            </>
          )}
        </div>
        {isPopup && (
          <Popup handlePoup={handlePopup} data={popupError} forgotPass />
        )}
      </div>
    </div>
  );
};

ForgotPasswordInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ForgotPasswordInner);
