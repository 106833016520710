import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ContactUs = (props) => {
  return (
    <div className='contact-us'>
      <h3 className="title-tab">{props.t('contact_us')}</h3>
      <p className="desc">{props.t('we_are_here_to_serve_customers_twenty_four_hours_a_day_and_contact_us_through_the_available_contacts_so_you_can_interact_directly_with_us')}.</p>
    </div>
  )
}

ContactUs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ContactUs);