import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import CommonSec from "../../commonSection/CommonSec";
import { useSelector } from "react-redux";

const AboutUs = (props) => {
  const [pageContent, setPageContent] = useState("");
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));
  const readAboutus = (event) => {
    let payload = {
      code: "about_us",
    };

    makeAjax(url.USER_API.getTextInfo, payload, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }

        if (response && response?.data && response?.data?.value) {
          setPageContent(response.data.value);
        }
      }
    );
  };
  useEffect(() => {
    readAboutus();
  }, []);
  return (
    <>
      <div
        className="about-us"
      >
        {
          componentSetting?.page_title?.options?.section_title && <div className="title">{props.t("about_us")}</div>
        }
        <div className="about-us-container">
          <div className="about-content">
            <p
              className="t-m"
              dangerouslySetInnerHTML={{ __html: pageContent }}
            />
          </div>
        </div>
      </div>
      <CommonSec />
    </>
  );
};
AboutUs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(AboutUs);
