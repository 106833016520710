import {
    GET_MEMO_COUNT_SUCCESS,
  } from "./actionTypes"
  
  const initialState = {
    data:0,
  }
  
  const getMemoCountR = (state = initialState, action) => {
    switch (action.type) {
      case GET_MEMO_COUNT_SUCCESS:
        state = { ...state, data: action.payload.response };
        return state;
    }
    return state
  }
  
  export default getMemoCountR
  