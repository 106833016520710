import { useState, useEffect } from "react";
import { Button, Nav } from "react-bootstrap";
import { PiUsersThreeLight } from "react-icons/pi";
import { RiInformationLine } from "react-icons/ri";
import { IoIosInformationCircle } from "react-icons/io";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
  dateFormat,
} from "../../helpers/global_helper";
import Popup from "../popup/Popup";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Tabs, Tab } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import ReferralInner from "../user-pages/referral/ReferralInner";
import ReferralsInnerNonLogin from "./ReferralsInnerNonLogin";
import TurnoverProviders from "./TurnoverProviders";
import CalculationMethod from "./CalculationMethod";
import ReferralsSidebar from "./ReferralsSidebar";
import ReferralsList from "./ReferralsList";
import ReferralsMemberRegister from "./ReferralsMemberRegister";
const ReferralsInner = (props) => {

  // Navbar
  const navigate = useNavigate();
  // Pagination Init
  const InitFilterPage = {
    per_page: 5,
    page: 1,
    last_page: 1,
    current_page: 1,
  };




  // Set & get data
  const [memberRegister, setMemberRegiter] = useState(false);

  const [isMessageSet, setIsMessageSet] = useState(false);
  const [history, setHistory] = useState(false);
  const [listReferral, setListReferral] = useState(true);

  const [referralCount, setReferralCount] = useState(0);
  const [singleFieldData, setSingleFieldData] = useState(InitFilterPage);
  const [referralPlayerList, setReferralPlayerList] = useState([]);
  const [playGamePopup, setPlayGamePopup] = useState(false);
  const [validationStatus, setValidationStatus] = useState(false);
  const [selectedPage, setSelectedPage] = useState('list_members');

  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));



  // Onclick create new mesage
  const clickMemberRegister = (event) => {
    setMemberRegiter(true);
    setHistory(false);
    setListReferral(false);
  };




  const listReferralPlayer = (event) => {
    setHistory(false);
    setListReferral(true);
    setMemberRegiter(false);
  };
  //popup link message
  const handlePopup = () => {
    setIsMessageSet(false);
    setPlayGamePopup(false);
  };

  // Handle open popup
  const popUpMessage = () => {
    setIsMessageSet(true);
  };

  // Get player profile data
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));

  // Use effect call
  useEffect(() => {
    if (typeof userProfile.referral_click_count != "undefined") {
      setReferralCount(userProfile.referral_click_count);
    }
  }, [referralCount, userProfile]);

  // Copy referral link
  const copy = (data) => {
    navigator.clipboard.writeText(data);
  };

  // User token from session
  let loginToken = window.localStorage.getItem("user_token");


  // Fetch other media
  const { otherMedia } = useSelector((state) => ({
    otherMedia: state.othermediaInformationListR.data,
  }));

  // Get device type
  let deviceType = localStorage.getItem("device_type");

  const initAssetConfig = {
    width: "300px",
    height: "200px",
    alt: "defautl alt",
    description: "default description",
    image: "",
    link: window.location.origin,
  };

  const initBannerAssetConfig = {
    width: 300,
    height: 200,
    alt: "defautl alt",
    description: "default description",
    image: "/assets/images/gohjiyongzz.gif",
    link: window.location.origin,
  };

  const initStyle = {
    width: "",
    height: "",
    backgroundImage: "",
  };

  const [assetsBg, setAssetsBg] = useState(initAssetConfig);
  const [assetsBanner, setAssetsBanner] = useState(initBannerAssetConfig);

  const [style, setStyle] = useState(initStyle);
  // Effect call for manupulate data
  useEffect(() => {
    // Width
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["width"] != "undefined"
    ) {
      assetsBg["width"] = otherMedia[deviceType + "_" + "background"]["width"];
      style["width"] =
        otherMedia[deviceType + "_" + "background"]["width"] + "px";
    }
    // Heigth
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["height"] !=
      "undefined"
    ) {
      assetsBg["height"] =
        otherMedia[deviceType + "_" + "background"]["height"];
      style["height"] =
        otherMedia[deviceType + "_" + "background"]["height"] + "px";
    }

    // Image path
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["path"] != "undefined"
    ) {
      assetsBg["image"] = otherMedia[deviceType + "_" + "background"]["path"];
      style["backgroundImage"] = `url(${otherMedia[deviceType + "_" + "background"]["path"]
        })`;
    }

    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["link"] != "undefined"
    ) {
      assetsBg["link"] = otherMedia[deviceType + "_" + "background"]["link"];
    }

    setAssetsBanner(assetsBanner);
    setAssetsBg(assetsBg);
    setStyle(style);
  }, [assetsBg, assetsBanner, style]);

  //validation check
  const getValidationStaus = (event) => {
    makeAjax(url.USER_API.getValidationStatus, [], url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
        }
        if (response.data.validation_status != "valid") {
          setValidationStatus(false);
          navigate("/validation");
        } else {
          setValidationStatus(true);
        }
      }
    );
  };

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobile = window.innerWidth <= 768;
      setIsDesktop(!isMobile);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);



  const [deviceTypeState, setDeviceTypeState] = useState(false);

  useEffect(() => {
    var device = localStorage.getItem("device_type");
    if (device == "mobile") {
      setDeviceTypeState(true);
    }
  }, []);

  // Effect List
  useEffect(() => {
    getValidationStaus();
  }, []);

  return (
    <>
      {validationStatus && (
        <div
          className="referrals"
          style={
            style["backgroundImage"] != ""
              ? {
                backgroundImage: style["backgroundImage"],
                width: style["width"],
                height: style["height"],
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
              : {}
          }
        >
          <div className="container">
            <div className="referal-bg">
              <div className="referrals-box">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                  {
                    componentSetting?.custom_loading?.options?.referral_tab ?
                      <>
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link eventKey="first">
                              <span> <PiUsersThreeLight /></span>
                              {props.t("referral")}
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="second">
                              <RiInformationLine />
                              {props.t("info_referral")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <ReferralsInnerNonLogin />
                          </Tab.Pane>

                          <Tab.Pane eventKey="second">
                            <ReferralsSidebar />
                          </Tab.Pane>
                        </Tab.Content>
                      </>

                      : < ReferralsSidebar />

                  }

                </Tab.Container>
              </div>
            </div>
          </div>
          {isMessageSet && <Popup handlePoup={handlePopup} referralMsg />}
          {playGamePopup && <Popup handlePoup={handlePopup} gameNotPlay />}
        </div >
      )}
    </>
  );
};
ReferralsInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReferralsInner);
