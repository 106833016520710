import React from "react";
import Readmore from "./readmore/Readmore";
import Payment from "./payment/Payment";
import Partners from "./partners/Partners";
import { CSS_CLASSES } from "../../css-classes";
import ExtraMenu from "./extra-menu/ExtraMenu";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PartnersGrid from "./partners/PartnersGrid";

const CommonSec = (props) => {
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));

  const location = useLocation();

  return (
    <div className={CSS_CLASSES.commonSec}>
      <div className="game-outer">
        {componentSetting?.['read-more'] != undefined && ['/'].includes(location.pathname) && componentSetting?.['read-more']?.["default-value"] == "fixed" && <Readmore />}
        {componentSetting?.custom_loading?.options?.common_section_handle && componentSetting?.["device-login-info"]?.options?.payments && ['/', '/deposit', '/withdraw', '/transaction-queue'].includes(location.pathname) && <Payment />}
        {typeof componentSetting?.["partners-logo"] != 'undefined' && componentSetting?.["partners-logo"]?.["default-value"] && <Partners />}
      </div>
    </div>
  );
};

CommonSec.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(CommonSec);
