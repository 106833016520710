import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { makeAjax, showMessage, url, respStatus } from "../../../helpers/global_helper";

const PartnersGrid = (props) => {
  const [providerIcon, setProviderIcon] = useState([]);

  // Get provider list
  const getPartnerIconList = (e) => {
    makeAjax(
      url.USER_API.getProviderIcon, [], url.USER_MS_EXT).then(response => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
        }
        setProviderIcon(response.data);
      });
  }

  // Use effect call
  useEffect(() => {
    getPartnerIconList();
  }, []);


  // Get device type
  let deviceType = window.localStorage.getItem("device_type");
  const data = [
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
    {
      imgpath: "../assets/images/partners/BNI.png"
    },
  ];
  return (
    <>
      <div className="partner-grid-item">
        {typeof providerIcon[deviceType + '_footer_provider_icon'] != 'undefined' && providerIcon[deviceType + '_footer_provider_icon'].map((v, i) =>
          <a href={v.link} key={i}>
            <img src={v.path} alt={v.alternate} width={v.width} height={v.height} className="img-fluid img-item" />
          </a>
        )}
      </div>
    </>
  )
}

export default PartnersGrid
