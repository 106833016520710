import { call, put, all, takeEvery } from "redux-saga/effects";
import {
    FETCH_DEPOSIT_PENDING
} from "./actionTypes"
import {
    fetchDepositPendingSuccess
} from "./actions";

import { makeAjax, url, showMessage } from "../../helpers/global_helper";

import { DPWD_MS_EXT } from "../../helpers/url_helper";

function* fetchDepositPending() {
  try {
    
    const response = yield call(
      makeAjax,
      url.DPWD_API.getDepostListTen,
      {},
      DPWD_MS_EXT
    );
    if (response.status == 'SUCCESS') {
      yield all([
        put(fetchDepositPendingSuccess({ response: response.data }))
      ]);
    }
    else {
      showMessage(response);
    }
  } catch (error) {
    showMessage(error);
    
    // alert
  }
}

function* fetchDepositPendingSaga() {
  yield takeEvery(FETCH_DEPOSIT_PENDING, fetchDepositPending);
}

export default fetchDepositPendingSaga;
