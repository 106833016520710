import { useState, useEffect } from "react";
import { FaHistory } from "react-icons/fa";

import {
  HiViewList,
  HiOutlineUsers,
  HiOutlineChartSquareBar
} from "react-icons/hi";

import { TbFileInvoice } from "react-icons/tb";
import { RiInformationLine } from "react-icons/ri";

import {
  showMessage,
  makeAjax,
  url,
  respStatus
} from "../../helpers/global_helper";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Popup from "../popup/Popup";
import ReferralsMemberRegister from "./ReferralsMemberRegister";
import HistoryProviders from "./HistoryProviders";
import ReferralsList from "./ReferralsList";
import TurnoverProviders from "./TurnoverProviders";

const ReferralsSidebar = (props) => {
  const [selectedPage, setSelectedPage] = useState("list_members");
  const [referralPlayerCount, setReferralPlayerCount] = useState(0);
  const [deviceTypeState, setDeviceTypeState] = useState(false);
  const [playGamePopup, setPlayGamePopup] = useState(false);
  const [isMessageSet, setIsMessageSet] = useState(false);
  const [memberRegister, setMemberRegiter] = useState(false);
  const [history, setHistory] = useState(false);
  const [infoTurnover, setInfoTurnover] = useState(false);
  const [listReferral, setListReferral] = useState(true);

  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));

  // Onclick create new mesage
  const clickMemberRegister = (event) => {
    setMemberRegiter(true);
    setHistory(false);
    setListReferral(false);
    setInfoTurnover(false);
  };
  const clickhistory = (event) => {
    setSelectedPage("history");
    setMemberRegiter(false);
    setHistory(true);
    setListReferral(false);
    setInfoTurnover(false);
  };
  const listReferralPlayer = (event) => {
    setSelectedPage("list_members");
    setListReferral(true);
    setMemberRegiter(false);
    setHistory(false);
    setInfoTurnover(false);
  };

  const clickInfoTurnover = () => {
    if (componentSetting?.custom_loading?.options?.referral_navigation_handle)
      setSelectedPage("info_turnover")
    setListReferral(false);
    setMemberRegiter(false);
    setHistory(false);
    setInfoTurnover(true);
  };

  // Get referral list
  const getReferralPlayerCount = () => {
    makeAjax(url.USER_API.listReferralCount, [], url.USER_MS_EXT).then(
      (response) => {
        
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setReferralPlayerCount(response.data);
      }
    );
  };

  // Effect List
  useEffect(() => {
    getReferralPlayerCount();
  }, []);

  // User token from session
  let loginToken = window.localStorage.getItem("user_token");

  // Redirect to lobby
  const goToPrividerLobby = (tab) => {
    if (loginToken != null) {
      let payload = {
        tab_info: tab,
      };
      makeAjax(url.GP_API.loadHkbLobby, payload, url.GP_MS_EXT).then(
        (response) => {
          if (response.status != respStatus["SUCCESS"]) {
            if (response.code != "validation_error") {
              showMessage(response);
            }
            showMessage(response);
            return;
          }
          if (
            response.data &&
            typeof response.data != "undefined" &&
            response.data.url
          ) {
            window.open(response.data.url, "_blank");
            return;
          }
          setPlayGamePopup(true);
        }
      );
    } else {
      
    }
  };

  useEffect(() => {
    if (localStorage.getItem("device_type") == "mobile") {
      setDeviceTypeState(true);
    }
  }, []);

  // popup link message
  const handlePopup = () => {
    setIsMessageSet(false);
    setPlayGamePopup(false);
  };

  const copy = (data) => {
    navigator.clipboard.writeText(data);
  };

  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));

  const popUpMessage = () => {
    setIsMessageSet(true);
  };

  return (
    <>
      <div className={`indox-div ${deviceTypeState ? "mobile" : ""}`}>
        <div className="member-reg-mobile">
          {
            componentSetting?.custom_loading?.options?.referral_tab &&
            <>
              <div className="member-btn">
                <button className="createbutton" onClick={clickMemberRegister}>
                  {props.t("member_registration")}
                </button>
              </div>

              <div className="copy-id">
                <span
                  onClick={() => {
                    copy(
                      window.location.origin + "/referral/" + userProfile.username
                    );
                    popUpMessage();
                  }}
                >
                  {props.t("copy_your_referral_id")}
                </span>
              </div>
            </>
          }

        </div>

        <div className="main-rm">
          <div className="message-box">
            <div
              className={`message-option ${listReferral && selectedPage == "list_members" && "active"
                }`}
              onClick={() => {
                listReferralPlayer();
              }}
            >
              <p>
                <HiViewList />
                &nbsp;
                <span className="listtext">{props.t("list_members")}</span>
              </p>
              <span className="count">{referralPlayerCount}</span>
            </div>

            <div
              className="message-option"
              onClick={() => goToPrividerLobby("bonus_referral")}
            >
              <p>
                <HiOutlineUsers />
                &nbsp;
                {props.t("bonus_referral")}
              </p>
            </div>

            <div
              className={`message-option ${selectedPage == "history" && " active"
                }`}
              onClick={() => { clickhistory(); }}
            >
              <p>
                <FaHistory />
                &nbsp;
                <NavLink className="drop-link">
                  {props.t("history")}
                </NavLink>
              </p>
            </div>

            {deviceTypeState ? (
              <div
                className={`message-option ${selectedPage == "info_turnover" && " active"
                  }`}
              >
                <p>
                  <NavLink
                    to={!componentSetting?.custom_loading?.options?.referral_navigation_handle && "../turnover"}
                    onClick={() => {
                      clickInfoTurnover();
                    }}
                  >
                    {props.t("info_turnover")}
                  </NavLink>
                </p>
              </div>
            ) : (
              <div className={`message-option ${infoTurnover && " active"
                }`} onClick={clickInfoTurnover}>
                <p>
                  <RiInformationLine />
                  &nbsp;
                  <NavLink className="drop-link" >
                    {props.t("info_turnover")}
                  </NavLink>
                </p>
              </div>
            )}

            <div
              className="message-option"
              onClick={() => goToPrividerLobby("invoice_togel")}
            >
              <p>
                <TbFileInvoice />
                &nbsp;
                {props.t("invoice_togel")}
              </p>
            </div>
            <div
              className="message-option"
              onClick={() => goToPrividerLobby("bonus_event")}
            >
              <p>
                <HiOutlineChartSquareBar />
                &nbsp;
                {props.t("bonus_event")}
              </p>
            </div>
            <div className="member-btn">
              <button className="createbutton" onClick={clickMemberRegister}>
                {props.t("member_registration")}
              </button>
            </div>
          </div>

          <div className="main-right">
            <div className="user-div  referral-member">
              {listReferral && (
                <div className="title-box">
                  <h3 className="text-create-message uppercase">
                    {props.t("list_members")}
                  </h3>
                </div>
              )}
              {memberRegister && (
                <div className="title-box">
                <h3 className="text-create-message uppercase">
                  {props.t("member_registration")}
                </h3>
                </div>
              )}
              {history && (
                <div className="title-box">
                  <h3 className="text-create-message uppercase">
                    {props.t("history")}
                  </h3>
                </div>
              )}
              {infoTurnover && (
                <div className="title-box">
                  <h3 className="text-create-message uppercase">
                    {props.t("turnover")}
                  </h3>
                </div>
              )}
              <div className="copy-id">
                <span
                  onClick={() => {
                    copy(
                      window.location.origin +
                      "/referral/" +
                      userProfile.username
                    );
                    popUpMessage();
                  }}
                >
                  {props.t("copy_your_referral_id")}
                </span>
              </div>
            </div>
            {memberRegister && <ReferralsMemberRegister getReferralPlayerCount={getReferralPlayerCount}  />}
            {history && <HistoryProviders />}
            {listReferral && <ReferralsList />}
            {infoTurnover && <TurnoverProviders showCommanSection={'disable'} />}
          </div>

        </div>


        {isMessageSet && <Popup handlePoup={handlePopup} referralMsg />}
        {playGamePopup && <Popup handlePoup={handlePopup} gameNotPlay />}

      </div >
    </>
  );
};
ReferralsSidebar.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReferralsSidebar);
