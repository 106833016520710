import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const GameNotPlay = (props) => {
    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>
            <p> {props.t('game_not_play')} !</p>
        </div>
    )
}

GameNotPlay.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(GameNotPlay);