import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { CSS_CLASSES } from "../../css-classes";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const initSetting = {
  slider: null,
  dots: null,
  arrows: null,
  autoplay: null,
  speed: null,
  infinite: null,
  slidesToShow: null,
  slidesToScroll: null,
  visibilty: true,
  nextArrow: <IoIosArrowForward />,
  prevArrow: <IoIosArrowBack />
};

const initClass = {
  class: null,
  default_class: null,
}

const SliderSec = (props) => {

  // Get device type
  let deviceType = localStorage.getItem("device_type");
  const [sliderComponent, setSliderComponent] = useState(initSetting);
  const [sliderClass, setSliderClass] = useState(initClass);

  const SliderImg = [
    {
      img: "/assets/images/default-images/banner_" + deviceType + ".png",
      link: "",
      width: "auto",
      height: 480,
      alt: "slider img",
    },
    // {
    //   img:
    //     "/assets/images/default-images/Main-Banner-GIFs" + deviceType + ".png",
    //   link: "",
    //   width: "auto",
    //   height: 480,
    //   alt: "slider img",
    // },
  ];
  // Set & get state
  const [assets, setAssets] = useState(SliderImg);

  // Fetch other media
  const { otherMedia } = useSelector((state) => ({
    otherMedia: state.othermediaInformationListR.data,
  }));

  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  // Effect call for manupulate data
  useEffect(() => {
    setAssets(SliderImg);
    if (typeof otherMedia[deviceType + "_" + "slider"] != "undefined" &&
      otherMedia[deviceType + "_" + "slider"].length > 0) {
      let sliderList = otherMedia[deviceType + "_" + "slider"];
      let sliderArr = [];
      for (const slider of sliderList) {
        let obj = {};
        obj["width"] = slider["width"];
        obj["height"] = slider["height"];
        obj["alt"] = slider["alternate"];
        obj["img"] = slider["path"];
        obj["link"] = slider["link"];
        obj["description"] = slider["description"];
        sliderArr.push(obj);
      }
      setAssets(sliderArr);
    }
  }, [otherMedia]);

  useEffect(() => {
    let temp = { ...sliderComponent };
    temp["slider"] = componentSetting?.slider?.options?.["slider"];
    temp["dots"] = componentSetting?.slider?.options?.dots;
    temp["arrows"] = componentSetting?.slider?.options?.arrows;
    temp["autoplay"] = componentSetting?.slider?.options?.autoplay;
    temp["speed"] = componentSetting?.slider?.options?.speed;
    temp["infinite"] = componentSetting?.slider?.options?.infinite;
    temp["slidesToShow"] = parseInt(componentSetting?.slider?.options?.slidesToShow);
    temp["slidesToScroll"] = parseInt(componentSetting?.slider?.options?.slidesToScroll);
    temp["visibility"] = componentSetting?.slider?.options?.visibility;
    temp["nextArrow"] = <IoIosArrowForward />;
    temp["prevArrow"] = <IoIosArrowBack />;
    setSliderComponent(temp);

  }, [componentSetting?.slider]);
  return (
    <>
      {
        sliderComponent['slider'] &&
        //  < div className={sliderClass['default_class']}>
        < div>
          <div className="header-slider-section">
            <div className="container">
              <div className="banner-outer">
                {/* <h6 className="banner-title">Featured Games</h6> */}
                <div className="banner-inner">
                  <Slider {...sliderComponent} className={CSS_CLASSES.sliderMainDiv}>

                    {
                      assets.map((v, i) => {

                        return (
                          < div key={i} >
                            <a target="_blank" href={v.link}>
                              <img
                                src={v.img}
                                width={v.width}
                                height={v.height}
                                alt={v.alt}
                              />
                            </a>
                          </div>
                        );
                      })
                    }
                  </Slider>
                </div>
              </div>

            </div>
          </div >
        </div >
      }
    </>
  );
};

SliderSec.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(SliderSec);
