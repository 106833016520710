import { useEffect, useState } from "react"
import { FiRefreshCw } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonSec from "../../components/commonSection/CommonSec";
import { showMessage, makeAjax, url, respStatus,currencyFormat } from "../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";


const TransactionDepositQueue = (props) => {
    const navigate = useNavigate();
    const [viewData, setViewData] = useState({});


    useEffect(() => {
        checkPendingTransaction();
    }, []);
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data
    }));


    const checkPendingTransaction = () => {
        makeAjax(url.DPWD_API.checkPendingTransaction, {}, url.DPWD_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response.messages);
                    return;
                }
                if (response.data && !response.data.pending_status) {
                    navigate("/deposit");
                    return;
                }
                setViewData(response.data);
            })
    }


    return (
        <>
            <section className="depo-message">
                        <div className='container'>
                            <div className="depo-message-box">
                                <h3 className="depo-text">{props.t('we_have_received_your_deposit_request_for')}:</h3>
                                <div className=" depo-amount">{userProfile.currency} &nbsp;{currencyFormat(viewData.amount)}</div>
                                <h4 className="depo-text">{props.t('destination_account')}</h4>
                                <table className="w-2/4 depo-message-table">
                                    <tbody>
                                        <tr>

                                            <td>{props.t('banks')} / {props.t('e_wallet')}</td>
                                            <td>:</td>
                                            {userProfile.player_bank_detail && userProfile.player_bank_detail.bank_detail ?
                                                <td>
                                                    {userProfile.player_bank_detail.bank_detail.bank_name}
                                                </td>
                                                : null}
                                        </tr>
                                        <tr>

                                            <td>{props.t('account')} / {props.t('mobile_number')}</td>
                                            <td>:</td>
                                            <td>
                                                {userProfile.account_number}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>{props.t('account_name')} / {props.t('e_wallet')}</td>
                                            <td>:</td>
                                            {userProfile.player_bank_detail && userProfile.player_bank_detail.account_name ?
                                                <td>
                                                    {userProfile.player_bank_detail.account_name}
                                                </td>
                                                : null}
                                        </tr>
                                    </tbody>
                                </table>
                                <p className='depo-message-para'> {props.t('please_contact_our_operator_via_live_chat_or_memo_to_speed_up_the_process_of_your_deposit')} .<br /> {props.t('thank_you')}.</p>
                            </div>
                        </div>
                    </section>
            <CommonSec />
        </>
    )
}


TransactionDepositQueue.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
  };

export default withTranslation()(TransactionDepositQueue);