import {
  LIST_LANGUAGE_DATA_SUCCESS
} from "./actionTypes"

const initialState = {
  messages: {},
  selected_language: 'en'
}

const myLangR = (state = initialState, action) => {
  switch (action.type) {
    case LIST_LANGUAGE_DATA_SUCCESS:
      state.messages = action.payload.response;
      state.selected_language = action.payload.selected_language_code;
      break
  }
  return state
}

export default myLangR
