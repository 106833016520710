import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {useNavigate} from 'react-router-dom'

const PlayerRejected = (props) => {
    const navigate = useNavigate();

    const messageRead=()=>{
      props.handle();
        // navigate('/memo');
    }
  return (
    <div className='popup-box'>
        <h4 className="title">{props.t('information')}</h4>
        <p> {props.t('your_account_failed_validation')}.</p>
        <p> {props.t('check_your_memo_for_more_information')}.</p>
        <div className="text-center pt-1">
        <button onClick={() => messageRead()}>{props.t('read')}</button>
        </div>
    </div>
  )
}

PlayerRejected.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PlayerRejected);