import { Link, NavLink } from "react-router-dom"
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Favicon = (props) => {

    // Set & get state
    const InitMedia = {
        width: '100px',
        height: '100px',
        alternate: 'deafult alt',
        description: 'deafult description',
        path: '',
        link: ''
    };
    const [fav, setFav] = useState(InitMedia);

    // Fetch other media
    const { otherMedia } = useSelector(state => ({
        otherMedia: state.othermediaInformationListR.data
    }));

    // Get device type
    let deviceType = localStorage.getItem('device_type');

    // Effect call for manupulate data
    useEffect(() => {
        setFav(InitMedia);
        // Description
        if (typeof otherMedia[deviceType + '_' + 'favicon'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'favicon']['description'] != 'undefined') {
            fav['description'] = otherMedia[deviceType + '_' + 'favicon']['description'];
            setFav({
                ...fav
            });
        }

        // alternative
        if (typeof otherMedia[deviceType + '_' + 'favicon'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'favicon']['alternate'] != 'undefined') {
            fav['alternate'] = otherMedia[deviceType + '_' + 'favicon']['alternate'];
            setFav({
                ...fav
            });
        }

        // Image path
        if (typeof otherMedia[deviceType + '_' + 'favicon'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'favicon']['path'] != 'undefined') {
            fav['path'] = otherMedia[deviceType + '_' + 'favicon']['path'];
            setFav({
                ...fav
            });
        }
        // Image link
        if (typeof otherMedia[deviceType + '_' + 'favicon'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'favicon']['link'] != 'undefined') {
            fav['link'] = otherMedia[deviceType + '_' + 'favicon']['link'];
            setFav({
                ...fav
            });
        }

    }, [otherMedia]);

    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        let icon = '/favicon.ico';

        if (fav['path'] != '') {
            icon = fav['path'];
        }
        link.href = icon;
    }, [fav, otherMedia]);
}

Favicon.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Favicon);