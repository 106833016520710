import React from "react";
import { CSS_CLASSES } from "../../../../../css-classes";
import { HiOutlineUsers, HiOutlineDeviceMobile } from "react-icons/hi";
import { BiHomeAlt, BiDice5 } from "react-icons/bi";
import { TbCards, TbCrystalBall } from "react-icons/tb";
import { BsPen, BsQuestionCircle, BsFire } from "react-icons/bs";
import { GiEightBall, GiInvertedDice5 } from "react-icons/gi";
import { MdOutlineVideogameAsset, MdSportsSoccer } from "react-icons/md";
import {
  LiaMoneyCheckAltSolid,
  LiaGiftsSolid,
  LiaCoinsSolid,
} from "react-icons/lia";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const VerticalNav = (props) => {
  // Get menu code
  const location = useLocation();
  let menu_code = location.pathname.split("/");
  let arr = menu_code.filter((e) => String(e).trim());
  const [allGameUrlStatus, setAllGameUrl] = useState(false);

  //  On click menu update status
  const updateMenuClick = (event) => {
    localStorage.setItem("all_game_click", false);
    if (event != "" && event === "home") {
      setAllGameUrl(true);
      localStorage.setItem("all_game_click", true);
    }
  };

  // Menu category
  const hoverGames = [
    { code: "home", menu: "Home Page", icon: <BiHomeAlt />, link: "/" },
    { code: "hot_games", menu: "Hot Games", icon: <BsFire />, link: "/games" },
    {
      code: "slots",
      menu: "Slots",
      icon: <LiaMoneyCheckAltSolid />,
      link: "/slots",
    },
    {
      code: "arcades",
      menu: "Arcades",
      icon: <MdOutlineVideogameAsset />,
      link: "/arcades",
    },
    {
      code: "jackpot_bar",
      menu: "Jackpot Bar",
      icon: <LiaCoinsSolid />,
      link: "/jackpot_bar",
    },
    {
      code: "live_casino",
      menu: "Casino",
      icon: <BiDice5 />,
      link: "/live_casino",
    },
    {
      code: "sportsbook",
      menu: "Sports",
      icon: <MdSportsSoccer />,
      link: "/sportsbook",
    },
    {
      code: "card_games",
      menu: "Card Games",
      icon: <TbCards />,
      link: "/card_games",
    },
    {
      code: "lottery",
      menu: "Lottery",
      icon: <GiEightBall />,
      link: "/lottery",
    },
    {
      code: "dingdong",
      menu: "Ding Dong",
      icon: <TbCrystalBall />,
      link: "/dingdong",
    },
    { code: "blogs", menu: "Blogs", icon: <BsPen />, link: "/blogs" },
    {
      code: "promotion",
      menu: "Promotions",
      icon: <LiaGiftsSolid />,
      link: "/promotion",
    },
    {
      code: "referral",
      menu: "Referrals",
      icon: <HiOutlineUsers />,
      link: "/referrals",
    },
    { code: "help", menu: "Help", icon: <BsQuestionCircle />, link: "/help" },
    {
      code: "mobile",
      menu: "Mobile",
      icon: <HiOutlineDeviceMobile />,
      link: "/mobile",
    },
  ];

  return (

    <div className={CSS_CLASSES.gamesVerticalMenu}>
      {hoverGames.map((v, i) => {
        return (
          props.playerMenu["before"][v.code] &&
          props.playerMenu["before"][v.code]["vertical_menu_enable"] &&
          props.playerMenu["before"][v.code]["status"] && (
            <div
              onClick={() =>
                updateMenuClick(props.playerMenu["before"][v.code]["code"])
              }
              data-id={"kkk"}
              className={`item ${(arr.includes(props.playerMenu["before"][v.code]["code"]) ||
                (arr.includes("games") &&
                  props.playerMenu["before"][v.code]["code"] ==
                  "hot_games") ||
                (arr.length == 0 &&
                  props.playerMenu["before"][v.code]["code"] == "home") ||
                (arr.includes(v.code) &&
                  props.playerMenu["before"][v.code]["code"] == "home")) &&
                "active"
                }`}
              key={i}
            >
              <div className="ver-background">
                {" "}
                {<NavLink to={v.link}>{v.icon}</NavLink>}
                {v.link ? (
                  <NavLink to={v.link}>
                    <h6 className="tabName">
                      {props.playerMenu["before"][v.code] &&
                        props.playerMenu["before"][v.code]["status"] &&
                        props.playerMenu["before"][v.code]["code"] == "home"
                        ? "All Games"
                        : props.playerMenu["before"][v.code]["name"]}
                    </h6>
                  </NavLink>
                ) : (
                  <a>
                    {" "}
                    {v.icon}{" "}
                    {props.playerMenu["before"][v.code]["code"] == "home"
                      ? "All Games"
                      : props.playerMenu["before"][v.code]["name"]}
                  </a>
                )}
              </div>
            </div>
          )
        );
      })}
    </div>

  );
};

VerticalNav.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(VerticalNav);
