import React from 'react'
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const ComingSoon = (props) => {
    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>
            <p>{props.t('coming_soon')} ...</p>
        </div>
    )
}
ComingSoon.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(ComingSoon);