import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ActionLoader from '../../../pages/ActionLoader';
const NewPasswordInner = (props) => {
    const InitData = {
        username: '',
        email_token: '',
    }

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        email_token: "",
        password: "",
        password_confirmation: ""
    })
    const [loaderStatus, setLoader] = useState(false);

    const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfirm, setValuesConfirm] = useState({
        password: "",
        showConfirmPassword: false,
    });
    const { emailToken } = useParams()
    const [getUsername, setUsername] = useState(InitData)
    const navigate = useNavigate()

    const verifyToken = () => {
        makeAjax(
            url.USER_API.verifyToken, { email_token: emailToken }, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != "validation_error") {
                        showMessage(response);
                        navigate("/");
                        return;
                    }
                }
                setUsername(response.data.data);
                setFormError({});
            });
    }
    let isLoaded = false;
    useEffect(() => {
        if (!isLoaded)
            verifyToken();
        isLoaded = true;
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoader(true);
        let init = formData;
        if (!event) {
            init['email_token'] = '';
        } else {
            init['email_token'] = emailToken;
        }
        setFormData(init);

        makeAjax(
            url.USER_API.verifiedToken,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setLoader(false);
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                setLoader(false);
                setFormError({});
                setFormData({ ...formData, ...{ password: '', password_confirmation: '' } });
                navigate("/");
                showMessage(response);
            });
    }

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }

    function titleCase(string) {
        if (!string) return string;
        return string[0].toUpperCase() + string.slice(1).toLowerCase();
    }

    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        const checkIsMobile = () => {
            const isMobile = window.innerWidth <= 768;
            setIsDesktop(!isMobile);
        };

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    const handleMouseOver = () => {
        if (isDesktop) {
            setValues({ ...values, showPassword: !values.showPassword });
        }
    };

    const handleClick = () => {
        if (!isDesktop) {
            setValues({ ...values, showPassword: !values.showPassword });
        }
    };

    const handleMouseOverConf = () => {
        if (isDesktop) {
            setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
        }
    };

    const handleClickConf = () => {
        if (!isDesktop) {
            setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
        }
    };

    return (
        <div className='new-auth'>
            <div className='container'>
                <div className="new-pass-box new-pass-box-bg">

                    {getUsername && getUsername.username ? (
                        <>
                            <h4 className="title" style={{ textTransform: 'capitalize' }}>{props.t('welcome')}, {titleCase(getUsername.username)}</h4>
                            <div className="new-pass-box-inner">
                                <form onSubmit={handleSubmit} >
                                    <div className="password-icon">
                                        <input type={values.showPassword ? "text" : "password"} placeholder={props.t('new_password')} value={formData.password} name="password" onChange={handleChange} />
                                        {formError.password ? (
                                            <span className="text-danger d-flex new-span error-msg">{formError.password[0]}</span>
                                        ) : null}

                                        {values.showPassword == false ? (
                                            <AiOutlineEyeInvisible onMouseEnter={handleMouseOver} onClick={handleClick} />
                                        ) :
                                            <AiOutlineEye onMouseLeave={handleMouseOver} onClick={handleClick} />
                                        }
                                    </div>
                                    <div className="password-icon">
                                        <input type={valuesConfirm.showConfirmPassword ? "text" : "password"} placeholder={props.t('confirm_new_password')} value={formData.password_confirmation} name="password_confirmation" onChange={handleChange} />
                                        {formError.password_confirmation ? (
                                            <span className="text-danger d-flex new-span error-msg">{formError.password_confirmation[0]}</span>
                                        ) : null}
                                        {valuesConfirm.showConfirmPassword == false ? (
                                            <AiOutlineEyeInvisible onMouseEnter={handleMouseOverConf} onClick={handleClickConf} />
                                        ) :
                                            <AiOutlineEye onMouseLeave={handleMouseOverConf} onClick={handleClickConf} />
                                        }
                                    </div>
                                    <ActionLoader label={'process'} loaderClass={'new-password'} showLoader={loaderStatus} />

                                </form>
                            </div>
                        </>
                    ) : null
                    }

                </div>
            </div>
        </div>
    )
}

NewPasswordInner.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(NewPasswordInner);