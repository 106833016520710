import { Navigate } from 'react-router-dom'
import SecurityQuestionInner from '../../components/authentication/securityQuestion/SecurityQuestionInner'
import FloatingComponents from '../../components/floatingComponents/FloatingComponents'
// import { componentSetting } from '../../template-settings'
import { useSelector } from 'react-redux'

const SecurityQuestion = () => {
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  return (<>
    <SecurityQuestionInner />
    {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
  </>
  )
}

export default SecurityQuestion