import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const PromoInfoInner = (props) => {
    return (
        <div className='promo-info'>
            <div className="container">
                <div className="promo-info-div">
                    <div className="promo-box">
                        <h4 className="title">{props.t('promotion_info')}</h4>
                        <table>
                            <tr><td>{props.t('promo_package')} :</td><td className="data">-</td></tr>
                            <tr><td>{props.t('bonus_amount')} :</td><td className="data">IDR</td></tr>
                            <tr><td>{props.t('target_turnover')} :</td><td className="data">IDR</td></tr>
                            <tr><td>{props.t('turnover_reached')} :</td><td className="data">0</td></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

PromoInfoInner.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PromoInfoInner);