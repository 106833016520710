import { useState, useEffect } from "react";
import { BsTelegram, BsWechat } from "react-icons/bs";
import { RiWhatsappFill, RiInstagramFill, RiTwitterXFill} from "react-icons/ri";
import { showMessage, makeAjax, url, respStatus, } from "../../../helpers/global_helper";
import { useDispatch } from "react-redux";
import { FaYoutube } from "react-icons/fa";
import { FaBlackberry, FaFacebook, FaWordpress, FaGooglePlus } from "react-icons/fa6";
import { GrSkype } from "react-icons/gr"
import { useSelector } from 'react-redux';

const initSetting = {
  social_media_static: null,
  class: null,
  default_class: null,
};
const SocialMedia = (props) => {
  // Set & get state
  const [getSocialMedia, setSocialMedia] = useState([]);
  const [orderSocialMedia, setOrderSocialMedia] = useState([]);

  const [fnSetting, setFnSetting] = useState(initSetting);

  const dispatch = useDispatch();
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  const socialMedia = [
    {
      title: "INSTAGRAM",
      menu: "Mobile",
      icon: <RiInstagramFill />,
      link: "mobile",
      name: "Instagram"
    },
    {
      title: "FACEBOOK",
      menu: "Mobile",
      icon: <FaFacebook />,
      link: "mobile",
      name: "Facebook"
    },
    {
      title: "WHATSAPP",
      menu: "Mobile",
      icon: <RiWhatsappFill />,
      link: "mobile",
      name: "Whatsapp"
    },
    {
      title: "TWITTER",
      menu: "Mobile",
      icon: <RiTwitterXFill />,
      link: "mobile",
      name: "Twitter"
    },
    { title: "YOUTUBE", menu: "Mobile", icon: <FaYoutube />, link: "mobile", name: "Youtube" },
    {
      title: "WORDPRESS",
      menu: "Mobile",
      icon: <FaWordpress />,
      link: "mobile",
      name: "Facebook"
    },
    { title: "SKYPE", menu: "Mobile", icon: <GrSkype />, link: "mobile", name: "Skype" },
    { title: "TELEGRAM", menu: "Mobile", icon: <BsTelegram />, link: "mobile", name: "Telegram" },
    {
      title: "GOOGLEPLUS",
      menu: "Mobile",
      // icon: <AiFillGooglePlusCircle />,
      icon: <FaGooglePlus />,
      link: "mobile",
      name: "Googleplus"
    },
    { title: "WECHAT", menu: "Mobile", icon: <BsWechat />, link: "mobile", name: "Wechat" },
    { title: "BBM", menu: "Mobile", icon: <FaBlackberry />, link: "mobile", name: "BBM" },
    {
      title: "LINE",
      menu: "Mobile",
      icon: <img src="../assets/images/line.svg" alt="Line" />,
      link: "mobile",
      name: 'Line'
    },
  ];
  // Get tennant media app
  const getSocialMediaApp = () => {
    makeAjax(url.ADMIN_API.listTenantSocialMedia, [], url.ADMIN_MS_EXT).then(
      (response) => {
        
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setSocialMedia(response.data);
      }
    );
  };
  // Effect call
  useEffect(() => {
    getSocialMediaApp();
  }, []);
  // Social app sorting
  useEffect(() => {
    if (
      typeof getSocialMedia.data != "undefined" &&
      getSocialMedia.data.length > 0
    ) {
      getSocialMedia.data.sort((a, b) => a.order - b.order);
      let updatedSocialApp = getSocialMedia.data.filter(
        (result) => result.status != "0"
      );
      setOrderSocialMedia(updatedSocialApp);
      if (updatedSocialApp.length > 5) {
        let data = updatedSocialApp.slice(0, 5);
        setOrderSocialMedia(data);
      }
    }
  }, [getSocialMedia]);

  useEffect(() => {
    let temp = { ...fnSetting };
    temp['social_media_static'] = componentSetting?.["social-media"]?.["default-value"];
    temp['default_class'] = componentSetting?.["social-media"]?.["default-value"];
    setFnSetting(temp);
    // if (typeof componentSetting?.["social-media"] != 'undefined') {
    //   setFnSetting({
    //     ...fnSetting,
    //     ['social_media_static']: componentSetting?.["social-media"]?.["default-value"],
    //     ['default_class']: componentSetting?.["social-media"]?.["default-value"],
    //   })
    // }
  }, [componentSetting?.["social-media"]]);

  return (
    <>
      {
        fnSetting['social_media_static'] && <div className="social-media">
          <div className={fnSetting['default_class']}>
            <div>
              {orderSocialMedia.length > 0 &&
                orderSocialMedia.map((item) => {
                  return (
                    typeof item.link != "undefined" &&
                    item.link != "" && (
                      <a href={item.link} target="_blank" key={item._id}>
                        {socialMedia.map((v, i) => {
                          return v.title == item.title &&
                            item.status != "0" &&
                            item.status != 0 ? (
                            <>
                              <span className="lable">{v.name}</span>
                              <span className="social-float-icon" key={i}>
                                {v.icon}
                              </span>
                            </>
                          ) : (
                            ""
                          );
                        })}
                      </a>
                    )
                  );
                })}

            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SocialMedia