import React from 'react'
import CommonSec from '../../components/commonSection/CommonSec'
import FloatingComponents from '../../components/floatingComponents/FloatingComponents'
import OutstandingBetInner from '../../components/user-pages/outstanding-bet/OutstandingBetInner'
import { useSelector } from 'react-redux'
// import { componentSetting } from '../../template-settings'

const OutstandingBet = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    return (
        <>
            <OutstandingBetInner />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
        </>
    )
}

export default OutstandingBet