import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const NewMessageNotification = (props) => {

    const refreshInboxList = (event) => {
        props.refreshInboxList();
    }
    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>
            <p>{props.t('please_check_your_memo_you_have')} {props.data} {props.t('message_from_operator')} !</p>
            <div className="text-center pt-2">
            <button onClick={refreshInboxList}
             >{props.t('read')}</button>
            </div>
            
        </div>
    )
}

NewMessageNotification.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(NewMessageNotification);