import { useDispatch, useSelector } from "react-redux"
import CommonSec from "../components/commonSection/CommonSec"
import FloatingComponents from "../components/floatingComponents/FloatingComponents"
import PromotionsInner from "../components/promotions-inner/PromotionsInner"
import { getPlayerProfileDetail } from "../store/actions"
import { useEffect } from "react";

const Promotion = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    let loggedIn = window.localStorage.getItem("user_token");

    const dispatch = useDispatch();
    useEffect(() => {
        if (loggedIn != null && loggedIn != '')
            dispatch(getPlayerProfileDetail());
    }, []);
    return (
        <>
            <PromotionsInner />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
        </>
    )
}

export default Promotion