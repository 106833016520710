import React from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

const Unauthorised401 = () => {
  return (
    <div className='error-404'>
            <div className="container">
                <div className="error-sub">
                    <div className="error-sub-left">
                        <h1>401</h1>
                        <h2>Unauthorised</h2>
                        <h2><strong>Access!</strong></h2>
                    </div>
                    <div className="error-sub-right">
                        <img alt='Error' className='img-fluid' src='/assets/images/error404.png'/>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Unauthorised401