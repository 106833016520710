import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const SecurityValidation = (props) => {
  if (!props.data.pin_failed_attempt) {
    props.handleErr(true);
  }

  return (
    <div className="popup-box">
      <h4 className="title">{props.t("information")}</h4>
      <p>
        {" "}
        {!props.data.pin_failed_attempt
          ? props.data.active_pin
          : props.data.pin_failed_attempt}
      </p>
    </div>
  );
};

SecurityValidation.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(SecurityValidation);
