import { FETCH_TEXT_INFO_SUCCESS } from "./actionTypes";

const initialState = {
    data: [],
};

const textInformationListR = (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_TEXT_INFO_SUCCESS:
            // state.data = action.payload.response;
            state = { ...state, data: action.payload.response };
            return state;
    }
    return state;
}

export default textInformationListR