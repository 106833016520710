import {
  FETCH_COMPONENT_SETTING,
  FETCH_MENU_COMPONENT_SETTING_SUCCESS
} from "./actionTypes"


export const fetchComponentSetting = () => {
  return {
    type: FETCH_COMPONENT_SETTING,
    payload: {},
  }
}

export const fetchComponentSettingSuccess = (response) => {
  return {
    type: FETCH_MENU_COMPONENT_SETTING_SUCCESS,
    payload: response,
  }
}