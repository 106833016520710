import { BsFillCalendarFill } from "react-icons/bs"
import { FaUserAlt } from "react-icons/fa"
import { NavLink } from "react-router-dom"
import { FaTag } from "react-icons/fa";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { dateFormat } from "../../../helpers/global_helper";

const BlogItem = (props) => {
    return (
        <div className='blog-item'>
            <img src={props.file_path + props.item.system_file_name} alt='Blog' className='img-fluid' />
            <div className="categories">
                {
                    props.item.category && props.item.category.map((v, i) =>
                        <a key={i} className="category-item">{v}</a>
                    )
                }
            </div>
            <h4 className="blog-title">{props.item.title}</h4>
            <div className="posted-on">
                <span className="date"> <BsFillCalendarFill /> {dateFormat(props.item.post_date.slice(0, 10))} </span>
                <span className="author"> <FaUserAlt /> {props.item.author} </span>
                {props.item.post_tags != null && <span className="tag"> <FaTag /> {props.item.post_tags} </span>}

            </div>
            <h6 className="short-desc" dangerouslySetInnerHTML={{ __html: props.item.short_content }} />
        </div>
    )
}

BlogItem.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(BlogItem);