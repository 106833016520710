import React, { useEffect, useState } from "react";
import {
  url,
  showMessage,
  respStatus,
  makeAjax,
} from "../../../helpers/global_helper";
import Popup from "../../popup/Popup";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";

const FindGames = (props) => {
  const [gameId, setGameId] = useState("");
  const [maintainanceError, setMaintainanceError] = useState(false);
  const [commingSoonPopup, setCommingSoonPopup] = useState(false);
  const [loginforLunchLobby, setLoginforLunchLobby] = useState(false);
  const [gameList, setGameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [ftp_path, setFtpPath] = useState(null);

  // Device type
  let deviceType = localStorage.getItem("device_type");
  const navigate = useNavigate();

  // Close maintaninace popup
  const handlePopup = () => {
    setMaintainanceError(false);
    setCommingSoonPopup(false);
    setLoginforLunchLobby(false);
  };

  // If game is on comming soon
  const commingSoon = (event) => {
    setCommingSoonPopup(true);
  };

  // If game is under maintennace
  const gameUnderMaintennace = (event) => {
    setMaintainanceError(true);
  };

  // Ajax call for game details
  const handleGameClick = (game) => {
    if (game.maintenance) {
      gameUnderMaintennace();
      return true;
    }
    if (game.status == "Coming Soon") {
      commingSoon();
      return true;
    }

    const payload = {
      game_id: game._id,
    };
    setGameId({ ...gameId, game_id: game._id });
    makeAjax(url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == "game_under_maintenance") {
            setMaintainanceError(true);
            return;
          }
          if (response.code == "game_not_active") {
            setCommingSoonPopup(true);
            return;
          }

          if (response.code == "validation.invalid_token_error") {
            navigate("/register");
            return;
          }

          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }
        //pg soft game data 7th feb 2024
        if (response?.data?.code === "bd200") {
          const newTab = window.open();
          newTab.document.write(response.data.data);
        } else {
          window.open(response.data, "_blank");
        }
      }
    );
  };

  useEffect(() => {
    if (typeof props.findGames.ftp_path != "undefined") {
      setFtpPath(props.findGames.ftp_path);
    }
    delete props.findGames.ftp_path;
  }, [props.findGames]);
  return (
    <>
      {Object.values(props.findGames).length > 0 ? (
        Object.values(props.findGames).map((v, i) => (
          <div
            key={i}
            onClick={() => {
              handleGameClick(v);
            }}
            className="findgames-box"
          >
            {v.maintainance_mode && (
              <div className="undermaintenance">
                {props.t("under_maintanance")}
              </div>
            )}
            <div className="relative">
              {v.game_assets_media != null ? (
                <img
                  className="find-img"
                  src={
                    v.ftp_path +
                    v.game_assets_media.base_path +
                    v.game_assets_media.system_file_name
                  }
                  alt={v.name}
                />
              ) : (
                <img
                  className="img-fluid"
                  src={
                    "../assets/images/default-images/game_" +
                    deviceType +
                    ".png"
                  }
                  alt={v.name}
                />
              )}
            </div>
            <a href="#">
              <div className="text-game">
                <div className="find-text">
                  {v.name.split("").length > 21 ? (
                    <div className="game-title hovering">{v.name}</div>
                  ) : (
                    <div className="game-title">{v.name}</div>
                  )}
                </div>
              </div>
            </a>
          </div>
        ))
      ) : (
        <>{!props?.loader && <p> {props.t("game_not_found")}.</p>}</>
      )}
      {props.loader && <Loading />}

      {maintainanceError && (
        <Popup handlePoup={handlePopup} providerMaintainance />
      )}
      {commingSoonPopup && <Popup handlePoup={handlePopup} commingSoonGame />}
      {loginforLunchLobby && (
        <Popup handlePoup={handlePopup} gameLunchLoginErrorPopup />
      )}

    </>
  );
};

FindGames.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(FindGames);
