import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const InvalidEmail = (props) => {
  return (
    <div className='popup-box'>
        <h4 className="title">{props.t('information')}</h4>
        <p>{props.data}</p>
    </div>
  )
}

InvalidEmail.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(InvalidEmail);