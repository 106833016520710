import React, { useEffect, useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { BiShowAlt } from "react-icons/bi";
import Popup from "../../popup/Popup";
import { NavLink, useNavigate, Link, withRouter } from "react-router-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getPlayerProfileDetail } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ActionLoader from "../../../pages/ActionLoader";
const EmailVerificationInner = (props) => {
  const InitFormData = {
    email_id: "",
    password: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopupInvalidEmail, setIsPopupInvalidEmail] = useState(false);
  const [hasVerificationEmailSent, setHasVerificationEmailSent] =
    useState(false);
  const [formError, setFormError] = useState({});
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [loaderStatus, setLoader] = useState(false);
  const [loaderVerifyStatus, setVerifyLoader] = useState(false);
  // const [loaderStatus, setLoaderStatus] = useState(false);
  const [formData, setFormData] = useState({
    email_id: "",
  });
  const [popUpData, setPopUpData] = useState({
    email_id: "",
    please_check: "",
  });

  const [formDataChangeEmail, setFormDataChangeEmail] = useState(InitFormData);
  const [formErrorChangeEmail, setFormErrorChangeEmail] = useState({});
  const [changeEmailData, setChangeEmailData] = useState(false);
  const [isErrorMessagePopUpChangeEmail, setIsErrorMessagePopUpChangeEmail] =
    useState(false);
  const [isErrorMessagePopUp, setIsErrorMessagePopUp] = useState(false);

  const [isSuccessMessagePopUp, setIsSuccessMessagePopUp] = useState(false);

  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));

  useEffect(() => {
    dispatch(getPlayerProfileDetail());
  }, []);

  const checkEmailVerified = () => {
    let verified_email = false;
    if (userProfile.email_verified_at) verified_email = true;
    setIsEmailVerified(verified_email);
  };

  useEffect(() => {
    checkEmailVerified();
  }, [userProfile]);

  const verifyOnEmailSubmit = (e) => {
    e.preventDefault();
    setVerifyLoader(true);
    makeAjax(url.USER_API.emailVerification, formData, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          setVerifyLoader(false);
          if (response.code != "validation_error") showMessage(response);
          setFormError(response.messages);
          // setIsErrorMessagePopUp(true);
          return;
        }
        setVerifyLoader(false);
        setFormError({});
        setPopUpData(response.data);
        setFormData({ ...formData, ...{ email_id: "" } });
        setFormSuccess(response.messages);
        setHasVerificationEmailSent(true);
        dispatch(getPlayerProfileDetail());
      }
    );
  };

  const changeEmail = () => {
    setIsEmailVerified(true);
  };

  const handlePopup = () => {
    setIsPopupInvalidEmail(false);
    setIsErrorMessagePopUp(false);
    setIsErrorMessagePopUpChangeEmail(false);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({ ...formError, [event.target.name]: "" });
  };

  const handleChangeEmail = (event) => {
    setFormDataChangeEmail({
      ...formDataChangeEmail,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    makeAjax(
      url.USER_API.changeEmail,
      formDataChangeEmail,
      url.USER_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        setLoader(false);
        if (response.code != "validation_error") showMessage(response);
        setFormErrorChangeEmail(response.messages);
        setIsErrorMessagePopUpChangeEmail(true);
        return;
      }
      setLoader(false);
      setFormSuccess(response.messages);
      setPopUpData(response.data);
      setFormDataChangeEmail(InitFormData);
      setIsSuccessMessagePopUp(true);
      dispatch(getPlayerProfileDetail());
    });
  };

  const handleSuccess = () => {
    setIsEmailVerified(true);
    setIsSuccessMessagePopUp(false);
    setHasVerificationEmailSent(true);
  };

  const [inputType, setInputType] = useState("password");
  const [isDesktop, setIsDesktop] = useState(true);

  const handleMouseOver = () => {
    if (isDesktop) {
      if (inputType == "password") setInputType("text");
      if (inputType == "text") setInputType("password");
    }
  };

  const handleClick = () => {
    if (!isDesktop) {
      if (inputType == "password") setInputType("text");
      if (inputType == "text") setInputType("password");
    }
  };

  return (
    <div className="email-verification">
      {!isEmailVerified && !hasVerificationEmailSent && (
        <div className="container">
          <div className="email-bg">
            <div className="form-div">
              <h4 className="title">{props.t("email_verification")}</h4>
              <form onSubmit={verifyOnEmailSubmit}>
                <div className="input-control">
                  <input
                    type="email"
                    name="email_id"
                    placeholder={props.t("enter_your_email")}
                    onChange={handleChange}
                  />
                  {formError.email_id ? (
                    <span className="text-danger d-flex error-msg">
                      {formError.email_id[0]}
                    </span>
                  ) : null}
                </div>
                <div className="pt-2">
                  <ActionLoader
                    label={"process"}
                    loaderClass={"verify-email-loader"}
                    showLoader={loaderVerifyStatus}
                  />
                </div>
              </form>
              <a onClick={changeEmail} className="change-email-link">
                {props.t("change_email")}?
              </a>
            </div>
          </div>
        </div>
      )}
      {isEmailVerified && !hasVerificationEmailSent && (
        <div className="container">
          <div className="email-bg">
            <div className="form-div">
              <h4 className="title">{props.t("change_email")}</h4>
              <form onSubmit={handleSubmit}>
                <div className="input-control">
                  <input
                    type="text"
                    name="email_id"
                    placeholder={props.t("new_email")}
                    onChange={handleChangeEmail}
                  />
                </div>
                <div className="input-control">
                  <input
                    type={inputType}
                    name="password"
                    placeholder={props.t("password")}
                    onChange={handleChangeEmail}
                  />
                  {inputType == "password" && (
                    <AiOutlineEyeInvisible
                      onMouseEnter={handleMouseOver}
                      onClick={handleClick}
                      className="hideicon"
                    />
                  )}
                  {inputType == "text" && (
                    <BiShowAlt
                      onMouseLeave={handleMouseOver}
                      onClick={handleClick}
                      className="showicon"
                    />
                  )}
                </div>
                <ActionLoader
                  label={"process"}
                  loaderClass={"change-email-loader"}
                  showLoader={loaderStatus}
                />
              </form>
            </div>
          </div>
        </div>
      )}
      {hasVerificationEmailSent && (
        <div className="container">
          <div className="verification-confirm">
            <HiOutlineMail className="icon" />
            <p>
              {popUpData.verification_link} <br />
              {popUpData.email_id}, {popUpData.please_check}
            </p>
            <button>
              <NavLink to="/profile" className="dropdown-item">
                {props.t("okay")}
              </NavLink>
            </button>
          </div>
        </div>
      )}
      {isPopupInvalidEmail && (
        <Popup
          handlePoup={handlePopup}
          data={[formError.email_id]}
          invalidEmail
        />
      )}
      {/* Error Message Pop Up */}
      {isErrorMessagePopUp && (
        <Popup handlePoup={handlePopup} data={[formError]} errorMessages />
      )}
      {isErrorMessagePopUpChangeEmail && (
        <Popup
          handlePoup={handlePopup}
          data={[formErrorChangeEmail]}
          errorMessages
        />
      )}

      {/* Success Message Pop Up */}
      {isSuccessMessagePopUp && (
        <Popup
          handlePoup={handleSuccess}
          data={[formSuccess]}
          successMessages
        />
      )}
    </div>
  );
};

EmailVerificationInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(EmailVerificationInner);
