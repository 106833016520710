import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const DiscountAndPrize = (props) => {
  return (
    <div className='discount-and-prize'>
      <h3 className="title-tab">{props.t('discounts_and_prizes_togel')}</h3>
      <p className="desc">Wigobet.com {props.t('has_the_ten_most_popular_online_lottery_markets_and_as_different_discounts_and_prizes_for_each_game')}</p>
    </div>
  )
}

DiscountAndPrize.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(DiscountAndPrize);