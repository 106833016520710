import PropTypes from "prop-types";
import CurrencyInput from 'react-currency-input-field'

const CInput = (props) => {
    return (
        <>
            <CurrencyInput
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                maxLength={props.maxLength?props.maxLength:15}
                onValueChange={(value, name) => props.onValueChange(value, name)}
            />
        </>
    )
}

CInput.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default CInput;