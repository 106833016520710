import { Navigate } from 'react-router-dom'
import SecurityValidationInner from '../../components/authentication/securityValidation/SecurityValidationInner'
import FloatingComponents from '../../components/floatingComponents/FloatingComponents'
import { useSelector } from 'react-redux'
// import { componentSetting } from '../../template-settings'

const SecurityValidation = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    return (<>
        <SecurityValidationInner />
        {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
    </>
    )
}

export default SecurityValidation