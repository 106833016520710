import {
    call,
    put,
    all,
    takeEvery
  } from "redux-saga/effects";
  import { GET_PLAYER_BALANCE} from "./actionTypes";
  import { getPlayerBalanceSuccess} from "./actions";
  import { makeAjax, url } from "../../helpers/global_helper";
  
  function* getPlayerBalanceData() {
    try {
      
      const response = yield call(
        makeAjax,
        url.GP_API.getBalance, [], url.GP_MS_EXT
      );
      if (response.status == 'SUCCESS') {
        yield all([
          put(getPlayerBalanceSuccess({ response: response.data }))
         
        ]);
      } else {
        // alert
      }
    } catch (error) {
      
      // alert
    }
  }
  
  function* getPlayerBalanceSaga() {
    yield takeEvery(GET_PLAYER_BALANCE, getPlayerBalanceData);
  }
  
  export default getPlayerBalanceSaga;