import { GrFormClose } from 'react-icons/gr';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ChangeEmail from './popup-components/ChangeEmail'
import ChangePassword from './popup-components/ChangePassword'
import CustomerComplaints from './popup-components/CustomerComplaints'
import EmailAlreadyUsed from './popup-components/EmailAlreadyUsed'
import ForgotPassword from './popup-components/ForgotPassword'
import InvalidEmail from './popup-components/InvalidEmail'
import LoginMobile from './popup-components/LoginMobile'
import SecurityQuestionChange from './popup-components/SecurityQuestionChange'
import PinProtection from './popup-components/PinProtection'
import SendEmail from './popup-components/SendEmail'
import SendEmailDuration from './popup-components/SendEmailDuration'
import NewMessageNotification from './popup-components/NewMessageNotification'
import ConfirmDelete from './popup-components/ConfirmDelete'
import ProviderMaintenance from './popup-components/ProviderMaintenance'

import { useState } from 'react'
import ReferralMessage from './popup-components/ReferralMessage'
import Agree from './popup-components/Agree'
import SecurityValidation from './popup-components/SecurityValidation'
import { useEffect } from 'react'
import { useRef } from 'react'
import EmailVerification from '../../pages/authentication/EmailVerification'
import ErrorMessages from './popup-components/ErrorMessages'
import SuccessMessages from './popup-components/SuccessMessages'
import RegisterWithLogin from './popup-components/RegisterWithLogin'
import UserBlock from './popup-components/UserBlock'
import { useSelector } from 'react-redux'
import ExpiredPassword from './popup-components/ExpiredPassword'
import Result from './popup-components/Result'
import ProviderGameCommingSoon from './popup-components/ProviderGameCommingSoon'
import TokenValidation from './popup-components/TokenValidation'
import GlobalMaintenance from './popup-components/GlobleMaintenance'
import PreLogin from './popup-components/PreLogin'
import PostLogin from './popup-components/PostLogin'
import DepositWithdrawMessage from './popup-components/DepositWithdrawMessage'
import PlayerRejected from './popup-components/PlayerRejected';
import GameNotPlay from './popup-components/GameNotPlay';
import CompleteResult from '../complete-result/CompleteResult';
import ShowWarning from './popup-components/ShowWarning';
import ShowSuccess from './popup-components/ShowSuccess';
import HomeScreenPopup from './popup-components/HomeScreenPopup';
import LoginPopup from './popup-components/LoginPopup';
import TextReadMore from './popup-components/TextReadMore';
import Notification from './popup-components/Notification';
import Register from './popup-components/Register';
import ComingSoon from './popup-components/ComingSoon';
const initSetting = {
    popup_cs: null,
    default_class: null,
};

const Popup = (props) => {
    const [fnSetting, setFnSetting] = useState(initSetting);

    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    const [custComplaint, setCustComplaint] = useState(false);

    const handle = () => {
        props.handlePoup()
    }

    const userData = () => {
        props.userDataHandle()
    }

    const changeEmailConfirm = () => {
        props.changeEmailConfirm()
    }

    const handleData = (formSuccess, data) => {
        props.handleData(formSuccess, data)
    }

    const handleErr = (err) => {
        props.handleErr(err)
    }

    const customerComplaintsClass = () => {
        setCustComplaint(true)
    }

    const handleEmailDurationPopupOn = () => {
        props.handleEmailDurationPopupOn();
    }

    const handlePopup = () => {
        props.handlePopup();
    }

    const getNotificationCount = () => {
        props.getNotificationCount()
    }

    const handleNewMessageCount = () => {
        props.handleNewMessageCount();
    }

    const deleteMessageConfirm = () => {
        props.deleteMessageConfirm();
    }

    const deleteConfirmMessage = () => {
        props.deleteConfirmMessage();
    }

    const refreshInboxList = () => {
        props.refreshInboxList();
    }

    const handleMe = () => {
        props.handleMe();
    }

    const expiredPassword = () => {
        props.expiredPassword();
    }

    const handleLoginPopup = () => {
        props.handleLoginPopup();
    }
    const btnRefLogin = useRef()
    const btnRefLoginEye = useRef()
    const btnRefLoginSidebarEnter = useRef()
    
    useEffect(() => {
        function handler(e) {
            if (!btnRefLogin.current?.contains(e.target)) {
                handle();
            }
        }

        const popupModel = document.getElementById("popup-model-id");

        if (popupModel) {
            popupModel.addEventListener('click', handler);
        }

        return () => {
            if (popupModel) {
                popupModel.removeEventListener('click', handler);
            }
        };
    }, []);

    const handleNotificationPopup = () => {
        handle();
    }

    useEffect(() => {
        let tmp = fnSetting;
        if (typeof componentSetting?.["popup"] != 'undefined') {
            let classDeafault = componentSetting?.["popup"]?.["default-value"];
            setFnSetting({
                ...fnSetting,
                ['default_class']: componentSetting?.["popup"]?.["default-value"],
            })
        }
    }, []);


    return (
        <>
            {

                typeof componentSetting.popup != 'undefined' && componentSetting?.["popup"]?.["default-value"] &&
                <div className={`popup-modal ${fnSetting['default_class']} ${custComplaint ? 'customerComplaintsClass' : ''}`} id='popup-model-id'>
                    <div className="modal-cus" ref={btnRefLogin}>
                        <span className="close" onClick={handle}><GrFormClose /></span>
                        {props.changePass &&
                            <ChangePassword />
                        }
                        {props.custComplaint &&
                            <CustomerComplaints customerComplaintsClass={customerComplaintsClass} handle={handle} />
                        }
                        {props.loginMobile &&
                            <LoginMobile handle={handle} userData={userData} />
                        }
                        {props.loginpopup &&
                            <LoginPopup handle={handle} userData={userData} />
                        }

                        {props.forgotPass &&
                            <ForgotPassword data={props.data} handle={handle} />
                        }
                        {props.invalidEmail &&
                            <InvalidEmail data={props.data} handle={handle} />
                        }
                        {props.changeEmail &&
                            <ChangeEmail handleData={handleData} changeEmailConfirm={changeEmailConfirm} />
                        }
                        {props.emailAlreadyUsed &&
                            <EmailAlreadyUsed />
                        }
                        {props.wrongPassword &&
                            <ErrorMessages data={props.data} handle={handle} />
                        }
                        {props.securityQuestionChange &&
                            <SecurityQuestionChange />
                        }
                        {props.pinProtection &&
                            <PinProtection data={props.data} />
                        }
                        {props.setNewPin &&
                            <ErrorMessages data={props.data} handle={handle} />
                        }
                        {props.agreeMessage &&
                            <Agree handle={handle}></Agree>
                        }
                        {props.expiredPassword &&
                            <ExpiredPassword handle={handle} data={props.data}></ExpiredPassword>
                        }
                        {
                            props.registerpopup &&
                            <Register handlePoup={() => props.HandleRegisterPopup} />
                        }
                        {props.securityValidationMessage &&
                            <SecurityValidation handleErr={handleErr} data={props.data} handle={handle}></SecurityValidation>
                        }
                        {props.sendEmail &&
                            <SendEmail />
                        }
                        {props.sendEmailDuration &&
                            <SendEmailDuration handleEmailDurationPopupOn={handleEmailDurationPopupOn} data={props.message} />
                        }
                        {props.newMessageCount &&
                            <NewMessageNotification handleNewMessageCount={handleNewMessageCount} data={props.count} refreshInboxList={refreshInboxList} handle={handle} />
                        }
                        {props.deleteMessagePopup &&
                            <ConfirmDelete handle={handle} deleteMessageConfirm={deleteMessageConfirm} deleteConfirmMessage={deleteConfirmMessage} />
                        }
                        {props.errorMessages &&
                            <ErrorMessages data={props.data} handle={handle} />
                        }
                        {props.successMessages &&
                            <SuccessMessages data={props.data} handle={handle} />
                        }
                        {props.referralMsg &&
                            <ReferralMessage />
                        }

                        {props.RegisterWithLogin &&
                            <RegisterWithLogin data={props.data} handle={handle} />
                        }
                        {props.blockPopUpMessage &&
                            <UserBlock handle={handle} />
                        }
                        {props.resultData &&
                            <Result numberData={props.numberData} />
                        }
                        {props.lotteryData &&
                            <CompleteResult />
                        }
                        {props.providerMaintainance &&
                            <ProviderMaintenance handlePopup={handlePopup} />
                        }

                        {props.commingSoonGame &&
                            <ProviderGameCommingSoon handlePopup={handlePopup} />
                        }


                        {props.gameLunchLoginErrorPopup &&
                            <TokenValidation handlePopup={handlePopup} />
                        }

                        {props.globalMaintenanceCondition &&
                            <GlobalMaintenance handlePopup={handlePopup} data={props.message} />
                        }

                        {/* {props.resultData &&
                            <PreLogin />
                        } */}

                        {props.afterLogin &&
                            <PostLogin handle={handle} />
                        }

                        {props.beforeLogin &&
                            <PreLogin handle={handle} />
                        }

                        {props.DepositWithdrawMessage &&
                            <DepositWithdrawMessage data={props.data} handleNotificationPopup={handleNotificationPopup} handle={handle} />
                        }

                        {props.HomeScreenPopup &&
                            <HomeScreenPopup data={props.data} handleNotificationPopup={handleNotificationPopup} handle={handle} />
                        }

                        {props.playerRejected &&
                            <PlayerRejected handle={handle} />
                        }

                        {props.gameNotPlay &&
                            <GameNotPlay handle={handle} />
                        }

                        {
                            props.warning &&
                            <ShowWarning handle={handlePopup} />
                        }

                        {
                            props.success &&
                            <ShowSuccess handle={handlePopup} />
                        }

                        {
                            props.readData &&
                            <TextReadMore handle={handlePopup} />
                        }

                        {
                            props.readNotification &&
                            <Notification handle={handlePopup} length={props.length} getNotificationCount={getNotificationCount} />
                        }
                        
                        {props.comingSoon &&
                            <ComingSoon handle={handlePopup}/>
                        }


                    </div>
                </div>
            }
        </>
    )
}

Popup.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Popup);