import {
  FETCH_MENU,
  FETCH_MENU_SUCCESS
} from "./actionTypes"


export const fetchMenu = () => {
  return {
    type: FETCH_MENU,
    payload: {},
  }
}

export const fetchMenuSuccess = (response) => {
  return {
    type: FETCH_MENU_SUCCESS,
    payload: response,
  }
}