import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ExpiredPassword = (props) => {
  return (
    <div className="popup-box">
      <h4 className="title">{props.t("information")}</h4>
      <p>{props.data}</p>
    </div>
  );
};

ExpiredPassword.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ExpiredPassword);
