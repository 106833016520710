import {
  FETCH_SUB_MENU,
  FETCH_SUB_MENU_SUCCESS
} from "./actionTypes"


export const fetchSubMenu = () => {
  return {
    type: FETCH_SUB_MENU,
    payload: {}
  }
}

export const fetchSubMenuSuccess = (response) => {
  return {
    type: FETCH_SUB_MENU_SUCCESS,
    payload: response,
  }
}