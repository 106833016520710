import {
  FETCH_OTHERMEDIA_INFO,
  FETCH_OTHERMEDIA_INFO_SUCCESS
} from "./actionTypes"


export const fetchOtherMediaInfo = () => {
  return {
    type: FETCH_OTHERMEDIA_INFO,
    payload: {},
  }
}

export const fetchOtherMediaInfoSuccess = (response) => {
  return {
    type: FETCH_OTHERMEDIA_INFO_SUCCESS,
    payload: response,
  }
}