import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { CSS_CLASSES } from "../../../css-classes";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import JackpotGrid from "../../games/jackpot/jackpotgrid/JackpotGrid";
import JackpotSlider from "../../games/jackpot/jackpotslider/JackpotSlider";
import Slider from "react-slick";
const initSetting = {
    gif_grid: null,
    default_class: null,
};
const GifImage = (props) => {
    const [fnSetting, setFnSetting] = useState(initSetting);
    // Get device type
    let deviceType = localStorage.getItem("device_type");
    // Fetch other media
    const { otherMedia } = useSelector((state) => ({
        otherMedia: state.othermediaInformationListR.data,
    }));
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    const initAssetConfig = {
        width: "300px",
        height: "200px",
        alt: "defautl alt",
        description: "default description",
        image: "",
        link: window.location.origin,
    };
    const initBannerAssetConfig = {
        width: "auto",
        height: 200,
        alt: "defautl alt",
        description: "default description",
        image: "/assets/images/default-images/banner_gif_" + deviceType + ".png",
        link: window.location.origin,
    };
    const initStyle = {
        width: "",
        height: "",
        backgroundImage: "",
    };
    const [assetsBanner, setAssetsBanner] = useState(initBannerAssetConfig);
    const [assets, setAssets] = useState(initAssetConfig);
    const [style, setStyle] = useState(initStyle);
    useEffect(() => {
        setAssets(initAssetConfig);
        setStyle(initStyle);
        // Width
        if (
            typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "background"]["width"] != "undefined"
        ) {
            assets["width"] = otherMedia[deviceType + "_" + "background"]["width"];
            style["width"] =
                otherMedia[deviceType + "_" + "background"]["width"] + "px";
            setStyle({
                ...style,
            });
            setAssets({
                ...assets,
            });
        }
        // Heigth
        if (
            typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "background"]["height"] !=
            "undefined"
        ) {
            assets["height"] = otherMedia[deviceType + "_" + "background"]["height"];
            style["height"] =
                otherMedia[deviceType + "_" + "background"]["height"] + "px";
            setStyle({
                ...style,
            });
            setAssets({
                ...assets,
            });
        }
        // Description
        if (
            typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "background"]["description"] !=
            "undefined"
        ) {
            assets["description"] =
                otherMedia[deviceType + "_" + "background"]["description"];
            setAssets({
                ...assets,
            });
        }
        // alternative
        if (
            typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "background"]["alternate"] !=
            "undefined"
        ) {
            assets["alt"] = otherMedia[deviceType + "_" + "background"]["alternate"];
            setAssets({
                ...assets,
            });
        }
        // Image path
        if (
            typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "background"]["path"] != "undefined"
        ) {
            assets["image"] = otherMedia[deviceType + "_" + "background"]["path"];
            style["backgroundImage"] = `url(${otherMedia[deviceType + "_" + "background"]["path"]
                })`;
            setStyle({
                ...style,
            });
            setAssets({
                ...assets,
            });
        }
        if (
            typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "background"]["link"] != "undefined"
        ) {
            assets["link"] = otherMedia[deviceType + "_" + "background"]["link"];
            setAssets({
                ...assets,
            });
        }
        // Banner Gif
        setAssetsBanner(initBannerAssetConfig);
        // Width
        if (
            typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "banner_gif"]["width"] != "undefined"
        ) {
            assetsBanner["width"] =
                otherMedia[deviceType + "_" + "banner_gif"]["width"];
            setAssetsBanner({
                ...assetsBanner,
            });
        }
        // Heigth
        if (
            typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "banner_gif"]["height"] !=
            "undefined"
        ) {
            assetsBanner["height"] =
                otherMedia[deviceType + "_" + "banner_gif"]["height"];
            setAssetsBanner({
                ...assetsBanner,
            });
        }
        // Description
        if (
            typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "banner_gif"]["description"] !=
            "undefined"
        ) {
            assetsBanner["description"] =
                otherMedia[deviceType + "_" + "banner_gif"]["description"];
            setAssetsBanner({
                ...assetsBanner,
            });
        }
        // alternative
        if (
            typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "banner_gif"]["alternate"] !=
            "undefined"
        ) {
            assetsBanner["alt"] =
                otherMedia[deviceType + "_" + "banner_gif"]["alternate"];
            setAssetsBanner({
                ...assetsBanner,
            });
        }
        // Image path
        if (
            typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "banner_gif"]["path"] != "undefined"
        ) {
            assetsBanner["image"] =
                otherMedia[deviceType + "_" + "banner_gif"]["path"];
            setAssetsBanner({
                ...assetsBanner,
            });
        }
        if (
            typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
            typeof otherMedia[deviceType + "_" + "banner_gif"]["link"] != "undefined"
        ) {
            assetsBanner["link"] =
                otherMedia[deviceType + "_" + "banner_gif"]["link"];
            setAssetsBanner({
                ...assetsBanner,
            });
        }
    }, [otherMedia]);

    useEffect(() => {
        let temp = { ...fnSetting };
        temp['gif_grid'] = componentSetting?.["gif-image"]?.["default-value"];
        setFnSetting(temp);
        // if (typeof componentSetting?.["gif-image"] != 'undefined') {
        //     setFnSetting({
        //         ...fnSetting,
        //         ['gif_grid']: componentSetting?.["gif-image"]?.["default-value"],
        //     })
        // }
    }, [componentSetting?.["gif-image"]]);
    const data = [
        {
            imgpath: "/assets/images/default-images/banner_gif_" + deviceType + ".png",
        },
        {
            mgpath: "/assets/images/default-images/banner_gif_" + deviceType + ".png",
        },
        {
            mgpath: "/assets/images/default-images/banner_gif_" + deviceType + ".png",
        },
        {
            mgpath: "/assets/images/default-images/banner_gif_" + deviceType + ".png",
        },
        {
            mgpath: "/assets/images/default-images/banner_gif_" + deviceType + ".png",
        },
    ];
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <IoIosArrowForward />,
        prevArrow: <IoIosArrowBack />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };
    return (
        <>
            {

                fnSetting['gif_grid'] &&
                <div className="container">
                    <div className="gif-imgae-wrapper">
                        <div className={fnSetting['gif_grid']}>
                            {
                                fnSetting['gif_grid'] == 'grid' &&
                                <img className={CSS_CLASSES.lotteryJackpotGif} src={assetsBanner['image']} width={assetsBanner['width']} height={assetsBanner['height']} alt={assetsBanner['alt']} />
                            }
                            {
                                fnSetting['gif_grid'] == 'slider' &&
                                <div className="gif-slider">
                                    <Slider {...settings}>
                                        {data.map((item, index) => (
                                            <div key={index} className="gif-item">
                                                <img className={CSS_CLASSES.lotteryJackpotGif} src={item.imgpath} width={assetsBanner['width']} height={assetsBanner['height']} alt={assetsBanner['alt']} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            }
                        </div>
                        <div>
                            < JackpotGrid />
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default GifImage
