import axios from "axios";
import * as url from "./url_helper";
import tokenPrefix from "./jwt-token-access/token-prefix";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { formatValue } from "react-currency-input-field";
import { daDK } from "rsuite/esm/locales";


// Register Method
const base_url = window.location.origin;
const axiosApi = axios.create({
  baseURL: (process.env.REACT_APP_ENABLE_LOCAL_API == 1) ? process.env.REACT_APP_API_URL : base_url
});
// const token = tokenPrefix + localStorage.getItem('user_token');
// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export const currencyFormat = (amount) => {
  let newAmount = undefined;

  if (amount != undefined) {
    if (amount == Math.floor(amount)) {
      newAmount = amount;
    } else {
      if (amount != undefined) {
        if (typeof (amount) == 'number') {
          newAmount = parseFloat(amount.toFixed(3));
        } else {
          newAmount = parseFloat(Number(amount).toFixed(3));
        }

      }
    }
  }

  if (newAmount != undefined) {
    let t = formatValue({
      value: newAmount.toString(),
      decimalsLimit: 3,
      decimalSeparator: '.',
      intlConfig: { locale: 'en-US', currency: '' },
    });
    return t;
  }
}


const showMessage = (data, toastType = null) => {
  
  toastr.options = {
    positionClass: 'toast-top-left',
    timeOut: 3000,
    closeButton: true,
    debug: false,
    progressBar: false,
    preventDuplicates: false,
    newestOnTop: true,
    showEasing: 'linear',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
    // showDuration: showDuration,
    // hideDuration: hideDuration
  };
  let message = data;
  if (data.messages)
    message = data.messages[0];
  let title = '';
  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (!toastType)
    toastType = ((data.status == 'SUCCESS') ? 'SUCCESS' : 'error');
  if (toastType === "info") {
    title = 'Info';
    toastr.info(message, title);
  }
  else if (toastType === "warning") {
    title = 'Warning';
    toastr.warning(message, title);
  }
  else if (toastType === "error") {
    title = 'Error';
    toastr.error(message, title);
  }
  else {
    title = 'Success';
    toastr.success(message, title);
  }
}


export const respStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

export const listDataFormat = {
  data: [],
  total: 0,
  per_page: 15,
  current_page: 1
}

export const defaultPerPage = 15;


const makeAjax = (extension, data, micro_service = url.USER_MS_EXT, header = null) => {
  startLoader()
  if (localStorage.getItem('user_token')) {
    let token = tokenPrefix + localStorage.getItem('user_token');
    axiosApi.defaults.headers.common["Authorization"] = token;
  }
  axiosApi.defaults.headers.common["tenant_sub_domain"] = localStorage.getItem('user_tenant_sub_domain');
  axiosApi.defaults.headers.common["device_type"] = localStorage.getItem('device_type');

  let end_url = url.API_PREFIX + url.APPLICATION_EXT + micro_service + extension;
  if (process.env.REACT_APP_ENABLE_LOCAL_API == 2) {
    end_url = url.APPLICATION_EXT + micro_service + extension;
  }
  
  return axiosApi.post(end_url, data)
    .then(response => {
      
      if (response.status >= 200 || response.status <= 299) {
        if (response.data.status == 'ERROR') {
          if (response.data.code == 'validation.access_denied') {
            localStorage.removeItem("user_token");
            window.localStorage.removeItem("isLoggedIn")
            
            if (window.location.pathname != '/access-denied')
              window.location.replace(base_url + '/' + "access-denied");
          }
          else if (response.data.code == 'validation.invalid_token_error') {
            localStorage.removeItem("user_token");
            window.localStorage.removeItem("isLoggedIn")
            if (extension != '/checkIfPinAlreadySet') {
              // window.location.href = base_url;
            }
          } else if (response.data.code == 'validation.server_session_out') {
            localStorage.removeItem("user_token");
            window.localStorage.removeItem("isLoggedIn")
            if (extension != '/checkIfPinAlreadySet') {
              window.location.href = base_url;
            }
          }
        }
        stopLoader();
        return response.data;
      }
      stopLoader();
      throw response;
    })
    .catch(err => {
      stopLoader();
      catchAxioExc(err);
    });;
};
if (custom_loader_count === undefined)
  var custom_loader_count = 0;
const startLoader = (extension) => {
  if (custom_loader_count == 0)
    custom_loader_count++;
  // var element = document.getElementById("ajax-loader");
  // element.classList.add("preloader");

  var buttonId = document.getElementsByClassName("loaderButton");
  if (buttonId != null && buttonId.length > 0) {
    for (let i = 0; i < buttonId.length; i++) {
      buttonId[i].style.pointerEvents = "none"
    }
  }
  
}


const stopLoader = () => {
  if (custom_loader_count != 0)
    custom_loader_count--;
  // if (custom_loader_count == 0) {
  //   var element = document.getElementById("ajax-loader");
  //   element.classList.remove("preloader");
  // }
  var buttonId = document.getElementsByClassName("loaderButton");
  if (buttonId != null && buttonId.length > 0) {
    for (let i = 0; i < buttonId.length; i++) {
      buttonId[i].style.pointerEvents = ""
    }
  }
}

const catchAxioExc = (err) => {
  var message;
  if (err.response && err.response.status) {
    switch (err.response.status) {
      case 404:
        message = "Sorry! the API you are looking for could not be found";
        break;
      case 500:
        message =
          "Sorry! something went wrong, please contact our support team";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = err[1];
        break;
    }
    showMessage(message, 'error');
  }
}

const currentDate = () => {
  return new Date().toISOString().slice(0, 10);
}

export const prevetHeaderLoad = ['/access-denied'];


const dateFormat = (inputDate) => {
  if (!inputDate)
    return ' ';

  switch (true) {
    case /^\d{4}-\d{2}-\d{2}$/.test(inputDate):
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    case /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}$/.test(inputDate):
      return inputDate;
    case /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(inputDate):
      const date2 = new Date(inputDate);
      const day2 = date2.getDate().toString().padStart(2, '0');
      const month2 = (date2.getMonth() + 1).toString().padStart(2, '0');
      const year2 = date2.getFullYear().toString();
      const hours = date2.getHours().toString().padStart(2, '0');
      const minutes = date2.getMinutes().toString().padStart(2, '0');
      const seconds = date2.getSeconds().toString().padStart(2, '0');
      return `${day2}-${month2}-${year2} ${hours}:${minutes}:${seconds}`;
    case /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/.test(inputDate):
      return inputDate.replace(/\//g, '-');
    case /^\d{2}-\d{2}-\d{4}$/.test(inputDate):
      return inputDate;
    default:
      return 'invalid date format';
  }
};

export {
  makeAjax,
  url,
  showMessage,
  currentDate,
  dateFormat,
};
