import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  FETCH_CSS_INFO
} from "./actionTypes"
import {
  fetchCssInfoSuccess
} from "./actions";

import { makeAjax, url, showMessage } from "../../helpers/global_helper";

function* fetchCssInfo(data) {
  try {
    
    const response = yield call(
      makeAjax,
      url.MASTER_API.getTenantTemplateCSS, [], url.MASTER_MS_EXT
    );
    if (response.status == 'SUCCESS') {
      yield all([
        put(fetchCssInfoSuccess({ response: response.data }))
      ]);
    }
    else {
      showMessage(response);
    }
  } catch (error) {
    showMessage(error);
    
    // alert
  }
}

function* cssInfomationSaga() {
  yield takeLatest(FETCH_CSS_INFO, fetchCssInfo);
}

export default cssInfomationSaga;
