import { useEffect, useRef, useState } from 'react'
import { MdDeleteOutline, MdOutlineKeyboardArrowDown, MdArrowBack } from 'react-icons/md'
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiInboxFill } from 'react-icons/ri'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { showMessage, makeAjax, url, respStatus, dateFormat } from '../../../helpers/global_helper'
import ReplyMessage from '../../user-pages/memo/reply-message/ReplyMessage'
import Popup from '../Popup'
import PropTypes from "prop-types";
import ActionLoader from '../../../pages/ActionLoader'
import { useDispatch } from 'react-redux'
import { getMemoCount } from '../../../store/actions'
import { withTranslation } from 'react-i18next';
import Loading from '../../games/Loading/Loading';


const Notification = (props) => {
    const dispatch = useDispatch();
    // Form initialization
    const initForm = {
        subject: "",
        message: "",
        username: "",
    };

    const InitFilterPage = {
        per_page: 30,
        page: 1,
    };

    const InitFilterPageOutbox = {
        per_page: 30,
        page: 1,
    };

    // Set & get data
    const [sendMsg, setSendMsg] = useState(false);
    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState(initForm);
    const [clientDetails, setClientDetails] = useState([]);
    const [playerDetails, setPalyerDetails] = useState([]);
    const [playerInboxMessage, setPlayerInboxMessage] = useState([]);
    const [playerOutboxMessage, setPlayerOutboxMessage] = useState([]);
    const [playerOutboxPagination, setPlayerOutboxPagination] = useState(InitFilterPageOutbox);
    const [playerInboxPagination, setPlayerInboxPagination] = useState(InitFilterPage);
    const [singleFieldData, setSingleFieldData] = useState(InitFilterPageOutbox);
    const [singleFieldDataInbox, setSingleFieldInboxData] = useState(InitFilterPage);
    const [replyMessage, setReplyMessage] = useState(false);
    const [inMsg, setInMsg] = useState(true);
    const [outMsg, setOutMsg] = useState(false);
    const [rowData, setRowData] = useState({});
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [sendMessage, setSendMessage] = useState(false);
    const [durationPopup, setDurationPopup] = useState(false);
    const [newMessageCount, setNewMessageCount] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteID] = useState([]);
    const [clickReadStatus, setClickReadStatus] = useState(true);
    const [latestMessage, setLatestMessage] = useState({});
    const [delete_popup, setDeletePopup] = useState(false);
    const [waitMessage, setWaitMessage] = useState({});
    const [loaderStatus, setLoader] = useState(false);
    const [select_all, setSelectAll] = useState(false);
    const [select_all_outbox, setSelectAllOutbox] = useState(false);
    const [multiCheck, setMultiCheck] = useState({});
    const [multiCheckOutbox, setMultiCheckOutbox] = useState({});
    const [showWarning, setWarning] = useState(false);
    const [showSuccess, setSuccess] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [loading, setLoading] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    // Scroll init
    let oldScrollY = 0;
    const inboxContainerRef = useRef(null);

    // Tenannt or client profile details based on domain
    const getClientDetails = (event) => {
        makeAjax(url.ADMIN_API.getClientDetails, formData, url.ADMIN_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    setFormError(response.messages);
                    return;
                }
                setClientDetails(response.data);
            })
    }

    // Get player unread message count
    const getUnreadMessageCount = (event) => {
        makeAjax(url.MEMO_API.getPlayerUnreadMessageCount, [], url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    setFormError(response.messages);
                    return;
                }
                setUnreadMessageCount(response.data);
            })
    }

    // Player inbox message details
    const getPlayerInboxMessageList = (event) => {
        makeAjax(url.MEMO_API.listPlayerInboxMessage, [], url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }

                setNewMessageCount(true);

                if (event == 'deleteMemo')
                    setNewMessageCount(false);

                setClickReadStatus(false);
                if (clickReadStatus) {
                    setClickReadStatus(true);
                }

                setSelectAll(false);
                setMultiCheck({});
                setPlayerInboxMessage(response.data);
            })
    }

    // Player outbox message list
    const getPlayerOutMessageList = (event) => {
        makeAjax(url.MEMO_API.listPlayerOutboxMessage, [], url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }

                if (!inMsg) {
                    setNewMessageCount(true);
                }
                if (event == 'deleteMemo')
                    setNewMessageCount(false);

                setSelectAllOutbox(false);
                setMultiCheckOutbox({})
                setPlayerOutboxMessage(response.data);
            })
    }

    // Player profile details
    const getPlayerDetails = (event) => {
        makeAjax(url.USER_API.getPlayerProfileDetail, formData, url.USER_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    setFormError(response.messages);
                    return;
                }
                setPalyerDetails(response.data);
            })
    }

    // Send message
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        makeAjax(url.MEMO_API.playerComposeMessage, formData, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setLoader(false);
                    if (response.status != 'send_email_duration_error') {
                        setDurationPopup(true);
                        return;
                    }
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    setFormError(response.messages);
                    return;
                }
                setLoader(false);
                setFormData(initForm);
                setFormError({});
                showMessage(response);
            })
    };

    // Effect List
    useEffect(() => {
        getClientDetails();
        getPlayerDetails();
        // getMessageList(page);
        getPlayerOutMessageList();
        // getUnreadMessageCount();
        setClickReadStatus(true);
    }, []);


    useEffect(() => {
        // getUnreadMessageCount();
    }, [unreadMessageCount]);

    useEffect(() => {
        getUnreadMessageCount();
        // getMessageList(page);
        const interval1 = setInterval(() => getUnreadMessageCount(), 60000)
        // const interval2 = setInterval(() => getMessageList(page), 60000)
        return () => {
            clearInterval(interval1);
            // clearInterval(interval2);
            setClickReadStatus(true);
        }
    }, []);

    // Input event
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value, ['username']: playerDetails['username'] });
    }

    // On click reply open
    const replyMsg = (event) => {
        makeAjax(url.MEMO_API.playerUpdateMessageStatus, { _id: event._id }, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    setFormError(response.messages);
                    return;
                }
                getUnreadMessageCount();
                dispatch(getMemoCount());
                props.getNotificationCount();
            })

        setReplyMessage(true)
        setRowData(event);
        setWaitMessage(`${props.t('you_have_just_sended_the_memo_please_wait_for_the_two_minutes_to_send_another_memo')}.${props.t('thank_you')}`);
    }

    // On click reply close
    const replyMsgClose = () => {
        setReplyMessage(false);
        setRowData({});
        setSendMessage(true);
        setInMsg(true);
        setOutMsg(false);
        setClickReadStatus(true);
        getMessageList(page);
    }

    // Outbox message goto filter
    const gotoPageOutbox = (page = 1) => {
        let payload = singleFieldData;
        payload['page'] = page;
        setPlayerOutboxPagination(payload);
        makeAjax(url.MEMO_API.listPlayerOutboxMessage, payload, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                setPlayerOutboxMessage(response.data);
            })
    }

    // Inbox message goto filter
    const gotoPageInbox = (page = 1) => {
        let payload = singleFieldDataInbox;
        payload['page'] = page;
        setPlayerInboxPagination(payload);
        makeAjax(url.MEMO_API.listPlayerInboxMessage, payload, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                setPlayerInboxMessage(response.data);
            })
    }

    // Outbox filter pagination
    const filterChangeOutbox = (event) => {
        
        let val = event.target.value;
        if (isNaN(val)) {
            val = '';
        } else {
            if (val != '')
                val = parseInt(val);
        }

        if (event.target.name == 'page' && val > playerOutboxMessage.last_page)
            val = parseInt(playerOutboxMessage.last_page);

        if (event.target.name == 'per_page') {
            singleFieldData['per_page'] = val;
        } else {
            singleFieldData['page'] = val;
        }
        setPlayerOutboxPagination(singleFieldData);
        makeAjax(url.MEMO_API.listPlayerOutboxMessage, singleFieldData, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                setPlayerOutboxMessage(response.data);
            })
    }

    // Inbox filter pagination
    const filterChangeInbox = (event) => {
        
        let val = event.target.value;
        if (isNaN(val)) {
            val = '';
        } else {
            if (val != '')
                val = parseInt(val);
        }

        if (event.target.name == 'page' && val > playerInboxMessage.last_page)
            val = parseInt(playerInboxMessage.last_page);

        if (event.target.name == 'per_page') {
            singleFieldDataInbox['per_page'] = val;
        } else {
            singleFieldDataInbox['page'] = val;
        }
        setPlayerInboxPagination(singleFieldDataInbox);
        makeAjax(url.MEMO_API.listPlayerInboxMessage, singleFieldDataInbox, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                setPlayerInboxMessage(response.data);
            })
    }

    // Onclick incoming mesage tab
    const updateIncommingMessage = (event) => {
        // setDeleteID([]);
        setPlayerInboxMessage([]);
        setSingleFieldInboxData(InitFilterPage);
        setPlayerInboxPagination(InitFilterPage);
        setPage(1);
        getMessageList(1);
        setInMsg(true);
        setOutMsg(false);
        setSendMsg(false);
        setReplyMessage(false);
        setRowData({});
        setClickReadStatus(true);
    }

    // Onclick outgoing mesage tab
    const updateOutgoingMessage = (event) => {
        // setDeleteID([]);
        setSingleFieldData(InitFilterPageOutbox);
        setPlayerOutboxPagination(InitFilterPageOutbox);
        setSendMessage(false);
        setDurationPopup(false);
        setNewMessageCount(false);
        getPlayerOutMessageList();
        setInMsg(false);
        setOutMsg(true);
        setSendMsg(false);
        setReplyMessage(false);
        setRowData({});
        setClickReadStatus(true);
    }

    // Onclick create new mesage
    const composeMessage = (event) => {
        // setDeleteID([]);
        setSendMessage(false);
        setDurationPopup(false);
        setNewMessageCount(false);
        setFormData({});
        setInMsg(false);
        setOutMsg(false);
        setSendMsg(true);
        setReplyMessage(false);
        setRowData({});
        setClickReadStatus(true);
        setWaitMessage(`${props.t('you_have_just_sended_the_memo_please_wait_for_a_reply_from_our_operator')}.${props.t('thank_you')}`);
    }

    // Set false popup
    const handlePopup = () => {
        setSendMessage(false);
        setDurationPopup(false);
        setNewMessageCount(false);
        setShowDelete(false);
        setWarning(false);
        setSuccess(false);
    }

    // Set true email duaration validation popup
    const emailDuration = () => {
        setDurationPopup(true);
    }

    // Set true email duaration validation popup
    const handleEmailDurationPopupOn = () => {
        setDurationPopup(false);
    }

    // Handle new message count
    const handleNewMessageCount = () => {
        setNewMessageCount(false);
        setDurationPopup(true);
    }

    // Close delete popup
    const deleteMessageConfirm = () => {
        // setDeleteID([]);
        setShowDelete(false)
    }

    // Refresh inbox listing
    const refreshInboxList = () => {
        setInMsg(true);
        setOutMsg(false);
        setSendMsg(false);
        setReplyMessage(false);
        setNewMessageCount(false);
        setUnreadMessageCount(0);
        setClickReadStatus(false);
    }

    // Pagination
    const gotoInboxListing = () => {
        setReplyMessage(false);
        setInMsg(true);
        setOutMsg(false);
        setSendMsg(false);
        getMessageList(page);
    }

    // Multiselect inbox array
    let selectedEntryObj = {};
    let allSiteData = {};
    useEffect(() => {
        selectedEntryObj = {};
        allSiteData = {};

        if (typeof playerInboxMessage.data != 'undefined') {
            for (let i = 0; i < playerInboxMessage.data.length; i++) {
                selectedEntryObj[playerInboxMessage.data[i]['_id']] = false;
                allSiteData[playerInboxMessage.data[i]['_id']] = JSON.parse(JSON.stringify(playerInboxMessage.data[i]));
            }
            setMultiCheck(selectedEntryObj);
        }
    }, [playerInboxMessage]);

    // Multiselect outbox array
    let selectedOutboxEntryObj = {};
    let allSiteOutboxData = {};
    useEffect(() => {
        selectedOutboxEntryObj = {};
        allSiteOutboxData = {};

        if (typeof playerOutboxMessage.data != 'undefined') {
            for (let i = 0; i < playerOutboxMessage.data.length; i++) {
                selectedOutboxEntryObj[playerOutboxMessage.data[i]['_id']] = false;
                allSiteOutboxData[playerOutboxMessage.data[i]['_id']] = JSON.parse(JSON.stringify(playerOutboxMessage.data[i]));
            }
            setMultiCheckOutbox(selectedOutboxEntryObj);
        }
    }, [playerOutboxMessage]);

    // Check / uncheck records
    const checkUnCheckMe = (_id, type) => {
        let check = {};
        if (type == 'inbox') {
            check[_id] = !multiCheck[_id];
            setMultiCheck({ ...multiCheck, ...check });
        }
        if (type == 'outbox') {
            check[_id] = !multiCheckOutbox[_id];
            setMultiCheckOutbox({ ...multiCheckOutbox, ...check });
        }

    }

    // Select all records
    const selectAll = (type) => {
        if (type == 'inbox') {
            for (let i = 0; i < playerInboxMessage.data.length; i++) {
                selectedEntryObj[playerInboxMessage.data[i]['_id']] = !select_all;
            }
            setMultiCheck(selectedEntryObj);
            setSelectAll(!select_all);
        }

        if (type == 'outbox') {
            for (let i = 0; i < playerOutboxMessage.data.length; i++) {
                selectedOutboxEntryObj[playerOutboxMessage.data[i]['_id']] = !select_all_outbox;
            }
            setMultiCheckOutbox(selectedOutboxEntryObj);
            setSelectAllOutbox(!select_all_outbox);
        }

    }

    // Click on delete button
    const deleteMe = (v, id) => {
        let memoId = [];
        if (v == 'inbox') {
            if (id) {
                memoId.push(id);
            }
            else {
                let keys = Object.keys(multiCheck);
                for (let i = 0; i < keys.length; i++) {
                    if (multiCheck[keys[i]]) {
                        memoId.push(keys[i]);
                    }
                }
            }
        }

        if (v == 'outbox') {
            let keys = Object.keys(multiCheckOutbox);
            for (let i = 0; i < keys.length; i++) {
                if (multiCheckOutbox[keys[i]]) {
                    memoId.push(keys[i]);
                }
            }
        }

        if (typeof v._id != 'undefined')
            memoId.push(v._id);

        if (!memoId.length) {
            setWarning(true);
            setFormError({})
            return;
        }
        setDeleteID(memoId);
        setShowDelete(true);
    }
    // Confirm deleting records
    const deleteConfirmMessage = () => {
        let payload = {
            _id: deleteId,
        }
        // 

        makeAjax(url.MEMO_API.deletePlayerMessage, payload, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                setNewMessageCount(false);
                setShowDelete(false);
                setSuccess(true);
                setDeleteID([]);
                getUnreadMessageCount();
                dispatch(getMemoCount());
                props.getNotificationCount()
                dispatch(getMemoCount());
                getMessageList(1, 'deleteMemo');
                getPlayerOutMessageList('deleteMemo');
            })
    }

    // Fetch game data
    const fetchMessageData = (nextPage, event) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            makeAjax(
                url.MEMO_API.listPlayerInboxMessage,
                { 'page': nextPage, 'per_page': 10 },
                url.MEMO_MS_EXT
            ).then(response => {
                setLoading(false);
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                if (event == 'first_run')
                    setNewMessageCount(true);

                if (event == 'deleteMemo' || event=='runInLoop')
                    setNewMessageCount(false);

                setClickReadStatus(false);
                if (clickReadStatus) {
                    setClickReadStatus(true);
                }

                setSelectAll(false);
                setMultiCheck({});
                if (response?.data?.data?.length > 0) {

                    resolve({
                        data: response?.data?.data,
                        currentPage: response?.data?.current_page,
                        lastPage: response?.data?.last_page,
                    });
                }
            }).catch(error => {
                reject(error);
            });
        });
    };

  // Get msg list
  const getMessageList = async (nextPage, event) => {
    try {
        if (event == 'deleteMemo')
            setPlayerInboxMessage([]);

        if (lastPage == null || nextPage <= lastPage) {
            const response = await fetchMessageData(nextPage, event);
            if(event === "runInLoop"){
                setPlayerInboxMessage(response.data)
                props.getNotificationCount()
                // dispatch(getMemoCount());
            }
            else{
                setPlayerInboxMessage((prevRecords) => [...prevRecords, ...response.data]);
            }
            setPage(nextPage + 1);
            setLastPage(response.lastPage);
        }

    } catch (error) {
        console.error('Error fetching records:', error);
    } finally {
        //
    }
};



    // On scroll game load
    let debounceTimer;
    const handleScroll = () => {
        clearTimeout(debounceTimer);

        debounceTimer = setTimeout(() => {
            const container = inboxContainerRef.current;
            const distanceFromBottom = container.scrollHeight - (container.scrollTop + container.clientHeight);
            const threshold = 50; //adjust this value
            if (distanceFromBottom < threshold) {
                
                getMessageList(page);
                
            }
        }, 200); // Adjust the delay
    };

       // On scroll game load
       useEffect(() => {
           getMessageList(1, 'first_run');
    }, []);

    // Initial game load
    useEffect(() => {
        // Set up an interval to fetch new messages every 15 seconds
        if(!replyMessage){
            const intervalId = setInterval(() => {
                getMessageList(1, 'runInLoop');
            }, 15000); // 15 seconds in milliseconds
    
            // Cleanup the interval when the component unmounts
            return () => clearInterval(intervalId);
        }

    }, [replyMessage]);

    // On scroll game load
    useEffect(() => {
        const container = inboxContainerRef.current;
        container?.addEventListener('scroll', handleScroll);
        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [page]);


    return (

        <>
            <div className="user-div">
                <div className="title-box title-box-color">
                    <h3 className="text-create-message">{props.t('notifications')}</h3>

                </div>
            </div>
            <div className='memo'>

                <div className="memo-box">

                    <div className="indox-div">
                        <div className={`message-option ${inMsg && 'active'}`}>
                            {replyMessage ? <div className='backarr' onClick={updateIncommingMessage}><MdArrowBack /></div> : <span className="count">{props.length ? props.length + " " + `${props.t('new')}` : 0}</span>}
                            <span onClick={updateIncommingMessage}>
                                <p className='mass-delete' onClick={() => { deleteMe('inbox', rowData?._id) }}>
                                    {replyMessage ? props.t('delete') : props.t('delete_all')}
                                </p>
                            </span>
                        </div>

                        <div className="table-div">
                            {(sendMsg === true) ?
                                <form className='create-message'>
                                    <div className="to-box">
                                        <label for="recipient" className="w-1/12 to-text">{props.t('to')}</label>
                                        <input className='domain-to' type="text" value={clientDetails.domain_name} readOnly /></div>
                                    <div className="to-box">
                                        <label for="title" className="w-1/12 to-text" >{props.t('subject')}</label>
                                        <input className='domain-to' type="text" placeholder={props.t('subject')} value={formData.subject} name="subject" onChange={handleChange} />
                                        {formError.subject ? (
                                            <span className="text-danger-red">{props.t('hello')}{formError.subject[0]}</span>
                                        ) : null}
                                    </div>
                                    <div className="to-box">
                                        <label for="title" className="w-1/12 to-text">{props.t('message')}</label>
                                        <textarea className='msg-text1' placeholder={props.t('message')} value={formData.message} name='message' onChange={handleChange}>
                                        </textarea>
                                        {formError.message ? (
                                            <span className="text-danger-red1">{formError.message[0]}</span>
                                        ) : null}
                                    </div>
                                    <div className="back-send-btn-box">
                                        <BsFillArrowLeftCircleFill onClick={gotoInboxListing} className='arrow-message-icon' />
                                        <ActionLoader label={props.t('send')} loaderClass={'send-button'} showLoader={loaderStatus} onClick={handleSubmit} />

                                    </div>
                                </form>
                                : (replyMessage === true) ?
                                    <>
                                        <ReplyMessage replyMsgClose={replyMsgClose} gotoInboxListing={gotoInboxListing} emailDuration={emailDuration} rowData={rowData} type={inMsg} />
                                    </>
                                    :
                                    <>
                                        <table>
                                            <thead>

                                                {
                                                    inMsg &&
                                                    <>

                                                        <tr>
                                                            <th style={{ fontSize: '50px', width: "100px" }}>
                                                                <input type="checkbox" checked={select_all} onChange={() => { }} onClick={() => { selectAll('inbox') }} />
                                                            </th>
                                                            <th className='cusWidth1'>{props.t('operator')}</th>
                                                            <th className='cusWidth'>{props.t('subject')}</th>
                                                            <th className='cusWidth1'>{props.t('date')}/{props.t('time')}</th>
                                                            <th className='cusWidth1'><p className='mass-delete' onClick={() => { deleteMe('inbox') }}>
                                                                {props.t('delete_all')}<MdDeleteOutline />
                                                            </p></th>

                                                        </tr>
                                                    </>
                                                }

                                            </thead>
                                            {inMsg &&
                                                <tbody>
                                                    <div className='inboxscroll' ref={inboxContainerRef}>
                                                        {
                                                            typeof playerInboxMessage != 'undefined' && playerInboxMessage.length > 0 ? <>
                                                                {playerInboxMessage.map((v, i) =>
                                                                    <tr key={i} style={!v.status ? { background: "#413D43", margin: "5px 0px" } : { background: '#252525', margin: "5px 0px" }}>
                                                                         <td style={{ fontSize: '10px', paddingTop: "8px" }}>
                                                                            <label>
                                                                                <input type="checkbox" id="checkbor_arr" name="checkbor_arr" checked={multiCheck[v._id]} onChange={() => { }} onClick={() => { checkUnCheckMe(v._id, 'inbox') }} />
                                                                                <span></span>
                                                                            </label>
                                                                        </td>
                                                                        <tr className='democlass'>
                                                                            <td className='zero' onClick={() => replyMsg(v)}>{v.domain_name}</td>
                                                                            {
                                                                                !v.status && <td
                                                                                    onClick={() => { replyMsg(v); }} style={{ padding: "0px 5px 8px 5px" }} className='subject-len zero'>{v.subject}
                                                                                </td>
                                                                            }

                                                                            {
                                                                                v.status && <td
                                                                                    onClick={() => replyMsg(v)} className='subject-len'>{v.subject}</td>
                                                                            }
                                                                        </tr>

                                                                        <tr className='democlass'>
                                                                            <td className='memodate' style={{ color: 'var(--vs-gray1)' }} onClick={() => replyMsg(v)}>{v.created_at}</td>
                                                                            <td className='memodate'>
                                                                                <span onClick={() => deleteMe('inbox', v?._id)}>
                                                                                    <RiDeleteBin6Line />
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tr>
                                                                )}
                                                            </> :
                                                                <>
                                                                    {!loading && <>
                                                                        {props.t('no_memo_found')}
                                                                    </>}
                                                                </>
                                                        }
                                                    </div>
                                                </tbody>
                                            }
                                        </table>


                                    </>
                            }
                            {
                                loading && <Loading />
                            }
                        </div>

                    </div>

                </div>
                {sendMessage && <Popup handlePoup={handlePopup} sendEmail />}
                {durationPopup && <Popup handlePoup={handlePopup} handleEmailDurationPopupOn={handleEmailDurationPopupOn} message={waitMessage} sendEmailDuration />}
                {unreadMessageCount > 0 && newMessageCount && <Popup handleNewMessageCount={handleNewMessageCount} count={unreadMessageCount} refreshInboxList={refreshInboxList} handlePoup={handlePopup} newMessageCount />}
                {showDelete && <Popup handlePoup={handlePopup} deleteMessageConfirm={deleteMessageConfirm} deleteConfirmMessage={deleteConfirmMessage} deleteMessagePopup />}
                {showWarning && <Popup handlePoup={handlePopup} warning />}
                {showSuccess && <Popup handlePoup={handlePopup} success />}

                {/* {
                    loading && <Loading />
                } */}


            </div >
        </>

    )
}

Notification.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Notification)