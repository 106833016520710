import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import {
  HiViewList,
  HiUserGroup,
  HiInformationCircle,
  HiOutlineChartSquareBar,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { FiCopy } from "react-icons/fi";
import { BsFillCalendar2Fill } from "react-icons/bs";
import Popup from "../popup/Popup";
import CommonSec from "../commonSection/CommonSec";
import { NavLink, useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  makeAjax,
  url,
  respStatus,
  showMessage,
  currencyFormat,
  dateFormat,
} from "../../helpers/global_helper";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import ReferralsSidebar from "./ReferralsSidebar";
import Loading from "../games/Loading/Loading";

const HistoryProviders = (props) => {
  // Init pagination
  const InitFilterPage = {
    per_page: 5,
    page: 1,
    from_date: formatDate(new Date()),
    to_date: formatDate(new Date()),
  };

  // State declaration
  const [isMessageSet, setIsMessageSet] = useState(false);
  const [referralCount, setReferralCount] = useState(0);
  const [historyInfo, setHistoryInfo] = useState([]);
  const [formData, setFormData] = useState(InitFilterPage);
  const [formError, setFormError] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    // Function to update window width state on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  // Handle popups
  const handlePopup = () => {
    setIsMessageSet(false);
  };
  const popUpMessage = () => {
    setIsMessageSet(true);
  };

  // Get player profile data
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));

  // Use effect call
  useEffect(() => {
    if (typeof userProfile.referral_click_count != "undefined") {
      setReferralCount(userProfile.referral_click_count);
    }
  }, [referralCount, userProfile]);

  // Copy referral link
  const copy = (data) => {
    navigator.clipboard.writeText(data);
  };

  // Gat turnover history
  const getHistoryInfo = () => {
    makeAjax(
      url.USER_API.listHistoryTurnover,
      formData,
      url.USER_MS_EXT
    ).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setHistoryInfo(response.data);
    });
  };



  // Format date
  function formatDate(date) {
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  const formChange = (e) => {
    setFormError({});

    let t = JSON.parse(JSON.stringify(formData));
    t[e.target.name] = e.target.value;
    setFormData(t);
  };

  const fetchHistoryTurnover = (nextPage) => {
    return new Promise((resolve, reject) => {
      setFormError({});
      if (nextPage == 1)
        setHistoryInfo([]);

      formData["page"] = nextPage;
      formData["per_page"] = 15;
      setLoading(true);
      makeAjax(url.USER_API.listHistoryTurnover, formData, url.USER_MS_EXT).then(
        (response) => {
          setLoading(false);
          if (response.status != respStatus["SUCCESS"]) {
            if (response.code == "validation_error") {
              setFormError(response.messages);
              return;
            }
            showMessage(response.messages);
            return;
          }
          if (response?.data) {
            resolve({
              data: response?.data,
              lastPage: response.data?.last_page,
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const historyTurnoverList = async (nextPage) => {
    try {
      let response = "";
      response = await fetchHistoryTurnover(nextPage);
      setHistoryInfo((prevRecords) => ({ ...prevRecords, ...response.data }));
      setLastPage(response.lastPage);
      setPage(nextPage + 1);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      //
    }
  };

  useEffect(() => {
    getHistoryInfo();
  }, []);

  return (
    <>
      <div className="table-div history-table-div">
        <div className="refferal-history">
          <form onSubmit={handleSubmit}>
            <div className="datepicker-table">
              <div className="history-date">
                <input
                  type="date"
                  name="from_date"
                  max={formData.to_date}
                  value={formData.from_date}
                  onChange={formChange}
                />
                <span className="text-danger">
                  {formError.from_date ? formError.from_date[0] : ""}
                </span>
              </div>
              <div className="history-date">
                <input
                  type="date"
                  name="to_date"
                  min={formData.from_date}
                  value={formData.to_date}
                  onChange={formChange}
                />
                <span className="text-danger">
                  {formError.to_date ? formError.to_date[0] : ""}
                </span>
              </div>
              <button
                className="date-Date-btn"
                onClick={() => {
                  historyTurnoverList(1);
                }}
              >
                {props.t("search")}
              </button>
            </div>
          </form>
          {windowWidth >= 991 ? (
            <div>
              <table className="history-table">
                <thead>
                  <tr>
                    <th className="history-data">{props.t("date")}</th>
                    <th className="history-data">{props.t("turnover")}</th>
                    <th className="history-data">{props.t("commision")}</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof historyInfo.data != "undefined" &&
                    Object.values(historyInfo.data).length > 0 &&
                    Object.values(historyInfo.data).map((v, i) => (
                      <tr key={i}>
                        <td>{dateFormat(v.date)}</td>
                        <td>{currencyFormat(v.cashback_turnover)}</td>
                        <td>{currencyFormat(v.roll_bonus)}</td>
                      </tr>
                    ))

                  }

                  {typeof historyInfo.data != "undefined" && lastPage != 1 && (
                    <tr>
                      <td colSpan={6} className="show-btn">
                        <button
                          onClick={() => {
                            lastPage > page - 1
                              ? historyTurnoverList(page)
                              : historyTurnoverList(1);
                          }}
                        >
                          {lastPage > page - 1 ? (
                            <>{props.t("show_more")}</>
                          ) : (
                            <>{props.t("show_less")}</>
                          )}
                        </button>
                      </td>
                    </tr>
                  )}

                  {typeof historyInfo.data == "undefined" && (
                    <tr>
                      <td className="table-no-reacord" colSpan={3}>
                        {props.t("no_record_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <table className="history-table">
                <tbody>
                  {typeof historyInfo.data != "undefined" &&
                    Object.values(historyInfo.data).length > 0 &&
                    Object.values(historyInfo.data).map((v, i) => (
                      <>
                        <tr key={i}>
                          <td colSpan={2}>
                            <div className="history-data">
                              <div className="provider-data">
                                <span>{props.t("date")}:</span>
                                <span className="info-data">
                                  {dateFormat(v.date)}
                                </span>
                              </div>

                            </div>
                          </td>
                        </tr>

                        <tr key={i}>
                          <td className="history-data">
                            {props.t("turnover")}:

                          </td>
                          <td className="history-data">
                            {" "}
                            {props.t("commision")}:
                          </td>
                        </tr>
                        <tr key={i}>
                          <td className="info-data-one">
                            {" "}
                            {currencyFormat(v.cashback_turnover)}
                          </td>
                          <td className="info-data-one">
                            {currencyFormat(v.roll_bonus)}
                          </td>
                        </tr>
                      </>
                    ))}

                  {typeof historyInfo.data == "undefined" && (
                    <tr>
                      <td className="table-no-reacord" colSpan={3}>
                        {props.t("no_record_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {typeof historyInfo.data != "undefined" && lastPage != 1 && (
            <div className="show-btn">
              <button
                onClick={() => {
                  lastPage > page - 1
                    ? historyTurnoverList(page)
                    : historyTurnoverList(1);
                }}
              >
                {lastPage > page - 1 ? (
                  <>{props.t("show_more")}</>
                ) : (
                  <>{props.t("show_less")}</>
                )}
              </button>
            </div>
          )}
        </div>
      </div>

      {isMessageSet && <Popup handlePoup={handlePopup} referralMsg />}
      {loading && <Loading />}
    </>
  );
};

HistoryProviders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(HistoryProviders);
