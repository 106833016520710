import {
    FETCH_DEPOSIT_PENDING_SUCCESS,
    FETCH_DEPOSIT_PENDING
  } from "./actionTypes"
  
  
  export const fetchDepositPending = () => {
    return {
      type: FETCH_DEPOSIT_PENDING,
      payload: {},
    }
  }
  
  export const fetchDepositPendingSuccess = (response) => {
    return {
      type: FETCH_DEPOSIT_PENDING_SUCCESS,
      payload: response,
    }
  }