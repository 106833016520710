import React from 'react';
import ChatConfig from '../../pages/ChatConfig';
import { useState, useEffect } from "react";
import axios from "axios";
import { makeAjax, url } from '../../helpers/global_helper';
const AccessDenied = () => {

    ChatConfig();
    // Set & get state
    const [countryDetails, setCountryDetails] = useState({
        ip: null,
        country_code: null,
        country_name: null,
        status: false
    });

    // Load config

    // Get current ip & country code
    const getData = () => {
        makeAjax(url.USER_API.isLoggedIn, {}, url.USER_MS_EXT)
            .then(response => {
                if (response.code != 'validation.access_denied') {

                    
                    
                    setCountryDetails({
                        ...countryDetails,
                        ['country_ip']: response.data.country_ip,
                        ['country_code']: response.data.country_code,
                        ['country_name']: response.data.country_name,
                        ['status']: true,
                    });
                }
            });

    };

    // Use effect call
    useEffect(() => {
        if (!countryDetails.status) {
            // getData();
        }
    }, []);

    return (
        <>
            {

                !countryDetails.status && <div className='denied'>
                    <div className='access-denied'>
                        <div className="container">
                            <div className="error-sub">
                                <div className="error-sub-left">
                                    <h2><strong>ACCESS RESTRICTED</strong></h2>
                                    <p style={{ color: '#adacaa' }}>ACCESS RESTRICTED / AKSES TERBATAS</p>
                                </div>

                                {

                                    <div className='access-denied-text'>
                                        <div className="head">
                                            <img className="flag" src="https://texasccgacor.com/images/flag/id.png?v=1.12.3.1" alt="Flag" /> INDONESIA
                                        </div>
                                        <p>Kepada Para Pelanggan Yang Terhormat :
                                            <br />
                                            Sistem kami mendeteksi alamat IP Anda gunakan berasal dari Singapore. Perlu diketahui, untuk halaman yang Anda coba akses telah dibatasi dari lokasi ini.<br />Jika Anda tidak menggunakan IP akses Anda dibatasi, silahkan hubungi kami melalui LIVECHAT untuk pelayanan lebih lanjut.
                                            <br />Kami mohon maaf atas ketidaknyamanan yang terjadi. Terima kasih.</p>

                                    </div>
                                }
                                {

                                    <div className='access-denied-text2'>
                                        <div className="head">
                                            <img className="flag" src="https://texasccgacor.com/images/flag/sg.png?v=1.12.3.1" alt="Flag" /> Singapore
                                        </div>
                                        <p>Dear Values Customer :<br />
                                            Our system has detected your IP from Singapore. Please be informed, the page that you are trying to access is restricted from this location.<br />If you are not accessing from this ip but your access get restricted, please contact us through LIVECHAT for further assistance.<br />We apologize for any inconveniece caused. Thank you.</p>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            }

        </>
    )
}

export default AccessDenied
