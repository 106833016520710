import { useEffect, useState } from "react"
import HotGamesCarousel from './hotgamescarousel/HotGamesCarousel'
import HotGamesGrid from './hotgamesgrid/HotGamesGrid'
import { makeAjax, url, showMessage, respStatus } from "./../../../../helpers/global_helper";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const initSetting = {
    hotgame_grid: null,
    class: null,
    default_class: null,
};

const HotGames = (props) => {
    const [fnSetting, setFnSetting] = useState(initSetting);
    const [hotGameCaroselList, setHotGameCaroselList] = useState([]);
    const [hotGameGridList, setHotGameGridList] = useState([]);
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    useEffect(() => {
        if (typeof componentSetting?.["hot-games"] != 'undefined') {
            setFnSetting({
                ...fnSetting,
                ['hotgame_grid']: componentSetting?.["hot-games"]?.["default-value"],
                ['default_class']: componentSetting?.["hot-games"]?.["default-value"],
            })
        }
    }, []);

    const getAllHotGame = () => {
        let payload = {
            category_code: 'games',
            provider_name: '',
            search_term: '',
        };
        makeAjax(
            url.USER_API.listProviderGame, payload, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus["SUCCESS"]) {
                    if (response.code != "validation_error") {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                }
                setHotGameCaroselList(response?.data?.gameInfo?.data);
            });
    };

    useEffect(() => {
        getAllHotGame();
    }, [])
    return (
        <>
            <div className="hotgame-outer">
                <div className="container">
                    <div className="hot-game-se">
                        <div className="title">{props.t('hot_games')}</div>
                    </div>
                    {
                        fnSetting['hotgame_grid'] && < div className="hot-game">
                            <div className={fnSetting['default_class']}>
                                {
                                    hotGameCaroselList && hotGameCaroselList.length > 0 && fnSetting['hotgame_grid'] == 'hotgame-slider' && < HotGamesCarousel hotGameList={hotGameCaroselList} />
                                }
                                {
                                    hotGameCaroselList && hotGameGridList.length > 0 && fnSetting['hotgame_grid'] == 'hotgame-grid' && < HotGamesGrid hotGameList={hotGameGridList} />
                                }
                            </div>
                        </div >
                    }
                </div>
            </div>
        </>

    )
}

HotGames.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(HotGames);
