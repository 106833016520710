import { BiHomeAlt, BiChat } from "react-icons/bi";
import { useEffect, useState } from "react";
import { BsCashCoin, BsPen, BsQuestionCircle } from "react-icons/bs";
import { FaGifts } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { MdOutlineNoteAlt, MdPeopleAlt } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SocialMedia from "../../components/commonSection/social-media/SocialMedia";
import { fetchTextInfo } from "../../store/actions";
import Popup from "../../components/popup/Popup";
import FooterApk from "../footer/FooterApk";

const FooterMobile = (props) => {
  let loggedIn = window.localStorage.getItem("isLoggedIn");

  const { playerMenu } = useSelector((state) => ({
    playerMenu: state.menuListR.data,
  }));

  const [isPopup, setIsPopup] = useState(false);
  const [showRegisterpopup, setShowRegisterpopup] = useState(false);
  const [userDataHandleClick, setUserDataHandleClick] = useState(false);

  const { componentSetting, copyrightInformation } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
    copyrightInformation: state.textInformationListR.data,
  }));

  const HandleRegisterPopup = () => {
    setShowRegisterpopup(!showRegisterpopup);
  };
  const userDataHandle = () => {
    setUserDataHandleClick(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTextInfo("copyright"));
  }, []);
  return (
    <>
      <footer className="footer">
        <div className="container">
          <FooterApk />
          <div className="content mt-3">
            <h2 className="title">{props.t("connect_with_us")}</h2>
            {typeof componentSetting?.["social-media"] != "undefined" &&
              componentSetting?.["social-media"]?.["default-value"] && (
                <SocialMedia />
              )}
            {
              <p>
                {copyrightInformation &&
                  copyrightInformation.code == "copyright"
                  ? copyrightInformation.value
                  : ""}
              </p>
            }
          </div>
        </div>
      </footer>
      <footer className="footer-mobile">
        <div className="footer-nav">
          {playerMenu.before &&
            playerMenu.before.home &&
            playerMenu.before.home.status && (
              <NavLink to={"/"} className="item">
                <BiHomeAlt />
                <span>
                  {props.t(`${playerMenu.before.home.name?.toLowerCase()}`)}
                </span>
              </NavLink>
            )}
          {loggedIn &&
            playerMenu.before &&
            playerMenu.before.promotion &&
            playerMenu.before.promotion.status && (
              <NavLink to={"promotion"} className="item">
                <img alt="Promotion" src="../assets/images/Promotions.svg" />
                <span>
                  {props.t('promos')}
                </span>
              </NavLink>
            )}

          {!loggedIn &&
            playerMenu.before &&
            playerMenu.before.promotion &&
            playerMenu.before.promotion.status && (
              <NavLink to={"promotion"} className="item">
                <img alt="Promotion" src="../assets/images/Promotions.svg" />
                <span>
                  {props.t('promos')}
                </span>
              </NavLink>
            )}

          {loggedIn &&
            playerMenu.after &&
            playerMenu.after.deposit &&
            playerMenu.after.deposit.status && (
              <NavLink to={"deposit"} className="item depo-item">
                <div className="depo-icon-bg">
                  <BsCashCoin />
                </div>
                <span className="register-span">
                  {props.t(`${playerMenu.after.deposit.name?.toLowerCase()}`)}
                </span>
              </NavLink>
            )}

          {/* {loggedIn && playerMenu.after && playerMenu.after.withdraw && playerMenu.after.withdraw.status &&
          <NavLink to={'withdraw'} className="item">
            <GiTakeMyMoney />
            <span>{playerMenu.after.withdraw.name}</span>
          </NavLink>
        } */}

          {!loggedIn &&
            playerMenu.before &&
            playerMenu.before.register &&
            playerMenu.before.register.status && (
              <>
                {
                  componentSetting?.["top-nav-register-box"]?.["default-value"] == "popup" &&
                  <Link className="item regi-item" onClick={HandleRegisterPopup}>
                    <div className="regiter-icon-bg">
                      <MdOutlineNoteAlt />
                    </div>
                    <span className="register-span">
                      {props.t(`${playerMenu.before.register.name?.toLowerCase()}`)}
                    </span>
                  </Link>
                }

                {
                  componentSetting?.["top-nav-register-box"]?.["default-value"] == "inline" &&
                  <NavLink to={"register"} className="item regi-item">
                    <div className="regiter-icon-bg">
                      <MdOutlineNoteAlt />
                    </div>
                    <span className="register-span">
                      {props.t(`${playerMenu.before.register.name?.toLowerCase()}`)}
                    </span>
                  </NavLink>
                }
              </>
            )}
          {/* {(!loggedIn && playerMenu.before && playerMenu.before.blogs && playerMenu.before.blogs.status) &&
          <NavLink to={'blogs'} className="item">
            <BsPen />
            <span>{playerMenu.before.blogs.name}</span>
          </NavLink>
        } */}
          {playerMenu.before &&
            playerMenu.before.blogs &&
            playerMenu.before.blogs.status && (
              <NavLink to={"help"} className="item">
                <BsQuestionCircle />
                <span>
                  {props.t(`${playerMenu.before.help.name?.toLowerCase()}`)}
                </span>
              </NavLink>
            )}


          {loggedIn &&
            playerMenu.before &&
            playerMenu.before.live_chat &&
            playerMenu.before.live_chat.status && (
              // <NavLink to={"livechat"} className="item">
              <a className="item">
                <BiChat />
                <span>
                  {props.t(
                    `${playerMenu.before.live_chat.name?.toLowerCase()}`
                  )}
                </span>
              </a>
            )}

          {!loggedIn &&
            playerMenu.before &&
            playerMenu.before.live_chat &&
            playerMenu.before.live_chat.status && (
              // <NavLink to={"livechat"} className="item">
              <a className="item deactive">
                <BiChat />
                <span>
                  {props.t(
                    `${playerMenu.before.live_chat.name?.toLowerCase()}`
                  )}
                </span>
              </a>
            )}
        </div>

        {showRegisterpopup && (
          <div className="register-popup">
            <Popup
              handlePoup={HandleRegisterPopup}
              registerpopup
              userDataHandle={userDataHandle}
            />
          </div>
        )}
      </footer>
    </>
  );
};

FooterMobile.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(FooterMobile);
