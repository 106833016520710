import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const PinProtection = (props) => {
  return (
    <div className="popup-box">
      <h4 className="title">{props.t("information")}</h4>
      <p>
        {" "}
        {props.t(
          "deactivating_the_pin_is_not_recomended_if_you_frequently_log_in_public_places"
        )}
      </p>
      <div className="text-center">
        <NavLink to="/pin-security-validation" data={props.data}>
          <button>{props.t("process")}</button>
        </NavLink>
      </div>
    </div>
  );
};

PinProtection.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PinProtection);
