import {
  LANGUAGE_DD_SUCCESS,
} from "./actionTypes"

const initialState = {
  data: [],
}

const dropdownR = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_DD_SUCCESS:
      state = { ...state, data: action.payload.response };
      return state;
  }
  return state
}

export default dropdownR
