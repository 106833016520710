import { useState } from "react";
import { CSS_CLASSES } from "../../../../css-classes";
import GameProvider from "./game-provider/GameProvider";
import VerticalNav from "./vertical-nav/VerticalNav";
import { useParams } from "react-router-dom";
import ProviderGames from "./main-games/ProviderGames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const GameCategoryMenu = (props) => {
  const { providerSlug } = useParams();
  const [activeId, setActiveId] = useState(0);
  const onActiveChange = (id) => {
    setActiveId(id);
  };
  
  return (
    <div className="container">
      <div className={` ${CSS_CLASSES.gamesFilter}`}>
        <VerticalNav
          tabs={props.tabs}
          activeId={activeId}
          playerMenu={props.playerMenu}
          onActiveChange={onActiveChange}
        />

        {
          typeof props.categorySlug == null || props.categorySlug !=  '' && (
            <GameProvider activeId={activeId} categorySlug={props.categorySlug} providerSlug={providerSlug} />
          )
        }

        {
           typeof props.providerSlug != "undefined" && props.providerSlug != null &&
           (
             <ProviderGames activeId={activeId} categorySlug={props.categorySlug} providerSlug={providerSlug} />
           )
        }
      </div>
    </div>
  );
};


GameCategoryMenu.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(GameCategoryMenu);
