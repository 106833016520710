import { FETCH_PLAYER_PROFILE_SUCCESS, RESET_PLAYER_PROFILE } from "./actionTypes";

const initialState = {
    data: {
        username: '',
        email_id: '',
        player_bank_detail: {
            account_name: '',
            account_number: '',
            bank_detail: {
                bank_name: ''
            }
        },
        email_verified_at: ''
    }
};


const playerProfileR = (state = initialState, action) => {
    // 
    switch (action.type) {
        case FETCH_PLAYER_PROFILE_SUCCESS:
            state = { ...state, data: action.payload.response };
            break;
        case RESET_PLAYER_PROFILE:
            state = { ...state, data: initialState.data };
            break;
    }
    return state;
}

export default playerProfileR