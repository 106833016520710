import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const GlobalMaintenance = (props) => {
    return (
        <div className='popup-box'>
            <h4 className="title text-center">{props.t('information')}</h4>
            <p>{props.data}</p>
        </div>
    )
}

GlobalMaintenance.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(GlobalMaintenance);