import { useSelector } from 'react-redux'
//import { componentSetting } from '../template-settings'
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";
import { useEffect, useState } from "react";

const Dummy = () => {
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  const [lotteryResult, setLotteryResult] = useState();

  const getLotteryResult = () => {
    makeAjax(url.GP_API.playerTransactionHistory, [], url.GP_MS_EXT).then(response => {
      //   if (response.status != respStatus['SUCCESS']) {
      //     showMessage(response);
      //     return;
      //   }
      setLotteryResult(response);
    });
  }

  useEffect(() => {
    getLotteryResult();
  }, []);

  
  return (
    <h1> "ATISH"</h1>
  )

}

export default Dummy