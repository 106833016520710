import { FETCH_USER_VALIDATION_SUCCESS, RESET_USER_VALIDATION } from "./actionTypes";

const initialState = {
    data: {},
    redirect: ['/login-pin', '/security-question', '/security-validation', '/reminder-password']
};


const checkPinStatusR = (state = initialState, action) => {
    // 
    switch (action.type) {
        case FETCH_USER_VALIDATION_SUCCESS:
            state = { ...state, data: action.payload.response };
            break;
        case RESET_USER_VALIDATION:
            state = { ...state, data: initialState.data };
            break;
    }
    return state;
}

export default checkPinStatusR