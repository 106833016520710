import { HiArrowDownTray } from "react-icons/hi2";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
    showMessage,
    makeAjax,
    url,
    respStatus,
} from "../../helpers/global_helper";
import { NavLink } from "react-router-dom";

const FooterApk = (props) => {
    const [apkData, setApkData] = useState({});

    const getApkData = () => {
        makeAjax(
            url.USER_API.listAppData, [], url.ADMIN_MS_EXT
        ).then((response) => {
            if (response.status != respStatus["SUCCESS"]) {
                if (response.code != "validation_error") {
                    showMessage(response);
                    return;
                }
                showMessage(response.messages);
                return;
            }
            setApkData(response.data);
        });
    };

    useEffect(() => {
        getApkData();
    }, [])

    return (
        <div className="content test-i">
            {apkData && Object.values(apkData).length > 0 &&
                <>
                    <h2 className="title">{props.t('downloads')}</h2>
                    <div className="d-flex mt-lg-2 mt-0 align-items-start">
                        {Object.values(apkData).map((data, i) => (
                            <div key={i} className="footer_download me-3">
                                <div className="footer_apk">
                                    <p>{props.t(`${data?.entity_type}`)}</p>
                                    <img alt="Qrcode" src={data?.qr_link} />
                                    <NavLink to={data?.link}>
                                        <button>
                                            <HiArrowDownTray />
                                            <span>{data?.entity_type === 'android' ? (props.t('apk')) : (props.t(`${data?.entity_type}`))}</span>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>
    );
};

export default withTranslation()(FooterApk);