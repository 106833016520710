import React from 'react'
import { NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Agree = (props) => {

    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>  
                <p>{props.t('the_rules_of_play_have_not_been_approved')}!</p>
        </div>
    )
}

Agree.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Agree);