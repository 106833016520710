import React from "react";
import { FaSquarePlus } from "react-icons/fa6";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdRefresh } from "react-icons/md";
import { RiInformationLine, RiErrorWarningLine } from "react-icons/ri";
import { PiCoins } from 'react-icons/pi'
import { HiOutlineCheckCircle } from "react-icons/hi";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
} from "../../helpers/global_helper";
import { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Balance = (props) => {
  const [playerBalance, setPlayerBalance] = useState([]);
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));
  const [spinning, setSpinning] = useState(false);

  const fetchPlayerBalance = (type) => {
    if (type == "refresh") {
      setSpinning(true);
    }

    makeAjax(url.GP_API.getBalance, [], url.GP_MS_EXT).then((response) => {
      if (type == "refresh") {
        setTimeout(() => {
          setSpinning(false);
        }, 1000);
      }
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setPlayerBalance(response.data);
    });
  };
  // Effect List
  useEffect(() => {
    fetchPlayerBalance();
  }, []);

  const [promotionCategory, setPromotionCategory] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  const getPromotionCategory = () => {
    makeAjax(url.ADMIN_API.promotionCategoryNameDD, [], url.ADMIN_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setPromotionCategory(response.data);
      }
    );
  };

  const handleIconClick = () => {
    makeAjax(url.ADMIN_API.promotionCategoryNameDD, [], url.ADMIN_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          // showMessage(response);
          return;
        }
        setPromotionCategory(response.data);
      }
    );
    setIsClicked(!isClicked);
  };

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, so close it
      setIsClicked(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [emailVer, setEmailVer] = useState(false)
  useEffect(() => {
    const timeout = setInterval(() => {
      setEmailVer(false);
    }, 5000);
    return () => {
      clearInterval(timeout);
    };
  }, []);
  const handleEmailVer = () => {
    setEmailVer(!emailVer);
  }

  return (
    <div className="user-balance">
      <div className="container">
        <div className="user-data">
          <div className="profile-data">
            <div className="user-name">
              <h3>
                <Link to="/profile">{userProfile.username}</Link>
              </h3>

            </div>
            <div className="txt-history">
              <Link to="/trans-history">
                {props.t("view_transaction_history")}
              </Link>{" "}
            </div>
          </div>
          <div className="profile-data-inner mt-1">
            <div className="row">
              <div className="col-7">
                <div className="blance">
                  <div className="idr-img">
                    <span className="idr-num idr">
                      {userProfile ? userProfile.currency : ""}{" "}
                      <>{currencyFormat(playerBalance?.main_balance)}</>
                    </span>
                  </div>
                  <div className="d-flex balance-chip">
                    <div className="chip-img">
                      {" "}
                      <PiCoins className="coins" />
                    </div>
                    <div className="chip-amount">
                      {currencyFormat(playerBalance?.promo_balance)}
                    </div>
                    <div className="iconhoverdiv" ref={dropdownRef}>
                      <div className="icon d-flex" onClick={handleIconClick}>
                        <RiInformationLine className="infohover-icon" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="deposit-btn">
                  <span className="refresh-icon">
                    <MdRefresh
                      onClick={() => {
                        fetchPlayerBalance("refresh");
                      }}
                      className={`spin-button ${spinning ? "spin" : ""}`}
                    />
                  </span>{" "}
                  <Link to="/deposit" className="button">
                    {props.t("deposit")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isClicked && (
          <div className="click-content">
            <p>{props.t("following_categories_are_applicable_for_promos")}</p>
            <ul>
              {promotionCategory &&
                promotionCategory !== null &&
                promotionCategory.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

Balance.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Balance);
