import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ShowWarning = (props) => {
    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>
            <p className='sub-info'> {props.t('select_atleast_one_entry')}</p>
        </div>
    )
}

ShowWarning.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ShowWarning);