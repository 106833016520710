import { CSS_CLASSES } from "../../../../../css-classes";
import { useState, useEffect } from "react";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../../../helpers/global_helper";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Popup from "../../../../popup/Popup";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BsSliders } from "react-icons/bs";
import Select from "react-select";
import { useSelector } from "react-redux";
import Loading from "../../../Loading/Loading";
import Search from "../../../search/Search";
import { GrClose, GrSearch } from "react-icons/gr";
import ScrollToTop from "../../../../../helpers/ScrollToTop";
import { FaArrowUp } from "react-icons/fa";

const ProviderGames = (props) => {
  const initSetting = {
    provider_grid: null,
    default_class: null,
  };

  const [fnSetting, setFnSetting] = useState(initSetting);
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));

  const { playerMenu } = useSelector((state) => ({
    playerMenu: state.menuListR.data,
  }));

  // Device type
  let deviceType = localStorage.getItem("device_type");

  // Scroll init
  let user_token = window.localStorage.getItem("user_token");
  const [page, setPage] = useState(1);
  let initFormData = {
    search_term: "",
    provider_name: "",
    category_code: "",
    all_games: true,
    dropdown_filter: false,
    per_page: 100,
  };
  // Set & get game list
  const [gameList, setGameList] = useState([]);
  const [gameId, setGameId] = useState("");
  const [maintainanceError, setMaintainanceError] = useState(false);
  const [commingSoonPopup, setCommingSoonPopup] = useState(false);
  const [selectedDropDown, setselectedDropDown] = useState(null);
  const [providerList, setProviderList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loginforLunchLobby, setLoginforLunchLobby] = useState(false);
  const [show, setShow] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [providerFilterName, setProviderFilter] = useState(null);
  const [formData, setFormData] = useState(initFormData);

  // Ajax call for game details
  const tenantProviderDD = () => {
    let payload = props.categorySlug;
    if (payload == "hot_games") payload = "games";
    makeAjax(
      url.GP_API.tenantCategoryProviderDD,
      { category_code: payload },
      url.GP_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setProviderList(response.data);
    });
  };

  const providerWiseCategoryDD = (payload) => {
    makeAjax(
      url.GP_API.providerWiseCategoryDD,
      { provider_name: decodeURIComponent(payload) },
      url.GP_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setCategoryList(response.data);
    });
  };

  const handleGameClick = (game) => {
    if (game.name.toLowerCase() == 'dummy game')
      return;

    if (!user_token) {
      navigate("/register");
      return;
    }

    if (
      componentSetting?.custom_loading?.options?.provider_game_handle &&
      props.categorySlug == "lottery"
    ) {
      navigate("/lottery/HKBG");
      return;
    }
    if (game.maintainance_mode) {
      gameUnderMaintennace(game);
      return;
    }
    const payload = {
      game_id: game._id,
    };
    setGameId({ ...gameId, game_id: game._id });
    makeAjax(url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == "game_under_maintenance") {
            setMaintainanceError(true);
            return;
          }

          if (response.code == "game_not_active") {
            setCommingSoonPopup(true);
            return;
          }

          if (response.code == "validation.invalid_token_error") {
            navigate("/register");
            return;
          }

          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }

        //pg soft game data 16th jan 2024
        if (response?.data?.code === "bd200") {
          const newTab = window.open();
          newTab.document.write(response.data.data);
        } else {
          window.open(response.data, "_blank");
        }
      }
    );
  };

  // Fetch game data
  const fetchGameListData = (nextPage, onChangePayload) => {
    return new Promise((resolve, reject) => {
      let categoryArr = [];
      let payload = { ...formData };
      payload["page"] = nextPage;
      for (const menu of Object.values(playerMenu["before"])) {
        if (menu?.vertical_menu_enable) categoryArr.push(menu.code);
      }
      if (componentSetting?.custom_loading?.options?.provider_game_handle) {
        payload["provider_name"] =
          decodeURIComponent(props.categorySlug) != "null"
            ? decodeURIComponent(props.categorySlug)
            : "";
        payload["category_code"] = "";
        if (categoryArr.includes(props.categorySlug)) {
          payload["category_code"] =
            decodeURIComponent(props.categorySlug) != "null"
              ? decodeURIComponent(props.categorySlug)
              : "";
          payload["provider_name"] = "";
        }
        setProviderFilter(payload["provider_name"]);
      } else {
        payload["provider_name"] =
          decodeURIComponent(props.providerSlug) != "null"
            ? decodeURIComponent(props.providerSlug)
            : "";
        payload["category_code"] =
          decodeURIComponent(props.categorySlug) != "null"
            ? decodeURIComponent(props.categorySlug)
            : "";
      }
      if (onChangePayload) {
        payload.category_code = onChangePayload.category_code;
        payload.provider_name = onChangePayload.provider_name;
        payload.search_term = onChangePayload.search_term;
      }

      setFormData(payload);
      setLoading(true);
      makeAjax(url.USER_API.listProviderGame, payload, url.USER_MS_EXT)
        .then((response) => {
          setLoading(false);

          if (response.status != respStatus["SUCCESS"]) {
            showMessage(response);
            return;
          }
          if (
            response?.data?.gameInfo?.data &&
            response?.data?.gameInfo?.data.length > 0
          ) {
            resolve({
              data: response?.data?.gameInfo?.data,
              currentPage: response?.data?.gameInfo?.current_page,
              lastPage: response?.data?.gameInfo?.last_page,
            });
          } else {
            resolve({
              data: [],
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getGameListOnChange = (event) => {
    let payload = formData;

    if (event) {
      setselectedDropDown(event);
      if (providerFilterName) {
        payload["category_code"] = event.menu_code;
      } else {
        payload["provider_name"] = event._id;
        payload["dropdown_filter"] = true;
      }
    } else {
      setselectedDropDown(null);
      if (providerFilterName) {
        payload["category_code"] = "";
      } else {
        payload["provider_name"] = "";
      }
    }
    setFormData({ ...payload });
    getGameList(1, payload);
  };
  // Get game list
  const getGameList = async (nextPage, onChangePayload) => {
    try {
      if (lastPage == null || nextPage <= lastPage) {
        let response = "";
        if (onChangePayload) {
          response = await fetchGameListData(nextPage, onChangePayload);
        } else {
          response = await fetchGameListData(nextPage);
        }
        if (nextPage == 1) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setGameList([]);
        }
        setGameList((prevRecords) => [...prevRecords, ...response.data]);
        setPage(nextPage + 1);
        setLastPage(response.lastPage);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
    }
  };

  const onChangeSearch = (val) => {
    let payload = { ...formData };
    payload["search_term"] = val;
    if (val != "") {
      setShow(false);
    }
    if (formData["search_term"] != val) setFormData(payload);
    getGameList(1, payload);
  };

  // On scroll game load
  let debounceTimer;

  const handleScroll = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      const scrolledFromTop = window.scrollY + window.innerHeight;

      const totalHeight = document.documentElement.scrollHeight;

      const threshold = 0.8;

      if (scrolledFromTop >= threshold * totalHeight) {
        getGameList(page, formData);
      }
    }, 450); // Adjust the delay (in milliseconds)
  };

  // On scroll game load
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, formData, lastPage]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "37px",
      height: "37px",
      boxShadow: state.isFocused ? null : null,
      cursor: "pointer",
      fontSize: "14px",

      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
        minHeight: "32px",
        height: "32px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
      backgroundColor: state.isFocused ? "#181618" : provided.backgroundColor,
      color: state.isSelected ? "#D47DFF" : "#BAAEC1",
      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#D47DFF" : "#D47DFF", // Change text color for selected value
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    menu: (provided) => ({
      ...provided,
      // For WebKit browsers (Chrome, Safari)
      "&::-webkit-scrollbar": {
        width: "3px",
        height: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#3498db", // Set the color of the scrollbar thumb
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
      },
      // For Firefox
      scrollbarColor: " #201E21",
      scrollbarWidth: "3px",
      scrollbarheight: "5px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  const onOpenFilter = () => {
    if (formData["search_term"] != "") {
      onChangeSearch("");
    }

    if (!show)
      if (providerFilterName) {
        providerWiseCategoryDD(providerFilterName);
      } else {
        tenantProviderDD();
      }

    setShow(!show);
  };

  // back button
  const navigate = useNavigate();
  const goBack = () => {
    if (props.categorySlug != null && props.providerSlug != null) {
      if (props.categorySlug == "lotteryHome") {
        navigate("/lottery");
        return;
      }
      navigate("/" + props.categorySlug);
      return;
    }
    navigate("/");
  };

  // Close maintaninace popup
  const handlePopup = () => {
    setMaintainanceError(false);
    setCommingSoonPopup(false);
    setLoginforLunchLobby(false);
  };

  // If game is under maintennace
  const gameUnderMaintennace = (event) => {
    setMaintainanceError(true);
  };
  // Use effect call
  useEffect(() => {
    setGameList([]);
    setFormData(initFormData);
    setLastPage(null);
    setselectedDropDown(null);
    setShow(false);
    getGameList(1);
  }, [props.providerSlug, props.categorySlug]);

  useEffect(() => {
    let clientName = componentSetting?.["client_name"];
    let providerLayoutType =
      componentSetting?.["provider-game"]?.["default-value"];
    setFnSetting({
      ...fnSetting,
      ["provider_grid"]: providerLayoutType,
    });
  }, []);

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 400)
            setShowTopBtn(true)
        else
            setShowTopBtn(false)
    })
}, [])
const scrollTop = () => {
    window.scrollTo({
        top: 0,
        left:0,
        behavior:'smooth'
    })
}

  return (
    <>
      <div className="games">
        {componentSetting?.back_button?.options?.back_button && (
          <div className="provider-box text-uppercase">
            <button className="back" onClick={goBack}>
              <BiArrowBack />
            </button>
            <h3 className="provider-text text-center">
              {props.providerSlug ? props.providerSlug : props.categorySlug}
            </h3>
          </div>
        )}

        {fnSetting["provider_grid"] && (
          <div className="provider-game-wrapper">
            <div className="slotgame-outer">
              <div className="container">
                {componentSetting?.game_filter?.options?.filter && (
                  <div className="hot-game-header ">
                    <div className="header-wrap">
                      {props.providerSlug != null && (
                        <div className="title">
                          {" "}
                          {decodeURIComponent(
                            props.providerSlug.replace(/_/g, " ").toUpperCase()
                          )}
                        </div>
                      )}
                      {props.categorySlug != null && (
                        <>
                          <div className="title">
                            {" "}
                            {decodeURIComponent(
                              props.categorySlug
                                .replace(/_/g, " ")
                                .toUpperCase()
                            )}
                          </div>
                          <div className="d-flex justify-content-between searchfill">
                            <form className={CSS_CLASSES.searchBar}>
                              <input
                                type="text"
                                name="search_box"
                                value={formData["search_term"]}
                                placeholder={props.t("find_your_games")}
                                onChange={(e) => onChangeSearch(e.target.value)}
                              />
                              {formData["search_term"] == "" ? (
                                <GrSearch />
                              ) : (
                                <span
                                  onClick={() => {
                                    onChangeSearch("");
                                  }}
                                >
                                  <GrClose />
                                </span>
                              )}
                            </form>
                            <div className="bt-fil">
                              <Button onClick={() => onOpenFilter()}>
                                {" "}
                                <BsSliders />
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {show && (
                      <div className="ca-category">
                        <div className="form-div">
                          <div className="input-control">
                            <label className="form-label">
                              {providerFilterName != null &&
                              providerFilterName != "" ? (
                                <>
                                  {props.t("provider")}:{" "}
                                  <span style={{ textTransform: "capitalize" }}>
                                    {decodeURIComponent(
                                      providerFilterName.replace(/_/g, " ")
                                    )}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {props.t("category")}:{" "}
                                  <span style={{ textTransform: "capitalize" }}>
                                    {props.categorySlug.replace(/_/g, " ")}
                                  </span>
                                </>
                              )}
                            </label>
                          </div>
                          <div className="input-control">
                            <label className="form-label">
                              {providerFilterName != null &&
                              providerFilterName != ""
                                ? props.t("category")
                                : props.t("provider")}
                            </label>
                            <Select
                              name={
                                providerFilterName != null &&
                                providerFilterName != ""
                                  ? "category"
                                  : "provider"
                              }
                              isClearable={true}
                              className="cusReactSelect"
                              styles={customStyles}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option._id}
                              onChange={getGameListOnChange}
                              options={
                                providerFilterName != null &&
                                providerFilterName != ""
                                  ? categoryList
                                  : providerList
                              }
                              value={selectedDropDown}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#181618",
                                  primary: "#181618",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className={fnSetting["provider_grid"]}>
                  {fnSetting["provider_grid"] == "provider-game-grid" && (
                    <div className={CSS_CLASSES.menuItemsHover}>
                      {gameList &&
                      typeof gameList != "undefined" &&
                      gameList != null &&
                      gameList.length > 0 ? (
                        <>
                          {gameList.map((v) => (
                            <>
                              {
                                <>
                                  <div
                                    onClick={() => {
                                      handleGameClick(v);
                                    }}
                                    className="under-games"
                                  >
                                    {v.maintainance_mode && (
                                      <div className="undermaintenance">
                                        {props.t("under_maintanance")}
                                      </div>
                                    )}

                                    <div
                                      key={v}
                                      className={`item ${
                                        v.status === "Coming Soon" ||
                                        v.is_hot_game ||
                                        v.is_new
                                          ? "overlay-image"
                                          : ""
                                      }`}
                                    >
                                      {v.status == "Coming Soon" ? (
                                        <span className="coming">
                                          {props.t("coming_soon")}
                                        </span>
                                      ) : v.is_hot_game ? (
                                        <span className="promo">
                                          {props.t("Hot")}
                                        </span>
                                      ) : v.is_new ? (
                                        <span className="new">
                                          {props.t("new")}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {v.game_assets_media != null ? (
                                        <img
                                          className="img-fluid"
                                          src={
                                            v.ftp_path +
                                            v.game_assets_media.base_path +
                                            v.game_assets_media.system_file_name
                                          }
                                          alt={v.name}
                                        />
                                      ) : (
                                        <img
                                          className="img-fluid"
                                          src={
                                            "/assets/images/default-images/game_" +
                                            deviceType +
                                            ".png"
                                          }
                                          alt={v.name}
                                        />
                                      )}
                                      {v.name.split("").length > 21 ? (
                                        <h6 className="item-name hovering">
                                          {v.name}
                                        </h6>
                                      ) : (
                                        <h6 className="item-name">{v.name}</h6>
                                      )}
                                    </div>
                                  </div>
                                </>
                              }
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          {!loading && (
                            <span className="title">
                              {props.t("no_game_found")}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {fnSetting["provider_grid"] == "provider-game-slider" && (
                    <div className="provider-game">
                      <Slider {...settings}>
                        {gameList &&
                          typeof gameList != "undefined" &&
                          gameList != null &&
                          gameList.length > 0 &&
                          gameList.map((v) => (
                            <>
                              {
                                <>
                                  <div
                                    onClick={() => {
                                      handleGameClick(v);
                                    }}
                                    className="under-games"
                                  >
                                    {v.maintainance_mode && (
                                      <div className="undermaintenance">
                                        {props.t("under_maintanance")}
                                      </div>
                                    )}
                                    <div key={v} className="item">
                                      {v.game_assets_media != null ? (
                                        <img
                                          className="img-fluid"
                                          src={
                                            v.ftp_path +
                                            v.game_assets_media.base_path +
                                            v.game_assets_media.system_file_name
                                          }
                                          alt={v.name}
                                        />
                                      ) : (
                                        <img
                                          className="img-fluid"
                                          src={
                                            "/assets/images/default-images/game_" +
                                            deviceType +
                                            ".png"
                                          }
                                          alt={v.name}
                                        />
                                      )}
                                      {v.name.split("").length > 15 ? (
                                        <marquee>
                                          <h6 className="item-name">
                                            {v.name}
                                          </h6>
                                        </marquee>
                                      ) : (
                                        <h6 className="item-name">{v.name}</h6>
                                      )}
                                    </div>
                                  </div>
                                </>
                              }
                            </>
                          ))}
                      </Slider>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {showTopBtn && (
          <div className="top-bg">
            <button className={CSS_CLASSES.scrollTop} onClick={scrollTop}>
              <FaArrowUp />
            </button>
          </div>
        )}

        {loading && <Loading />}
      </div>
      {maintainanceError && (
        <Popup handlePoup={handlePopup} providerMaintainance />
      )}
      {commingSoonPopup && <Popup handlePoup={handlePopup} commingSoonGame />}
    </>
  );
};

ProviderGames.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ProviderGames);
