import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withTranslation } from "react-i18next";

//i18n
import i18n from "i18next";
import Language from "./Language";
import { useDispatch, useSelector } from "react-redux";
import { languageDD } from "../../store/actions";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../helpers/global_helper";
import { NavLink } from "react-router-dom";
const LanguageDropdown = (props) => {
  // Default select set & get
  const selectedLang = localStorage.getItem("I18N_LANGUAGE") || 'en';

  const [selectedLanguage, setSelectedLanguage] = useState(selectedLang);

  const [menu, setMenu] = useState(false);

  // Init dispatch
  const dispatch = useDispatch();

  // Language json list
  const frontendLang = Language;


  // Language dd
  const { language_dd } = useSelector((state) => ({
    language_dd: state.dropdownR.data,
  }));

  // Use effect for lang
  useEffect(() => {
    dispatch(languageDD());
  }, []);

  useEffect(() => {
    setSelectedLanguage(selectedLang);
  }, [selectedLang]);

  const changeLanguageAction = (lang) => {
    
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    makeAjax(url.USER_API.updateMyLanguage, { lang: lang }).then((response) => {
      
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return false;
      }
    });
  };

  // On change toggle dorpdown
  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div className="after-login-header">
        <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
          <DropdownToggle
            className="flag-btn"
            tag="button"
          >
            {props?.view != 'mobile' &&
              <>
                {selectedLanguage ? (
                  <img
                    src={frontendLang[selectedLanguage].flag}
                    alt={selectedLanguage}
                    height="16"
                    className="me-1 flag-img"
                  />
                ) : null}
              </>
            }
          </DropdownToggle>

          {props?.view == 'mobile' && language_dd.length > 0 &&
            language_dd.map((lang) => {
              return (
                <div className="sub-menu-dropdown">
                  <NavLink
                    key={lang.code}
                    onClick={() => changeLanguageAction(lang.code, lang._id)}
                    className="dropdown-item"
                  >
                    <img
                      src={
                        frontendLang[lang.code]
                          ? frontendLang[lang.code].flag
                          : ""
                      }
                      alt={lang.code}
                      className="me-1 flag-img"
                      height="12"
                    />

                    <span className="align-middle">{lang.name}</span>
                  </NavLink>
                </div>
              );
            })}

          {props?.view != 'mobile' &&
            <DropdownMenu className="language-switch dropdown-menu-end">
              {language_dd.length > 0 &&
                language_dd.map((lang) => {
                  return (
                    <DropdownItem
                      style={{ background: "transparent" }}
                      key={lang.code}
                      onClick={() => changeLanguageAction(lang.code, lang._id)}
                      className={`notify-item ${selectedLanguage === lang.code ? "active" : "none"
                        }`}
                    >
                      <img
                        src={
                          frontendLang[lang.code]
                            ? frontendLang[lang.code].flag
                            : ""
                        }
                        alt={lang.code}
                        className="me-1 flag-img"
                        height="12"
                      />

                      <span className="align-middle">{lang.name}</span>
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          }
        </Dropdown>
      </div >
    </>
  );
};

export default withTranslation()(LanguageDropdown);
