import { useState, useEffect } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Popup from "../../../../components/popup/Popup";
import { CSS_CLASSES } from "../../../../css-classes";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../../helpers/global_helper";
import { BiShowAlt } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FloatingLabel } from "react-bootstrap";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  getPlayerProfileDetail,
  resetPlayerProfile,
} from "../../../../store/actions";
import {
  checkPinStatus,
  resetPinStatus,
} from "../../../../store/check-pin-status/actions";
import ActionLoader from "../../../../pages/ActionLoader";
import LanguageDropdown from "../../../../components/languageDropdown/LanguageDropdown";
import { TbMapPause } from "react-icons/tb";

const initSetting = {
  login_modal: null,
  register_modal: null,
  default_class: null,
  default_register_class: null,
};

const UserLogin = (props) => {
  const [fnSetting, setFnSetting] = useState(initSetting);
  const [fnSettingR, setFnSettingR] = useState(initSetting);
  const [globalMaintenance, setGlobalMaintenance] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [isLoginPopup, setIsLoginPopup] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [loaderStatus, setLoader] = useState(false);
  const [userDataHandleClick, setUserDataHandleClick] = useState(false);
  const [formError, setFormError] = useState({});
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const [showRegisterpopup, setShowRegisterpopup] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    key: "",
  });

  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePopup = () => {
    setIsPopup(false);
    setGlobalMaintenance(false);
    setIsLoginPopup(false);
  };

  const userDataHandle = () => {
    setUserDataHandleClick(true);
  };

  const HandleRegisterPopup = () => {
    setShowRegisterpopup(!showRegisterpopup);
  };

  const fieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((values) => ({ ...values, [name]: value }));
    setErrors((values) => ({ ...values, [name]: null }));
  };

  const { pinStatus, playerMenu } = useSelector((state) => ({
    pinStatus: state.checkPinStatusR.data,
    playerMenu: state.menuListR.data,
  }));

  const redirectMe = (pinStatus, path, component_location) => {
    if (Object.keys(pinStatus).length) {
      let config_status = pinStatus.get_tenant_config?.value;
      if (
        config_status === true &&
        pinStatus.enabled_active_pin === true &&
        pinStatus.active_pin === false
      ) {
        navigate("/login-pin");
        return;
      } else if (
        config_status === true &&
        pinStatus.enabled_active_pin === true &&
        pinStatus.is_pin_verified === false
      ) {
        navigate("/security-validation");
        return;
      } else if (
        pinStatus.is_security_question_set === false ||
        pinStatus.is_security_question_set === null
      ) {
        navigate("/security-question");
        return;
      } else if (pinStatus.is_password_active === false) {
        navigate("/reminder-password");
        return;
      } else {
        dispatch(getPlayerProfileDetail());
        navigate("/");
        return;
      }
    }
  };

  useEffect(() => {
    redirectMe(pinStatus, location.pathname, "/");
  }, [pinStatus]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    makeAjax(url.USER_API.login, formData, url.USER_MS_EXT).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        setLoader(false);
        if (response.code == "api_message.loginMaintenanceOn") {
          let msg = response.messages;
          if (
            typeof response.data != "undefined" &&
            typeof response.data.tenant != "undefined" &&
            response.data.tenant != null
          ) {
            msg = response.data.tenant;
          }
          setGlobalMaintenance(msg);
          return;
        }
        if (response.code !== "validation_error") {
          showMessage(response);
        }
        setFormError(response.messages);
        return;
      }
      localStorage.setItem("user_token", response.data.token);
      localStorage.setItem("device_type", "desktop");
      window.localStorage.setItem("isLoggedIn", true);
      // dispatch(getPlayerProfileDetail());
      makeAjax(url.USER_API.listPlayerAlternativeSite, {})
        .then((response) => {
          console.log("listPlayerAlternativeSite", response);
          if (
            response.status !== respStatus["SUCCESS"] ||
            (response.data &&
              response.data.data &&
              response.data.data.length == 0)
          ) {
            dispatch(checkPinStatus());
            // navigate(0);
          }
          let sites = response.data.data;
          console.log("sites", sites);
          for (let i = 0; i < sites.length; i++) {
            if (sites[i]["is_login"]) {
              if (window.location.host == sites[i]["domain"]) {
                // navigate("/");
                // navigate(0);
                dispatch(checkPinStatus());
                return;
              } else {
                window.location.assign(
                  (sites[i]["is_secure"] ? "https" : "http") +
                    "://" +
                    sites[i]["domain"] +
                    "/update_token/web/" +
                    localStorage.getItem("user_token")
                );
                return;
              }
            }
          }
          dispatch(checkPinStatus());
          // navigate("/");
          // navigate(0);
          setLoader(false);
        })
        .catch((ex) => {
          // navigate("/");
          // navigate(0);
          setLoader(false);
          dispatch(checkPinStatus());
        });
    });
  };
  // Show password
  const showPassword = (event) => {
    if (inputType == "password") setInputType("text");
    if (inputType == "text") setInputType("password");
  };

  useEffect(() => {
    dispatch(resetPlayerProfile());
    dispatch(resetPinStatus());
  }, []);

  useEffect(() => {
    let tmp = fnSetting;
    if (typeof componentSetting?.["top-nav-login-box"] != "undefined") {
      tmp["login_modal"] =
        componentSetting?.["top-nav-login-box"]?.["default-value"];
    }

    if (typeof componentSetting?.["top-nav-register-box"] != "undefined") {
      tmp["register_modal"] =
        componentSetting?.["top-nav-register-box"]?.["default-value"];
    }
    setFnSetting(tmp);
  }, [componentSetting]);

  return (
    <>
      <div className="user-login-sec">
        <div>
          {fnSetting["login_modal"] && (
            <div className={CSS_CLASSES.userLoginBox}>
              <div className={fnSetting["login_modal"]}>
                {fnSetting["login_modal"] == "inline" && (
                  <form className="user-login-form">
                    {errors.messages ? (
                      <div color="danger">{errors.messages[0]}</div>
                    ) : null}

                    <FloatingLabel
                      controlId="floatingInput"
                      label="username"
                      className="field"
                    >
                      <FaRegUser className="user-icon" />
                      <Form.Control
                        type="text"
                        placeholder={props.t("username")}
                        name="username"
                        value={formData.username}
                        onChange={fieldChange}
                      />
                      <span
                        className="forget-password"
                        onClick={() => setIsPopup((curr) => !curr)}
                      >
                        {props.t("customer_complaints")}
                      </span>
                      {formError.username ? (
                        <span className="text-danger">
                          {formError.username[0]}
                        </span>
                      ) : null}
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingPassword"
                      label={props.t("password")}
                      className="field"
                    >
                      <MdLockOutline className="user-icon2" />
                      <Form.Control
                        type={inputType}
                        placeholder={props.t("password")}
                        name="password"
                        value={formData.password}
                        onChange={fieldChange}
                      />
                      {inputType == "password" && (
                        <BsEyeSlash
                          className="icon"
                          onMouseEnter={showPassword}
                        />
                      )}
                      {inputType == "text" && (
                        <BiShowAlt
                          className="icon"
                          onMouseLeave={showPassword}
                        />
                      )}
                      <NavLink
                        to={"forgot-password"}
                        className="forget-password"
                      >
                        {props.t("forget_password")}?
                      </NavLink>
                      {formError.password ? (
                        <span className="text-danger">
                          {formError.password[0]}
                        </span>
                      ) : null}
                    </FloatingLabel>

                    <ActionLoader
                      label={"login"}
                      loaderClass={"login-loader"}
                      showLoader={loaderStatus}
                      onClick={handleSubmit}
                    />
                  </form>
                )}
                {fnSetting["login_modal"] == "popup" && (
                  <form className="user-login-form">
                    {errors.messages ? (
                      <div color="danger">{errors.messages[0]}</div>
                    ) : null}

                    <Link
                      className="login-wrapbtn"
                      onClick={() => setIsLoginPopup(true)}
                    >
                      {props.t("login")}
                    </Link>
                  </form>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          {fnSettingR["register_modal"] && (
            <div className={CSS_CLASSES.userLoginBox}>
              <div className={fnSettingR["register_modal"]}>
                {fnSettingR["register_modal"] == "inline" && (
                  <form>
                    {errors.messages ? (
                      <div color="danger">{errors.messages[0]}</div>
                    ) : null}
                    {playerMenu.before &&
                    playerMenu.before.register &&
                    playerMenu.before.register.status ? (
                      <div className="reg-btn">
                        <NavLink to={"register"} className="register">
                          {props.t(`${playerMenu.before.register.code}`)}
                        </NavLink>
                      </div>
                    ) : null}
                  </form>
                )}

                {fnSettingR["register_modal"] == "popup" && (
                  <form className="user-login-form">
                    {errors.messages ? (
                      <div color="danger">{errors.messages[0]}</div>
                    ) : null}

                    {playerMenu.before &&
                    playerMenu.before.register &&
                    playerMenu.before.register.status ? (
                      <div className="reg-btn">
                        <NavLink
                          className="register"
                          onClick={HandleRegisterPopup}
                        >
                          {props.t(`${playerMenu.before.register.code}`)}
                        </NavLink>
                      </div>
                    ) : null}
                  </form>
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <LanguageDropdown />
        </div>
      </div>

      {showRegisterpopup && (
        <div className="register-popup">
          <Popup
            handlePoup={HandleRegisterPopup}
            registerpopup
            userDataHandle={userDataHandle}
          />
        </div>
      )}

      {isLoginPopup && (
        <Popup
          handlePoup={handlePopup}
          loginpopup
          userDataHandle={userDataHandle}
        />
      )}

      {isPopup && <Popup handlePoup={handlePopup} custComplaint />}

      {globalMaintenance && (
        <Popup
          handlePoup={handlePopup}
          globalMaintenanceCondition
          message={globalMaintenance}
        />
      )}
    </>
  );
};

UserLogin.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(UserLogin);
