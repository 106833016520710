import { useEffect, useState } from "react";
import { CSS_CLASSES } from "../../../../css-classes"
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Popup from "../../../../components/popup/Popup";
import { useNavigate } from "react-router-dom";
import { makeAjax, url, showMessage, respStatus } from '../../../../helpers/global_helper'
const HoverNav = (props) => {
    // set & get state
    const [maintainanceError, setMaintainanceError] = useState(false);
    const [commingSoonPopup, setCommingSoonPopup] = useState(false);
    const [loginforLunchLobby, setLoginforLunchLobby] = useState(false);
    const [specialCategoryGame, setSpecialCategoryGame] = useState([]);

    const [gameId, setGameId] = useState('');
    const navigate = useNavigate();

    // Device type
    let deviceType = localStorage.getItem('device_type');

    // Fetch subMenu Info
    const { providerSubMenuList } = useSelector(state => ({
        providerSubMenuList: state.submenuListR.data
    }));

    // Ajax call for game details
    const handleGameClick = (game) => {
        if (game.maintainance_mode) {
            setMaintainanceError(true);
            return;
        }

        const payload = {
            game_id: game._id
        };
        setGameId(
            { ...gameId, game_id: game._id }
        );
        makeAjax(
            url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(response => {
                if (response.status != respStatus["SUCCESS"]) {
                    if (response.code == "game_under_maintenance") {
                        setMaintainanceError(true);
                        return;
                    }
                    if (response.code == "game_not_active") {
                        setCommingSoonPopup(true);
                        return;
                    }

                    if (response.code == "validation.invalid_token_error") {
                        navigate('/register');
                        return;
                    }

                    if (response.code != "validation_error") {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                    return;
                }

                //pg soft game data 7th feb 2024
                if (response?.data?.code === "bd200") {
                    const newTab = window.open();
                    newTab.document.write(response.data.data);
                } else {
                    window.open(response.data, "_blank");
                }
            }
            )
    };

    // Hot game list
    const getSpecialCategoryGame = () => {
        let payload = {
            category_code: props.code == 'hot_games' ? 'games' : props.code,
            provider_name: '',
            search_term: '',
        };
        makeAjax(
            url.USER_API.listProviderGame, payload, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus["SUCCESS"]) {
                    if (response.code != "validation_error") {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                }
                setSpecialCategoryGame(response.data.gameInfo);
            });
    };

    // Use effect call
    useEffect(() => {
        if (['card_games', 'lottery', 'dingdong', 'hot_games'].includes(props.code)) {
            getSpecialCategoryGame();
        }
    }, [props.code]);

    // Close maintaninace popup
    const handlePopup = () => {
        setMaintainanceError(false);
        setCommingSoonPopup(false);
        setLoginforLunchLobby(false);
    }
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    const { playerMenu } = useSelector(state => ({
        playerMenu: state.menuListR.data
    }));
    return (
        <>
            {
                componentSetting?.["horizonatal-nav-bar"]?.["default-value"] == 'hover-listing' && props.code != 'home' &&
                <div className={componentSetting?.["horizonatal-nav-bar"]?.["default-value"]}>
                    <div className={CSS_CLASSES.hoverMenu}>

                        <div className="container games">
                            <div className={CSS_CLASSES.menuItemsHover}>
                                {Object.values(providerSubMenuList).length > 0 && Object.values(providerSubMenuList).map((v) =>
                                    Object.values(v).length > 0 && Object.values(v).map((obj) =>
                                        (obj.menu_code == props.code && !['card_games', 'lottery', 'dingdong', 'hot_games'].includes(props.code)) && <>
                                            <div>
                                                <NavLink key={v} to={obj.menu_code + '/' + obj.tenant_provider_reco.provider_name}>
                                                    {obj.maintainance_mode && <div className="undermaintenance">
                                                        {props.t('under_maintanance')}
                                                    </div>}
                                                    <div key={v} className="item">
                                                        {/* <span className="promo">{props.t(`${'promo'}`)}</span> */}
                                                        {obj.menu_provider_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.menu_provider_media.base_path + obj.menu_provider_media.system_file_name} alt={obj.tenant_provider_reco.provider_name} /> : <img className="img-fluid" src={'/assets/images/default-images/provider_' + deviceType + ".png"} alt={obj.tenant_provider_reco.provider_name} />}
                                                        {
                                                            obj.tenant_provider_reco.provider_name.split('').length > 15 ?
                                                                <marquee><h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6></marquee>
                                                                : <h6 key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</h6>
                                                        }
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </>
                                    )
                                )}

                                {specialCategoryGame && ['card_games', 'lottery', 'dingdong', 'hot_games'].includes(props.code) && specialCategoryGame.length > 0 && specialCategoryGame.map((obj) =>
                                    <>
                                        {
                                            props.code != 'lottery' ?
                                                <div onClick={() => { handleGameClick(obj) }}>
                                                    <NavLink key={obj}>
                                                        {obj.maintainance_mode && <div className="undermaintenance">
                                                            {props.t('under_maintanance')}
                                                        </div>}
                                                        <div key={obj} className="item">
                                                            {obj.is_hot_game ? <span className="promo">{'Hot'}</span> : ''}
                                                            {obj.game_assets_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.game_assets_media.base_path + obj.game_assets_media.system_file_name} alt={obj.name} /> : <img className="img-fluid" src={'/assets/images/default-images/game_' + deviceType + ".png"} alt={obj.name} />}
                                                            {
                                                                obj.name.split('').length > 15 ?
                                                                    <marquee><h6 key={obj} className="item-name">{obj.name}</h6></marquee>
                                                                    : <h6 key={obj} className="item-name">{obj.name}</h6>
                                                            }
                                                        </div>
                                                    </NavLink>
                                                </div> :

                                                <>
                                                    <div>
                                                        <NavLink key={obj} to={'lottery' + '/' + obj.provider.tenant_provider.provider_name}>
                                                            {obj.maintainance_mode && <div className="undermaintenance">
                                                                {props.t('under_maintanance')}
                                                            </div>}
                                                            <div key={obj} className="item">
                                                                {obj.is_hot_game ? <span className="promo">{'Hot'}</span> : ''}
                                                                {obj.game_assets_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.game_assets_media.base_path + obj.game_assets_media.system_file_name} alt={obj.name} /> : <img className="img-fluid" src={'/assets/images/default-images/game_' + deviceType + ".png"} alt={obj.name} />}
                                                                {
                                                                    obj.name.split('').length > 15 ?
                                                                        <marquee><h6 key={obj} className="item-name">{obj.name}</h6></marquee>
                                                                        : <h6 key={obj} className="item-name">{obj.name}</h6>
                                                                }
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </>
                                        }

                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>}

            {maintainanceError && <Popup handlePoup={handlePopup} providerMaintainance />}
            {commingSoonPopup && <Popup handlePoup={handlePopup} commingSoonGame />}
        </>
    )
}

HoverNav.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(HoverNav);
