import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { makeAjax, respStatus, url } from '../helpers/global_helper';

const UpdateToken = () => {
    const { user_token } = useParams();
    const { device_type } = useParams();
    const navigate = useNavigate();
    const setUserToken = () => {
        localStorage.setItem('device_type', device_type ? device_type : 'web');
        if (user_token != '') {
            localStorage.setItem('user_token', user_token);
            makeAjax(url.USER_API.isLoggedIn, {}, url.USER_MS_EXT).then(response => {
                if (response.status !== respStatus['SUCCESS'] || !response.data) {
                    localStorage.removeItem('user_token');
                }
                navigate('/');
            });
        }
    }
    useEffect(() => {
        setUserToken();
    }, []);
}

export default UpdateToken;