import { useEffect, useState } from 'react'
import { BiHomeAlt, BiDice5 } from 'react-icons/bi'
import { BsPen, BsQuestionCircle, BsFire } from 'react-icons/bs'
import { MdOutlineVideogameAsset, MdSportsSoccer } from 'react-icons/md'
import { TbCards, TbCrystalBall } from 'react-icons/tb'
import { CSS_CLASSES } from '../../../css-classes'
import HoverNav from './hover/HoverNav'
import { NavLink } from 'react-router-dom'
import { HiOutlineUsers, HiOutlineDeviceMobile, HiOutlineFire } from 'react-icons/hi'
import { GiEightBall, GiInvertedDice5 } from 'react-icons/gi'
import { LiaGiftsSolid, LiaMoneyCheckAltSolid, LiaCoinsSolid } from 'react-icons/lia'
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Logo from '../main-header/logo/Logo'


const VerticalNav = (props) => {
    const [isItem, setIsItem] = useState();

    const { playerMenu } = useSelector(state => ({
        playerMenu: state.menuListR.data
    }));

    const mouseOver = (code) => {
        setIsItem(code);
    }

    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));


    const hoverGames = [
        { code: "home", menu: 'Home Page', icon: <BiHomeAlt />, link: '/' },
        { code: "hot_games", menu: 'Hot Games', icon: <BsFire />, link: '/games' },
        { code: "slots", menu: 'Slots', icon: <LiaMoneyCheckAltSolid />, link: '/slots' },
        { code: "arcades", menu: 'Arcades', icon: <MdOutlineVideogameAsset />, link: '/arcades' },
        { code: "jackpot_bar", menu: 'Jackpot Bar', icon: <LiaCoinsSolid />, link: '/jackpot_bar' },
        { code: "live_casino", menu: 'Casino', icon: <BiDice5 />, link: '/live_casino' },
        { code: "sportsbook", menu: 'Sports', icon: <MdSportsSoccer />, link: '/sportsbook' },
        { code: "card_games", menu: 'Card Games', icon: <TbCards />, link: '/card_games' },
        { code: "lottery", menu: 'Lottery', icon: <GiEightBall />, link: '/lottery' },
        { code: "dingdong", menu: 'Ding Dong', icon: <TbCrystalBall />, link: '/dingdong' },
        { code: "blogs", menu: 'Blogs', icon: <BsPen />, link: '/blogs' },
        { code: "promotion", menu: 'Promotions', icon: <LiaGiftsSolid />, link: '/promotion' },
        { code: "referral", menu: 'Referrals', icon: <HiOutlineUsers />, link: '/referrals' },
        { code: "help", menu: 'Help', icon: <BsQuestionCircle />, link: '/help' },
        { code: "mobile", menu: 'Mobile', icon: <HiOutlineDeviceMobile />, link: '/mobile' }
    ]



    const [hovered, setHovered] = useState(false);

    const handleOver = () => {
        setHovered(true)
    }
    const handleOut = () => {
        setHovered(false)
    }

    return (
        <div className='vertical-nav'>
            {typeof componentSetting?.["top-nav-logo"] != 'undefined' && componentSetting?.["top-nav-logo"]?.["default-value"] && <Logo />}
            <ul>
                {
                    hoverGames.map((v, i) => {
                        return (playerMenu['before'][v.code] && playerMenu['before'][v.code]['status']) &&
                            <>
                                {!props.sideMenuClickPropState ?
                                    <li key={playerMenu['before'][v.code]['_id']} className={componentSetting["horizonatal-nav-bar"]["default-value"]}>
                                        {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></a>}
                                    </li>
                                    :
                                    <li key={playerMenu['before'][v.code]['_id']} className={componentSetting["horizonatal-nav-bar"]["default-value"]}>
                                        {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon} </NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} </a>}
                                    </li>
                                }
                            </>

                    })
                }
            </ul>
        </div>
    )
}

VerticalNav.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(VerticalNav);