import { call, put, all, takeEvery } from "redux-saga/effects";
import {
  FETCH_PLAYER_PROFILE
} from "./actionTypes"
import {
  getPlayerProfileDetailSuccess
} from "./actions";

import { makeAjax, url, showMessage } from "../../helpers/global_helper";
import { USER_MS_EXT } from "../../helpers/url_helper";

function* getPlayerProfileDetail() {
  try {
    const response = yield call(
      makeAjax,
      url.USER_API.getPlayerProfileDetail,
      {},
      USER_MS_EXT
    );
    if (response.status == 'SUCCESS') {
      let resp = response.data;
      let aNumber = resp.player_bank_detail.account_number;
      let mask = aNumber.replace(/\d(?=\d{4})/g, "*");
      resp.player_bank_detail.account_number = mask;
      yield all([
        put(getPlayerProfileDetailSuccess({ response: resp }))
      ]);
    }
    else {
      showMessage(response);
    }
  } catch (error) {
    showMessage(error);
    
    // alert
  }
}

function* playerProfileSaga() {
  yield takeEvery(FETCH_PLAYER_PROFILE, getPlayerProfileDetail);
}

export default playerProfileSaga;
