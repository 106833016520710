import { useState, useEffect } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { FaBackspace } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import Popup from "../../popup/Popup";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getPlayerProfileDetail } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ActionLoader from "../../../pages/ActionLoader";
const LoginPinOnInner = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const InitFormData = {
    pin: "",
    pin_confirmation: "",
    type: "pin-on",
  };

  const { userProfile } = useSelector(state => ({
    userProfile: state.playerProfileR.data
  }));

  const [formData, setFormData] = useState(InitFormData);
  const [formError, setFormError] = useState({});
  const [pinBox1, setPinBox1] = useState(false);
  const [pinBox2, setPinBox2] = useState(false);
  const [pinProtectionChange, setPinProtectionChange] = useState(false);
  const [getData, setData] = useState([]);
  const [formSuccess, setFormSuccess] = useState(false);
  const [isSuccessMessagePopUp, setIsSuccessMessagePopUp] = useState(false);
  const [loaderStatus, setLoader] = useState(false);
  const [disabledNum1, setDisabledNum1] = useState(true);
  const [disabledNum2, setDisabledNum2] = useState(true);

  const numPad1 = (e) => {
    const numMain1 = formData.pin + e.target.id;
    if (numMain1.length > 6) {
      return false;
    }
    if (numMain1.length == 6) {
      setDisabledNum1(false);
    }
    setFormData({ ...formData, ...{ pin: numMain1 } });
  };

  const numPad2 = (e) => {
    const numMain2 = formData.pin_confirmation + e.target.id;
    if (numMain2.length > 6) {
      return false;
    }
    if (numMain2.length == 6) {
      setDisabledNum2(false);
    }
    setFormData({ ...formData, ...{ pin_confirmation: numMain2 } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    makeAjax(url.USER_API.setUserNewPin, formData, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          setLoader(false);
          if (response.code != "validation_error") showMessage(response);
          setPinProtectionChange(true);
          setFormError(response.messages);
          return;
        }
        setLoader(false);
        setFormError({});
        setFormData({ ...formData, ...{ pin: "", pin_confirmation: "" } });
        setFormSuccess(response.messages);
        setIsSuccessMessagePopUp(true);
      }
    );
  };

  const numPadClear1 = (e) => {
    setFormData({ ...formData, ...{ pin: formData.pin.slice(0, -1) } });
    setDisabledNum1(true);
  };

  const numPadClear2 = () => {
    setFormData({
      ...formData,
      ...{ pin_confirmation: formData.pin_confirmation.slice(0, -1) },
    });
    setDisabledNum2(true);
  };

  const handlePopup = () => {
    setFormData(InitFormData);
    setPinProtectionChange(false);
  };

  const back = () => {
    navigate("/profile");
  };

  const [numbers, setNumbers] = useState([]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    const shuffledNumbers = shuffleArray([...Array(10).keys()]);
    setNumbers(shuffledNumbers);
  }, []);

  const closeBox1 = () => {
    setPinBox1(false);
    setFormData({ ...formData, pin: "" });
    const shuffledNumbers = shuffleArray([...Array(10).keys()]);
    setNumbers(shuffledNumbers);
    setDisabledNum1(true);
  };
  const closeBox2 = () => {
    setPinBox2(false);
    setFormData({ ...formData, pin_confirmation: "" });
    const shuffledNumbers = shuffleArray([...Array(10).keys()]);
    setNumbers(shuffledNumbers);
    setDisabledNum2(true);
  };

  const handleSuccess = () => {
    setIsSuccessMessagePopUp(false);
    dispatch(getPlayerProfileDetail());
    navigate("/profile");
    // navigate(0);
  };

  return (
    <div className="new-auth login-pin">
      <div className="new-pass-box">
        <h4 className="title">
          {props.t("welcome")}, {userProfile?.username}
        </h4>
        <div className="new-pass-box-inner">
          <h4 className="sub-title">{props.t("enter_your_six_digits_pin")}</h4>
          <p className="desc">
            {props.t(
              "this_system_is_to_increase_security_and_privacy_of_your_account"
            )}
          </p>
          <form>
            <div className="password-icon">
              <input
                type="password"
                placeholder={props.t("enter_your_new_pin")}
                value={formData.pin}
                onClick={() => (setPinBox1((curr) => !curr), setPinBox2(false))}
              />
              <CgMenuGridR
                onClick={() => (setPinBox1((curr) => !curr), setPinBox2(false))}
              />
              {pinBox1 && (
                <div className="pin-box">
                  <input
                    type="password"
                    placeholder={props.t("pin")}
                    name="pin"
                    value={formData.pin}
                    onChange={() => { }}
                    maxLength={6}
                    pattern="[^\s]+"
                  />
                  <div className="enter-pin">
                    <div className="num-box">
                      {numbers.map((number) => (
                        <span
                          key={number}
                          className="num"
                          id={number}
                          onClick={numPad1}
                        >
                          {number}
                        </span>
                      ))}
                    </div>
                    <div className="button-box">
                      <button
                        className="okay"
                        onClick={() => setPinBox1(false)}
                        disabled={disabledNum1}
                      >
                        {props.t("okay")}
                      </button>
                      <span className="back" onClick={numPadClear1}>
                        <FaBackspace />
                      </span>
                      <span className="close" onClick={closeBox1}>
                        {props.t("close")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="password-icon">
              <input
                type="password"
                placeholder={props.t("confirm_new_pin")}
                value={formData.pin_confirmation}
                onClick={() => (setPinBox2((curr) => !curr), setPinBox1(false))}
              />
              <CgMenuGridR
                onClick={() => (setPinBox2((curr) => !curr), setPinBox1(false))}
              />
              {pinBox2 && (
                <div className="pin-box">
                  <input
                    type="password"
                    placeholder={props.t("pin")}
                    name="pin_confirmation"
                    value={formData.pin_confirmation}
                    onChange={() => { }}
                    maxLength={6}
                    pattern="[^\s]+"
                  />
                  <div className="enter-pin">
                    <div className="num-box">
                      {numbers.map((number) => (
                        <span
                          key={number}
                          className="num"
                          id={number}
                          onClick={numPad2}
                        >
                          {number}
                        </span>
                      ))}
                    </div>
                    <div className="button-box">
                      <button
                        className="okay"
                        onClick={() => setPinBox2(false)}
                        disabled={disabledNum2}
                      >
                        {props.t("okay")}
                      </button>
                      <span className="back" onClick={numPadClear2}>
                        <FaBackspace />
                      </span>
                      <span className="close" onClick={closeBox2}>
                        {props.t("close")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="pin-setting-btn">
              <button className="cancel" type="button" onClick={back}>
                {props.t("log_out")}
              </button>
              <ActionLoader
                label={"process"}
                loaderClass={"pin-on"}
                showLoader={loaderStatus}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              />
            </div>
          </form>
          <span className="note">
            {props.t("this_pin_will_be_used_every_time_you_enter_the_game")}
          </span>
        </div>
      </div>
      {pinProtectionChange && (
        <Popup handlePoup={handlePopup} data={[formError]} setNewPin />
      )}
      {/* Success Message Pop Up */}
      {isSuccessMessagePopUp && (
        <Popup
          handlePoup={handleSuccess}
          data={[formSuccess]}
          successMessages
        />
      )}
    </div>
  );
};

LoginPinOnInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(LoginPinOnInner);
