import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ErrorMessages = (props) => {
  return (
    <div className="popup-box">
      <h4 className="title">{props.t("information")}</h4>
      {props.data.map((value, key) => (
        <>
          <div className="popup-error-msg">{Object.values(value)[0]}</div>
        </>
      ))}
    </div>
  );
};

ErrorMessages.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ErrorMessages);
