import React from 'react'
import { Link } from "react-router-dom";

const HotGamesGrid = (props) => {

  let deviceType = localStorage.getItem('device_type');

  return (
    <>
      <div className="hotgame-list-grid pt-2">
        {props?.hotGameList.map((item, index) => (
          <div key={index} className="hotgame-item">
            <Link to={item?.link ?? '/register'}>
              <div className="hotgame-img">
                {item.game_assets_media != null ? <img src={item?.ftp_path + item?.game_assets_media?.base_path + item?.game_assets_media?.system_file_name} alt={item.name} /> : <img src={'/assets/images/default-images/game_' + deviceType + ".png"} alt="Game Icon" />}
                <div className="hotgame-content pt-2">
                  <p className="game-title">{item.name}</p>
                  <p className="game-subtitle">{item.provider.name}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

    </>
  )
}

export default HotGamesGrid
