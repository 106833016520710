import { FETCH_MENU_COMPONENT_SETTING_SUCCESS } from "./actionTypes";

const initialState = {
    data: {},
};

const componentSettingListR = (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_MENU_COMPONENT_SETTING_SUCCESS:
            state = { ...state, data: action.payload.response };
            return state;
    }
    return state;
}

export default componentSettingListR