import { FETCH_OTHERMEDIA_INFO_SUCCESS } from "./actionTypes";

const initialState = {
    data: [],
};

const othermediaInformationListR = (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_OTHERMEDIA_INFO_SUCCESS:
            state = { ...state, data: action.payload.response };
            return state;
    }
    return state;
}

export default othermediaInformationListR