import { useState, useEffect } from "react";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../helpers/global_helper";
import { useSelector, useDispatch } from "react-redux";
import { fetchTextInfo } from "../../store/actions";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SocialMedia from "../../components/commonSection/social-media/SocialMedia";
import LiveChat from "../../components/livechat/LiveChat";
import { HiArrowDownTray } from "react-icons/hi2";
import { Link } from "react-router-dom";
import FooterMenu from "./FooterMenu";
import FooterApk from "./FooterApk";

const Footer = (props) => {
  // Set & get state
  const [getSocialMedia, setSocialMedia] = useState([]);
  const [orderSocialMedia, setOrderSocialMedia] = useState([]);
  const dispatch = useDispatch();


  // Dispatch call for copyright
  // Fetch seo info
  const { copyrightInformation } = useSelector((state) => ({
    copyrightInformation: state.textInformationListR.data,
  }));
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));
  const extractScriptAttributes = (scriptString) => {
    const srcRegex = /src=["'](.*?)["']/;
    const idRegex = /id=["'](.*?)["']/;
    const srcMatch = scriptString.match(srcRegex);
    const idMatch = scriptString.match(idRegex);
    const src = srcMatch ? srcMatch[1] : null;
    const id = idMatch ? idMatch[1] : null;
    if (src == null && id == null) {
      let doc = new DOMParser().parseFromString(scriptString, "text/xml");
      let src = doc.firstChild.textContent;
      return { src, id };
    }
    return { src, id };
  };
  const loadCustomScript = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        code: "custom_script",
      };
      makeAjax(url.USER_API.getTextInfo, payload, url.USER_MS_EXT)
        .then((response) => {
          if (response.status !== respStatus["SUCCESS"]) {
            if (response.code !== "validation_error") {
              showMessage(response);
              reject(response);
              return;
            }
            showMessage(response);
            reject(response);
            return;
          }
          if (response.data && response.data.value) {
            const { src, id } = extractScriptAttributes(response.data.value);
            resolve({ scriptSrc: src, scriptId: id });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  useEffect(() => {
    let scriptElement;
    loadCustomScript()
      .then((scriptAttributes) => {
        const { scriptSrc, scriptId } = scriptAttributes;
        let element = document.getElementById("seo_footer");
        scriptElement = document.createElement("script");
        if (scriptId == null) {
          scriptElement.innerHTML = scriptSrc;
        } else {
          scriptElement.src = scriptSrc;
          scriptElement.id = scriptId;
        }
        element.append(scriptElement);
      })
      .catch((error) => {
        console.error(error);
      });
    return () => {
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, []);
  useEffect(() => {
    dispatch(fetchTextInfo("copyright"));
  }, []);
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer_cont">
            <div className="content">
              <h2 className="title">{props.t('connect_with_us')}</h2>
              <div className="so-if">
                {typeof componentSetting?.["social-media"] != 'undefined' && componentSetting?.["social-media"]?.["default-value"] && <SocialMedia />}
              </div>
              <LiveChat />
            </div>
            {
              componentSetting?.footer_menu?.options?.footer_menu && <FooterMenu />
            }
            {
              componentSetting?.footer_menu?.options?.footer_menu && <FooterApk />
            }
          </div>
          <div className="footer_copy mt-4">
            {<p>
              {copyrightInformation && copyrightInformation.code == "copyright"
                ? copyrightInformation.value
                : ""}
            </p>}
          </div>
        </div>
      </footer>

    </>
  );
};
Footer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(Footer);