import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const SuccessMessages = (props) => {
  return (
    <div className='popup-box'>
      <h4 className="title">{props.t('information')}</h4>
      <div className="popup-error-msg">
        {props.data}
      </div>
    </div>
  )
}

SuccessMessages.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(SuccessMessages);