import {
  call,
  put,
  all,
  takeEvery
} from "redux-saga/effects";
import { LANGUAGE_DD} from "./actionTypes";
import { languageDDSuccess} from "./actions";
import { makeAjax, url } from "../../helpers/global_helper";

function* languageDD() {
  try {
    
    const response = yield call(
      makeAjax,
      url.MASTER_API.languageDD, {}, url.MASTER_MS_EXT
    );
    if (response.status == 'SUCCESS') {
      yield all([
        put(languageDDSuccess({ response: response.data }))
      ]);
    } else {
      // alert
    }
  } catch (error) {
    
    // alert
  }
}

function* dropdownSaga() {
  yield takeEvery(LANGUAGE_DD, languageDD);
}

export default dropdownSaga;