import { useEffect, useState, useRef } from 'react'
import { BiHomeAlt, BiDice5, BiTimeFive, BiCategory } from 'react-icons/bi'
import { BsPen, BsQuestionCircle, BsFire, BsBarChartLineFill, BsGlobe, BsBarChartLine } from 'react-icons/bs'
import { MdOutlineVideogameAsset, MdSportsSoccer, MdPeopleAlt, MdOutlineFactCheck } from 'react-icons/md'
import { TbCards, TbCrystalBall } from 'react-icons/tb'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { CSS_CLASSES } from '../../../css-classes'
import HoverNav from './hover/HoverNav'
import { NavLink } from 'react-router-dom'
import { HiOutlineUsers, HiOutlineDeviceMobile, HiOutlineFire } from 'react-icons/hi'
import { GiEightBall, GiInvertedDice5 } from 'react-icons/gi'
import { LiaGiftsSolid, LiaMoneyCheckAltSolid, LiaCoinsSolid, LiaInfoSolid } from 'react-icons/lia';
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { LuTimerReset } from "react-icons/lu";
import { PiUsersThreeLight } from "react-icons/pi";
import Popup from '../../../components/popup/Popup'
const Navbar = (props) => {
  const [isItem, setIsItem] = useState();
  const [loggedIn, setLoggedIn] = useState('');
  let token = window.localStorage.getItem("user_token");

  const { playerMenu } = useSelector(state => ({
    playerMenu: state.menuListR.data
  }));
  const mouseOver = (code) => {
    setIsItem(code);
  }
  const hoverGames = [
    { code: "home", menu: 'Home Page', icon: <BiHomeAlt />, link: '/' },
    { code: "hot_games", menu: 'Hot Games', icon: <BsFire />, link: '/games' },
    { code: "slots", menu: 'Slots', icon: <LiaMoneyCheckAltSolid />, link: '/slots' },
    { code: "arcades", menu: 'Arcades', icon: <MdOutlineVideogameAsset />, link: '/arcades' },
    { code: "jackpot_bar", menu: 'Jackpot Bar', icon: <LiaCoinsSolid />, link: '/jackpot_bar' },
    { code: "live_casino", menu: 'Casino', icon: <BiDice5 />, link: '/live_casino' },
    { code: "sportsbook", menu: 'Sports', icon: <MdSportsSoccer />, link: '/sportsbook' },
    { code: "card_games", menu: 'Card Games', icon: <TbCards />, link: '/card_games' },
    { code: "lottery", menu: 'Lottery', icon: <GiEightBall />, link: '/lottery' },
    { code: "dingdong", menu: 'Ding Dong', icon: <TbCrystalBall />, link: '/dingdong' },
    { code: "blogs", menu: 'Blogs', icon: <BsPen />, link: '/blogs' },
    { code: "promotion", menu: 'Promotions', icon: <LiaGiftsSolid />, link: '/promotion' },
    { code: "referral", menu: 'Referrals', icon: <HiOutlineUsers />, link: '/referrals' },
    { code: "help", menu: 'Help', icon: <BsQuestionCircle />, link: '/help' },
    { code: "mobile", menu: 'Mobile', icon: <HiOutlineDeviceMobile />, link: '/mobile' },
    { code: "about_us", menu: 'About us', icon: <LiaInfoSolid />, link: '/about_us' }
  ]

  let hoverMainMenuDemo7 = [
    {
      code: "home",
      menu: 'Home Page',
      icon: <BiHomeAlt />,
      link: '/',
      isNested: false,
    },
    {
      code: "all_games",
      menu: "All Games",
      icon: <IoExtensionPuzzleOutline />,
      link: "/all-games",
      isNested: false,
    },
    {
      code: "categories",
      menu: "Categories",
      icon: <BiCategory />,
      link: "",
      isNested: true,
    },
    {
      code: "transactions",
      menu: "Transactions",
      icon: <MdOutlineFactCheck />,
      link: "",
      isNested: true,
    },
    {
      code: "referral",
      menu: "Referrals",
      icon: <PiUsersThreeLight />,
      link: "/referrals",
      isNested: false,
    },
    {
      code: "promotion",
      menu: 'Promotions',
      icon: <LiaGiftsSolid />,
      link: '/promotion',
      isNested: false,
    },

  ];

  let categoryListWLS = [
    {
      code: "slots",
      menu: "Slots",
      icon: <LiaMoneyCheckAltSolid />,
      link: "/slots",
    },
    {
      code: "arcades",
      menu: "Arcades",
      icon: <MdOutlineVideogameAsset />,
      link: "/arcades",
    },
    {
      code: "jackpot_bar",
      menu: "Jackpot Bar",
      icon: <LiaCoinsSolid />,
      link: "/jackpot_bar",
    },
    {
      code: "live_casino",
      menu: "Casino",
      icon: <BiDice5 />,
      link: "/live_casino",
    },
    {
      code: "sportsbook",
      menu: "Sports",
      icon: <MdSportsSoccer />,
      link: "/sportsbook",
    },
    {
      code: "card_games",
      menu: "Card Games",
      icon: <TbCards />,
      link: "/card_games",
    },
    {
      code: "lottery",
      menu: "Lottery",
      icon: <GiEightBall />,
      link: "/lottery",
    },
    {
      code: "dingdong",
      menu: "Ding Dong",
      icon: <TbCrystalBall />,
      link: "/dingdong",
    },
  ];

  let transListDemo7 = [
    {
      code: "trans_history",
      menu: "history",
      icon: <LuTimerReset />,
      link: "/trans-history",
    },
    {
      code: "turnover",
      menu: "Turnover",
      icon: <BsBarChartLine />,
      link: "/turnover",
    },
    {
      code: "outstanding_bet",
      menu: "Outstanding Bet",
      icon: <BiTimeFive />,
      link: "/outstanding-bet",
    },
    {
      code: "hkb_transactions",
      menu: "HKB Transactions",
      icon: "../assets/images/HKB-Gaming.png"
      // link: "",
    },
  ];

  const [openDrop, setOpenDrop] = useState('');

  let deviceType = localStorage.getItem('device_type');
  const [hovered, setHovered] = useState(false);
  const handleOver = () => {
    setHovered(true);
  }
  const handleOut = () => {
    setHovered(false);
  }
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  const { providerSubMenuList } = useSelector(state => ({
    providerSubMenuList: state.submenuListR.data
  }));
  const dropdownRef = useRef(null);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleToggleDropdown = (code) => {
    setOpenDrop(code);
    if (code !== openDrop) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
    }
  };

  const outSideClickClose = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
      setOpenDrop(false)
    }
  };
  useEffect(() => {
    setLoggedIn(token);
  }, [token]);

  useEffect(() => {
    document.addEventListener('mousedown', outSideClickClose);

    return () => {
      document.removeEventListener('mousedown', outSideClickClose);
    };
  }, [isDropdownOpen]);

  const [isComingSoon, setComingSoon] = useState(false);

  const handlePopup = () => {
    // Open the popup only if the code is "hkb_transactions"
    setComingSoon(false);
  };
  const handleClosePopup = (code) => {
    // Close the popup
    if (code === "hkb_transactions") {
      setComingSoon(true);
      setDropdownOpen(false);
    }
  };

  return (
    <div className={hovered ? 'hover pos-r' : 'pos-r'} onMouseOver={handleOver} onMouseLeave={handleOut}>
      <div className="container">
        <ul className={CSS_CLASSES.navbar}>
          {hoverMainMenuDemo7.map((v, i) => {
            return ((!loggedIn && playerMenu['before'][v.code] && playerMenu['before'][v.code]['status'])
              || (loggedIn && playerMenu["after"][v.code] && playerMenu["after"][v.code]["status"])
              || (loggedIn && v.code == 'referral' && playerMenu["after"]?.['my_referral'] && playerMenu["after"]?.['my_referral']['status'])
            ) &&
              <>
                {componentSetting?.["horizonatal-nav-bar"]?.["default-value"] == 'label-icon' &&
                  <li className={componentSetting?.["horizonatal-nav-bar"]?.["default-value"]}>
                    {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{v?.code}</span> </NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{v?.code}</span></a>}
                  </li>
                }
                {
                  componentSetting?.["horizonatal-nav-bar"]?.["default-value"] == 'only-lable' &&
                  <li className={componentSetting?.["horizonatal-nav-bar"]?.["default-value"]}>
                    {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> <span>{v?.code}</span> </NavLink> : <a onMouseOver={() => mouseOver(v.code)}> <span>{v?.code}</span></a>}
                  </li>
                }
                {
                  componentSetting?.["horizonatal-nav-bar"]?.["default-value"] == 'menu-dropdown' &&
                  <>
                    <li className={componentSetting?.["horizonatal-nav-bar"]?.["default-value"]} onClick={() => { handleToggleDropdown(v.code); }} >
                      {v.isNested ? (
                        <>{
                          v.code == "transactions" && !loggedIn ? ('') :

                            <NavLink onMouseOver={() => mouseOver(v.code)}
                              className={isActive => (openDrop == 'categories' || openDrop == 'transactions') && openDrop == v.code && "active"}
                            >
                              {v?.icon}{" "}{props.t(`${v.code}`)}

                              {openDrop == v.code && isDropdownOpen ? <IoIosArrowUp className="down-arrow" /> : <IoIosArrowDown className="down-arrow" />}

                            </NavLink>
                        }
                        </>
                      ) : (
                        <NavLink to={v?.link}>
                          {v.code == 'blogs' ? (
                            <>
                              {v?.icon}{" "}{props.t('blog')}
                            </>
                          ) : (
                            <>
                              {v?.icon}{" "}{props.t(`${v.code}`)}
                            </>
                          )}
                        </NavLink>
                      )}

                      {isDropdownOpen && v.isNested && openDrop == 'categories' && v.code == 'categories' && (
                        <div className="dropdown-content" ref={dropdownRef}>
                          {categoryListWLS?.map((data) => {
                            return (
                              playerMenu["before"][data.code] &&
                              playerMenu["before"][data.code]["status"] && (
                                <>
                                  <NavLink to={data.link} >
                                    {data?.icon}{" "}{props.t(`${data.code}`)}
                                  </NavLink>
                                </>
                              )
                            )
                          })}
                        </div>
                      )}
                      {isDropdownOpen && v.isNested && openDrop == 'transactions' && v.code == 'transactions' && (
                        <div className="dropdown-content" ref={dropdownRef}>
                          {transListDemo7?.map((data) => {
                            return (
                              playerMenu["after"][data.code] &&
                              playerMenu["after"][data.code]["status"] && (
                                <NavLink
                                  to={data.link}
                                  className="dropdown-item"
                                  onClick={() => handleClosePopup(data.code)}
                                >
                                  {data.code == "hkb_transactions" ? (
                                    <img alt='Icon'
                                      src={data.icon}
                                      className="hkb-img"
                                    />
                                  ) : (
                                    data.icon
                                  )}
                                  {data.code == 'turnover' ?
                                    <>
                                      {props.t('turnover')}
                                    </>
                                    : data.code == 'trans_history' ?
                                      <>
                                        {props.t('history')}
                                      </>
                                      :
                                      <>
                                        {props.t(`${data.code}`)}
                                      </>
                                  }
                                </NavLink>
                              ))
                          })}
                        </div>
                      )}
                    </li>

                  </>
                }
                {
                  componentSetting?.["horizonatal-nav-bar"]?.["default-value"] == 'hover-listing' &&
                  <div className={componentSetting?.["horizonatal-nav-bar"]?.["default-value"]}>
                    <li>
                      {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon}<span>{v?.code}</span> </NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{v?.code}</span></a>}
                    </li>
                    {v.code != 'home' &&
                      <div className="dropdown-content">
                        <div className="listing-items">
                          {Object.values(providerSubMenuList).length > 0 && Object.values(providerSubMenuList).map((v) =>
                            Object.values(v).length > 0 && Object.values(v).map((obj) =>
                              (obj.menu_code == props.code && !['card_games', 'lottery', 'dingdong', 'hot_games'].includes(props.code)) &&
                              <>
                                <NavLink key={v} to={obj.menu_code + '/' + obj.tenant_provider_reco.provider_name}>
                                  <>
                                    {obj.maintainance_mode && <div className="undermaintenance">
                                      {props.t('under_maintanance')}
                                    </div>}
                                  </>
                                  <>
                                    {obj.menu_provider_media != null ? <img className="img-fluid" src={obj.ftp_path + obj.menu_provider_media.base_path + obj.menu_provider_media.system_file_name} alt={obj.tenant_provider_reco.provider_name} /> : <img className="img-fluid" src={'/assets/images/default-images/provider_' + deviceType + ".png"} alt={obj.tenant_provider_reco.provider_name} />}
                                    {
                                      obj.tenant_provider_reco.provider_name.split('').length > 15 ?
                                        <marquee><span key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</span></marquee>
                                        : <span key={obj} className="item-name">{obj.tenant_provider_reco.provider_name}</span>
                                    }
                                  </>
                                </NavLink>
                              </>
                            )
                          )}
                        </div>
                      </div>
                    }
                  </div>
                }

              </>

          })
          }
        </ul>
      </div>
      <HoverNav code={isItem} />
      {isComingSoon && <Popup handlePoup={handlePopup} comingSoon />}
    </div >
  )
}
Navbar.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(Navbar);