import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Popup from "../../../../popup/Popup";
import { makeAjax, url, respStatus, showMessage } from "../../../../../helpers/global_helper";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const HotGamesCarousel = (props) => {
  const navigate = useNavigate();

  const [gameId, setGameId] = useState('');
  const [maintainanceError, setMaintainanceError] = useState(false);
  const [commingSoonPopup, setCommingSoonPopup] = useState(false);
  const [loginforLunchLobby, setLoginforLunchLobby] = useState(false);

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,

    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    responsive: [

      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };
  let deviceType = localStorage.getItem('device_type');


  const handlePopup = () => {
    setMaintainanceError(false);
    setCommingSoonPopup(false);
    setLoginforLunchLobby(false);
  }

  // If game is on comming soon
  const commingSoon = (event) => {
    setCommingSoonPopup(true);
  }

  // If game is under maintennace
  const gameUnderMaintennace = (event) => {
    setMaintainanceError(true);
  }
  const handleGameClick = (game) => {

    if (game.maintenance) {
      gameUnderMaintennace();
      return true;

    }
    if (game.status == 'Coming Soon') {
      commingSoon();
      return true;
    }

    const payload = {
      game_id: game._id
    };
    setGameId(
      { ...gameId, game_id: game._id }
    );
    makeAjax(
      url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(response => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == "game_under_maintenance") {
            setMaintainanceError(true);
            return;
          }
          if (response.code == "game_not_active") {
            setCommingSoonPopup(true);
            return;
          }

          if (response.code == "validation.invalid_token_error") {
            navigate('/register');
            return;
          }

          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }

        //pg soft game data 7th feb 2024
        if (response?.data?.code === "bd200") {
          const newTab = window.open();
          newTab.document.write(response.data.data);
        } else {
          window.open(response.data, "_blank");
        }
      }
      )
  };

  return (
    <>
      <div className="hotgame-list">
        <Slider {...settings}>
          {props.hotGameList.map((item, index) => (
            <div key={index} className="hotgame-item">

              <Link onClick={() => handleGameClick(item)}>
                <div className="hotgame-img under-games">
                  {
                    item.maintainance_mode &&
                    <div className="undermaintenance">
                      {props.t('under_maintanance')}
                    </div>
                  }
                  <div className="item">
                    {item.game_assets_media != null ? <img src={item?.ftp_path + item?.game_assets_media?.base_path + item?.game_assets_media?.system_file_name} alt={item.name} /> : <img src={'/assets/images/default-images/game_' + deviceType + ".png"} alt="Game Icon" />}
                    <div className="hotgame-content ">
                      {
                        item.name.split('').length > 21 ?
                          <p className="game-title hovering">{item.name}</p>
                          : <p className="game-title">{item.name}</p>
                      }
                      <p className="game-subtitle">{item?.tenant_provider_reco?.provider_name}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      {maintainanceError && <Popup handlePoup={handlePopup} providerMaintainance />}
      {commingSoonPopup && <Popup handlePoup={handlePopup} commingSoonGame />}
      {loginforLunchLobby && <Popup handlePoup={handlePopup} gameLunchLoginErrorPopup />}
    </>
  );
};

HotGamesCarousel.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(HotGamesCarousel);
