import { CSS_CLASSES } from "../../css-classes";
import LotteryResults from "./lottery-results/LotteryResults";
import Search from "./search/Search";
import GameCategory from "./game-category/GameCategory";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Payment from "../commonSection/payment/Payment";
import Readmore from "../commonSection/readmore/Readmore";
import GameProvider from "./game-category/game-category-ver-menu/game-provider/GameProvider";
import NewGamesCarousel from "../newgames/NewGamesCarousel";
import HotGames from "./game-category/hot-games/HotGames";
import Balance from "../Balance/Balance";
import GifImage from "../commonSection/gif-imgae/GifImage";
const Games = (props) => {

  // Get device type
  let deviceType = localStorage.getItem("device_type");

  // Fetch other media
  const { otherMedia } = useSelector((state) => ({
    otherMedia: state.othermediaInformationListR.data,
  }));

  // Componet setting
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  const initAssetConfig = {
    width: "300px",
    height: "200px",
    alt: "defautl alt",
    description: "default description",
    image: "",
    link: window.location.origin,
  };

  const initBannerAssetConfig = {
    width: "auto",
    height: 200,
    alt: "defautl alt",
    description: "default description",
    image: "/assets/images/default-images/banner_gif_" + deviceType + ".png",
    link: window.location.origin,
  };
  const initStyle = {
    width: "",
    height: "",
    backgroundImage: "",
  };
  // Set & get state
  const [assets, setAssets] = useState(initAssetConfig);
  const [assetsBanner, setAssetsBanner] = useState(initBannerAssetConfig);
  const [style, setStyle] = useState(initStyle);

  const userToken = localStorage.getItem('user_token');

  let [finalRenderArray, setFinalRenderArray] = useState([]);

  let oldClient = [
    { key: 1, element: (<Search placeholder={props.t("find_your_games")} providerSlug={props.providerSlug} />), show: true, },
    { key: 2, element: <GameCategory providerSlug={props.providerSlug} categorySlug={props.categorySlug} />, show: true },
  ]

  let customClient = [
    { key: 3, element: <GameCategory providerSlug={props.providerSlug} categorySlug={props.categorySlug} />, show: true },
  ];

  let commonComponent = [
    { key: 7, element: <LotteryResults />, show: true },
    { key: 8, element: <GifImage />, show: true },
    // { key: 9, element: <Readmore />, show: true },
    // { key: 10, element: <Payment />, show: true },
  ]
  // Effect call for manupulate data
  useEffect(() => {
    setAssets(initAssetConfig);
    setStyle(initStyle);
    // Width
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["width"] != "undefined"
    ) {
      assets["width"] = otherMedia[deviceType + "_" + "background"]["width"];
      style["width"] =
        otherMedia[deviceType + "_" + "background"]["width"] + "px";
      setStyle({
        ...style,
      });
      setAssets({
        ...assets,
      });
    }
    // Heigth
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["height"] !=
      "undefined"
    ) {
      assets["height"] = otherMedia[deviceType + "_" + "background"]["height"];
      style["height"] =
        otherMedia[deviceType + "_" + "background"]["height"] + "px";
      setStyle({
        ...style,
      });
      setAssets({
        ...assets,
      });
    }
    // Description
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["description"] !=
      "undefined"
    ) {
      assets["description"] =
        otherMedia[deviceType + "_" + "background"]["description"];
      setAssets({
        ...assets,
      });
    }
    // alternative
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["alternate"] !=
      "undefined"
    ) {
      assets["alt"] = otherMedia[deviceType + "_" + "background"]["alternate"];
      setAssets({
        ...assets,
      });
    }
    // Image path
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["path"] != "undefined"
    ) {
      assets["image"] = otherMedia[deviceType + "_" + "background"]["path"];
      style["backgroundImage"] = `url(${otherMedia[deviceType + "_" + "background"]["path"]
        })`;
      setStyle({
        ...style,
      });
      setAssets({
        ...assets,
      });
    }
    if (
      typeof otherMedia[deviceType + "_" + "background"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "background"]["link"] != "undefined"
    ) {
      assets["link"] = otherMedia[deviceType + "_" + "background"]["link"];
      setAssets({
        ...assets,
      });
    }
    // Banner Gif
    setAssetsBanner(initBannerAssetConfig);
    // Width
    if (
      typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "banner_gif"]["width"] != "undefined"
    ) {
      assetsBanner["width"] =
        otherMedia[deviceType + "_" + "banner_gif"]["width"];
      setAssetsBanner({
        ...assetsBanner,
      });
    }
    // Heigth
    if (
      typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "banner_gif"]["height"] !=
      "undefined"
    ) {
      assetsBanner["height"] =
        otherMedia[deviceType + "_" + "banner_gif"]["height"];
      setAssetsBanner({
        ...assetsBanner,
      });
    }
    // Description
    if (
      typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "banner_gif"]["description"] !=
      "undefined"
    ) {
      assetsBanner["description"] =
        otherMedia[deviceType + "_" + "banner_gif"]["description"];
      setAssetsBanner({
        ...assetsBanner,
      });
    }
    // alternative
    if (
      typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "banner_gif"]["alternate"] !=
      "undefined"
    ) {
      assetsBanner["alt"] =
        otherMedia[deviceType + "_" + "banner_gif"]["alternate"];
      setAssetsBanner({
        ...assetsBanner,
      });
    }
    // Image path
    if (
      typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "banner_gif"]["path"] != "undefined"
    ) {
      assetsBanner["image"] =
        otherMedia[deviceType + "_" + "banner_gif"]["path"];
      setAssetsBanner({
        ...assetsBanner,
      });
    }
    if (
      typeof otherMedia[deviceType + "_" + "banner_gif"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "banner_gif"]["link"] != "undefined"
    ) {
      assetsBanner["link"] =
        otherMedia[deviceType + "_" + "banner_gif"]["link"];
      setAssetsBanner({
        ...assetsBanner,
      });
    }


  }, [otherMedia]);

  useEffect(() => {
    if (componentSetting?.custom_loading?.options?.loading_component) {
      finalRenderArray = customClient;
      if (props.providerSlug == null && props.categorySlug == null) {
        finalRenderArray = customClient.concat(commonComponent);
        finalRenderArray.push({ key: 2, element: <GameProvider providerSlug={props.providerSlug} categorySlug={props.categorySlug} />, show: true });
        finalRenderArray.push({
          key: 4, element: (<Search placeholder={props.t("find_your_games")} providerSlug={props.providerSlug} />), show: true,
        });
        if (userToken != '' && userToken != null) {
          finalRenderArray = customClient.concat(commonComponent);
          finalRenderArray.push({ key: 2, element: <GameProvider providerSlug={props.providerSlug} categorySlug={props.categorySlug} />, show: true });
          finalRenderArray.push({ key: 1, element: <Balance />, show: userToken ? true : false });
          finalRenderArray.push({
            key: 5, element: (<Search placeholder={props.t("find_your_games")} providerSlug={props.providerSlug} />), show: true,
          });
        }
        finalRenderArray.push({ key: 5, element: <HotGames />, show: true });
        finalRenderArray.push({ key: 6, element: <NewGamesCarousel />, show: true });
      }
    } else {
      finalRenderArray = oldClient.concat(commonComponent);
    }
    setFinalRenderArray(finalRenderArray);
  }, [props.providerSlug, props.categorySlug, componentSetting, userToken]);

  return (
    <div
      className={CSS_CLASSES.gamesMain}
      style={
        style["backgroundImage"] != ""
          ? {
            backgroundImage: style["backgroundImage"],
            width: style["width"],
            height: style["height"],
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }
          : {}
      }
    >
      <div>
        <div className="games-wrapper">
          {finalRenderArray
            .sort((a, b) => a.key - b.key)
            .filter((v) => v.show === true)
            .map((v, i) => (
              <React.Fragment key={i}>{v.element} </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};
Games.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(Games);
