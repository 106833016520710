import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { makeAjax, url, respStatus, showMessage } from '../../helpers/global_helper';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const PlayerReferral = (props) => {
    // Slug from param
    const { referralPlayer } = useParams();
   
    // Navigate for  redirect
    const navigate = useNavigate();

    // Check plyare and update player count
    const playerCountUpdate = () => {
        makeAjax(
            url.USER_API.referralPlayerCountUpdate,
            { username: referralPlayer },
            url.USER_MS_EXT).then(response => {
                
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                    return;
                }
                localStorage.setItem('referral_slug',referralPlayer);
                navigate('/register')
            });
    }

    // Use effect to load update count call
    useEffect(() => {
        playerCountUpdate();
    }, []);
}

PlayerReferral.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PlayerReferral);