import { useState, useEffect } from "react";
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper";
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const RegisterWithLogin = (props) => {
  const navigate = useNavigate();
  let interval = undefined;
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }else{
        props.handle();
        navigate("/");
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [seconds]);


  return (
    <div className='popup-box'>
      {props.data.username != "" &&
        <>
          <h4 className="title">{props.t('information')}</h4>
          <div className="popup-error-msg"> {props.t('successful_registration')}</div>
          <div className="popup-error-msg">{props.t('username')}: {props.data.username}</div>
          <div className="popup-error-msg">{props.t('thank_you')}</div>
          <div className="popup-error-msg">{props.t('you_will_be_logged_in')}</div>
          {seconds >= 5? <p className="popup-error-msg">{props.t('login_process')}!</p> : <p className="popup-error-msg">{seconds}</p>}
        </>
      }
    </div>
  )
}

RegisterWithLogin.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(RegisterWithLogin);