import { useSelector } from "react-redux"
import EmailVerificationInner from "../../components/authentication/emailVerification/EmailVerificationInner"
import CommonSec from "../../components/commonSection/CommonSec"
import FloatingComponents from "../../components/floatingComponents/FloatingComponents"
//import { componentSetting } from "../../template-settings"

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const EmailVerification = (props) => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    return (
        <>
            <EmailVerificationInner />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
        </>
    )
}

EmailVerification.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(EmailVerification);