import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Popup from '../../popup/Popup';
import { getPlayerProfileDetail } from '../../../store/actions';
import { checkPinStatus } from '../../../store/check-pin-status/actions';
import { useDispatch, useSelector } from 'react-redux';

const ReminderPassword = (props) => {

    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        opassword: "",
        password: "",
        password_confirmation: ""
    })
    const [formError, setFormError] = useState({});

    const [valuesOld, setValuesOld] = useState({
        password: "",
        showOldPassword: false,
    });
    const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfirm, setValuesConfirm] = useState({
        password: "",
        showConfirmPassword: false,
    });
    const location = useLocation();
    const dispatch = useDispatch();

    const { userProfile, pinStatus, redirect } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
        pinStatus: state.checkPinStatusR.data,
        redirect: state.checkPinStatusR.redirect
    }));

    const redirectMe = (pinStatus, path, component_location) => {

        if (Object.keys(pinStatus).length) {
            let config_status = pinStatus.get_tenant_config?.value;
            if (config_status === true && pinStatus.enabled_active_pin === true && pinStatus.active_pin === false) {
                navigate("/login-pin");
                return;
            } else if (config_status === true && pinStatus.enabled_active_pin === true && pinStatus.is_pin_verified === false) {
                navigate("/security-validation");
                return;
            }
            else if ((pinStatus.is_security_question_set === false || pinStatus.is_security_question_set === null)) {
                navigate("/security-question");
                return;
            }
            else if (pinStatus.is_password_active === false && component_location == path) {
                return;
            } else if (pinStatus.is_password_active === true && path == component_location) {
                navigate("/");
                return;
            } else if (!config_status) {
                navigate("/");
                return;
            }
        }
    }

    useEffect(() => {
        redirectMe(pinStatus, location.pathname, '/reminder-password')
    }, [pinStatus]);

    const handleSubmit = (e) => {
        makeAjax(
            url.USER_API.changePassword,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                setFormError({});
                setFormData({ ...formData, ...{ opassword: '', password: '', password_confirmation: '' } });
                showMessage(response);
                dispatch(checkPinStatus());
                // navigate('/');
                // navigate(0);
            });
    }

    const handleSamePassword = (e) => {
        makeAjax(
            url.USER_API.oldPassword,
            [],
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response)
                    return;
                }
                dispatch(checkPinStatus());
                // navigate('/');
                // navigate(0);
            });
    }

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
        setFormError({ ...formError, [event.target.name]: '' })
    }

    // Check player email verified

    const [enableUseOld, setEnableUseOld] = useState(false)
    const [expiredMessage, setExpiredMessage] = useState('')
    const [showExpiredMessage, setShowExpiredMessage] = useState(true)

    const checkPasswordValidity = () => {
        makeAjax(
            url.USER_API.checkIfPinAlreadySet, [], url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                setEnableUseOld(response.data.enable_use_current_password);
                setExpiredMessage(response.data.expired_message);
            });
    }

    useEffect(() => {
        checkPasswordValidity();
        dispatch(getPlayerProfileDetail());
    }, []);

    // const handleClickShowOldPassword = () => {
    //     setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
    // };
    // const handleClickShowPassword = () => {
    //     setValues({ ...values, showPassword: !values.showPassword });
    // };

    // const handleClickShowConfirmPassword = () => {
    //     setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
    // };

    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        const checkIsMobile = () => {
            const isMobile = window.innerWidth <= 768;
            setIsDesktop(!isMobile);
        };

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    const handleMouseOverOld = () => {
        if (isDesktop) {
            setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
        }
    };

    const handleClickOld = () => {
        if (!isDesktop) {
            setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
        }
    };

    const handleMouseOver = () => {
        if (isDesktop) {
            setValues({ ...values, showPassword: !values.showPassword });
        }
    };

    const handleClick = () => {
        if (!isDesktop) {
            setValues({ ...values, showPassword: !values.showPassword });
        }
    };

    const handleMouseOverConf = () => {
        if (isDesktop) {
            setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
        }
    };

    const handleClickConf = () => {
        if (!isDesktop) {
            setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
        }
    };



    return (
        <div className='new-auth login-pin reminder-password'>
            <div className="new-pass-box mt-5">
                <h4 className="title">{props.t('welcome')}, {userProfile.username}</h4>
                <div className="new-pass-box-inner">
                    <form>
                        <div className="input-control ">
                            <div className="password-icon">
                                <input type={valuesOld.showOldPassword ? "text" : "password"} placeholder={props.t('old_password')} name="opassword" onChange={handleChange} />

                                {valuesOld.showOldPassword == false ? (
                                    <AiOutlineEyeInvisible onMouseEnter={handleMouseOverOld} onClick={handleClickOld} />
                                ) :
                                    <AiOutlineEye onMouseLeave={handleMouseOverOld} onClick={handleClickOld} />
                                }

                            </div>
                            {formError.opassword ? (
                                <span className="text-danger d-flex remi-span">{formError.opassword[0]}</span>
                            ) : null}
                        </div>
                        <div className="input-control ">
                            <div className="password-icon">
                                <input type={values.showPassword ? "text" : "password"} placeholder={props.t('new_password')} name="password" onChange={handleChange} />
                                {values.showPassword == false ? (
                                    <AiOutlineEyeInvisible onMouseEnter={handleMouseOver} onClick={handleClick} />
                                ) :
                                    <AiOutlineEye onMouseLeave={handleMouseOver} onClick={handleClick} />
                                }
                            </div>
                            {formError.password ? (
                                <span className="text-danger d-flex remi-span">{formError.password[0]}</span>
                            ) : null}
                        </div>
                        <div className="input-control ">
                            <div className="password-icon">
                                <input type={valuesConfirm.showConfirmPassword ? "text" : "password"} placeholder={props.t('confirm_new_password')} name="password_confirmation" onChange={handleChange} />
                                {valuesConfirm.showConfirmPassword == false ? (
                                    <AiOutlineEyeInvisible onMouseEnter={handleMouseOverConf} onClick={handleClickConf} />
                                ) :
                                    <AiOutlineEye onMouseLeave={handleMouseOverConf} onClick={handleClickConf} />
                                }
                            </div>
                            {formError.password_confirmation ? (
                                <span className="text-danger d-flex remi-span">{formError.password_confirmation[0]}</span>
                            ) : null}
                        </div>
                        <div className='re-password'>
                            {enableUseOld === true ? (
                                <button type='button' className='keep-btn' onClick={handleSamePassword}>{props.t('keep_using_old_password')}</button>
                            ) : ""}&nbsp;
                            <button type='button' className='proc-btn' onClick={handleSubmit}>{props.t('process')}</button>
                        </div>
                    </form>
                </div>
            </div>
            {!enableUseOld && showExpiredMessage && <Popup handlePoup={() => { setShowExpiredMessage(false) }} data={expiredMessage} forgotPass />}
        </div>
    )
}

ReminderPassword.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReminderPassword);