import {
  LANGUAGE_DD,
  LANGUAGE_DD_SUCCESS,
} from "./actionTypes"

export const languageDD = () => {
  return {
    type: LANGUAGE_DD,
    payload: {  },
  }
}

export const languageDDSuccess = (response) => {
  return {
    type: LANGUAGE_DD_SUCCESS,
    payload: response,
  }
}
