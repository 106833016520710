export const CSS_CLASSES = {
    menuItemsHover: 'menu-items-hover',
    userLoginBox: 'user-login',
    navbar: 'navbar-main',
    navbarVer: 'navbar-vertical',
    sliderMainDiv: 'slider-main-div',
    logo: 'logo',
    hoverMenu: 'hover-menu',
    news: 'news',
    searchBar: 'search-bar',
    gamesVerticalMenu: 'games-vertical-menu',
    lottery: 'lottery',
    jackpot: 'jackpot',
    lotteryJackpotGif: 'lottery-jackpot-gif',
    readMore: 'read-more',
    paymentMethod: 'payment-method',
    partnersImg : 'partners-img',
    gamesMain: 'games-main',
    gamesFilter: 'games-filter',
    commonSec: 'common-sec',
    alternativeLinks: 'alternative-links',
    scrollTop:'scroll-top',
    lotteryFullBoxSlider: 'lottery-full-box-slider',
    paymentMethodSlider:'payment-method-slider',
    gameCategoryGrid: 'game-category-grid'
}