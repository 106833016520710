import React from 'react'
import { makeAjax, url, respStatus, showMessage } from "../helpers/global_helper"
import { useEffect } from 'react';
const ChatConfig = () => {
    const extractScriptAttributes = (scriptString) => {
        const srcRegex = /src=["'](.*?)["']/;
        const idRegex = /id=["'](.*?)["']/;
        const srcMatch = scriptString.match(srcRegex);
        const idMatch = scriptString.match(idRegex);
        const src = srcMatch ? srcMatch[1] : null;
        const id = idMatch ? idMatch[1] : null;
        if (src == null && id == null) {
            let doc = new DOMParser().parseFromString(scriptString, "text/xml");
            let src = doc.firstChild.textContent;
            return { src, id };
        }
        return { src, id };
    };

    const getLiveChatScript = () => {
        return new Promise((resolve, reject) => {
            makeAjax(url.MASTER_API.getLiveChatConfig, [], url.MASTER_MS_EXT)
                .then(response => {
                    if (response.status !== respStatus['SUCCESS']) {
                        if (response.code !== 'validation_error') {
                            showMessage(response);
                            reject(response);
                            return;
                        }
                        showMessage(response);
                        reject(response);
                        return;
                    }
                    if (response.data && response.data.script) {
                        const { src, id } = extractScriptAttributes(response.data.script);
                        resolve({ scriptSrc: src, scriptId: id });
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    useEffect(() => {
        let scriptElement;
        getLiveChatScript()
            .then(scriptAttributes => {
                const { scriptSrc, scriptId } = scriptAttributes;
                let element = document.getElementById("title");
                scriptElement = document.createElement("script");
                if (scriptId == null) {
                    scriptElement.innerHTML = scriptSrc;
                } else {
                    scriptElement.src = scriptSrc;
                    scriptElement.id = scriptId;
                }
                element.before(scriptElement);
            })
            .catch(error => {
                console.error(error);
            });
        return () => {

            if (scriptElement) {
                scriptElement.remove();
            }
        };

    }, []);
}

export default ChatConfig
