
import {
  MdArrowBack,
  MdOutlineVideogameAsset,
  MdSportsSoccer,
  MdPeopleAlt,
} from "react-icons/md";
import { MdRefresh, MdOutlineFactCheck } from "react-icons/md";
import { RiInformationLine } from "react-icons/ri";
import { BiTimeFive, BiDice5, BiCategory } from "react-icons/bi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LuTimerReset } from "react-icons/lu";
import {
  LiaMoneyCheckAltSolid,
  LiaCoinsSolid,
} from "react-icons/lia";
import { TbCards, TbCrystalBall } from "react-icons/tb";
import { GiEightBall } from "react-icons/gi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { useEffect, useState, useRef } from "react";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";
import { PiUsersThreeLight, PiCoins } from "react-icons/pi";
import Popup from "../../../components/popup/Popup";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
} from "../../../helpers/global_helper";
import { IoIosArrowDown } from "react-icons/io";
import {
  BsBarChartLineFill,
  BsGlobe,
  BsPen, BsBarChartLine
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// import { getLogout, resetPlayerProfile } from "../../../store/actions";
// import { resetPinStatus } from "../../../store/check-pin-status/actions";
import LanguageDropdown from "../../../components/languageDropdown/LanguageDropdown";
import DepositWithdrawMessage from "../../../components/popup/popup-components/DepositWithdrawMessage";

const SideBar = (props) => {
  const [subMenu, setSubMenu] = useState(false);
  const [subMenu2, setSubMenu2] = useState(false);
  const [subMenu3, setSubMenu3] = useState(false);
  const [subMenu4, setSubMenu4] = useState(false);

  const [unreadMessage, setUnreadMessageCount] = useState(false);
  const [playerBalance, setPlayerBalance] = useState([]);
  const [dwMessageId, setDwMessageId] = useState(null);
  const [referralPlayerCount, setReferralPlayerCount] = useState(0);

  const [
    depositWithdrawNotificationClosed,
    setDepositWithdrawNotificationClosed,
  ] = useState(false);
  const [depositWithdrawMessage, setDepositWithdrawMessage] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [loggedIn, setLoggedIn] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfile, playerMenu } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
    playerMenu: state.menuListR.data,
  }));

  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));

  const handleClose = () => {
    props.sideBarClose();
    // setOpenDrop(!openDrop);
    setOpenMenu([]);
  };

  const loginPopup = () => {
    props.loginPopupTrigger();
    props.sideBarClose();
  };

  let token = window.localStorage.getItem("user_token");

  useEffect(() => {
    setLoggedIn(token);
  }, [token]);
  const handleOutsideClick = (e) => {
    if (props.sideRef.current && !props.sideRef.current.contains(e.target)) {
      // setOpenDrop(!openDrop);
      setOpenMenu([]);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  let hoverGamesCategory = [
    {
      code: "slots",
      menu: "Slots",
      icon: <LiaMoneyCheckAltSolid />,
      link: "/slots",
    },
    {
      code: "arcades",
      menu: "Arcades",
      icon: <MdOutlineVideogameAsset />,
      link: "/arcades",
    },
    {
      code: "jackpot_bar",
      menu: "Jackpot Bar",
      icon: <LiaCoinsSolid />,
      link: "/jackpot_bar",
    },
    {
      code: "live_casino",
      menu: "Casino",
      icon: <BiDice5 />,
      link: "/live_casino",
    },
    {
      code: "sportsbook",
      menu: "Sports",
      icon: <MdSportsSoccer />,
      link: "/sportsbook",
    },
    {
      code: "card_games",
      menu: "Card Games",
      icon: <TbCards />,
      link: "/card_games",
    },
    {
      code: "lottery",
      menu: "Lottery",
      icon: <GiEightBall />,
      link: "/lottery",
    },
    {
      code: "dingdong",
      menu: "Ding Dong",
      icon: <TbCrystalBall />,
      link: "/dingdong",
    },
  ];

  let hoverTransactions = [
    {
      code: "trans_history",
      menu: "history",
      icon: <LuTimerReset />,
      link: "/trans-history",
    },
    {
      code: "turnover",
      menu: "Turnover",
      icon: <BsBarChartLine />,
      link: "/turnover",
    },
    {
      code: "outstanding_bet",
      menu: "Outstanding Bet",
      icon: <BiTimeFive />,
      link: "/outstanding-bet",
    },
    {
      code: "hkb_transactions",
      menu: "HKB Transactions",
      icon: "../assets/images/HKB-Gaming.png",
      link: "",
    },
  ];

  let hoverMainMenu = [
    {
      code: "all_games",
      menu: "All Games",
      icon: <IoExtensionPuzzleOutline />,
      link: "/all-games",
      isNested: false,
    },
    {
      code: "categories",
      menu: "Categories",
      icon: <BiCategory />,
      link: "",
      isNested: true,
    },
    {
      code: "transactions",
      menu: "Transactions",
      icon: <MdOutlineFactCheck />,
      link: "",
      isNested: true,
    },
    {
      code: "referral",
      menu: "Referrals",
      icon: <PiUsersThreeLight />,
      link: "/referrals",
      isNested: false,
    },
    {
      code: "blogs",
      menu: "Blogs",
      icon: <BsPen />,
      link: "/blogs",
      isNested: false,
    },
    {
      code: "about_us",
      menu: "About Us",
      icon: <AiOutlineInfoCircle />,
      link: "/about_us",
      isNested: false,
    },
    {
      code: "terms_and_conditions",
      menu: "Terms And conditions",
      icon: <HiOutlineNewspaper />,
      link: "/terms_and_conditions",
      isNested: false,
    },
    {
      code: "language",
      menu: "Language",
      icon: <BsGlobe />,
      link: "",
      isNested: true,
    },
  ];


  const playerUnreadMessage = (event) => {
    if (!userProfile.username) return;
    makeAjax(
      url.MEMO_API.getPlayerUnreadMessageCount,
      [],
      url.MEMO_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        return;
      }
      setUnreadMessageCount(response.data);
    });
  };

  const depositWithdrawNotificationMessage = () => {
    makeAjax(
      url.DPWD_API.depositWithdrawNotificationMessage,
      [],
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setDepositWithdrawMessage(response.data);
      if (response.data.length > 0) {
        setDwMessageId(response.data[0]._id);
        setDepositWithdrawNotificationClosed(true);
      }
    });
  };

  useEffect(() => {
    if (loggedIn) {
      playerUnreadMessage();
      fetchPlayerBalance();
      depositWithdrawNotificationMessage();
      const interval1 = setInterval(() => playerUnreadMessage(), 60000);
      const interval = setInterval(
        () => depositWithdrawNotificationMessage(),
        60000
      );
      return () => {
        clearInterval(interval1);
        clearInterval(interval);
      };
    }
  }, [userProfile]);

  const handleRefreshBalance = () => {
    makeAjax(url.GP_API.getBalance, "", url.GP_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
    });
    fetchPlayerBalance();
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 1000);
  };

  const handleNotificationPopup = () => {
    messageRead();
    setDepositWithdrawNotificationClosed(false);
  };
  const messageRead = () => {
    if (messageRead != null) {
      let payload = {
        _id: dwMessageId,
      };
      makeAjax(
        url.DPWD_API.depositWithdrawReadMessage,
        payload,
        url.DPWD_MS_EXT
      ).then((response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        fetchPlayerBalance();
      });
    }
  };

  const fetchPlayerBalance = () => {
    makeAjax(url.GP_API.getBalance, [], url.GP_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setPlayerBalance(response.data);
    });
  };

  // Get referral list
  const getReferralPlayerCount = () => {
    makeAjax(url.USER_API.listReferralCount, [], url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setReferralPlayerCount(response.data);
      }
    );
  };

  // Effect List
  useEffect(() => {
    if (loggedIn != "" && loggedIn != null) {
      fetchPlayerBalance();
      getReferralPlayerCount();
    }
  }, []);

  const [promotionCategory, setPromotionCategory] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  const getPromotionCategory = () => {
    makeAjax(url.ADMIN_API.promotionCategoryNameDD, [], url.ADMIN_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setPromotionCategory(response.data);
      }
    );
  };

  const handleIconClick = () => {
    makeAjax(url.ADMIN_API.promotionCategoryNameDD, [], url.ADMIN_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          // showMessage(response);
          return;
        }
        setPromotionCategory(response.data);
      }
    );
    setIsClicked(!isClicked);
  };

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, so close it
      setIsClicked(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const pushMenu = (menuName) => {
    const selectedMenu = [...openMenu];

    // Check if menuName is already in openMenu
    const index = selectedMenu.indexOf(menuName);

    if (index === -1) {
      // If not present, add it
      selectedMenu.push(menuName);
    } else {
      // If already present, remove it
      selectedMenu.splice(index, 1);
    }

    setOpenMenu(selectedMenu);
  }

  const [isComingSoon, setComingSoon] = useState(false);

  const handlePopup = () => {
    setComingSoon(!isComingSoon);
  };


  return (
    <>
      <div
        className={`side-bar ${props.sideBar ? "show" : ""}`}
        ref={props.sideRef}
      >
        <div className="login-btns">
          <div className="close" onClick={handleClose}>
            <MdArrowBack />
          </div>
          <div className="user-btns">
            {loggedIn ? (
              <div className="menu-title"></div>
            ) : (
              <>
                {typeof componentSetting?.["top-nav-login-box"] !=
                  "undefined" &&
                  componentSetting?.["top-nav-login-box"]?.[
                  "default-value"
                  ] && (
                    <div className="signup-btns">
                      <button className="login" onClick={loginPopup}>
                        {props.t("login")}
                      </button>
                      {playerMenu.before &&
                        playerMenu.before.register &&
                        playerMenu.before.register.status && (
                          <NavLink
                            to="register"
                            className="register"
                            onClick={handleClose}
                          >
                            {props.t(`${playerMenu.before.register.code}`)}
                          </NavLink>
                        )}
                    </div>
                  )}
              </>
            )}
            {loggedIn && (
              <div className="postlogin-btns">
                {playerMenu["after"]['withdraw']["status"] &&
                  <Link to="/withdraw" className="with-btn" onClick={handleClose}>
                    {props.t("withdraw")}
                  </Link>
                }
                {playerMenu["after"]['deposit']["status"] &&
                  <Link className="depo-btn" to="/deposit " onClick={handleClose}>
                    {props.t("deposit")}
                  </Link>
                }
              </div>
            )}
            {loggedIn && (
              <div className="user-details">
                <div className="user-icon">
                  <span className="usr-profle">
                    {userProfile.username
                      ? userProfile.username[0].toUpperCase()
                      : "U"}
                  </span>
                </div>
                <div className="idr-info">
                  <span className="idr-num idr">
                    {userProfile ? userProfile.currency : ""}{" "}
                    <>{currencyFormat(playerBalance?.main_balance)}</>
                  </span>

                  <div className="d-flex balance-chip">
                    <div className="chip-img">
                      <PiCoins className="coins" />
                    </div>
                    <div className="chip-amount">
                      {currencyFormat(playerBalance?.promo_balance)}
                    </div>
                    <div className="iconhoverdiv" ref={dropdownRef}>
                      <div className="icon d-flex" onClick={handleIconClick} >
                        <RiInformationLine className="infohover-icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="side-refresh">
                  <a className="spin-iconbtn">
                    <MdRefresh
                      onClick={() => {
                        handleRefreshBalance();
                      }}
                      className={`spin-button ${spinning ? "spin" : ""}`}
                    />
                  </a>{" "}
                </div>
              </div>
            )}
          </div>
        </div>

        {isClicked && (
          <div className="click-content">
            <p>{props.t("following_categories_are_applicable_for_promos")}</p>
            <ul>
              {promotionCategory &&
                promotionCategory !== null &&
                promotionCategory.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
          </div>
        )}

        <div className="menu-links-mobile">
          <div className="item">
            {hoverMainMenu.map((v, i) => {
              return ((!loggedIn && playerMenu['before'][v.code] && playerMenu['before'][v.code]['status'])
                || (loggedIn && playerMenu["after"][v.code] && playerMenu["after"][v.code]["status"])
                || (loggedIn && v.code == 'referral' && playerMenu["after"]?.['my_referral'] && playerMenu["after"]?.['my_referral']['status'])
                || v.code == 'language'
              ) &&
                <>
                  {v.isNested ? (
                    <>
                      {v.code == "transactions" && !loggedIn ? (
                        ""
                      ) : (
                        <a
                          className={`dropdown-item fade-in ${openMenu.includes(v.code) ? "active" : ""
                            }`}
                          onClick={() => {
                            pushMenu(v.code);
                            // if (openDrop !== "") {
                            //   setOpenDrop("");
                            // } else {
                            //   setOpenDrop(v.code);
                            // }
                          }}
                        >
                          {v.icon} {props.t(`${v.code}`)}
                          {"  "}
                          <IoIosArrowDown className="down-arrow" />
                          {openMenu.includes(v.code) && (
                            <div className="sub-menu">
                              {v.code == "transactions" &&
                                hoverTransactions.map((v, i) => {
                                  return (
                                    playerMenu["after"][v.code] &&
                                    playerMenu["after"][v.code]["status"] && (
                                      <>
                                        <NavLink
                                          to={v.link}
                                          className="dropdown-item"
                                          onClick={() => {
                                            handleClose();
                                            if (v.code === 'hkb_transactions') {
                                              handlePopup();
                                            }
                                          }}
                                        >
                                          {v.code == "hkb_transactions" ? (
                                            <img alt="Icon"
                                              src={v.icon}
                                              className="hkb-img"
                                            />
                                          ) : (
                                            v.icon
                                          )}
                                          {v.code == 'turnover' ?
                                            <>
                                              {props.t('turnover')}
                                            </>
                                            : v.code == 'trans_history' ?
                                              <>
                                                {props.t('history')}
                                              </>
                                              :
                                              <>
                                                {props.t(`${v.code}`)}
                                              </>
                                          }
                                        </NavLink>
                                      </>
                                    ));
                                })}

                              {v.code == "categories" &&
                                hoverGamesCategory.map((data, i) => {
                                  return (
                                    playerMenu["before"][data.code] &&
                                    playerMenu["before"][data.code]["status"] && (
                                      <NavLink
                                        to={data.link}
                                        className="dropdown-item"
                                        onClick={handleClose}
                                      >
                                        {data.icon} {props.t(`${data.code}`)}
                                      </NavLink>
                                    )
                                  );
                                })}

                              {v.code == "language" && (
                                <LanguageDropdown view="mobile" />
                              )}
                            </div>
                          )}
                        </a>
                      )}
                    </>
                  ) : (
                    <NavLink
                      to={v.link}
                      className="dropdown-item"
                      onClick={handleClose}
                    >
                      {v.icon} {props.t(`${v.code}`)}
                    </NavLink>
                  )}
                </>

            })}

          </div>
        </div>
      </div>
      {isComingSoon && <Popup handlePoup={handlePopup} comingSoon />}
      <div className={`overlay ${props.sideBar ? "show" : ""}`}></div>
    </>
  );
};

SideBar.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(SideBar);
