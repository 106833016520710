import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ReferralInner = (props) => {
  return (
    <div>{props.t('referral_inner')}</div>
   
  );
};

ReferralInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReferralInner);
