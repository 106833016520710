import { useState, useEffect } from "react"
import { makeAjax, url, respStatus, showMessage } from "../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const TermsCondition = (props) => {

    const [textData, setTextData] = useState({});

    const getRules = () => {
        makeAjax(
            url.ADMIN_API.getRule, { 'code': 'rule' }, url.ADMIN_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                if (typeof response.data != null && response.data.value != '') {
                    setTextData(response.data);
                }
            });
    }

    useEffect(() => {
        getRules();
    }, []);

    return (
        <div className='term-condition'>
            <div className="container">
                <h4 className="title">{props.t('terms_and_conditions')}</h4>
                <div className="term-condition-text">
                    <p className="desc"><div dangerouslySetInnerHTML={{ __html: textData.value }}></div></p>
                </div>
            </div>
        </div>
    )
}

TermsCondition.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(TermsCondition);
