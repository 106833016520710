import { call, put, all, takeEvery } from "redux-saga/effects";
import {
  FETCH_USER_VALIDATION
} from "./actionTypes"
import {
  checkPinStatusSuccess
} from "./actions";

import { makeAjax, url, showMessage } from "../../helpers/global_helper";
import { USER_MS_EXT } from "../../helpers/url_helper";

function* checkPinStatus() {
  try {
    
    const response = yield call(
      makeAjax,
      url.USER_API.checkIfPinAlreadySet,
      {},
      USER_MS_EXT
    );
    if (response.status == 'SUCCESS') {
      let resp = response.data;
      yield all([
        put(checkPinStatusSuccess({ response: resp }))
      ]);
    }
    else {
      showMessage(response);
    }
  } catch (error) {
    showMessage(error);
    
    // alert
  }
}

function* checkPinStatusSaga() {
  yield takeEvery(FETCH_USER_VALIDATION, checkPinStatus);
}

export default checkPinStatusSaga;
