import { combineReducers } from "redux"

import menuListR from "./menu-list/reducer";
import componentSettingListR from "./component-setting/reducer";
import metaInformationListR from "./meta-information/reducer";
import textInformationListR from "./text-information/reducer";
import logoutR from "./logout/reducer";
import footerInformationListR from "./footer-infomation/reducer";
import submenuListR from "./submenu-list/reducer";
import playerProfileR from "./player-profile/reducer";
import referalInformationListR from "./referal-device/reducer";
import othermediaInformationListR from "./other-media/reducer";
import cssInformationListR from "./template-css/reducer";
import checkPinStatusR from "./check-pin-status/reducer";
import dropdownR from "./dropdown/reducer";
import myLangR from "./my-lang/reducer";
import getMemoCountR from "./memo-unread-count/reducer";
import getPlayerBalanceR from "./player-balance/reducer";
import DepositPendingRequestR from "./deposit-pending/reducer";
const rootReducer = combineReducers({
  menuListR,
  componentSettingListR,
  metaInformationListR,
  textInformationListR,
  logoutR,
  footerInformationListR,
  submenuListR,
  playerProfileR,
  referalInformationListR,
  othermediaInformationListR,
  cssInformationListR,
  checkPinStatusR,
  dropdownR,
  myLangR,
  getMemoCountR,
  getPlayerBalanceR,
  DepositPendingRequestR
})

export default rootReducer
