import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper"
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const RulesOfPlayInner = (props) => {
    const navigate = useNavigate()

    const [textData, setTextData] = useState({});

    const getRules = () => {
        makeAjax(
            url.ADMIN_API.getRule, { 'code': 'rule' }, url.ADMIN_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                if (typeof response.data != null && response.data.value != '') {
                    setTextData(response.data);
                }
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        navigate("/security-validation");
        return;
    }

    useEffect(() => {
        getRules();
    }, []);

    return (
        <div className='new-auth security-validation rules'>
            <div className="new-pass-box">
                <h4 className="title">{props.t('rules_of_play')}</h4>
                <div className="new-pass-box-inner">
                    <p className="desc"><div dangerouslySetInnerHTML={{ __html: textData.value }}></div></p>

                    <form onSubmit={handleSubmit} >
                        <button>{props.t('agree')}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

RulesOfPlayInner.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(RulesOfPlayInner);