import { useEffect, useState } from "react";
import Slider from "react-slick";
import { FiRefreshCcw } from "react-icons/fi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { CSS_CLASSES } from "../../../../css-classes";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const LotteryGrid = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          infinite: false,
          swipeToSlide: true,
        },
      },
    ],
  };

  const [spinning, setSpinning] = useState(false);
  const [infinite, setInfinite] = useState(false);
  const [gameId, setGameId] = useState("");
  const [countdownTimeRemaining, setCountdownTimeRemaining] = useState([]);
  const navigate = useNavigate();

  const handleRefreshBalance = () => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 1000);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedCountdowns = props.lotteryResult.map((v) => {
        if (
          v.opening_time &&
          v.opening_time != null &&
          v.opening_time != undefined
        ) {
          const now = new Date();
          const openingTimeParts = v.opening_time.split(":");
          const closingTimeParts = v.closing_time.split(":");
          const openingTime = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            parseInt(openingTimeParts[0], 10),
            parseInt(openingTimeParts[1], 10)
          );
          const closingTime = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            parseInt(closingTimeParts[0], 10),
            parseInt(closingTimeParts[1], 10)
          );

          const currentTime = new Date();
          currentTime.setMilliseconds(0); // Ensure milliseconds are set to 0 for accurate comparison

          let openingTimeRemaining = openingTime - currentTime;
          let closingTimeRemaining = closingTime - currentTime;

          if (closingTime < openingTime) {
            closingTime.setDate(closingTime.getDate() + 1);
          }

          closingTimeRemaining = closingTime - currentTime;

          const difference = currentTime - openingTime;
          const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
          const remainingTimeAfterSubtraction =
            twentyFourHoursInMilliseconds - difference;

          if (openingTimeRemaining > 0) {
            const hours = Math.floor(openingTimeRemaining / (1000 * 60 * 60));
            const minutes = Math.floor(
              (openingTimeRemaining % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor(
              (openingTimeRemaining % (1000 * 60)) / 1000
            );

            return { hours, minutes, seconds };
          } else if (openingTimeRemaining < 0 && closingTimeRemaining > 0) {
            return { hours: 0, minutes: 0, seconds: 0 };
          }

          if (openingTimeRemaining < 0 && closingTimeRemaining < 0) {
            const hours = Math.floor(
              remainingTimeAfterSubtraction / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
              (remainingTimeAfterSubtraction % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor(
              (remainingTimeAfterSubtraction % (1000 * 60)) / 1000
            );

            return { hours, minutes, seconds };
          }
        } else {
          return { hours: 0, minutes: 0, seconds: 0 };
        }
      });

      setCountdownTimeRemaining(updatedCountdowns);
    }, 0);

    return () => clearInterval(timer);
  }, [props.lotteryResult]);

  const callCompleteResult = (game) => {
    const code = game.code;
    navigate(`/result/${code}`);
    return;
  };

  return (
    <>
      <div className="lottery-result-grid-wrapper">
        <div className="container">
          <div className={CSS_CLASSES.lotteryFullBoxSlider}>
            <h2 className="title">{props.t("lottery_results")}</h2>
            <div className="bgblack">
              <div className="lottery-slider">
                {props.lotteryResult &&
                  props.lotteryResult != undefined &&
                  props.lotteryResult.length > 0 &&
                  props.lotteryResult &&
                  props.lotteryResult.map((v, i) => (
                    <div className="item" key={i}>
                      <div className="country">
                        <img
                          onClick={() => {
                            callCompleteResult(v);
                          }}
                          src={`../assets/images/lottery-country/${v.lottery_name.toLowerCase()}.png`}
                          alt="country"
                          className="img-fluid"
                          style={{ cursor: "pointer" }}
                        />
                        <div className="details">
                          <h6
                            onClick={() => {
                              callCompleteResult(v);
                            }}
                            className="name"
                            style={{ cursor: "pointer" }}
                          >
                            {v.lottery_name}
                          </h6>
                          <span
                            onClick={() => {
                              callCompleteResult(v);
                            }}
                            className="date"
                            style={{ cursor: "pointer" }}
                          >
                            {v.date + "     " + "|"} {v.period}
                          </span>
                          <br></br>
                        </div>
                      </div>
                      <div className="num-div">
                        {v.lottery_number.map((n, i) => (
                          <span
                            onClick={() => {
                              callCompleteResult(v);
                            }}
                            className="num"
                            key={i}
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              onClick={() => {
                                callCompleteResult(v);
                              }}
                              className="num-color"
                              style={{ cursor: "pointer" }}
                            >
                              {n}
                            </span>
                          </span>
                        ))}
                      </div>
                      <span className="timezone">
                        {" "}
                        {props.t("opening_hours")} : {v.opening_time}
                      </span>
                      <div className="countdown">
                        <div className="number">
                          <span className="hours time">
                            {countdownTimeRemaining[i]?.hours
                              .toString()
                              .padStart(2, "0")}
                          </span>
                          <span className="text">{props.t("hrs")}</span>
                        </div>

                        <div className="number">
                          <span className="minutes time">
                            {countdownTimeRemaining[i]?.minutes
                              .toString()
                              .padStart(2, "0")}
                          </span>
                          <span className="text">{props.t("min")}</span>
                        </div>

                        <div className="number">
                          <span className="seconds time">
                            {countdownTimeRemaining[i]?.seconds
                              .toString()
                              .padStart(2, "0")}
                          </span>
                          <span className="text">{props.t("sec")}</span>
                        </div>
                      </div>
                      <div className="result-history-btn">
                        <button
                          className="lottery-result-btn"
                          onClick={() => {
                            callCompleteResult(v);
                          }}
                          key={i}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          {props.t("result_history")}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <a onClick={props.getLotteryResult} className={`refresh`}>
              <FiRefreshCcw
                className={props.spinning ? "spin" : ""}
                style={{ cursor: "pointer" }}
              />{" "}
              {props.t("refresh")}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

LotteryGrid.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(LotteryGrid);
