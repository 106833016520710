import { useSelector } from 'react-redux'
import CommonSec from '../components/commonSection/CommonSec'
import FloatingComponents from '../components/floatingComponents/FloatingComponents'
import SliderSec from '../components/slider/SliderSec'
import Games from '../components/games/Games'
const GamesList = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    // Get provider slug
    const categoryUrl = window.location.pathname.split('/');
    const arr = categoryUrl.length > 0 ? categoryUrl.filter(e => String(e).trim()) : [];
    return (
        <>
            {!componentSetting?.custom_loading?.options?.slider_handle && typeof componentSetting.slider != 'undefined' && componentSetting?.["slider"]?.["default-value"] === 'slider' && <SliderSec />}
            <Games categorySlug={arr.length > 0 ? arr[0] : null} providerSlug={arr.length > 1 ? arr[1] : null} />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === 'right_vertical' && <FloatingComponents />}
        </>
    )
}

export default GamesList