import React, { useState, useEffect } from "react";
import { MdRefresh } from "react-icons/md";
import Select from "react-select";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ActionLoader from "../../../pages/ActionLoader";
const CustomerComplaints = (props) => {
  const [captcha, setCaptcha] = useState([]);
  const [formError, setFormError] = useState({});
  const [consumerCategory, setConsumerCategory] = useState([]);
  const [loaderStatus, setLoader] = useState(false);

  // =============== form ============
  const IniFormdata = {
    username: "",
    email: "",
    consumer_category_id: "",
    description: "",
    captcha: "",
  };
  const [formDataComplaint, setFormDataComplaint] = useState(IniFormdata);

  const handleChange = (event) => {
    if (event.target.name == "username") {
      formError.username = {};
      setFormError({
        ...formError,
      });
    }

    if (event.target.name == "email") {
      formError.email = {};
      setFormError({
        ...formError,
      });
    }

    if (event.target.name == "description") {
      formError.description = {};
      setFormError({
        ...formError,
      });
    }

    if (event.target.name == "captcha") {
      formError.captcha = {};
      setFormError({
        ...formError,
      });
    }
    setFormDataComplaint({
      ...formDataComplaint,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    formDataComplaint["key"] = !captcha.key ? "" : captcha.key;
    makeAjax(
      url.MEMO_API.addConsumerCompaint,
      formDataComplaint,
      url.MEMO_MS_EXT
    ).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        setLoader(false);
        getCaptcha();
        setFormError(response.messages);

        return;
      }
      setLoader(false);
      setFormError({});
      setFormDataComplaint(IniFormdata);
      getCaptcha();
      props.handle();
      showMessage(response);
    });
  };
  // end
  // Form Error

  // ==========================  Category api implemenation ================
  // Refresh dd
  const emptyCategoryDrop = {
    _id: null,
    name: null,
  };
  const [refreshCategory, setRefreshCategory] = useState(null);

  const onChangeCategory = (event) => {
    formError.consumer_category_id = {};
    setFormError({
      ...formError,
    });

    setRefreshCategory(null);
    setFormError({ ...formError, ["consumer_category_id"]: "" });
    if (!event) {
      setFormDataComplaint({
        ...formDataComplaint,
        ["consumer_category_id"]: event.target.value,
      });
    } else {
      let t = emptyCategoryDrop;
      t._id = event._id;
      t.name = event.name;
      setRefreshCategory(t);
      formDataComplaint.consumer_category_id = event._id;
      setFormDataComplaint({ ...formDataComplaint });

    }
  };
  // end

  const listConsumerCategory = () => {
    makeAjax(url.MEMO_API.listConsumerCategory, [], url.MEMO_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setConsumerCategory(response.data.data.data);
      }
    );
  };
  // end
  //  getConsumerComplain
  const [consumerComplaint, setConsumerComplaint] = useState([]);

  const getConsumerComplaint = () => {
    makeAjax(url.MASTER_API.getConsumerComplain, [], url.MASTER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setConsumerComplaint(response.data);

      }
    );
  };
  // Load captcha
  const getCaptcha = (event) => {
    formError.captcha = {};
    setFormError({
      ...formError,
    });

    makeAjax(url.MEMO_API.loadCaptcha, [], url.MEMO_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setCaptcha(response.data);
    });
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 2000);
  };

  useEffect(() => {
    listConsumerCategory();
    getCaptcha();
    getConsumerComplaint();
    props.customerComplaintsClass();
  }, []);

  const [spinning, setSpinning] = useState(false);

  const styles = `
    .customer-complaints .title {
      padding: 5px;
     }
    
      textarea {
        min-height: 90px;
      }
     
      .popup-modal .modal-cus{
        margin-top:50px;
      }  
      `;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "37px",
      height: "37px",
      boxShadow: state.isFocused ? null : null,
      cursor: "pointer",
      fontSize: "14px",

      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
        minHeight: "32px",
        height: "32px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
      backgroundColor: state.isSelected ? "transparent" : "transparent", // Change background color for selected option
      color: state.isSelected ? "#D47DFF" : "#BAAEC1",
      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#D47DFF" : "#D47DFF", // Change text color for selected value
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    menu: (provided) => ({
      ...provided,
      // For WebKit browsers (Chrome, Safari)
      "&::-webkit-scrollbar": {
        width: "3px",
        height: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#3498db", // Set the color of the scrollbar thumb
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
      },
      // For Firefox
      scrollbarColor: " #201E21",
      scrollbarWidth: "3px",
      scrollbarheight: "5px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  return (
    <>
      <style>{styles}</style>
      <div className="customer-complaints">
        <h4 className="customer-com-name">{consumerComplaint.name}</h4>
        <div className="customer-com-bg">
          <div dangerouslySetInnerHTML={{ __html: consumerComplaint.value }} />
          <span>
            {" "}
            {props.t("website")}: {consumerComplaint.domain_name}{" "}
          </span>
          <form autoComplete="off">
            <div className="input-control">
              <label>
                {props.t("username")}: ({props.t("registered_on_this_site")})
              </label>
              <input
                type="text"
                placeholder={props.t("username")}
                value={formDataComplaint.username}
                name="username"
                onChange={handleChange}
              />
              {formError.username ? (
                <span
                  style={{ color: "white" }}
                  className="text-danger error-msg"
                >
                  {formError.username[0]}
                </span>
              ) : null}
            </div>
            <div className="input-control">
              <label>{props.t("email")} </label>:
              <input
                type="email"
                placeholder={props.t("email")}
                name="email"
                value={formDataComplaint.email}
                onChange={handleChange}
              />
              {formError.email ? (
                <span
                  style={{ color: "white" }}
                  className="text-danger error-msg"
                >
                  {formError.email[0]}
                </span>
              ) : null}
            </div>
            <div className="input-control">
              <label>{props.t("category")}</label>:
              <Select
                name="consumer_category_id"
                value={refreshCategory}
                onChange={onChangeCategory}
                isClearable={true}
                placeholder={props.t("select_category")}
                getOptionLabel={(consumerCategory) => consumerCategory.name}
                getOptionValue={(consumerCategory) => consumerCategory._id}
                options={consumerCategory}
                styles={customStyles}
                className="cusReactSelect"
                theme={(theme) => ({
                  borderRadius: 0,
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#0e0e0e",
                    // primary: "#000",
                  },
                })}
              />
              {formError.consumer_category_id ? (
                <span
                  style={{ color: "white" }}
                  className="text-danger error-msg"
                >
                  {formError.consumer_category_id[0]}
                </span>
              ) : null}
            </div>
            <div className="input-control">
              <label>{props.t("description")}</label>:
              <textarea
                name="description"
                placeholder={props.t("write_description_of_your_complaint")}
                value={formDataComplaint.description}
                onChange={handleChange}
                cols="10"
              >
                {formDataComplaint.description}
              </textarea>
              {formError.description ? (
                <span
                  style={{ color: "white" }}
                  className="text-danger error-msg"
                >
                  {formError.description[0]}
                </span>
              ) : null}
            </div>
            <div className="merge-button">
              <div className="input-control">
                <label>{props.t("validation_code")}</label>:
                <div className="captcha-div-input">
                  <input
                    onChange={handleChange}
                    value={formDataComplaint.captcha}
                    name="captcha"
                    type="text"
                    placeholder={props.t("enter_captcha")}
                  />
                  {captcha ? <img alt="Captcha" src={captcha.img} /> : null}


                  <button
                    onClick={getCaptcha}
                    type="button"
                    className="btn ref-btn"
                  >
                    <MdRefresh
                      className={`spin-button ${spinning ? "spin" : ""}`}
                    />
                  </button>
                </div>
                {formError.captcha ? (
                  <span className="text-danger error-msg">
                    {formError.captcha[0]}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="cust-precess-btn">
              <ActionLoader
                label={props.t("submit")}
                loaderClass={"customer-complain-loader"}
                showLoader={loaderStatus}
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

CustomerComplaints.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(CustomerComplaints);
