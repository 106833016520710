import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const FAQs = () => {

  const accordian = [
    { id: 1, title: 'How can I play and join Wigobet.com?', content: 'The method is very easy, you only need to register first on the REGISTER menu , make sure you are over 17 years old and have one of the Bank BCA, MANDIRI, BNI BRI and DANAMON accounts. After successfully registering and your account has been activated, please make a deposit and play.' },
    { id: 2, title: 'What is the minimum deposit at Wigobet.com?', content: 'The minimum deposit at Wigobet.com is IDR 10,000.' },
    { id: 3, title: 'How long will it take for my deposit to be confirmed?', content: 'We will process the deposit as quickly as possible according to confirmation of the incoming queue with a record that the bank service in question is still online/not experiencing interruptions, if the bank in question is offline or is experiencing disruption, we will process the deposit after online bank services return.' },
    { id: 4, title: `Why can't a confirmed withdrawal be cancelled?`, content: 'Because there are many cases of player passwords being known by other people. To minimize the risk of chip theft, we enforce this policy.' }
  ]

  const [active, setActive] = useState(1)
  const [showAnimate, setShowAnimate] = useState(false);
  const handleAccordian = (id) => {
    setTimeout(() => {
      setShowAnimate(true);
    }, 1000)
    setActive(id);
    if (id === active) {
      setActive(0)
    }
  }

  return (
    <div className="faqs">
      <h3 className="title-tab">FAQs</h3>
      <div className="custom-accordian">
        {accordian.map((v, i) =>
          <div className="accordian-item" key={i}>
            <div className="title" onClick={() => handleAccordian(v.id)}>
              <span className='text'>{v.id}. {v.title}</span>
              <span className='icon'> {active === v.id ? <BsChevronUp /> : <BsChevronDown />}</span>
            </div>
            {active === v.id &&
              <div className={"content " + (showAnimate ? 'animate' : '')}>
                {v.content}
              </div>
            }
          </div>
        )}
      </div>
    </div>
  )
}

FAQs.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(FAQs);