import { CSS_CLASSES } from "../../../css-classes";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PartnersGrid from "./PartnersGrid";
import PartnersSlider from "./PartnersSlider";
import { useSelector } from 'react-redux';
const initSetting = {
  partner_grid: null,
  default_class: null,
};
const Partners = (props) => {

  // Set & get state
  const [fnSetting, setFnSetting] = useState(initSetting);
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  useEffect(() => {
    let temp = { ...fnSetting };
    temp['partner_grid'] = componentSetting?.["partners-logo"]?.["default-value"];
    setFnSetting(temp);
  }, [componentSetting?.["partners-logo"]]);

  return (
    <>

      {
        fnSetting['partner_grid'] && < div className="partners-logo">
          <div className="container">
            <div className={CSS_CLASSES.partnersImg}>
              <h2 className="title">{props.t('all_providers')}</h2>


              <div className={fnSetting['partner_grid']}>
                {
                  fnSetting['partner_grid'] == 'grid' &&
                  < PartnersGrid />

                }
                {
                  fnSetting['partner_grid'] == 'full-width' &&
                  < PartnersGrid />

                }
                {
                  fnSetting['partner_grid'] == 'half-width' &&
                  < PartnersGrid />

                }
                {
                  fnSetting['partner_grid'] == 'slider' &&
                  <PartnersSlider />
                }

              </div>
            </div >

          </div>
        </div>
      }

    </>
  )
}

Partners.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Partners);