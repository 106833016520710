import React, { useState } from 'react'
import { RxCrossCircled } from 'react-icons/rx';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Result = (props) => {
  const [numbers, setNumber] = useState(props.numberData);

  return (
    <>

      <div className='resultbg' id='popuptable'>

        <table className="w-full border-collapse table-fixed" style={{ fontSize: '13px' }}>
          <thead className="text-white bg-brown-newtemplate-1">
            <tr className="head">
              <td rowspan="2" width="40%" className="border border-black-0" style={{ backgroundColor: '#222' }}>GAMES</td>
              <td colspan="2" className="border border-black-0 6d-column" style={{ backgroundColor: '#222' }}></td>
              <td colspan="4" className="border border-black-0" style={{ backgroundColor: '#222' }}>ABCD, A=As, B=Kop, C=Kepala, D=Ekor</td>
            </tr>
            <tr>
              <td className="border border-black-0 6d-column" style={{ backgroundColor: '#222' }}>6D</td>
              <td className="border border-black-0 6d-column" style={{ backgroundColor: '#222' }}>5D</td>
              <td className="border border-black-0 " style={{ backgroundColor: '#222' }}>US</td>
              <td className="border border-black-0 " style={{ backgroundColor: '#222' }}>Header</td>
              <td className="border border-black-0 " style={{ backgroundColor: '#222' }}>Head</td>
              <td className="border border-black-0 " style={{ backgroundColor: '#222' }}> Tail </td>
            </tr>
          </thead>
          <tbody>
            <tr className="6d-column">
              <td className="border border-black-0">6D</td>

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length === 6 ? (
                props.numberData.number_detail.number.split('').map((num, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">{num}</td>
                ))
              ) : (
                <>
                  {[...Array(6)].map((_, index) => (
                    <td key={index} className="border togel-6d-0 border-black-0">-</td>
                  ))}
                </>
              )}

            </tr>
            <tr className="5d-column">
              <td className="border border-black-0">5D</td>
              <td className="border togel-6d-0 border-black-0">-</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length === 5 ? (
                props.numberData.number_detail.number.split('').map((num, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">{num}</td>
                ))
              ) : (
                <>
                  {[...Array(5)].map((_, index) => (
                    <td key={index} className="border togel-6d-0 border-black-0">-</td>
                  ))}
                </>
              )}
            </tr>
            <tr>
              <td className="border border-black-0">4D</td>

              {[...Array(2)].map((_, index) => (
                <td key={index} className="border togel-6d-0 border-black-0">-</td>
              ))}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length === 4 ? (
                props.numberData.number_detail.number.split('').map((num, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">{num}</td>
                ))
              ) : (
                <>
                  {[...Array(4)].map((_, index) => (
                    <td key={index} className="border togel-6d-0 border-black-0">-</td>
                  ))}
                </>
              )}

            </tr>
            <tr>
              <td className="border border-black-0">3D</td>
              {[...Array(3)].map((_, index) => (
                <td key={index} className="border togel-6d-0 border-black-0">-</td>
              ))}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length === 3 ? (
                props.numberData.number_detail.number.split('').map((num, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">{num}</td>
                ))
              ) : (
                <>
                  {[...Array(3)].map((_, index) => (
                    <td key={index} className="border togel-6d-0 border-black-0">-</td>
                  ))}
                </>
              )}

            </tr>
            <tr>
              <td className="border border-black-0">2D</td>

              {[...Array(4)].map((_, index) => (
                <td key={index} className="border togel-6d-0 border-black-0">-</td>
              ))}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length === 2 ? (
                props.numberData.number_detail.number.split('').map((num, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">{num}</td>
                ))
              ) : (
                <>
                  {[...Array(2)].map((_, index) => (
                    <td key={index} className="border togel-6d-0 border-black-0">-</td>
                  ))}
                </>
              )}

            </tr>
            <tr>
              <td className="border border-black-0">Color Jitu</td>
              {props.numberData.number_detail && props.numberData.number_detail.number &&
                [...Array(6 - props.numberData.number_detail.number.length)].map((_, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">-</td>
                ))
              }

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ? (
                props.numberData.number_detail.number.split('').map((num, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">{num}</td>
                ))
              ) : (
                <>

                  {props.numberData.number_detail && props.numberData.number_detail.number &&
                    [...Array(props.numberData.number_detail.number.length)].map((_, index) => (
                      <td key={index} className="border togel-6d-0 border-black-0">-</td>
                    ))
                  }
                </>
              )}

            </tr>
            <tr>
              <td rowspan="2" className="border border-black-0">Kombinasi</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ? props.numberData.number_detail.as50.split('#')[0] : (
                <>

                  <td className="border togel-6d-0 border-black-0">-</td>
                </>
              )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kop50.split('#')[0]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kepala50.split('#')[0]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.ekor50.split('#')[0]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}
            </tr>

            <tr>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.as50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kop50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kepala50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.ekor50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}
            </tr>
            <tr>
              <td className="border border-black-0">Colok Jitu</td>
              {props.numberData.number_detail && props.numberData.number_detail.number &&
                [...Array(6 - props.numberData.number_detail.number.length)].map((_, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">-</td>
                ))
              }

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ? (
                props.numberData.number_detail.number.split('').map((num, index) => (
                  <td key={index} className="border togel-6d-0 border-black-0">{num}</td>
                ))
              ) : (
                <>
                  {/* {[...Array(props.numberData.number_detail.number.length)].map((_, index) => (
                    <td key={index} className="border togel-6d-0 border-black-0">-</td>
                  ))}
                   */}
                  {props.numberData.number_detail && props.numberData.number_detail.number &&
                    [...Array(props.numberData.number_detail.number.length)].map((_, index) => (
                      <td key={index} className="border togel-6d-0 border-black-0">-</td>
                    ))
                  }
                </>
              )}

            </tr>
            <tr>
              <td rowspan="2" className="border border-black-0">50-50</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ? props.numberData.number_detail.as50.split('#')[0] : (
                <>

                  <td className="border togel-6d-0 border-black-0">-</td>
                </>
              )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kop50.split('#')[0]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kepala50.split('#')[0]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.ekor50.split('#')[0]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

            </tr>
            <tr>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.as50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kop50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.kepala50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )}

              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border togel-6d-0 border-black-0">{props.numberData.number_detail.ekor50.split('#')[1]}</td>
                </> : (
                  <>

                    <td className="border togel-6d-0 border-black-0">-</td>
                  </>
                )
              }

            </tr>
            <tr>
              <td className="border border-black-0">50-50 2D</td>
              <td className="border  border-black-0" colspan="6">-</td>
            </tr>
            <tr className="head">
              <td colspan="6" width="100%" className="border border-black-0 bg-brown-newtemplate-1" style={{ backgroundColor: '#222' }}>&nbsp;</td>
              <td colspan="6" width="100%" className="border border-black-0 bg-brown-newtemplate-1" style={{ backgroundColor: '#222' }}>&nbsp;</td>
            </tr>
            <tr>
              <td rowspan="2" className="border border-black-0">Colok Bebas 2D</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border  border-black-0">-</td>
              <td className="border  border-black-0">-</td>
              <td className="border  border-black-0">-</td>
              <td className="border  border-black-0">-</td>
            </tr>
            <tr>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border  border-black-0"></td>
              <td className="border  border-black-0"></td>
              <td className="border  border-black-0"></td>
              <td className="border  border-black-0"></td>
            </tr>
            <tr>
              <td rowspan="3" className="border border-black-0">Colok Naga</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border  border-black-0">-</td>
              <td className="border  border-black-0">-</td>
              <td className="border  border-black-0">-</td>
              <td className="border  border-black-0">-</td>
            </tr>
            <tr>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border  border-black-0"></td>
              <td className="border  border-black-0"></td>
              <td className="border  border-black-0"></td>
              <td className="border  border-black-0"></td>
            </tr>
            <tr>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0 6d-column">-</td>
              <td className="border border-black-0"></td>
              <td className="border  border-black-0"></td>
              <td className="border  border-black-0"></td>
              <td className="border border-black-0"></td>
            </tr>
            <tr>
              <td className="border border-black-0">Shio</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border border-black-0" colspan="6">{props.numberData.number_detail.shio}</td>
                </> : (
                  <>

                    <td className="border border-black-0" colspan="6">-</td>
                  </>
                )
              }
            </tr>
            <tr>
              <td className="border border-black-0">Silang Homo</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border border-black-0" colspan="6">{props.numberData.number_detail.silangHomo}</td>
                </> : (
                  <>

                    <td className="border border-black-0" colspan="6">-</td>
                  </>
                )
              }
            </tr>
            <tr>
              <td className="border border-black-0">Tengah Tepi</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border border-black-0" colspan="6">{props.numberData.number_detail.tengahTepi}</td>
                </> : (
                  <>

                    <td className="border border-black-0" colspan="6">-</td>
                  </>
                )
              }
            </tr>
            <tr>

              <td className="border border-black-0">Kembang Kempis Kembar</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border border-black-0" colspan="6">{props.numberData.number_detail.kembangKempis}</td>
                </> : (
                  <>

                    <td className="border border-black-0" colspan="6">-</td>
                  </>
                )
              }
            </tr>
            <tr>
              <td className="border border-black-0">Dasar</td>
              {props.numberData.number_detail && props.numberData.number_detail.number && props.numberData.number_detail.number.length !== 0 ?
                <>

                  <td className="border border-black-0" colspan="6">{props.numberData.number_detail.dasar}</td>
                </> : (
                  <>

                    <td className="border border-black-0" colspan="6">-</td>
                  </>
                )
              }
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

Result.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Result);
