import React, { useState, useEffect } from 'react';
import { CiBullhorn } from 'react-icons/ci'
import { CSS_CLASSES } from '../../../css-classes';
import { showMessage, makeAjax, url, respStatus } from '../../../helpers/global_helper';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const News = (props) => {
  const InitData = [
    { 'code': 'before_login_message', 'value': '', 'name': 'before_login' },
    { 'code': 'after_login_message', 'value': '', 'name': 'after_login' },
  ]
  const [dateState, setDateState] = useState(new Date());
  const [runningData, setRunningData] = useState(InitData);

  const listTextData = () => {
    makeAjax(
      url.ADMIN_API.listTextData, [], url.ADMIN_MS_EXT).then(response => {
        if (response.status != respStatus['SUCCESS']) {
          if (response.code != 'validation_error') {
            return;
          }
          showMessage(response);
          return;
        }
        setRunningData(response.data);
      });
  }

  useEffect(() => {
    listTextData();
    setInterval(() => setDateState(new Date()));
  }, []);


  const date = dateState.toLocaleDateString('en-GB', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  })

  let loggedIn = window.localStorage.getItem('user_token')

  return (
    <div className={CSS_CLASSES.news}>
      <div className="dateandann">
        <span className="date">{date}</span>
        <span className="announcement"><CiBullhorn className='icon' />{props.t('news')} </span>
      </div>
      <div className="moving-text" >
        {runningData.length > 0 && typeof runningData[0] != 'undefined' && runningData[0]['value'] != 'null' && runningData.map((data, index) => {
          return (
            <React.Fragment key={index}>
              {data.code == "before_login_message" && loggedIn == null ? (
                <marquee>
                  <div dangerouslySetInnerHTML={{ __html: data['value'] }}></div>
                </marquee>
              ) : data.code == "after_login_message" && loggedIn != null ? (
                <marquee>
                  <div dangerouslySetInnerHTML={{ __html: data['value'] }}></div>
                </marquee>
              ) : null}
            </React.Fragment>
          )
        })
        }

      </div>
    </div>
  )
}

News.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(News);