import { useState, useEffect } from "react";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
  dateFormat,
} from "../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import Select from "react-select";
import ActionLoader from "../../pages/ActionLoader";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { Button, Nav } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";

const ReferralsMemberRegister = (props) => {
  // Navbar
  const navigate = useNavigate();
  // FormInit
  const InitForm = {
    username: "",
    password: "",
    password_confirmation: "",
    security_question_id: "",
    security_answer: "",
    contact: "",
    email: "",
    bank_id: "",
    account_name: "",
    account_number: "",
    captcha: "",
    key: "",
    validation_length: "",
    referral_registration: true,
  };

  // Refresh dd
  const emptyBankDrop = {
    _id: null,
    bank_name: null,
  };
  // Refresh dd
  const emptySecurityDrop = {
    _id: null,
    question: null,
  };
  const [memberRegister, setMemberRegiter] = useState(false);

  const [colorUsername, setColorUsername] = useState(null);
  const [formData, setFormData] = useState(InitForm);
  const [formError, setFormError] = useState({});
  const [refreshBank, setRefreshBank] = useState(null);
  const [refreshSecurity, setRefreshSecurity] = useState(null);
  const [securityDD, setSecurityList] = useState([]);
  const [bankDD, setBankListData] = useState([]);
  const [captcha, setCaptcha] = useState([]);
  const [loginCheck, setLoginCheck] = useState(false);

  // show Password
  const [inputType, setInputType] = useState("password");
  const [inputConfirmType, setConfirmInputType] = useState("password");
  const [loaderStatus, setLoader] = useState(false);
  const [onhoverMessage, setOnhoverMessage] = useState(false);
  const [onhoverPasswordMsg, setOnhoverPasswordMsg] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);

  const [onhoverConfirmPasswordMsg, setOnhoverConfirmPasswordMsg] =
    useState(false);
  const [spinning, setSpinning] = useState(false);
  useEffect(() => {
    if (formData.username != "") {
      let payload = {
        username: formData.username,
      };
      makeAjax(
        url.USER_API.checkDuplicateUsername,
        payload,
        url.USER_MS_EXT
      ).then((response) => {

        if (response.status != respStatus["SUCCESS"]) {
          setFormError(response.messages);
          setColorUsername("red");
          return;
        }
        setColorUsername(null);
        setFormError({});
      });
    } else {
      setColorUsername(null);
      setFormError({});
    }
  }, [formData.username]);

  // On change form fields
  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    if (name == "account_number") {
      if (formData["bank_id"] != "" && val.length) {
        val = val.substring(0, formData["validation_length"]);
      } else {
        val = String(parseInt(val));
      }
      if (isNaN(val)) val = "";
    }
    setFormError({ ...formError, [name]: "" });
    setFormData({ ...formData, [name]: val });
  };

  // On change bank
  const onChangeBank = (event) => {
    setRefreshBank(null);
    setFormError({ ...formError, ["bank_id"]: "" });
    if (!event) {
      setFormData({
        ...formData,
        ["bank_id"]: "",
        ["validation_length"]: "",
        ["account_number"]: "",
      });
    } else {
      let t = emptyBankDrop;
      t._id = event._id;
      t.bank_name = event.bank_name;
      setRefreshBank(t);
      setFormData({
        ...formData,
        ["bank_id"]: event._id,
        ["validation_length"]: event.validation_length,
        ["account_number"]: "",
      });
    }
  };

  // On change security question
  const onChangeSecQue = (event) => {
    setRefreshSecurity(null);
    setFormError({ ...formError, ["security_question_id"]: "" });
    if (!event) {
      setFormData({ ...formData, ["security_question_id"]: "" });
    } else {
      let t = emptySecurityDrop;
      t._id = event._id;
      t.question = event.question;
      setRefreshSecurity(t);
      setFormData({ ...formData, ["security_question_id"]: event._id });
    }
  };

  // Get client list
  const getSecurityList = () => {
    makeAjax(url.USER_API.securityQuestionDD, [], url.USER_MS_EXT).then(
      (response) => {

        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setSecurityList(response.data);
      }
    );
  };

  // Bank list
  const getBankList = () => {
    makeAjax(url.USER_API.bankDD, [], url.USER_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setBankListData(response.data);
    });
  };

  // Load captcha
  const getCaptcha = (event) => {
    makeAjax(url.USER_API.loadCaptcha, [], url.USER_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setCaptcha(response.data);
    });
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 1000);
  };

  // User register
  const registerUser = (event) => {
    event.preventDefault();
    setLoader(true);
    formData["key"] = !captcha.key ? "" : captcha.key;
    makeAjax(url.USER_API.userRegistration, formData, url.USER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          setLoader(false);
          if (
            response.code !== "validation_error" &&
            response.code !== "validation.credential_error"
          ) {
            showMessage(response);
            return;
          }
          getCaptcha();
          setCaptcha([]);
          formData["key"] = "";
          setFormData(formData);
          setFormError(response.messages);
          return;
        }
        getCaptcha();
        setCaptcha([]);
        setRefreshBank(null);
        setRefreshSecurity(null);
        setFormError({});
        showMessage(response);
        setMemberRegiter(false);
        navigate("/referrals");
        setLoader(false);
        props.getReferralPlayerCount()
        // Reset all form fields to empty strings
        setFormData(InitForm)
      }
    );
  };


  //unique player client side validation

  const handleUsernameChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setFormData({ ...formData, [name]: val });
  };

  const onHoverHandel = (event) => {
    if (event.target.name == "username") {
      setOnhoverMessage(true);
    }
    if (event.target.name == "password") {
      setOnhoverPasswordMsg(true);
    }
    if (event.target.name == "password_confirmation") {
      setOnhoverConfirmPasswordMsg(true);
    }
  };

  const onLeaveHandel = () => {
    setOnhoverMessage(false);
    setOnhoverPasswordMsg(false);
    setOnhoverConfirmPasswordMsg(false);
  };
  const handleMouseOver = () => {
    if (isDesktop) {
      if (inputType == "password") setInputType("text");
      if (inputType == "text") setInputType("password");
    }
  };

  const handleClick = () => {
    if (!isDesktop) {
      if (inputType == "password") setInputType("text");
      if (inputType == "text") setInputType("password");
    }
  };

  const handleMouseOverConf = () => {
    if (isDesktop) {
      if (inputConfirmType == "password") setConfirmInputType("text");
      if (inputConfirmType == "text") setConfirmInputType("password");
    }
  };

  const handleClickConf = () => {
    if (!isDesktop) {
      if (inputConfirmType == "password") setConfirmInputType("text");
      if (inputConfirmType == "text") setConfirmInputType("password");
    }
  };

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobile = window.innerWidth <= 768;
      setIsDesktop(!isMobile);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  // Effect List
  useEffect(() => {
    getSecurityList();
    getBankList();
    getCaptcha();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "37px",
      height: "37px",
      boxShadow: state.isFocused ? null : null,
      cursor: "pointer",
      fontSize: "14px",

      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
        minHeight: "32px",
        height: "32px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
      backgroundColor: state.isFocused ? "#201E21" : "#201E21",
      backgroundColor: state.isSelected ? "transparent" : "transparent", // Change background color for selected option
      color: state.isSelected ? "#D47DFF" : "#BAAEC1",
      "@media (max-width: 540px)": {
        fontSize: "10px", // Font size for screens up to 540px wide
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#D47DFF" : "#D47DFF",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  return (
    <div className="table-div member-data-table">
      <div className="memberinnersep">
        <div className="member-form-box">
          <form>
            <div className="form-div">
              <div className="input-control">
                <label for="recipient" className="member-text">
                  {props.t("username")}
                  <span className="text-red">*</span> :
                </label>

                <input
                  type="text"
                  placeholder={props.t("minimum_3_maximum_13_characters")}
                  name="username"
                  className="member-input"
                  onChange={handleUsernameChange}
                  style={{ backgroundColor: colorUsername }}
                  value={formData.username}
                  onMouseEnter={onHoverHandel}
                  onMouseLeave={onLeaveHandel}
                />
                {formError.username ? (
                  <span className="text-danger member-form-text1 error-msg ">
                    {formError.username[0]}
                  </span>
                ) : null}
              </div>





              <div className="input-control">
                <label for="recipient" className="member-text">
                  {props.t("password")}
                  <span className="text-danger">*</span> :
                </label>
                <div className="member-inputs">
                  <input
                    type={inputType}
                    placeholder={props.t("min_8_one_uppercase_one_number_and_special_characters")}
                    value={formData.password}
                    name="password"
                    onChange={handleChange}
                    className="member-input"
                    onMouseEnter={onHoverHandel}
                    onMouseLeave={onLeaveHandel}
                  />
                  {inputType == "password" && (
                    <AiOutlineEyeInvisible
                      onMouseEnter={handleMouseOver}
                      onClick={handleClick}
                      className="member-password"
                    />
                  )}
                  {inputType == "text" && (
                    <AiOutlineEye
                      onMouseLeave={handleMouseOver}
                      onClick={handleClick}
                      className="member-password"
                    />
                  )}
                  {formError.password ? (
                    <span className="text-danger member-form-text1 error-msg">
                      {formError.password[0]}
                    </span>
                  ) : null}
                </div>
              </div>


              <div className="input-control">
                <label for="recipient" className="member-text">
                  {props.t("confirm_password")}
                  <span className="text-danger">*</span> :
                </label>
                <div className="member-inputs">
                  <input
                    type={inputConfirmType}
                    placeholder={props.t("min_8_one_uppercase_one_number_and_special_characters")}
                    name="password_confirmation"
                    onChange={handleChange}
                    value={formData.password_confirmation}
                    className="member-input"
                    onMouseEnter={onHoverHandel}
                    onMouseLeave={onLeaveHandel}
                  />
                  {inputConfirmType == "password" && (
                    <AiOutlineEyeInvisible
                      onMouseEnter={handleMouseOverConf}
                      onClick={handleClickConf}
                      className="member-password"
                    />
                  )}
                  {inputConfirmType == "text" && (
                    <AiOutlineEye
                      onMouseLeave={handleMouseOverConf}
                      onClick={handleClickConf}
                      className="member-password"
                    />
                  )}
                  {formError.password_confirmation ? (
                    <span className="text-danger member-form-text1 error-msg">
                      {formError.password_confirmation[0]}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="memform-dummy"></div>




              <div className="input-control">
                <label for="recipient" className="member-text">
                  {props.t("email")}
                  <span className="text-danger">*</span> :
                </label>

                <input
                  type="email"
                  className="member-input"
                  value={formData.email}
                  placeholder={props.t("enter_email_id")}
                  onChange={handleChange}
                  name="email"
                />
                {formError.email ? (
                  <span className="text-danger member-form-text1 error-msg">
                    {formError.email[0]}
                  </span>
                ) : null}
              </div>

              <div className="input-control">
                <label for="recipient" className="member-text">
                  {props.t("phone_number")}
                  <span className="text-danger">*</span> :
                </label>

                <input
                  onChange={handleChange}
                  name="contact"
                  className="member-input"
                  value={formData.contact}
                  type="tel"
                  placeholder={props.t("enter_phone_number")}
                />
                {formError.contact ? (
                  <span className="text-danger member-form-text1 error-msg">
                    {formError.contact[0]}
                  </span>
                ) : null}
              </div>
            </div>
          </form>
        </div>

        <div className="member-form-box">
          <form>
            <div className="form-div">
              <div className="input-control">
                <label for="recipient" className="member-text">
                  {`${props.t("bank")}/${props.t("e_wallet")}`}
                  <span className="text-danger error-msg">*</span> :
                </label>

                <Select
                  name="bank_id"
                  value={refreshBank}
                  onChange={onChangeBank}
                  isClearable={true}
                  placeholder={`${props.t("select_bank")}/${props.t("e_wallet")}`}
                  getOptionLabel={(option) => option.bank_name}
                  getOptionValue={(option) => option._id}
                  options={bankDD}
                  className="cusMemberInput"
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "transparent",
                      primary: "#181618",
                    },
                  })}
                />
                {formError.bank_id ? (
                  <span className="text-danger member-form-text1 error-msg">
                    {formError.bank_id[0]}
                  </span>
                ) : null}
              </div>
              <div className="input-control">
                <label for="recipient" className="member-text">
                  {`${props.t("account_name")}/${props.t("e_wallet")}`}
                  <span className="text-danger">*</span> :
                </label>

                <input
                  onChange={handleChange}
                  value={formData.account_name}
                  className="member-input"
                  name="account_name"
                  type="text"
                  placeholder={`${props.t("enter_account_name")}/${props.t(
                    "e_wallet"
                  )}`}
                />
                {formError.account_name ? (
                  <span className="text-danger member-form-text1 error-msg">
                    {formError.account_name[0]}
                  </span>
                ) : null}
              </div>

              <div className="input-control ">
                <label for="recipient" className="member-text">
                  {props.t("bank_account_number")}
                  <span className="text-danger error-msg">*</span> :
                </label>

                <input
                  onChange={handleChange}
                  className="member-input"
                  value={formData.account_number}
                  name="account_number"
                  type="text"
                  placeholder={props.t("enter_bank_account_number")}
                />
                {formError.account_number ? (
                  <span className="text-danger member-form-text1 error-msg">
                    {formError.account_number[0]}
                  </span>
                ) : null}
              </div>
            </div>
          </form>

        </div>
        <div className="referral-probtn">
          <ActionLoader
            label={"process"}
            loaderClass={"member-bttn referral-register-loader"}
            showLoader={loaderStatus}
            onClick={registerUser}
          />
        </div>
      </div>
    </div>
  );
};

ReferralsMemberRegister.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReferralsMemberRegister);