import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { makeAjax, url, respStatus, showMessage } from '../../helpers/global_helper';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const AlternativeSite = () => {
    // Slug from param
    const { token } = useParams();
    // const { url } = useParams();
    // window.location.reload(url);
    
    useEffect(() => {
        localStorage.setItem('user_token', token);
        let login_url = localStorage.getItem('login-url');
        window.location.replace(login_url);
    }, []);
}

AlternativeSite.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(AlternativeSite);