import React from 'react'
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper"
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";


const UserBlock = (props) => {
    const navigate = useNavigate()
    // Logout api call
    const logout = () => {
        makeAjax(
            url.USER_API.logout, [], url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                }
            });

        window.localStorage.removeItem("user_token");
        navigate('/')
    }

    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>
            <p> <b>{props.t('warning')}!</b> <br /> 
            {props.t('your_user_id_blocked')}.{props.t('please_contact_out_operator_via_livechat')} 
            </p>
            <button onClick={logout}>{props.t('okay')}</button>
        </div>
    )
}

UserBlock.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(UserBlock);