import { useState, useEffect } from 'react';
import Logo from './logo/Logo';
import Favicon from '../../../components/favicon/Favicon';
import AfterUserLogin from './userlogin/AfterUserLogin';
import { useNavigate } from 'react-router-dom';
import { makeAjax, showMessage, url, respStatus } from '../../../helpers/global_helper';
import UserLogin from './userlogin/UserLogin';
import { useSelector } from 'react-redux';
import Popup from '../../../components/popup/Popup';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import HomeScreenPopup from '../../../components/popup/popup-components/HomeScreenPopup';
import NavCarousel from '../navbar/NavCarousel';
import Navbar from '../navbar/Navbar';
import { useDispatch } from "react-redux";
import { fetchDepositPending, fetchOtherMediaInfo, fetchSubMenu } from '../../../store/actions'

const MainHeader = (props) => {
  let loggedIn = window.localStorage.getItem("user_token");
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));
  const [popup, setPopup] = useState(false);
  const [popupUnreadMessage, setPopupUnreadMessage] = useState(undefined);
  const [popupNotificationClosed, setPopupNotificationClosed] = useState(false);
  const [popupMessageId, setPopupMessageId] = useState(null);
  const [destopBeforeLogin, setDestopBeforeLogin] = useState(false);
  const [destopAfterLogin, setDestopAfterLogin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;

  const handlePopup = () => {
    const currentDate = new Date();
    const dateString = currentDate.toISOString();
    if (loggedIn == null) {
      localStorage.setItem('desktop_pop_up_before_login', dateString);
    } else {
      localStorage.setItem('desktop_pop_up_after_login', dateString);
    }
    setPopup(false);
    setDestopBeforeLogin(false);
    setDestopAfterLogin(false);
  };
  let urlData = window.location.pathname.split("/").pop();



  //before login image hide
  useEffect(() => {
    if (localStorage.getItem('desktop_pop_up_before_login') == null) {
      setDestopBeforeLogin(true);
    } else {
      if (localStorage.getItem('desktop_pop_up_before_login') != null) {
        const currentDate = new Date();
        const targetDate = new Date(localStorage.getItem('desktop_pop_up_before_login'));

        const timeDifference = currentDate - targetDate;
        const timeDifferenceInMinutes = timeDifference / (1000 * 60);
        if (timeDifferenceInMinutes > 10) {
          setDestopBeforeLogin(true);
        } else {
          setDestopBeforeLogin(false);
        }

      }

    }
  }, [destopBeforeLogin]);


  //after login image hide
  useEffect(() => {
    if (localStorage.getItem('desktop_pop_up_after_login') == null) {
      setDestopAfterLogin(true);
    } else {
      if (localStorage.getItem('desktop_pop_up_after_login') != null) {
        const currentDate = new Date();
        const targetDate = new Date(localStorage.getItem('desktop_pop_up_after_login'));

        const timeDifference = currentDate - targetDate;
        const timeDifferenceInMinutes = timeDifference / (1000 * 60);
        if (timeDifferenceInMinutes > 10) {
          setDestopAfterLogin(true);
        } else {
          setDestopAfterLogin(false);
        }

      }

    }
  }, [destopAfterLogin]);

  useEffect(() => {
    if (urlData == "") {
      setPopup(true);
    }
  }, [urlData]);

  useEffect(() => {
    if (loggedIn != "" && loggedIn != null) {
      unreadPopUpMessages();
      const interval = setInterval(() => unreadPopUpMessages(), 6000);
      return () => {
        clearInterval(interval);
      };
    }
    dispatch(fetchOtherMediaInfo());
    dispatch(fetchSubMenu());
  }, [loggedIn]);

  const unreadPopUpMessages = () => {
    makeAjax(url.MEMO_API.getUnreadPopUpMessage, [], url.MEMO_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }

        setPopupUnreadMessage(response.data);
        if (response.data.length > 0 && response.data[0] != null) {
          setPopupMessageId(response.data[0]._id);
          setPopupNotificationClosed(true);
        }
      }
    );
  };

  const handleNotificationPopup = () => {
    messageRead();
    setPopupNotificationClosed(false);
  };

  const messageRead = () => {
    if (messageRead != null) {
      let payload = {
        _id: popupMessageId,
      };
      makeAjax(
        url.MEMO_API.updatePopupMessageStatus,
        payload,
        url.MEMO_MS_EXT
      ).then((response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        dispatch(fetchDepositPending());
        if (currentPath == "/transaction-queue") {
          navigate("/withdraw");
        }
      });
    }
  };
  return (
    <div className="header-components">
      {
        typeof componentSetting?.["top-nav-logo"] != 'undefined'
        && componentSetting?.["top-nav-logo"]?.["default-value"]
        && <Logo />
      }
      <Favicon />
      {
        componentSetting?.["nav-bar"]?.["default-value"] == 'horizonatal'
        && componentSetting?.custom_loading?.options?.header_handle
        && <Navbar />
      }
      {
        componentSetting?.["nav-bar"]?.["default-value"] == 'nav-carozal'
        && <NavCarousel />
      }
      {
        loggedIn ? <>{componentSetting != null
          && typeof componentSetting?.["device-login-info"]?.["options"]?.["user-login"] != 'undefined'
          && componentSetting?.["device-login-info"]?.["options"]?.["user-login"]
          && popupUnreadMessage != undefined
          && popupUnreadMessage != 'undefined'
          && popupNotificationClosed
          && <HomeScreenPopup handlePopup={handleNotificationPopup} popupNotificationClosed={popupNotificationClosed} data={popupUnreadMessage} />}</>
          : null
      }

      {
        loggedIn ? <>{componentSetting != null
          && typeof componentSetting?.["device-login-info"]?.["options"]?.["user-login"] != 'undefined'
          && componentSetting?.["device-login-info"]?.["options"]?.["user-login"]
          && <AfterUserLogin />}</> : <>{componentSetting != null
            && typeof componentSetting?.["device-login-info"]?.["options"]?.["user-login"] != 'undefined'
            && componentSetting?.["device-login-info"]?.["options"]?.["user-login"]
            && <UserLogin />}</>
      }

      {
        popup && loggedIn != null
        && loggedIn != ''
        && destopAfterLogin
        && <div className='homepage-popup'> <Popup handlePoup={handlePopup} afterLogin /></div>
      }

      {
        popup && loggedIn == null
        && destopBeforeLogin
        && <div className='homepage-popup'><Popup handlePoup={handlePopup} beforeLogin /></div>
      }
    </div>
  );
};
MainHeader.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(MainHeader);
