import { useState } from 'react'
import { BiHomeAlt, BiDice5 } from 'react-icons/bi'
import { BsPen, BsQuestionCircle, BsFire } from 'react-icons/bs'
import { MdOutlineVideogameAsset, MdSportsSoccer } from 'react-icons/md'
import { TbCards, TbCrystalBall } from 'react-icons/tb'
import { CSS_CLASSES } from '../../../css-classes'
import { NavLink } from 'react-router-dom'
import { HiOutlineUsers, HiOutlineDeviceMobile } from 'react-icons/hi'
import { GiEightBall } from 'react-icons/gi'
import { LiaGiftsSolid, LiaMoneyCheckAltSolid, LiaCoinsSolid } from 'react-icons/lia'
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";


const NavCarousel = (props) => {
    const [isItem, setIsItem] = useState();
    const { playerMenu } = useSelector(state => ({
        playerMenu: state.menuListR.data
    }));
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    const mouseOver = (code) => {
        setIsItem(code);
    }
    const hoverGames = [
        { code: "home", icon: <BiHomeAlt />, link: '/' },
        { code: "hot_gabbbbmes", icon: <BsFire />, link: '/game' },
        { code: "slots", icon: <LiaMoneyCheckAltSolid />, link: '/slot' },
        { code: "arcades", icon: <MdOutlineVideogameAsset />, link: '/arcade' },
        { code: "jackpot_bar", icon: <LiaCoinsSolid />, link: '/jackpot_bars' },
        { code: "live_casino", icon: <BiDice5 />, link: '/live_casinos' },
        { code: "sportsbook", icon: <MdSportsSoccer />, link: '/sports_book' },
        { code: "card_games", icon: <TbCards />, link: '/card_game' },
        { code: "lottery", icon: <GiEightBall />, link: '/lottery_games' },
        { code: "dingdong", icon: <TbCrystalBall />, link: '/dingdong_game' },
        { code: "blogs", icon: <BsPen />, link: '/blog' },
        { code: "promotion", icon: <LiaGiftsSolid />, link: '/promotions' },
        { code: "referral", icon: <HiOutlineUsers />, link: '/referral' },
        { code: "help", icon: <BsQuestionCircle />, link: '/helps' },
        { code: "mobile", icon: <HiOutlineDeviceMobile />, link: '/mobile' }
    ]

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1
    };

    return (
        <>
            <div className='navbar-main-carousel'>
                <div className="container">
                    <Slider {...settings} className={CSS_CLASSES.navbar}>
                        {
                            hoverGames.map((v, i) => {
                                return (playerMenu['before'][v.code] && playerMenu['before'][v.code]['status']) &&
                                    <>
                                        {componentSetting["horizonatal-nav-bar"]["default-value"] == 'label-icon' ?

                                            <li key={playerMenu['before'][v.code]['_id']} className={componentSetting["horizonatal-nav-bar"]["default-value"]}>
                                                {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span> </NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></a>}
                                            </li>
                                            : componentSetting["horizonatal-nav-bar"]["default-value"] == 'only-lable' ?
                                                <li key={playerMenu['before'][v.code]['_id']} className={componentSetting["horizonatal-nav-bar"]["default-value"]}>
                                                    {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> <span>{playerMenu['before'][v.code]['name']}</span> </NavLink> : <a onMouseOver={() => mouseOver(v.code)}><span>{playerMenu['before'][v.code]['name']}</span></a>}
                                                </li>
                                                : componentSetting["horizonatal-nav-bar"]["default-value"] == 'hover-dropdown' ?
                                                    <div className={componentSetting["horizonatal-nav-bar"]["default-value"]}>
                                                        <li key={playerMenu['before'][v.code]['_id']}>
                                                            {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></a>}
                                                        </li>
                                                        <div className="dropdown-content">
                                                            <a href="#">Link 1</a>
                                                            <a href="#">Link 2</a>
                                                            <a href="#">Link 3</a>
                                                        </div>
                                                    </div>
                                                    : componentSetting["horizonatal-nav-bar"]["default-value"] == 'hover-listing' ?
                                                        <div className={componentSetting["horizonatal-nav-bar"]["default-value"]}>
                                                            <li key={playerMenu['before'][v.code]['_id']}>
                                                                {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></a>}
                                                            </li>
                                                            <div className="dropdown-content">
                                                                <div className="listing-items">
                                                                    <img alt='navbar img' src='/assets/images/item-image.png' className='img-fluid' />
                                                                    <img alt='navbar img' src='/assets/images/item-image.png' className='img-fluid' />
                                                                    <img alt='navbar img' src='/assets/images/item-image.png' className='img-fluid' />
                                                                    <img alt='navbar img' src='/assets/images/item-image.png' className='img-fluid' />
                                                                    <img alt='navbar img' src='/assets/images/item-image.png' className='img-fluid' />
                                                                    <img alt='navbar img' src='/assets/images/item-image.png' className='img-fluid' />
                                                                    <img alt='navbar img' src='/assets/images/item-image.png' className='img-fluid' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : componentSetting["horizonatal-nav-bar"]["default-value"] == 'icon-toggel' ?
                                                            <li key={playerMenu['before'][v.code]['_id']} className={componentSetting["horizonatal-nav-bar"]["default-value"]} >
                                                                {v.link ? <NavLink to={v.link} onMouseOver={() => mouseOver(v.code)} data-tooltip={playerMenu['before'][v.code]['name']}> {v.icon} </NavLink> : <a onMouseOver={() => mouseOver(v.code)}> {v.icon} <span>{playerMenu['before'][v.code]['name']}</span></a>}
                                                            </li>
                                                            :
                                                            <h5>Error</h5>
                                        }
                                    </>
                            })
                        }
                    </Slider>
                </div>
            </div>
        </>


    )
}

NavCarousel.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(NavCarousel);