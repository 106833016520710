import React from 'react'
import { useState, useEffect } from 'react'
import Faq from '../faq/Faq';

const Chats = ({ messages }) => {

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        // Cleanup the interval to avoid memory leaks
        return () => clearInterval(interval);
    }, []);

    const hours = time.getHours();
    const minutes = time.getMinutes();

    const [showFaq, setShowFaq] = useState(false);

    const handleOptionClick = (option) => {
        if (option === 'yes' || option === 'no') {
            setShowFaq(true);
            setShowFaq([...messages, { sender: 'selection' }]);
            setShowFaq('');
            // Perform any other actions you need based on the option clicked
        }
    };

    // Define your accordion data
    const accordionData = [
        {

            label: "Login/Register",
            subtabs: [
                { key: "subtab-0", label: "Option 1" },
                // Add more subtabs as needed
            ],
        },
        {

            label: "Deposit",
            subtabs: [
                { key: "subtab-0", label: "Option 1" },
                { key: "subtab-1", label: "Option 2" },
                // Add more subtabs as needed
            ],
        },
        {

            label: "Others"
            // No subtabs for "Others" in this example
        },
    ];

    return (
        <>

            {messages.map((message, index) => {
                switch (message.sender) {
                    case 'user':
                        return (
                            <li className="chat incoming" key={index} >
                                <div className='chat-time-in'>{`${hours}:${minutes < 10 ? '0' : ''}${minutes}`}</div>
                                <p>{message.text}</p>
                            </li>
                        )

                    case 'operator':
                        return (
                            <li className="chat outgoing fadeInLeft" key={index}>
                                <p>{message.text}</p>
                                <div className='chat-time-out'>{`${hours}:${minutes < 10 ? '0' : ''}${minutes}`}</div>
                            </li>
                        )

                    case 'error':
                        return (
                            <li className="chat outgoing error-text fadeInLeft">
                                <p>Do you have any question?</p>
                            </li>
                        )

                    case 'notify':
                        return (
                            <p className='notify'>Today</p>
                        )

                    case 'selection':
                        return (
                            <>
                                <li className="chat outgoing fadeInLeft" key={index}>
                                    <p>Do you have any question?</p>
                                </li>
                                <ul className='chat-option'>
                                    <li onClick={() => handleOptionClick('yes')}>yes</li>
                                    <li>no</li>
                                </ul>
                            </>
                        )

                    case 'faq':
                        return (
                            <>
                                <Faq accordionData={accordionData} />
                            </>
                        )
                }
            })}



        </>
    )
}

export default Chats
