import React from "react"
import Register from "../pages/authentication/Register"
import Go from "../pages/Go"
import Home from "../pages/Home"
import Referral from "../pages/Referral"
import Promotion from "../pages/Promotion"
import Help from "../pages/Help"
import Blog from "../pages/Blog"
import BlogInnerPage from "../components/blogs/blogInnerPage/BlogInnerPage"
import ForgotPassword from "../pages/authentication/ForgotPassword"
import NewPassword from "../pages/authentication/NewPassword"
import VerifyToken from "../components/authentication/emailVerification/VerifyToken"
import LoginPin from "../pages/authentication/LoginPin"
import LoginPinOn from "../pages/authentication/LoginPinOn"
import SecurityQuestion from "../pages/authentication/SecurityQuestion"
import RulesOfPlay from "../pages/authentication/RulesOfPlay"
import SecurityValidation from "../pages/authentication/SecurityValidation"
import Profile from "../pages/user-pages/Profile"
import Memo from "../pages/user-pages/Memo"
import Deposit from "../pages/user-pages/Deposit"
import Withdraw from "../pages/user-pages/Withdraw"
import PromoInfo from "../pages/user-pages/PromoInfo"
import TransHistory from "../pages/user-pages/TransHistory"
import OutstandingBet from "../pages/user-pages/OutstandingBet"
import Error404 from "../layout/main/Error404"
import EmailVerification from "../pages/authentication/EmailVerification"
import PinSecurityValidation from "../components/user-pages/profile/pin-security-validation/PinSecurityValidation"
import HistoryProviders from "../components/referrals-inner/HistoryProviders"
import CalculationMethod from "../components/referrals-inner/CalculationMethod"
import TurnoverProviders from "../components/referrals-inner/TurnoverProviders"
import Mobile from "../pages/Mobile"
import Status from "../pages/Status"
import ValidationInner from "../components/user-pages/validation/ValidationInner"
import LotteryGames from "../components/lotterygames/LotteryGames"
import CompleteResult from "../components/complete-result/CompleteResult"
import GamesList from "../pages/GamesList"
import ReminderPassword from "../components/authentication/reminderPassword/ReminderPassword"
import TransactionQueue from "../pages/transaction-queue/TransactionQueue"
import PlayerReferral from "../components/referrals-inner/PlayerReferral"
import AlternativeSite from "../layout/header/AlternativeSite"
import UpdateToken from "../pages/UpdateToken"
import Dummy from "../components/games/DUMMY/Dummy"
import ComingSoon from "../pages/ComingSoon"
import PreLoader from "../pages/PreLoader"
import TransactionDepositQueue from "../pages/transaction-queue/TransactionDepositQueue"
import Unauthorised401 from "../layout/main/Unauthorised401"
import AccessDenied from "../layout/main/AccessDenied"
import Balance from "../components/Balance/Balance"
import AllGames from "../pages/user-pages/AllGames"
import LiveChat from "../components/livechat/LiveChat"
import AboutUs from "../components/help-inner/aboutus/AboutUs"
import GameProvider from "../components/games/game-category/game-category-ver-menu/game-provider/GameProvider"
import LazyLoading from "../pages/LazyLoading"
import TermsAndCondition from "../pages/TermsAndCondition"

const authProtectedRoutes = [

    { path: "/withdraw", component: <Withdraw /> },

    { path: "/promo-info", component: <PromoInfo /> },

    { path: "/trans-history", component: <TransHistory /> },

    { path: "/trans-history", component: <TransHistory /> },

    { path: "/outstanding-bet", component: <OutstandingBet /> },

    { path: "/history", component: <HistoryProviders /> },

    { path: "/calculation", component: <CalculationMethod /> },

    { path: "/turnover", component: <TurnoverProviders /> },

    { path: "/deposit", component: <Deposit /> },

    { path: "/profile", component: <Profile /> },

    { path: "/memo", component: <Memo /> },

    { path: "/transaction-queue", component: <TransactionQueue /> },

    { path: "/login-pin", component: <LoginPin /> },

    { path: "/pin-on", component: <LoginPinOn /> },

    { path: "/security-question", component: <SecurityQuestion /> },

    { path: "/rules-of-play", component: <RulesOfPlay /> },

    { path: "/security-validation", component: <SecurityValidation /> },

    { path: "/pin-security-validation", component: <PinSecurityValidation /> },


    { path: "/validation", component: <ValidationInner /> },

    { path: "/reminder-password", component: <ReminderPassword /> },
    { path: "/transaction-deposit-queue", component: <TransactionDepositQueue /> },

];

const publicRoutes = [
    { path: "/promotion", component: <Promotion /> },
    { path: "/referrals", component: <Referral /> },
    { path: "/help", component: <Help /> },
    // { path: "/result", component: <CompleteResult /> },
    { path: "/result/:parameter", component: <CompleteResult /> },
    { path: "/dingdong", component: <GamesList /> },
    { path: "/dingdong/:providerSlug", component: <GamesList /> },

    { path: "/lottery", component: <GamesList /> },

    { path: "/card_games", component: <GamesList /> },
    { path: "/card_games/:providerSlug", component: <GamesList /> },

    { path: "/sportsbook", component: <GamesList /> },
    { path: "/sportsbook/:providerSlug", component: <GamesList /> },

    { path: "/live_casino", component: <GamesList /> },
    { path: "/live_casino/:providerSlug", component: <GamesList /> },

    { path: "/jackpot_bar", component: <GamesList /> },
    { path: "/jackpot_bar/:providerSlug", component: <GamesList /> },
    { path: "/arcades", component: <GamesList /> },
    { path: "/arcades/:providerSlug", component: <GamesList /> },

    { path: "/games", component: <GamesList /> },
    { path: "/games/:providerSlug", component: <GamesList /> },
    { path: "/dummy", component: <Dummy /> },

    { path: "/slots/:providerSlug", component: <GamesList /> },
    { path: "/slots", component: <GamesList /> },

    { path: "/lottery/:providerSlug", component: <LotteryGames /> },

    { path: "/lotteryHome/:providerSlug", component: <GamesList /> },

    { path: "/:providerSlug", component: <GamesList /> },

    { path: "/register", component: <Register /> },

    { path: "/forgot-password", component: <ForgotPassword /> },

    { path: "/go/:subDomain", component: <Go /> },

    { path: "/new-password/:emailToken", component: <NewPassword /> },

    { path: "/email-verify/:emailToken", component: <VerifyToken /> },

    { path: "/email-verification", component: <EmailVerification /> },

    { path: "/update_token/:device_type/:user_token", component: <UpdateToken /> },

    { path: "/game-provider", component: <GameProvider providerSlug={null} categorySlug={null} /> },

    { path: "error404", component: <Error404 /> },

    { path: "unauthorised401", component: <Unauthorised401 /> },

];

const bothRoutes = [
    { path: "/", component: <Home /> },
    { path: "/lazy-loading", component: <LazyLoading /> },
    { path: "/mobile", component: <Mobile /> },
    { path: "/status", component: <Status /> },
    { path: "/lotterygames", component: <LotteryGames /> },
    { path: "/lotterygames", component: <LotteryGames /> },
    { path: "/blogs", component: <Blog /> },
    { path: "/blogs/category/:page/:category", component: <Blog /> },
    { path: "blogs/post/:slug", component: <BlogInnerPage /> },
    { path: "referral/:referralPlayer", component: <PlayerReferral /> },
    { path: "alternativeSite/:token", component: <AlternativeSite /> },
    { path: "/comingsoon", component: <ComingSoon /> },
    { path: "/preloader", component: <PreLoader /> },
    { path: "/all-games", component: <AllGames /> },
    { path: "/about_us", component: <AboutUs /> },
    { path: "/terms_and_conditions", component: <TermsAndCondition /> },
];

const specialRoutes = [

    { path: "/access-denied", component: <AccessDenied /> },
];

export { authProtectedRoutes, publicRoutes, bothRoutes, specialRoutes }