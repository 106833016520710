import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row, Button, Input } from "reactstrap";
import PropTypes from "prop-types";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineChevronLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineChevronRight,
} from "react-icons/md";

const WLPagination = (props) => {
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(15);

  useEffect(() => {
    setPage(props.pageData.current_page);
    setPerPage(props.pageData.per_page);
  }, [props.pageData.current_page, props.pageData.per_page]);

  const perPageChangeLocal = (e) => {
    setPerPage(e.target.value);
    props.perPageChange(e.target.value);
  };

  const pageChangeLocal = (e) => {
    let val = e.target.value;
    if (isNaN(val)) {
      val = props.pageData.current_page;
    } else {
      if (val != "") val = parseInt(val);
    }

    setPage(val);
  };

  const gotoPage = (tPage) => {
    setPage(tPage);

    if (tPage == 0) {
      tPage = 1;
      setPage(1);
    }

    if (tPage > props.pageData.last_page) {
      tPage = props.pageData.last_page;
      setPage(props.pageData.last_page);
    }

    if (props.pageData.current_page != tPage) {
      props.pageChange(tPage);
    }
  };

  const handleKeyDown = (event) => {
    let cPage = page;

    if (page == 0) {
      cPage = 1;
    }

    if (page > props.pageData.last_page) {
      cPage = props.pageData.last_page;
    }
    if (event.key === "Enter") {
      setPage(cPage);
      props.pageChange(cPage);
    }
  };

  return (
    <div className="wlpagination-page">
      <div className="pagination-page">
        <div className="col-md-auto">
          <div className="page-show d-flex">
            <div className="show-txt">{props.t("show")}:</div>
            <div className="show-select">
              <select
                className="form-select"
                name="per_page"
                value={per_page}
                onChange={perPageChangeLocal}
              >
                {[5, 10, 15, 50, 100, 200, 500].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="page-col">
          <div className="d-flex gap-1 page-btn">
            <button
              onClick={() => gotoPage(1)}
              disabled={props.pageData.current_page == 1}
              className="page-button"
            >
              <MdOutlineKeyboardDoubleArrowLeft className="left-arrow" />
            </button>
            <button
              onClick={() => gotoPage(props.pageData.current_page - 1)}
              disabled={props.pageData.current_page == 1}
              className="page-button"
            >
              <MdOutlineChevronLeft className="left-arrow" />
            </button>
          </div>
        </div>
        <div
          className="d-md-block page-number"
        >
          {props.t("page")}&nbsp;
            {props.pageData.current_page} {props.t("of")}{" "}
            {props.pageData.last_page}
        </div>
        <div className="">
          <input
            type="text"
            min={1}
            style={
              props.pageData.last_page < page || page == 0
                ? { width: 70, }
                : { width: 30 }
            }
            name="page"
            max={props.pageData.last_page}
            value={page}
            onChange={pageChangeLocal}
            onKeyDown={handleKeyDown}
            onBlur={(e) => gotoPage(page)}
            className="text-center"
          />
        </div>
        <div className=" page-col">
          <div className="d-flex gap-1 page-btn">
            <button
              disabled={props.pageData.current_page == props.pageData.last_page}
              className="page-button"
            >
              <MdOutlineChevronRight className="right-arrow" />
            </button>
            <button
              onClick={() => gotoPage(props.pageData.last_page)}
              disabled={props.pageData.current_page == props.pageData.last_page}
              className="page-button"
            >
              <MdOutlineKeyboardDoubleArrowRight className="right-arrow" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

WLPagination.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(WLPagination);
