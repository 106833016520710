import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const PostLogin = (props) => {

  // Get device type
  let deviceType = localStorage.getItem('device_type');

  // Init assets
  const initAssetConfig = {
    width: 200,
    height: 200,
    alt: 'defautl alt',
    description: 'default description',
    image: "/assets/images/default-images/popup_image_" + deviceType + ".png",
    link: ''
  }
  // Set & get state
  const [assets, setAssets] = useState(initAssetConfig);

  // Fetch other media
  const { otherMedia } = useSelector(state => ({
    otherMedia: state.othermediaInformationListR.data
  }));



  // Effect call for manupulate data
  useEffect(() => {
    setAssets(initAssetConfig);
    // Width
    if (typeof otherMedia[deviceType + '_' + 'pop_up_after_login'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'pop_up_after_login']['width'] != 'undefined') {
      assets['width'] = otherMedia[deviceType + '_' + 'pop_up_after_login']['width'];
      setAssets({
        ...assets
      })
    }

    // Heigth
    if (typeof otherMedia[deviceType + '_' + 'pop_up_after_login'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'pop_up_after_login']['height'] != 'undefined') {
      assets['height'] = otherMedia[deviceType + '_' + 'pop_up_after_login']['height'];
      setAssets({
        ...assets
      })
    }

    // Description
    if (typeof otherMedia[deviceType + '_' + 'pop_up_after_login'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'pop_up_after_login']['description'] != 'undefined') {
      assets['description'] = otherMedia[deviceType + '_' + 'pop_up_after_login']['description'];
      setAssets({
        ...assets
      })
    }

    // alternative
    if (typeof otherMedia[deviceType + '_' + 'pop_up_after_login'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'pop_up_after_login']['alternate'] != 'undefined') {
      assets['alt'] = otherMedia[deviceType + '_' + 'pop_up_after_login']['alternate'];
      setAssets({
        ...assets
      })
    }

    // Image path
    if (typeof otherMedia[deviceType + '_' + 'pop_up_after_login'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'pop_up_after_login']['path'] != 'undefined') {
      assets['image'] = otherMedia[deviceType + '_' + 'pop_up_after_login']['path'];
      setAssets({
        ...assets
      })
    }

    if (typeof otherMedia[deviceType + '_' + 'pop_up_after_login'] != 'undefined' && typeof otherMedia[deviceType + '_' + 'pop_up_after_login']['link'] != 'undefined') {
      assets['link'] = otherMedia[deviceType + '_' + 'pop_up_after_login']['link'];
      setAssets({
        ...assets
      })
    }
  }, [otherMedia]);
  return (
    <>

      {
        assets['link'] != '' ? <a target="_blank" href={assets['link']}>
          <div className='popup-box1'>
            <div>
              <img src={assets['image']} width={assets['width']} height={assets['height']} alt={assets['alt']} style={{ width: '100%' }} />
            </div>
          </div>
        </a> :
          <div className='popup-box1'>
            <div>
              <img src={assets['image']} width={assets['width']} height={assets['height']} alt={assets['alt']} style={{ width: '100%' }} />
            </div>
          </div>
      }

    </>
  )
}

PostLogin.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PostLogin);
