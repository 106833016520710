import { useEffect, useRef, useState } from "react";
import React from 'react'
import { BiUserCircle } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import Popup from "../../components/popup/Popup";
import SideBar from "./header-mobile-components/SideBar";
import { MdNotificationsNone } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import { LuTimerReset } from "react-icons/lu";
import { CgPassword } from "react-icons/cg";
import { BiLogOut, BiUser } from "react-icons/bi";
import { TbLogout } from "react-icons/tb";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import { fetchDepositPending, getLogout, resetPlayerProfile, fetchOtherMediaInfo } from "../../store/actions";
import { resetPinStatus } from "../../store/check-pin-status/actions";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
} from "../../helpers/global_helper";
import { RiErrorWarningLine } from "react-icons/ri";
import { FiLogIn, FiRefreshCw } from "react-icons/fi";
import { fetchReferalInfo } from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LanguageDropdown from "../../components/languageDropdown/LanguageDropdown";
import DepositWithdrawMessage from "../../components/popup/popup-components/DepositWithdrawMessage";
import HomeScreenPopup from "../../components/popup/popup-components/HomeScreenPopup";
import { HiMenuAlt1 } from "react-icons/hi";
import News from "../header/news/News";

const HeaderMobile = (props) => {
  // set & get data
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));
  const [sideBar, setSideBar] = useState(false);
  const [popup, setPopup] = useState(false);
  const sideRef = useRef();
  const btnRef = useRef();
  const [
    depositWithdrawNotificationClosed,
    setDepositWithdrawNotificationClosed,
  ] = useState(false);
  const [depositWithdrawMessage, setDepositWithdrawMessage] = useState([]);
  const [dwMessageId, setDwMessageId] = useState(null);
  const btnRefEmail = useRef();
  const [spinning, setSpinning] = useState(false);
  const [playerBalance, setPlayerBalance] = useState([]);
  const [popupUnreadMessage, setPopupUnreadMessage] = useState(undefined);
  const [popupNotificationClosed, setPopupNotificationClosed] = useState(false);
  const [popupMessageId, setPopupMessageId] = useState(null);
  const [isNotification, setNotification] = useState(false);
  const [notificationCount, setNoticationCount] = useState(0)
  const [emailVer1, setEmailVer1] = useState(false)
  const [emailVer, setEmailVer] = useState(false)

  const navigate = useNavigate();

  let loggedIn = window.localStorage.getItem("user_token");
  const initAssetConfig = {
    width: 40,
    height: 40,
    alt: "defautl alt",
    description: "default description",
    image: "/assets/images/default-images/logo.svg",
    link: "",
  };
  // Set & get state
  const [assets, setAssets] = useState(initAssetConfig);
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;

  const sideBarClose = () => {
    setSideBar(false);
    document.body.style.overflowY = "scroll";
  };
  const [isPopup, setIsPopup] = useState(false);
  const [isPopupReset, setIsPopupReset] = useState(false);
  const [mobileBeforeLogin, setMobileBeforeLogin] = useState(false);
  const [mobileAfterLogin, setMobileAfterLogin] = useState(false);

  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));


  const handlePopup = () => {
    const currentDate = new Date();
    const dateString = currentDate.toISOString();
    if (!userProfile.username) {
      localStorage.setItem('mobile_pop_up_before_login', dateString);
    } else {
      localStorage.setItem('mobile_pop_up_after_login', dateString);
    }
    setPopup(false);
    setIsPopup(false);
    setNotification(false);
    setMobileBeforeLogin(false);
    setMobileAfterLogin(false);
  };


  const resetHandlePopup = () => {
    setIsPopupReset(false);
  }

  const loginPopupTrigger = () => {
    setIsPopup(true);
  };

  const messageRead = () => {
    if (messageRead != null) {
      let payload = {
        _id: popupMessageId,
      };
      makeAjax(
        url.MEMO_API.updatePopupMessageStatus,
        payload,
        url.MEMO_MS_EXT
      ).then((response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        dispatch(fetchDepositPending());
        if (currentPath == "/transaction-queue") {
          navigate('/withdraw');
        }
      });
    }
  };

  useEffect(() => {
    function handler(e) {
      if (
        !sideRef.current?.contains(e.target) &&
        !btnRef.current?.contains(e.target)
      ) {
        setSideBar(false);
        document.body.style.overflowY = "scroll";
      }
    }
    document.body.addEventListener("click", handler);
    return () => document.body.removeEventListener("click", handler);
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      setEmailVer1(false);
    }, 5000);
    return () => {
      clearInterval(timeout);
    };
  }, []);
  const handleEmailVer = () => {
    setEmailVer1(!emailVer1);
  }

  const depositWithdrawNotificationMessage = () => {
    makeAjax(
      url.DPWD_API.depositWithdrawNotificationMessage,
      [],
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setDepositWithdrawMessage(response.data);
      if (response.data.length > 0) {
        setDwMessageId(response.data[0]._id);
        setDepositWithdrawNotificationClosed(true);
      }
    });
  };


  const updateLastLoginAt = () => {
    if (!userProfile.username) return;
    makeAjax(url.USER_API.lastActiveAt, [], url.USER_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
    });
  };

  useEffect(() => {

    if (loggedIn != '' && loggedIn != null) {
      updateLastLoginAt();
      const interval1 = setInterval(() => updateLastLoginAt(), 60000);
      return () => {
        clearInterval(interval1);
      };
    }
  }, [userProfile]);

  const hamburgerClick = () => {
    setSideBar(true);
    document.body.style.overflow = "hidden";
  };

  const [userDataHandleClick, setUserDataHandleClick] = useState(false);

  const userDataHandle = () => {
    setUserDataHandleClick(true);
  };

  useEffect(() => {
    dispatch(fetchReferalInfo("mobile"));
    // localStorage.setItem("device_type", "mobile");
    function handlerEmail(e) {
      if (!btnRefEmail.current?.contains(e.target)) {
        setEmailVer(false);
      }
    }
    document.body.addEventListener("click", handlerEmail);
    return () => document.body.removeEventListener("click", handlerEmail);
  }, []);

  // Fetch other media
  const { otherMedia } = useSelector((state) => ({
    otherMedia: state.othermediaInformationListR.data,
  }));

  // Get device type
  let deviceType = localStorage.getItem("device_type");

  // Effect call for manupulate data
  useEffect(() => {
    setAssets(initAssetConfig);
    // Width
    if (
      typeof otherMedia[deviceType + "_" + "logo"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "logo"]["width"] != "undefined"
    ) {
      assets["width"] = otherMedia[deviceType + "_" + "logo"]["width"];
      setAssets({
        ...assets,
      });
    }

    // Heigth
    if (
      typeof otherMedia[deviceType + "_" + "logo"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "logo"]["height"] != "undefined"
    ) {
      assets["height"] = otherMedia[deviceType + "_" + "logo"]["height"];
      setAssets({
        ...assets,
      });
    }

    // Description
    if (
      typeof otherMedia[deviceType + "_" + "logo"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "logo"]["description"] != "undefined"
    ) {
      assets["description"] =
        otherMedia[deviceType + "_" + "logo"]["description"];
    }

    // alternative
    if (
      typeof otherMedia[deviceType + "_" + "logo"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "logo"]["alternate"] != "undefined"
    ) {
      assets["alt"] = otherMedia[deviceType + "_" + "logo"]["alternate"];
      setAssets({
        ...assets,
      });
    }

    // Image path
    if (
      typeof otherMedia[deviceType + "_" + "logo"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "logo"]["path"] != "undefined"
    ) {
      assets["image"] = otherMedia[deviceType + "_" + "logo"]["path"];
      setAssets({
        ...assets,
      });
    }

    if (
      typeof otherMedia[deviceType + "_" + "logo"] != "undefined" &&
      typeof otherMedia[deviceType + "_" + "logo"]["link"] != "undefined"
    ) {
      assets["link"] = otherMedia[deviceType + "_" + "logo"]["link"];
      setAssets({
        ...assets,
      });
    }
  }, [otherMedia]);

  let urlData = window.location.pathname.split("/").pop();

  useEffect(() => {
    if (urlData == "") {
      setPopup(true);
    }
  }, [urlData]);

  const handleRefreshBalance = () => {
    makeAjax(url.GP_API.getBalance, "", url.GP_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        // showMessage(response);
        return;
      }
    });
    fetchPlayerBalance();
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 1000);
  };

  useEffect(() => {
    if (loggedIn != '' && loggedIn != null) {
      unreadPopUpMessages();
      // depositWithdrawNotificationMessage();
      const interval = setInterval(() => unreadPopUpMessages(), 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [loggedIn]);

  const unreadPopUpMessages = () => {
    makeAjax(url.MEMO_API.getUnreadPopUpMessage, [], url.MEMO_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }

        setPopupUnreadMessage(response.data);
        if (response.data.length > 0 && response.data[0] != null) {
          setPopupMessageId(response.data[0]._id);
          setPopupNotificationClosed(true);
        }
      }
    );
  };

  const handleNotificationPopup = () => {
    messageRead();
    // setDepositWithdrawNotificationClosed(false);
    setPopupNotificationClosed(false);
  };

  const fetchPlayerBalance = () => {
    makeAjax(url.GP_API.getBalance, [], url.GP_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setPlayerBalance(response.data);
    });
  };



  const extractScriptAttributes = (scriptString) => {
    const srcRegex = /src=["'](.*?)["']/;
    const idRegex = /id=["'](.*?)["']/;
    const srcMatch = scriptString.match(srcRegex);
    const idMatch = scriptString.match(idRegex);
    const src = srcMatch ? srcMatch[1] : null;
    const id = idMatch ? idMatch[1] : null;
    if (src == null && id == null) {
      let doc = new DOMParser().parseFromString(scriptString, "text/xml");
      let src = doc.firstChild.textContent;
      return { src, id };
    }
    return { src, id };
  };

  const getLiveChatScript = () => {
    return new Promise((resolve, reject) => {
      makeAjax(url.MASTER_API.getLiveChatConfig, [], url.MASTER_MS_EXT)
        .then((response) => {
          if (response.status !== respStatus["SUCCESS"]) {
            if (response.code !== "validation_error") {
              showMessage(response);
              reject(response);
              return;
            }
            showMessage(response);
            reject(response);
            return;
          }
          if (response.data && response.data.script) {
            const { src, id } = extractScriptAttributes(response.data.script);
            resolve({ scriptSrc: src, scriptId: id });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getNotificationCount = () => {
    makeAjax(url.MEMO_API.getPlayerUnreadMessageCount, [], url.MEMO_MS_EXT).then((response) => {

      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setNoticationCount(response.data);
    })
  }

  const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest} >
      {children}
    </Link>
  ));

  useEffect(() => {
    if (loggedIn != null) {
      getNotificationCount();
    }
    let scriptElement;
    getLiveChatScript()
      .then((scriptAttributes) => {
        const { scriptSrc, scriptId } = scriptAttributes;
        let element = document.getElementById("title");
        scriptElement = document.createElement("script");
        if (scriptId == null) {
          scriptElement.innerHTML = scriptSrc;
        } else {
          scriptElement.src = scriptSrc;
          scriptElement.id = scriptId;
        }
        element.before(scriptElement);
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, []);

  const logout = () => {
    dispatch(resetPlayerProfile());
    dispatch(resetPinStatus());
    dispatch(getLogout());
    window.localStorage.removeItem("user_token");
    window.localStorage.removeItem("isLoggedIn");
    makeAjax(url.USER_API.listPlayerAlternativeSite, {})
      .then((response) => {
        if (
          response.status !== respStatus["SUCCESS"] ||
          (response.data &&
            response.data.data &&
            response.data.data.length == 0)
        ) {
          navigate("/");
          return;
        }
        let sites = response.data.data;
        for (let i = 0; i < sites.length; i++) {
          if (
            sites[i]["is_logout"] &&
            window.location.host != sites[i]["domain"]
          ) {
            window.location.assign(
              (sites[i]["is_secure"] ? "https" : "http") +
              "://" +
              sites[i]["domain"]
            );
            return;
          }
        }
        let nonPrimary = {};
        for (let j = 0; j < sites.length; j++) {
          if (
            sites[j]["is_primary"] &&
            window.location.host != sites[j]["domain"]
          ) {
            window.location.assign(
              (sites[j]["is_secure"] ? "https" : "http") +
              "://" +
              sites[j]["domain"]
            );
            return;
          } else if (
            !sites[j]["is_login"] &&
            !sites[j]["is_logout"] &&
            !sites[j]["is_redirect"] &&
            window.location.host != sites[j]["domain"]
          ) {
            nonPrimary = sites[j];
          }
        }
        if (nonPrimary.domain) {
          window.location.assign(
            (nonPrimary.is_secure ? "https" : "http") +
            "://" +
            nonPrimary.domain
          );
          return;
        } else {
          navigate("/");
        }
      })
      .catch((ex) => {
        navigate("/");
      });
  };

  const goToMemoPage = (event) => {
    navigate('/memo')
  }

  // mobile before login image hide
  useEffect(() => {
    if (localStorage.getItem('mobile_pop_up_before_login') == null) {
      setMobileBeforeLogin(true);
    } else {
      if (localStorage.getItem('mobile_pop_up_before_login') != null) {
        const currentDate = new Date();
        const targetDate = new Date(localStorage.getItem('mobile_pop_up_before_login'));

        const timeDifference = currentDate - targetDate;
        const timeDifferenceInMinutes = timeDifference / (1000 * 60);
        if (timeDifferenceInMinutes > 10) {
          setMobileBeforeLogin(true);
        } else {
          setMobileBeforeLogin(false);
        }

      }

    }
  }, [mobileBeforeLogin]);


  //mobile after login image hide
  useEffect(() => {
    if (localStorage.getItem('mobile_pop_up_after_login') == null) {
      setMobileAfterLogin(true);
    } else {
      if (localStorage.getItem('mobile_pop_up_after_login') != null) {
        const currentDate = new Date();
        const targetDate = new Date(localStorage.getItem('mobile_pop_up_after_login'));

        const timeDifference = currentDate - targetDate;
        const timeDifferenceInMinutes = timeDifference / (1000 * 60);
        if (timeDifferenceInMinutes > 10) {
          setMobileAfterLogin(true);
        } else {
          setMobileAfterLogin(false);
        }

      }

    }
  }, [mobileAfterLogin]);

  return (
    <>
      <header className="header-mobile">
        <div>
          <span
            className={`hamburger ${sideBar && "click"}`}
            ref={btnRef}
            onClick={hamburgerClick}
          >
            <HiMenuAlt1 />
          </span>

          {typeof componentSetting?.["top-nav-logo"] != 'undefined' && componentSetting?.["top-nav-logo"]?.["default-value"] &&

            <a href={assets["link"]} className="header-mlogo">
              <span>
                <img
                  className=""
                  src={assets["image"]}
                  alt={assets["alt"]}
                  width={assets["width"]}
                  height={assets["height"]}
                />
              </span>
            </a>
          }
        </div>
        {loggedIn != null && loggedIn != '' && userProfile.username ? (
          <div className="user-info">
            {notificationCount > 0 ? <div className="red-dot"></div> : ("")}
            <div className="notification-icon" onClick={() => setNotification(true)}>
              <MdNotificationsNone />
            </div>

            <Dropdown data-bs-theme="dark">
              <Dropdown.Toggle className="user-icon">
                {/* U */}
                {userProfile.username ? userProfile.username[0].toUpperCase() : 'U'}
              </Dropdown.Toggle>
              {!userProfile.email_verified_at && <div className="red-dot1"></div>}
              <Dropdown.Menu>
                <Dropdown.Item as={NavLink} href="/profile" active>
                  <BiUser className="header-proicons" />
                  <span>{props.t('profile')}
                    {!userProfile.email_verified_at && <div className="red-dot1"></div>}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item>
                  <a onClick={() => setIsPopupReset(!isPopupReset)}>
                    <CgPassword className="header-proicons" />
                    <span>{props.t('reset_password')}</span>
                  </a>
                </Dropdown.Item>
                <Dropdown.Item onClick={logout}>
                  {" "}
                  <TbLogout className="header-proicons" />
                  <span>{props.t('log_out')}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </div>
        ) : (
          <div>
            {typeof componentSetting?.["top-nav-login-box"] != 'undefined' && componentSetting?.["top-nav-login-box"]?.["default-value"] &&
              <>
                {/* <LanguageDropdown /> */}
                <a className="purple-btn" onClick={() => setIsPopup(true)}>
                  {props.t('login')}{" "}
                </a>
              </>
            }
          </div>

        )}
      </header>
      <SideBar
        sideRef={sideRef}
        sideBar={sideBar}
        sideBarClose={sideBarClose}
        loginPopupTrigger={loginPopupTrigger}
        userDataHandleClick={userDataHandleClick}
      />
      {isPopup && (
        <Popup
          handlePoup={handlePopup}
          // loginMobile
          loginpopup
          userDataHandle={userDataHandle}
        />
      )}
      {typeof componentSetting?.["news"] != 'undefined' && componentSetting?.["news"]?.["default-value"] && <News />}
      {popup && userProfile.username && mobileAfterLogin && (
        <div className="homepage-popup"><Popup handlePoup={handlePopup} afterLogin /></div>
      )}

      {popup && !userProfile.username && mobileBeforeLogin && (
        <div className="homepage-popup"><Popup handlePoup={handlePopup} beforeLogin /></div>
      )}

      {isPopupReset && (<Popup handlePoup={resetHandlePopup} changePass />)}

      {isNotification &&
        <Popup handlePoup={handlePopup} length={notificationCount} getNotificationCount={getNotificationCount} readNotification />
      }

      {popupUnreadMessage != undefined &&
        popupUnreadMessage != "undefined" &&
        popupNotificationClosed && (
          <HomeScreenPopup
            handlePopup={handleNotificationPopup}
            popupNotificationClosed={popupNotificationClosed}
            data={popupUnreadMessage}
          />
        )}
    </>
  );
};

HeaderMobile.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(HeaderMobile);
