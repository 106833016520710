import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';

const Go = () => {
    const { subDomain } = useParams();
    const navigate = useNavigate();
    const getSubDomain = () => {
        if (subDomain != '') {
            localStorage.setItem('user_tenant_sub_domain', subDomain);
            navigate('/');
        }
    }
    useEffect(() => {
        getSubDomain();
    }, []);
}

export default Go;