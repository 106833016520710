import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
  currencyFormat,
  currentDate,
  dateFormat,
} from "../../../helpers/global_helper";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineChevronLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineChevronRight,
} from "react-icons/md";
import WLPagination from "../../../pages/utility/WLPagination";
import Loading from "../../games/Loading/Loading";

const TransHistoryInner = (props) => {
  const [formData, setFormData] = useState({
    from_date: currentDate(),
    to_date: currentDate(),
    transaction_type: "win",
    page: 1,
  });
  const [listData, setListData] = useState([]);
  const [formError, setFormError] = useState({});
  const [transactionTypeDD, setTransactionTypeDD] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    // Function to update window width state on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };


  const transationTypeDropDown = () => {
    setFormError({});
    makeAjax(url.GP_API.transactionTypeDD, [], url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == "validation_error") {
            setFormError(response.messages);
            return;
          }
          showMessage(response.messages);
          return;
        }

        setTransactionTypeDD(response.data);

      }
    );
  };

  useEffect(() => {
    transationTypeDropDown();
  }, []);

  const formChange = (e) => {
    setFormError({});

    let t = JSON.parse(JSON.stringify(formData));
    t[e.target.name] = e.target.value;
    setFormData(t);
  };

  const fetchTransHistory = (nextPage) => {
    return new Promise((resolve, reject) => {
      setFormError({});
      if (nextPage == 1) setListData([]);
      formData["page"] = nextPage;
      formData["per_page"] = 15;
      setLoading(true);
      makeAjax(url.DPWD_API.listPlayerAllTransation, formData, url.DPWD_MS_EXT)
        .then((response) => {
          setLoading(false);
          if (response.status != respStatus["SUCCESS"]) {
            if (response.code == "validation_error") {
              setFormError(response.messages);
              return;
            }
            showMessage(response.messages);
            return;
          }
          if (response?.data) {
            resolve({
              data: response?.data.data,
              lastPage: response.data?.last_page,
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const TransHistoryList = async (nextPage) => {
    try {
      let response = "";
      response = await fetchTransHistory(nextPage);
      setListData((prevRecords) => [...prevRecords, ...response.data]);
      setLastPage(response.lastPage);
      setPage(nextPage + 1);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      //
    }
  };

  return (
    <div className="trans-history">
      <div className="container">
        <div className="trans-history-div">
          <h4 className="title">{props.t("transaction_history")}</h4>
          <div className="trans-history-box">
            <form onSubmit={handleSubmit}>
              <div className="trans-container">
                <div className="trans-date">
                  <div className="datepicker">
                    <input
                      type="date"
                      name="from_date"
                      max={formData.to_date}
                      value={formData.from_date}
                      onChange={formChange}
                    />
                    <span className="text-danger">
                      {formError.from_date ? formError.from_date[0] : ""}
                    </span>
                  </div>
                </div>

                <div className="trans-datepic">
                  <div className="datepicker">
                    <input
                      type="date"
                      name="to_date"
                      min={formData.from_date}
                      value={formData.to_date}
                      onChange={formChange}
                    />
                    <span className="text-danger">
                      {formError.to_date ? formError.to_date[0] : ""}
                    </span>
                  </div>
                </div>

                <div className="select-type">
                  <select
                    name="transaction_type"
                    onChange={formChange}
                    selected={formData.transaction_type}
                  >
                    {transactionTypeDD.map((item, i) => (
                      <option key={i} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="trans-btn">
                  <button className="m-0" onClick={() => TransHistoryList(1)}>
                    {props.t("search")}
                  </button>
                </div>
              </div>
            </form>

            {windowWidth >= 991 ? (
              // Desktop div
              <div>
                <div className="table-res-cus">
                  <table>
                    <thead>
                      <tr>
                        <th>{props.t("date")}</th>
                        <th>{props.t("trans_id")}</th>
                        <th>{props.t("information")}</th>
                        <th>{props.t("debit")}</th>
                        <th>{props.t("credit")}</th>
                        <th>{props.t("balance")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData && listData?.length > 0 ? (
                        <>
                          {listData.map((item, i) => (
                            <tr key={i}>
                              <td>{dateFormat(item.created_at)}</td>
                              <td>{item._id}</td>
                              <td>{item.transaction_type}</td>
                              <td>
                                {item.debit ? currencyFormat(item.debit) : "-"}
                              </td>
                              <td>
                                {item.credit
                                  ? currencyFormat(item.credit)
                                  : "-"}
                              </td>
                              <td>{currencyFormat(item.current_balance)}</td>
                            </tr>
                          ))}
                          {lastPage != 1 && (
                            <tr>
                              <td colSpan={6} className="show-btn">
                                <button
                                  onClick={() => {
                                    lastPage > page - 1
                                      ? TransHistoryList(page)
                                      : TransHistoryList(1);
                                  }}
                                >
                                  {lastPage > page - 1 ? (
                                    <>{props.t("show_more")}</>
                                  ) : (
                                    <>{props.t("show_less")}</>
                                  )}
                                </button>
                              </td>
                            </tr>
                          )}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center table-no-reacord">
                            {props.t("no_record_found")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              // Mobile div
              <div>
                {listData && (
                  <>
                    {listData && listData?.length > 0 ? (
                      <>
                        <div className="trans-histry-data">
                          {listData?.map((item) => (
                            <div className="histry-card">
                              <div className="table-data">
                                <div className=" d-flex">
                                  {" "}
                                  <div className="data-one">
                                    {props.t("transaction_id")}:
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="data-two">
                                    {dateFormat(item?.created_at)}
                                  </div>
                                </div>
                              </div>
                              <div className="table-data d-flex">
                                <div className="data-three">
                                  {item.transaction_id}
                                </div>
                                {item.transaction_type == "win" ? (
                                  <div className="data-four text-success">
                                    {item.transaction_type}
                                  </div>
                                ) : item.transaction_type == "bet" ? (
                                  <div className="data-four text-warning">
                                    {item.transaction_type}
                                  </div>
                                ) : item.transaction_type == "loss" ? (
                                  <div className="data-four text-danger">
                                    {item.transaction_type}
                                  </div>
                                ) : (
                                  <div>{item.transaction_type}</div>
                                )}
                              </div>
                              <div className="table-info d-flex">
                                <div className="credit-data d-flex">
                                  <div className="data-txtdt">
                                    {props.t("credit")}:
                                  </div>
                                  <div className="data-eight">
                                    {currencyFormat(item.credit)}
                                  </div>
                                </div>

                                <div className="debit-data d-flex">
                                  <div className="data-one">
                                    {props.t("debit")}:
                                  </div>
                                  <div className="data-eight">
                                    {currencyFormat(item.debit)}
                                  </div>
                                </div>
                              </div>
                              <div className="debit-data d-flex">
                                <div className="data-one">
                                  {props.t("balance")}:
                                </div>
                                <div className="data-six">
                                  {currencyFormat(item.current_balance)}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        {lastPage != 1 && (
                          <div className="show-btn">
                            <button
                              onClick={() => {
                                lastPage > page - 1
                                  ? TransHistoryList(page)
                                  : TransHistoryList(1);
                              }}
                            >
                              {lastPage > page - 1 ? (
                                <>{props.t("show_more")}</>
                              ) : (
                                <>{props.t("show_less")}</>
                              )}
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="text-center pt-2">
                        <span className="table-no-reacord">
                          {props.t("no_record_found")}
                        </span>
                      </div>

                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

TransHistoryInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(TransHistoryInner);
