import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: hardSet,
  whitelist: [
    'menuListR',
    'componentSettingListR',
    'metaInformationListR',
    'textInformationListR',
    'logoutR',
    'footerInformationListR',
    'submenuListR',
    'playerProfileR',
    'referalInformationListR',
    'othermediaInformationListR',
    'cssInformationListR',
    'checkPinStatusR',
    'dropdownR',
    'myLangR',
    'getMemoCountR',
    'getPlayerBalanceR',
    'DepositPendingRequestR'
  ]
}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storer = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

const persistor = persistStore(storer);
sagaMiddleware.run(rootSaga);
export { storer, persistor };