import React from "react";
import Search from "../games/search/Search";
import { GoCalendar } from "react-icons/go";
import { ImInfo } from "react-icons/im";
import CommonSec from "../commonSection/CommonSec";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  dateFormat,
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../helpers/global_helper";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../popup/Popup";
import { Route } from "react-router-dom";
import CompleteResult from "../complete-result/CompleteResult";
const LotteryGames = (props) => {
  const currDate = new Date().toLocaleDateString();

  const [lotteryResult, setLotteryResult] = useState([]);
  const [openPopUp, setModal] = useState(false);
  const [gameId, setGameId] = useState("");
  const [maintainanceError, setMaintainanceError] = useState(false);
  const navigate = useNavigate();

  const getLotteryResult = () => {
    makeAjax(url.GP_API.getLotteryResult, [], url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setLotteryResult(response.data);
      }
    );
  };

  const callRegisterMethod = (game) => {
    const payload = {
      game_id: game.game_id,
    };
    setGameId({ ...gameId, game_id: game.game_id });
    makeAjax(url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == "game_under_maintenance") {
            setMaintainanceError(true);
            return;
          }

          if (response.code == "validation.invalid_token_error") {
            navigate("/register");
            return;
          }

          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }

        //pg soft game data 7th feb 2024
        if (response?.data?.code === "bd200") {
          const newTab = window.open();
          newTab.document.write(response.data.data);
        } else {
          window.open(response.data, "_blank");
        }
      }
    );
  };

  const callCompleteResult = (game) => {
    const code = game.code;
    navigate(`/result/${code}`);
    return;
  };
  const activeModal = () => {
    setModal(!openPopUp);
  };

  useEffect(() => {
    getLotteryResult();
  }, []);

  return (
    <>
      <div className="games-inner">
        <div className="container">
          <div className="row">
            <div className="lottery-text">
              <div className="title">{props.t('lottery_results')}</div>
              <div className="sub-title">{props.t('hkb_gaming')}</div>
            </div>
            <div className="countrygames">
              {lotteryResult.map((v, i) => (
                <div key={i} className="countrygames-main-col">
                  <div className="logo-left">
                    <img alt="Countrygames"
                      onClick={() => {
                        callRegisterMethod(v);
                      }}
                      src={`../assets/images/lottery/${v.lottery_name.toLowerCase()}.png`}
                    />
                    <div className="right-content-wrap">
                      <h2>
                        {props.t("togel")}
                        <span
                          onClick={() => {
                            callRegisterMethod(v);
                          }}
                        >
                          {" "}
                          {v?.lottery_name}
                        </span>
                      </h2>
                      <div className="date-periode">
                        <div
                          className="boxes-sub"
                          onClick={() => {
                            callRegisterMethod(v);
                          }}
                        >
                          {/* <GoCalendar />
                         */}
                          {dateFormat(v?.date)}
                        </div>
                        <div
                          className="boxes-sub-txt"
                          onClick={() => {
                            callRegisterMethod(v);
                          }}
                        >
                          {/* <ImInfo />
                          {props.t("period")} SD:{v.period} */}
                          {props.t('elementry_period')}:{v?.period}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="numbers-wrap">
                    <div className="col-xs-12 mx-auto numbers grid w-3/4 gap-2 mx-auto items-center justify-center">

                      {v.lottery_number &&
                        v.lottery_number.map((n) => (
                          <div className="number-balls" onClick={() => {
                            callRegisterMethod(v);
                          }}>
                            {n}
                          </div>
                        ))}
                    </div>

                    <div className="countrygames-result-btn text-center">

                      <a
                        onClick={() => {
                          callCompleteResult(v);
                        }}
                      >
                        {props.t("complete_results")}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LotteryGames.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(LotteryGames);
