import React from 'react'

const Loading = () => {
  return (
    <div className='loading'>

      <div className='text'>Loading<span className="dot-one">.</span><span className="dot-two">.</span><span className="dot-three">.</span></div>
    </div>

  )
}

export default Loading
