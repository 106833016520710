import {
  FETCH_TEXT_INFO,
  FETCH_TEXT_INFO_SUCCESS
} from "./actionTypes"


export const fetchTextInfo = (param = '') => {
  return {
    type: FETCH_TEXT_INFO,
    payload: {'code': param},
  }
}

export const fetchTextInfoSuccess = (response) => {
  return {
    type: FETCH_TEXT_INFO_SUCCESS,
    payload: response,
  }
}