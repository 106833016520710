import {
  FETCH_CSS_INFO,
  FETCH_CSS_INFO_SUCCESS
} from "./actionTypes"


export const fetchCssInfo = (param = '') => {
  return {
    type: FETCH_CSS_INFO,
    payload: {'code': param},
  }
}

export const fetchCssInfoSuccess = (response) => {
  return {
    type: FETCH_CSS_INFO_SUCCESS,
    payload: response,
  }
}