import React from "react";
import CommonSec from "../commonSection/CommonSec";
import {
  Container,
  Row,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
  Table,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import Popup from "../popup/Popup";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  dateFormat,
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../helpers/global_helper";
import { useParams } from "react-router-dom";

const CompleteResult = (props) => {
  const [isMessageSet, setIsMessageSet] = useState(false);
  const [numberDetail, setNumberDetail] = useState([]);
  const [toggleName, setToggleName] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handlePopup = () => {
    setIsMessageSet(false);
  };
  const popUpMessage = (v) => {
    setIsMessageSet(true);
    setNumberDetail(v);
  };
  const toggleCode = {
    code: "",
  };

  const { parameter } = useParams();
  const [lotteryData, setLotteryData] = useState([]);
  const [lotteryResult, setLotteryResult] = useState([]);

  const getLotteryDD = () => {
    makeAjax(url.GP_API.tenantToggleDD, [], url.GP_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setLotteryData(response.data);
    });
  };

  const getLotteryWiseData = (id) => {
    if (id == undefined) {
      toggleCode["code"] = parameter;
      let selectedOption = lotteryData.find((v) => v.code === parameter);
      setToggleName(selectedOption);

    } else {
      toggleCode["code"] = lotteryData[id]["code"];
    }

    makeAjax(url.GP_API.getLotteryResultDetail, toggleCode, url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setLotteryResult(response.data);
      }
    );


    if (id !== undefined) {
      setToggleName(lotteryData[id]);

    }
  };

  useEffect(() => {
    getLotteryDD();
  }, []);

  useEffect(() => {
    if (lotteryData.length > 0) {
      getLotteryWiseData();
    }
  }, [lotteryData]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  };


  useEffect(() => {
    // Function to update window width state on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section className="completeresult">
        <Container>
          <Row>
            <div className="resultbg">
              <h3>
                {" "}
                {toggleName && toggleName.name && `${toggleName.name}`}{" "}
                {props.t("all_lottery_results")}
              </h3>

              <div className="text-center">
                <h3 className="para">
                  {props.t("market_close_and_market_open_time")}{" "}
                  {toggleName &&
                    toggleName.opening_time &&
                    `${toggleName.opening_time}`}{" "}
                  /{" "}
                  {toggleName &&
                    toggleName.closing_time &&
                    `${toggleName.closing_time}`}{" "}
                </h3>
              </div>
              <Row>
                <Col xs={{ order: 2, span: 12 }} lg={{ order: 1, span: 2 }}>
                  <select
                    id="country"
                    name="country"
                    className="coun-button"
                    onChange={(e) => getLotteryWiseData(e.target.value)}
                    styles={customStyles}
                  >
                    {lotteryData &&
                      lotteryData.map((v, i) => (
                        <option
                          className="cs-pointer"
                          value={i}
                          selected={v.code === parameter}
                        >
                          {v.name}
                        </option>
                      ))}
                  </select>
                </Col>
              </Row>

              {windowWidth >= 991 ? (
                <div>
                  <table className="resulttable">
                    <thead className="tablebg">
                      <tr className="resultrow">
                        <th>{props.t("date")}</th>
                        <th>{props.t("period")}</th>
                        <th>{props.t("result")}</th>
                      </tr>
                    </thead>
                    <tbody className="resulttable">
                      {typeof lotteryResult != "undefined" &&
                        lotteryResult &&
                        lotteryResult.map((v, i) => (
                          <tr className="resultrow">
                            <td>{dateFormat(v.date)}</td>
                            <td>{v.period}</td>
                            <td>
                              <a onClick={() => popUpMessage(v)}>{v.result}</a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="resulttable">
                  {typeof lotteryResult != "undefined" &&
                    lotteryResult &&
                    lotteryResult.map((v, i) => (
                      <table className="result-card">
                        <tbody>
                          <tr>
                            <td className="result-demo"><span className="title-text">{props.t("period")}:</span></td>
                            <td className="date-form">
                              <span className="title-text">{props.t("date")}:</span>
                              <span className="result-date">{dateFormat(v.date)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td><span className="period">{v.period}</span></td>
                          </tr>
                          <tr>
                            <td>
                              <span className="title-text">{props.t("result")}:</span>
                              <span className="result-link">
                                {" "}
                                <a onClick={() => popUpMessage(v)}>
                                  {v.result}
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                </div>
              )}
            </div>
          </Row>
        </Container>
        {isMessageSet && (
          <Popup
            handlePoup={handlePopup}
            resultData
            numberData={numberDetail}
          />
        )}
      </section>
      <CommonSec />

    </>
  );
};

CompleteResult.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(CompleteResult);
