import { useState, useEffect } from 'react'
import { CgMenuGridR } from 'react-icons/cg'
import { FaBackspace } from 'react-icons/fa'
import { showMessage, makeAjax, url, respStatus } from "../../../../helpers/global_helper"
import { useNavigate } from 'react-router-dom';
import Popup from '../../../popup/Popup';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getPlayerProfileDetail } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import ActionLoader from '../../../../pages/ActionLoader';
const PinSecurityValidation = (props) => {

    const [disabledNum1, setDisabledNum1] = useState(true)


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pinBox1, setPinBox1] = useState(false)

    const InitFormData = {
        active_pin: '',
        type: 'security-profile'
    }

    const [formData, setFormData] = useState(InitFormData);
    const [formError, setFormError] = useState({});
    const [formSuccess, setFormSuccess] = useState(false);
    const [isSuccessMessagePopUp, setIsSuccessMessagePopUp] = useState(false);
    const [loaderStatus, setLoader] = useState();
    const { userProfile } = useSelector((state) => ({
        userProfile: state.playerProfileR.data,
    }));
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true);
        makeAjax(
            url.USER_API.checkValidatePin, formData, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setLoader(false);
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                setLoader(false);
                setFormError({});
                setFormData({ ...formData, ...{ active_pin: '' } });
                pinDisable();
            });
    }

    const pinDisable = () => {
        makeAjax(
            url.USER_API.updatePinStatus, { enabled_active_pin: false }, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                setFormSuccess(response.messages);
                setIsSuccessMessagePopUp(true);
            });
    }


    const numPadClear1 = () => {
        setFormError({});
        setFormData({ ...formData, ...{ active_pin: formData.active_pin.slice(0, -1) } })
        setDisabledNum1(true)
    }
    const numPad1 = (e) => {
        setFormError({});
        const numMain1 = formData.active_pin + e.target.id
        if (numMain1.length > 6) {
            return false;
        }
        if (numMain1.length == 6) {
            setDisabledNum1(false)
        }

        setFormData({ ...formData, ...{ active_pin: numMain1 } })

    }

    // Logout api call
    const back = () => {
        dispatch(getPlayerProfileDetail());
        navigate('/profile');
        // navigate(0);
    }

    const [numbers, setNumbers] = useState([]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    useEffect(() => {
        const shuffledNumbers = shuffleArray([...Array(10).keys()]);
        setNumbers(shuffledNumbers);
    }, []);

    const closeBox = () => {
        setPinBox1(false);
        setFormData(InitFormData);
        const shuffledNumbers = shuffleArray([...Array(10).keys()]);
        setNumbers(shuffledNumbers);
        setDisabledNum1(true);
    }

    const handleSuccess = () => {
        setIsSuccessMessagePopUp(false)
        dispatch(getPlayerProfileDetail());
        navigate("/profile");
        // navigate(0);
    }

    return (
        <div className='new-auth login-pin pin-security-validation'>
            {/* <img src='/assets/images/logo.png' alt='logo' /> */}
            <div className="new-pass-box mt-5">
                <h4 className="title">{props.t('welcome')}  {userProfile.username}</h4>
                <div className="new-pass-box-inner">
                    <h4 className="sub-title">{props.t('security_validation')}</h4>
                    <p className="desc">{props.t('aims_to_increase_the_security_and_privacy_of_your_account')}</p>
                    <form>
                        <div className="password-icon">
                            <input type="password" placeholder={props.t('enter_your_pin')} value={formData.active_pin} onClick={() => setPinBox1(curr => !curr)} />
                            <CgMenuGridR onClick={() => setPinBox1(curr => !curr)} />
                            {formError.active_pin ? (
                               <div className='errmsgsec'> <span className="text-danger error-msg">{formError.active_pin[0]}</span></div>
                            ) : null}
                            {pinBox1 &&
                                <div className="pin-box">
                                    <input type="password" placeholder="Pin" value={formData.active_pin} onChange={() => { }} maxLength={6} pattern="[^\s]+" />
                                    <div className="enter-pin">
                                        <div className="num-box">
                                            {numbers.map(number => (
                                                <span key={number} className="num" id={number} onClick={numPad1}>{number}</span>
                                            ))}
                                        </div>
                                        <div className="button-box">
                                            <button className='okay' onClick={() => setPinBox1(false)} disabled={disabledNum1}>{props.t('okay')}</button>
                                            <span className="back" onClick={numPadClear1}><FaBackspace /></span>
                                            <span className='close' onClick={closeBox}>{props.t('close')}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <button type='button' onClick={handleSubmit}>{props.t('process')}</button> */}
                        <button className='cancel' type='button' onClick={back} >{props.t('log_out')}</button>
                        <ActionLoader label={'process'} loaderClass={'pin-sec-loader'} showLoader={loaderStatus} onClick={handleSubmit} />
                    </form>
                    <span className="note">{props.t('forgot_pin')} ? <a href='#'>{props.t('contact_our_operator')}</a></span>
                </div>
            </div>
            {/* Success Message Pop Up */}
            {isSuccessMessagePopUp && <Popup handlePoup={handleSuccess} data={[formSuccess]} successMessages />}
        </div>
    )
}

PinSecurityValidation.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PinSecurityValidation);