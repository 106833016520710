import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

const Faq = ({ accordionData, messages, setMessages }) => {

    const [openTabs, setOpenTabs] = useState([]);



    const handleTabClick = (eventKey) => {
        const isOpen = openTabs.includes(eventKey);
        if (isOpen) {
            setOpenTabs(openTabs.filter((tab) => tab !== eventKey));
        } else {
            setOpenTabs([...openTabs, eventKey]);
        }
    };


    const handleSubtabClick = (data) => {
        setMessages([...messages, { text: data, sender: 'user' }]);
    }

    const [isContentVisible, setIsContentVisible] = useState(true);

    const toggleContent = () => {
        setIsContentVisible(!isContentVisible);
    };



    return (
        <>

            {/* Active FAQ state */}
            <Accordion>
                {accordionData.map((item, index) => (
                    <Accordion.Item key={index} eventKey={index} className='accordian-tab-active in-down'>
                        <Accordion.Header className='main-tab' onClick={() => handleTabClick(index)}>
                            {item.label} <span className="icon-wrapper" onClick={toggleContent}>
                                {isContentVisible ? <FaChevronDown /> : <FaChevronUp />}
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion>
                                {item.subtabs &&
                                    item.subtabs.map((subtab, index) => (
                                        <Accordion.Item key={index} className='accordian-subtab-active'>
                                            <Accordion.Header onClick={() => handleSubtabClick(subtab.label)}>
                                                {subtab.label}
                                            </Accordion.Header>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>

            {/* Disabled FAQ state */}
            <Accordion>
                {accordionData.map((item, index) => (
                    <Accordion.Item key={index} eventKey={index} className='accordian-tab-disabled selected-bg in-down'>
                        <Accordion.Header className='main-tab' onClick={() => handleTabClick(index)}>
                            {item.label} <span className="icon-wrapper" onClick={toggleContent}>
                                {isContentVisible ? <FaChevronDown /> : <FaChevronUp />}
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion>
                                {item.subtabs &&
                                    item.subtabs.map((subtab, index) => (
                                        <Accordion.Item key={index} className='accordian-subtab-disabled'>
                                            <Accordion.Header onClick={() => handleSubtabClick(subtab.label)}>
                                                {subtab.label}
                                            </Accordion.Header>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    )
}

export default Faq
