import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";


const ForgotPassword = (props) => {
    return (
        <div className='popup-box'>
            <h4 className="title">{props.t('information')}</h4>
            <p className='popup-error-msg'>{props.data}</p>
        </div>
    )
}

ForgotPassword.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ForgotPassword);