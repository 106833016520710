import { useDispatch, useSelector } from 'react-redux'
import CommonSec from '../../components/commonSection/CommonSec'
import FloatingComponents from '../../components/floatingComponents/FloatingComponents'
import DepositInner from '../../components/user-pages/deposit/DepositInner'
import { getPlayerProfileDetail } from '../../store/actions'
import { useEffect } from 'react'
// import { componentSetting } from '../../template-settings'

const Deposit = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPlayerProfileDetail());
    }, []);
    
    return (
        <div className='deposit-com'>
            <DepositInner />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
        </div>
    )
}

export default Deposit