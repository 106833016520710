import { FETCH_REFERAL_INFO_SUCCESS } from "./actionTypes";

const initialState = {
    data: {},
};

const referalInformationListR = (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_REFERAL_INFO_SUCCESS:
            state = { ...state, data: action.payload.response };
            return state;
    }
    return state;
}

export default referalInformationListR