import { call, put, all, takeEvery } from "redux-saga/effects";
import {
  FETCH_FOOTER_INFO
} from "./actionTypes"
import {
  fetchFooterInfoSuccess
} from "./actions";

import { makeAjax, url, showMessage } from "../../helpers/global_helper";
import { USER_MS_EXT } from "../../helpers/url_helper";

function* fetchFooterInfo() {
  try {
    
    const response = yield call(
      makeAjax,
      url.USER_API.listPageFooterInfo,
      {},
      USER_MS_EXT
    );
    if (response.status == 'SUCCESS') {
      yield all([
        put(fetchFooterInfoSuccess({ response: response.data }))
      ]);
    }
    else {
      showMessage(response);
    }
  } catch (error) {
    showMessage(error);
    
    // alert
  }
}

function* footerInfomationSaga() {
  yield takeEvery(FETCH_FOOTER_INFO, fetchFooterInfo);
}

export default footerInfomationSaga;
