import {
  FETCH_USER_VALIDATION,
  FETCH_USER_VALIDATION_SUCCESS,
  RESET_USER_VALIDATION,
} from "./actionTypes"


export const checkPinStatus = () => {
  return {
    type: FETCH_USER_VALIDATION,
    payload: {},
  }
}


export const resetPinStatus = () => {
  return {
    type: RESET_USER_VALIDATION,
    payload: {},
  }
}

export const checkPinStatusSuccess = (response) => {
  return {
    type: FETCH_USER_VALIDATION_SUCCESS,
    payload: response,
  }
}