import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
  dateFormat,
} from "../../../helpers/global_helper";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DepositWithdrawMessage from "../../popup/popup-components/DepositWithdrawMessage";
import ActionLoader from "../../../pages/ActionLoader";
import Select from "react-select";
import CInput from "../../commonSection/currency-input/CInput";
import { Col, Row, Table } from "react-bootstrap";

const DepositInner = (props) => {
  const navigate = useNavigate();

  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));
  const [loaderStatus, setLoaderStatus] = useState(false);
  const getValidationStaus = (event) => {
    makeAjax(url.USER_API.getValidationStatus, [], url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
        }

        if (response.data.validation_status != "valid") {
          setValidationStatus(false);
          navigate("/validation");
        } else {
          setValidationStatus(true);
        }
      }
    );
  };

  const initData = {
    bank_id: "",
    amount: "",
    file_name: "",
    base64_file: "",
    promotion_id: "",
  };

  const [formData, setFormData] = useState(initData);
  const [formError, setFormError] = useState({ unknown_error: "" });
  const [unknownError, setUnknownError] = useState("");
  const [viewData, setViewData] = useState({});
  const [depositList, setDepositList] = useState({});
  const [bankList, setBankList] = useState([]);
  const [depositWithdrawMessage, setDepositWithdrawMessage] = useState([]);
  const [
    depositWithdrawNotificationClosed,
    setDepositWithdrawNotificationClosed,
  ] = useState(false);
  const [dwMessageId, setDwMessageId] = useState(null);
  const [validationStatus, setValidationStatus] = useState(false);

  const playerBankDD = () => {
    makeAjax(url.USER_API.playerBankDD, {}, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          return;
        }
        setBankList(response.data);
        if (response.data.length > 0) {
          getBankDetail(response.data[0]._id);
        }
      }
    );
  };

  const checkPendingTransaction = () => {
    makeAjax(url.DPWD_API.getDepostListTen, {}, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          return;
        }
        setViewData({});
        response.data.data.map((data) => {
          if (data.status == "Pending" && data.transaction_type == "deposit") {
            setViewData(data);
            return;
          }
        });
      }
    );
  };

  const listLastTenDepositwithdrawRequest = () => {
    makeAjax(
      url.DPWD_API.listLastTenDepositwithdrawRequest,
      {},
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response.messages);
        return;
      }
      setDepositList(response.data.deposit);
    });
  };

  const depositAmount = () => {
    setLoaderStatus(true);
    if (
      BankDetail.bank.tenant_bank.status == "Deactive" ||
      BankDetail.bank.tenant_bank.status == "Coming_Soon" ||
      BankDetail.bank.tenant_bank.status == "Online"
    ) {
      formData["bank_id"] = BankDetail["bank_id"];
    } else {
      if (
        BankDetail.bank.tenant_bank.status == "Offline" ||
        BankDetail.bank.tenant_bank.status == "Problem"
      ) {
        if (BankDetail.hasOwnProperty("alternateBank")) {
          formData["bank_id"] = BankDetail.alternateBank["bank_id"];
        } else {
          formData["bank_id"] = BankDetail["bank_id"];
        }
      }
    }

    setUnknownError("");
    makeAjax(url.DPWD_API.depositAmount, formData, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          setLoaderStatus(false);
          if (response.code != "validation_error") {
            setUnknownError(response.messages[0]);
          }
          setFormError(response.messages);
          return;
        }
        setLoaderStatus(false);
        showMessage(response);
        initData["bank_id"] = formData["bank_id"];
        setFormData(initData);
        checkPendingTransaction();
      }
    );
  };

  const inputHandler = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
    setUnknownError("");
  };
  useEffect(() => {
    checkPendingTransaction();
    playerBankDD();
    getValidationStaus();
    getRules();
    listLastTenDepositwithdrawRequest();
    depositWithdrawNotificationMessage();
  }, []);

  const [BankDetail, setBankDetail] = useState({});
  const getBankDetail = (id) => {
    makeAjax(url.DPWD_API.getBankDetail, { bank_id: id }, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
          }
          setFormError(response.messages);
          return;
        }
        setBankDetail(response.data);
      }
    );
  };
  const onChangeBank = (event) => {
    getBankDetail(event.target.value);
    setCopyData("");
  };

  const [copyData, setCopyData] = useState("");
  const copy = (data) => {
    navigator.clipboard.writeText(data);
    setCopyData("Copied");
  };

  const uploadImage = (e) => {
    if (!e.target.files.length) {
      formData["base64_file"] = "";
      return;
    }
    let file = e.target.files[0];
    let file_name = e.target.files[0].name;
    let reader = new FileReader();
    reader.onload = (e) => {
      formData["base64_file"] = e.target.result;
      formData["file_name"] = file_name;
      setFormData({ ...formData });
    };
    reader.readAsDataURL(file);
  };

  const [textData, setTextData] = useState({});

  const getRules = () => {
    makeAjax(
      url.ADMIN_API.getRule,
      { code: "deposit_rule" },
      url.ADMIN_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      if (response.data && response.data.value != "") {
        setTextData(response.data);
      }
    });
  };

  //withdraw notification pop up

  const handleNotificationPopup = () => {
    messageRead();
    setDepositWithdrawNotificationClosed(false);
  };

  const messageRead = () => {
    if (messageRead != null) {
      let payload = {
        _id: dwMessageId,
      };
      makeAjax(
        url.DPWD_API.depositWithdrawReadMessage,
        payload,
        url.DPWD_MS_EXT
      ).then((response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
      });
    }
  };

  const depositWithdrawNotificationMessage = () => {
    makeAjax(
      url.DPWD_API.depositWithdrawNotificationMessage,
      [],
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setDepositWithdrawMessage(response.data);
      if (response.data.length > 0) {
        setDwMessageId(response.data[0]._id);
        setDepositWithdrawNotificationClosed(true);
      }
    });
  };

  const { depositRequest } = useSelector((state) => ({
    depositRequest: state.DepositPendingRequestR, //checking redux
  }));

  useEffect(() => {
    setViewData({});
    if (depositRequest != undefined && depositRequest?.data?.data?.length > 0) {
      depositRequest.data.data.map((data) => {
        if (data.status == "Pending" && data.transaction_type == "deposit") {
          setViewData(data);
          return;
        }
      });
    }
  }, [depositRequest]);

  const handleDownloadImage = async (_id) => {

    let payload = {
      _id: _id,
    };
    makeAjax(url.DPWD_API.downloadQRRead, payload, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        if (response?.data?.base_64 != "") {
          const byteCharacters = atob(response?.data?.base_64);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

          // Create a download link
          const downloadLink = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);

          downloadLink.href = objectURL;
          downloadLink.download = response.data.image_name; // Specify the filename

          // Simulate a click on the download link
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Clean up
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(objectURL);
        }
      }
    );
  }



  return (
    <>
      {validationStatus && (
        <div>
          {Object.keys(viewData).length !== 0 ? (
            <>
              <section className="depo-message">
                <div className="container">
                  <div className="depo-message-box">
                    <h3 className="depo-text">
                      {props.t("we_have_received_your_deposit_request")}:
                    </h3>
                    <div className=" depo-amount">
                      {userProfile.currency} &nbsp;
                      {currencyFormat(viewData.amount)}
                    </div>
                    <h4 className="depo-text">
                      {props.t("destination_account")}
                    </h4>
                    <table className="w-2/4 depo-message-table">
                      <tbody>
                        <tr>
                          <td>
                            {props.t("bank")} / {props.t("e_wallet")}:
                          </td>

                          {userProfile.player_bank_detail &&
                            userProfile.player_bank_detail.bank_detail ? (
                            <td className="data-b">
                              {
                                userProfile.player_bank_detail.bank_detail
                                  .bank_name
                              }
                            </td>
                          ) : null}
                        </tr>
                        <tr>
                          <td>
                            {props.t("account")} / {props.t("phone_number")}:
                          </td>

                          <td className="data-b">
                            {userProfile.account_number}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {props.t("account_e_wallet_name")}:
                          </td>

                          {userProfile.player_bank_detail &&
                            userProfile.player_bank_detail.account_name ? (
                            <td className="data-b">
                              {userProfile.player_bank_detail.account_name}
                            </td>
                          ) : null}
                        </tr>
                      </tbody>
                    </table>
                    <p className="depo-message-para">
                      {" "}
                      {props.t(
                        "please_contact_our_operator_via_live_chat_or_memo_to_speed_up_the_process_of_your_deposit"
                      )}{" "}
                      .<br /> {props.t("thank_you")}.
                    </p>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <>
              <div className="deposit">
                <div className="container">
                  {/* <h4 className="title">{props.t('deposit')}</h4> */}
                  <div className="deposit-box">
                    <div className="deposit-box-l">
                      <div className="title-box">
                        <h4 className="sub-title">{props.t("destination")}</h4>
                        <div className="table-res">
                          <table>
                            <tr>
                              <td className="data-label">
                                {props.t("bank")}/{props.t("e_wallet")}:
                              </td>

                              <td className="data">
                                <select onChange={onChangeBank}>
                                  {bankList.map((data, index) => {
                                    return (
                                      <option value={data._id}>
                                        {data.bank_name}
                                      </option>
                                    );
                                  })}
                                </select>{" "}
                              </td>
                            </tr>
                            {BankDetail.bank &&
                              BankDetail.bank.tenant_bank &&
                              BankDetail.bank.tenant_bank.status != "Online" &&
                              BankDetail.alternateBank &&
                              BankDetail.alternateBank.account_name ? (
                              <>
                                {/* <tr>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {BankDetail["base64_file"] && (
                                      <img
                                        src={
                                          BankDetail["ftp_path"] +
                                          BankDetail["base64_file"]
                                        }
                                        alt="no_img_found"
                                        height="100px"
                                        width="100px"
                                      />
                                    )}
                                  </td>
                                </tr> */}
                                <tr>
                                  <td className="data-label">
                                    {props.t("status")}:
                                  </td>

                                  {BankDetail.bank &&
                                    BankDetail.bank.tenant_bank ? (
                                    <td
                                      className="data"
                                      style={
                                        BankDetail.bank.tenant_bank.status ==
                                          "Online"
                                          ? { color: "green" }
                                          : { color: "red" }
                                      }
                                    >
                                      {BankDetail.bank &&
                                        BankDetail.bank.tenant_bank &&
                                        BankDetail.bank.tenant_bank.status
                                        ? BankDetail.bank.tenant_bank.status
                                        : null}
                                    </td>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("alternate_bank_name")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.alternateBank.bank_name
                                      ? BankDetail.alternateBank.bank_name
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("account")} /{" "}
                                    {props.t("e_wallet_name")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.alternateBank.account_name
                                      ? BankDetail.alternateBank.account_name
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("alternate_bank_status")}:
                                  </td>

                                  {BankDetail.alternateBank &&
                                    BankDetail.alternateBank ? (
                                    <td className="data" style={{ color: "green" }}>
                                      {BankDetail.alternateBank &&
                                        BankDetail.alternateBank &&
                                        BankDetail.alternateBank &&
                                        BankDetail.alternateBank.status
                                        ? BankDetail.alternateBank.status
                                        : null}
                                    </td>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("min_deposit")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.alternateBank.deposit_min ? (
                                      <>
                                        {" "}
                                        {currencyFormat(
                                          BankDetail.alternateBank.deposit_min
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {currencyFormat(BankDetail.min_deposit)}
                                      </>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("max_deposit")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.alternateBank.deposit_max
                                      ? BankDetail.alternateBank.deposit_max
                                      : BankDetail.max_deposit}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("first_max_deposit_limit")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.max_first_deposit
                                      ? BankDetail.max_first_deposit
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("deposit_queue_limit")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail?.deposit_queue_max}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("current_queue_number")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail?.current_queue_number}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <tr>
                                  <td className="data-label">
                                    {props.t("type_of_bank")} /{" "}
                                    {props.t("e_wallet")}:
                                  </td>

                                  {BankDetail.bank ? (
                                    <td className="data">
                                      {BankDetail.bank &&
                                        BankDetail.bank.bank_type
                                        ? BankDetail.bank.bank_type
                                        : null}
                                    </td>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("account")} /{" "}
                                    {props.t("e_wallet_name")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.account_name
                                      ? BankDetail.account_name
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("account")} /{" "}
                                    {props.t("phone_number")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.account_number
                                      ? BankDetail.account_number
                                      : null}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {copyData != "" ? (
                                      <button className="copy-btn"
                                        onClick={() =>
                                          copy(
                                            BankDetail.account_number
                                              ? BankDetail.account_number
                                              : null
                                          )
                                        }
                                      >
                                        {props.t("copied")}
                                      </button>
                                    ) : (
                                      <button className="copy-btn"
                                        onClick={() =>
                                          copy(
                                            BankDetail.account_number
                                              ? BankDetail.account_number
                                              : null
                                          )
                                        }
                                      >
                                        {props.t("copy")}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("bank_e_wallet_status")}:
                                  </td>

                                  {BankDetail.bank &&
                                    BankDetail.bank.tenant_bank ? (
                                    <td
                                      className="data"
                                      style={
                                        BankDetail.bank.tenant_bank.status ==
                                          "Online"
                                          ? { color: "green" }
                                          : { color: "red" }
                                      }
                                    >
                                      {BankDetail.bank &&
                                        BankDetail.bank.tenant_bank &&
                                        BankDetail.bank.tenant_bank.status
                                        ? BankDetail.bank.tenant_bank.status
                                        : null}
                                    </td>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("min_deposit")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.deposit_min ? (
                                      <>
                                        {currencyFormat(BankDetail.deposit_min)}{" "}
                                      </>
                                    ) : (
                                      <>
                                        {currencyFormat(BankDetail.min_deposit)}{" "}
                                      </>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("max_deposit")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.deposit_max ? (
                                      <>
                                        {currencyFormat(BankDetail.deposit_max)}{" "}
                                      </>
                                    ) : (
                                      <>
                                        {currencyFormat(BankDetail.max_deposit)}{" "}
                                      </>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("first_max_deposit")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail.max_first_deposit ? (
                                      <>
                                        {currencyFormat(
                                          BankDetail.max_first_deposit
                                        )}{" "}
                                      </>
                                    ) : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("max_deposit_queue")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail?.deposit_queue_max}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="data-label">
                                    {props.t("current_queue_number")}:
                                  </td>

                                  <td className="data">
                                    {BankDetail?.current_queue_number}
                                  </td>
                                </tr>
                              </>
                            )}
                          </table>
                        </div>
                        <div className="depositqr-code text-center">

                          {BankDetail['alternateBank']
                            ? (
                              BankDetail['alternateBank']['base64_file'] &&
                              <>
                                <div style={{ textAlign: "center" }}>
                                  <img src={BankDetail['ftp_path'] + BankDetail['alternateBank']['base64_file']} alt='no_img_found' height='100px' width='100px' />
                                </div>
                                <div className="pt-3">
                                  <a className="gray-btn" onClick={() => handleDownloadImage(BankDetail['alternateBank']['_id'])}>
                                    {props.t("save_qr_code")}
                                  </a>
                                </div>
                              </>
                            )
                            : (
                              BankDetail['base64_file'] &&
                              <>
                                <div style={{ textAlign: "center" }}>
                                  <img src={BankDetail['ftp_path'] + BankDetail['base64_file']} alt='no_img_found' height='100px' width='100px' />
                                </div>
                                <div className="pt-3">
                                  <a className="gray-btn" onClick={() => handleDownloadImage(BankDetail['_id'])}>
                                    {props.t("save_qr_code")}
                                  </a>
                                </div>

                              </>
                            )
                          }
                        </div>

                        {textData.value ? (
                          <p className="deposite-p">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: textData.value,
                              }}
                            ></div>
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="deposit-box-r">
                      <div className="title-box">
                        <h4 className="sub-title">{props.t("sender")}</h4>
                        <div className="table-res ">
                          <table>
                            <tr>
                              <td className="data-label">
                                {props.t("username")}:
                              </td>

                              <td className="data">{userProfile.username}</td>
                            </tr>
                            {/* <tr><td>{props.t('nickname')}</td><td> : </td><td className="data">{userProfile.nickname}</td></tr> */}
                            <tr>
                              <td className="data-label">
                                {props.t("bank")} /{" "}
                                {props.t("e_wallet")}:
                              </td>

                              {userProfile.player_bank_detail &&
                                userProfile.player_bank_detail.bank_detail ? (
                                <td className="data">
                                  {
                                    userProfile.player_bank_detail.bank_detail
                                      .bank_name
                                  }
                                </td>
                              ) : null}
                            </tr>
                            <tr>
                              <td className="data-label">
                                {props.t("account")} /{" "}
                                {props.t("e_wallet_name")}:
                              </td>

                              <td className="data">{userProfile.account_name}</td>
                            </tr>
                            <tr>
                              <td className="data-label">
                                {props.t("deposit_amount")}:
                              </td>

                              <td className="data">
                                <div className="input-control">
                                  <CInput
                                    name="amount"
                                    placeholder={props.t("amount")}
                                    value={formData.amount}
                                    maxLength={15}
                                    onValueChange={(value, name) =>
                                      inputHandler(value, name)
                                    }
                                  />
                                  {formError.amount ? (
                                    <span className="error-msg text-danger">
                                      {formError.amount}
                                    </span>
                                  ) : null}
                                  {unknownError ? (
                                    <span className="error-msg text-danger">
                                      {unknownError}
                                    </span>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="data-label">
                                {props.t("proof_of_transfer")}:
                              </td>

                              <td>
                                <label for="files" className="evi-file">
                                  {props.t("upload")}
                                </label>
                                <input
                                  type="file"
                                  className="evi-file"
                                  name="file_name"
                                  accept="image/*"
                                  onChange={(e) => {
                                    uploadImage(e);
                                  }}
                                  required
                                  style={{ display: "none" }}
                                  id="files"
                                />
                                <span className="upload-span-text">
                                  {formData["file_name"]
                                    ? formData["file_name"]
                                    : "No file chosen"}
                                </span>
                                {formError.file_name ? (
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: "245px",
                                    }}
                                  >
                                    {formError.file_name}
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <td className="data-label">
                                {props.t("account")} /{" "}
                                {props.t("phone_number")}:
                              </td>

                              {userProfile.player_bank_detail &&
                                userProfile.player_bank_detail.account_number ? (
                                <td className="data">
                                  {
                                    userProfile.player_bank_detail
                                      .account_number
                                  }
                                </td>
                              ) : null}
                            </tr>
                            <tr>
                              <td className="data-label">
                                {props.t("last_login")}:
                              </td>

                              <td className="data">{userProfile.last_login_at}</td>
                            </tr>
                          </table>
                        </div>
                        <ActionLoader
                          label={"process"}
                          loaderClass={"cus-process"}
                          showLoader={loaderStatus}
                          onClick={depositAmount}
                        />

                        {/* <button className="cus-process"  style={{ borderRadius: '20px' }}>{props.t('process')}</button> */}
                      </div>
                      <div className="title-box1">
                        <h4 className="sub-title">
                          {props.t("last_ten_deposits")}
                        </h4>
                        <div className="table-res1">
                          <Table>
                            <thead>
                              <td>{props.t("date")}</td>
                              <td className="deposit-data">
                                {props.t("amount")}
                              </td>
                              <td className="deposit-data">
                                {props.t("status")}
                              </td>
                            </thead>

                            {depositList.length > 0
                              ? depositList.map((item, index) => {
                                return (
                                  <>
                                    <tbody>
                                      <tr>
                                        <td>{dateFormat(item.created_at)}</td>
                                        <td className="deposit-data">
                                          {currencyFormat(item.amount)}
                                        </td>
                                        <td className="deposit-data">
                                          {item.status}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </>
                                );
                              })
                              : null}
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {/* {depositWithdrawNotificationClosed && <DepositWithdrawMessage handlePoup={handleNotificationPopup} depositWithdrawNotificationClosed={depositWithdrawNotificationClosed} data={depositWithdrawMessage} />} */}
    </>
  );
};

DepositInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(DepositInner);
