import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { FaUser } from 'react-icons/fa'
import { BiArrowBack } from 'react-icons/bi'
import { showMessage, makeAjax, url, respStatus } from "../../../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ActionLoader from '../../../../pages/ActionLoader';
const ReplyMessage = (props) => {

    // Init message data
    const InitMessage = {
        message: '',
        subject: '',
        parent_id: '',
    };

    // Set & get message data
    const editor = useRef(null);
    const [content, setContent] = useState(InitMessage);
    const [formError, setFormError] = useState({});
    const rowData = props.rowData;
    const [loaderStatus, setLoaderStatus] = useState(false);

    // Onchange editor content
    const OnchangeContent = (event) => {
        setContent({
            ...content,
            ['message']: event,
        })
    }

    // Player profile details
    const sendPlayerMessage = (event) => {
        content['subject'] = rowData.subject;
        content['parent_id'] = rowData._id;
        content['operator_id'] = rowData.operator_id;
        content['player_id'] = rowData.player_id;
        content['super_parent_id'] = rowData.super_parent_id;
        content['out_box_id'] = rowData.out_box_id;
        content['group_name'] = rowData.group_name;
        setLoaderStatus(true);
        makeAjax(url.MEMO_API.sendPlayerMessage, content, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setLoaderStatus(false);
                    if (response.code == 'send_email_duration_error') {
                        props.emailDuration();
                        return;
                    }
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    setFormError(response.messages);
                    return;
                }
                setLoaderStatus(false);
                props.replyMsgClose()
                setContent(InitMessage)
                showMessage(response);
            })
    }

    const gotoInboxListing = (event) => {
        props.gotoInboxListing();
    }

    return (
        <div className='reply-message'>
            <h5 style={{ wordBreak: 'break-word' }} className="subject">{rowData.subject}</h5>
            <div className="user-profile">
                {/* <FaUser className='user-face' /> */}
                <div className="user-details">
                    <span className="username">{rowData.domain_name}</span>
                    <span className="email">{rowData.created_at}</span>
                </div>
            </div>
            <div className="replyscroll">
                <span className='text rplyin2' dangerouslySetInnerHTML={{ __html: rowData.message }}></span>
                <span className='text rplyin3'>{props.t('enjoy_playing_at')} {rowData.domain_name} </span>
                <span className='text rplyin4'>{props.t('thank_you')} </span>
            </div>


        </div>
    )
}

ReplyMessage.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReplyMessage);