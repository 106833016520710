import {
    call,
    put,
    all,
    takeEvery
  } from "redux-saga/effects";
  import { GET_MEMO_COUNT} from "./actionTypes";
  import { getMemoCountSuccess} from "./actions";
  import { makeAjax, url } from "../../helpers/global_helper";
  
  function* getPlayerUnreadMessageCount() {
    try {
      
      const response = yield call(
        makeAjax,
        url.MEMO_API.getPlayerUnreadMessageCount, {}, url.MEMO_MS_EXT
      );
      if (response.status == 'SUCCESS') {
        yield all([
          put(getMemoCountSuccess({ response: response.data }))
         
        ]);
      } else {
        // alert
      }
    } catch (error) {
      
      // alert
    }
  }
  
  function* getMemoCountSaga() {
    yield takeEvery(GET_MEMO_COUNT, getPlayerUnreadMessageCount);
  }
  
  export default getMemoCountSaga;