import { useSelector } from "react-redux"
import RegisterInner from "../../components/authentication/register/RegisterInner"
import CommonSec from "../../components/commonSection/CommonSec"
import FloatingComponents from "../../components/floatingComponents/FloatingComponents"
// import { componentSetting } from "../../template-settings"

const Register = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    return (
        <>
            <RegisterInner />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
        </>
    )
}

export default Register