import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { makeAjax, url, respStatus, showMessage } from "../../helpers/global_helper"
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Popup from "../popup/Popup";

const NewGamesCarousel = (props) => {
  let deviceType = localStorage.getItem("device_type");
  let user_token = window.localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [gameId, setGameId] = useState('');
  const [maintainanceError, setMaintainanceError] = useState(false);
  const [commingSoonPopup, setCommingSoonPopup] = useState(false);
  const [loginforLunchLobby, setLoginforLunchLobby] = useState(false);
  const [showNewGameTitle, setShowNewGameTitle] = useState(false);
  const [showRecentPlayedGameTitle, setShowRecentPlayedGameTitle] = useState(false);
  const [listNewlyGame, setNewlyGame] = useState([]);
  const [load, setLoad] = useState(false);
  const newlyGameAdd = () => {
    let payload = {
      new_game: true,
      category_code: '',
      provider_name: '',
    };
    makeAjax(
      url.USER_API.listProviderGame, payload, url.USER_MS_EXT).then(response => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }

        setNewlyGame(response?.data?.gameInfo?.data);
      });
  };

  useMemo(() => {
    if (listNewlyGame.length == 0) {
      newlyGameAdd();
    }
  }, [])

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    responsive: [

      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };



  const handlePopup = () => {
    setMaintainanceError(false);
    setCommingSoonPopup(false);
    setLoginforLunchLobby(false);
  }

  // If game is on comming soon
  const commingSoon = (event) => {
    setCommingSoonPopup(true);
  }

  // If game is under maintennace
  const gameUnderMaintennace = (event) => {
    setMaintainanceError(true);
  }
  const handleGameClick = (game) => {

    if (game.maintenance) {
      gameUnderMaintennace();
      return true;

    }
    if (game.status == 'Coming Soon') {
      commingSoon();
      return true;
    }

    const payload = {
      game_id: game._id
    };
    setGameId(
      { ...gameId, game_id: game._id }
    );
    makeAjax(
      url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(response => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == "game_under_maintenance") {
            setMaintainanceError(true);
            return;
          }
          if (response.code == "game_not_active") {
            setCommingSoonPopup(true);
            return;
          }

          if (response.code == "validation.invalid_token_error") {
            navigate('/register');
            return;
          }

          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }

        //pg soft game data 7th feb 2024
        if (response?.data?.code === "bd200") {
          const newTab = window.open();
          newTab.document.write(response.data.data);
        } else {
          window.open(response.data, "_blank");
        }
      }
      )
  };

  return (
    <>
      {listNewlyGame && listNewlyGame.length > 0 &&
        <div className="hotgame-outer new-games">
          <div className="container">
            <div className="hot-game-se">
              <div className="title">{props.t('newly_launched_games')}</div>
            </div>
            <div className="hotgame-list">
              <Slider {...settings}>
                {listNewlyGame && listNewlyGame?.map((item, index) => {
                  return (
                    <div key={index} className="hotgame-item">
                      {item.is_new && <Link onClick={() => handleGameClick(item)}>
                        <div className="hotgame-img under-games">
                          {
                            item.maintainance_mode &&
                            <div className="undermaintenance">
                              {props.t('under_maintanance')}
                            </div>
                          }
                          <div className="item">
                            {item.game_assets_media != null ? <img src={item?.ftp_path + item?.game_assets_media?.base_path + item?.game_assets_media?.system_file_name} alt={item.name} /> : <img src={'/assets/images/default-images/game_' + deviceType + ".png"} alt="Game Icon" />}
                            <div className="hotgame-content">
                              {
                                item.name.split('').length > 21 ?
                                  <p className="game-title hovering">{item.name}</p>
                                  : <p className="game-title">{item.name}</p>
                              }
                              <p className="game-subtitle">{item?.tenant_provider_reco?.provider_name}</p>
                            </div>
                          </div>
                        </div>
                      </Link>}
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      }

      {maintainanceError && <Popup handlePoup={handlePopup} providerMaintainance />}
      {commingSoonPopup && <Popup handlePoup={handlePopup} commingSoonGame />}
      {loginforLunchLobby && <Popup handlePoup={handlePopup} gameLunchLoginErrorPopup />}
    </>
  );
};

NewGamesCarousel.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(NewGamesCarousel);
