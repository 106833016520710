import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const EmailAlreadyUsed = (props) => {
  return (
    <div className='popup-box'>
        <h4 className="title">{props.t('information')}</h4>
        <p>{props.t('error_is_already_in_use')}</p>
    </div>
  )
}

EmailAlreadyUsed.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(EmailAlreadyUsed);