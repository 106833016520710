import React, { useEffect } from 'react'
import CommonSec from '../components/commonSection/CommonSec'
import FloatingComponents from '../components/floatingComponents/FloatingComponents'
import HelpInner from '../components/help-inner/HelpInner'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayerProfileDetail } from '../store/actions'
const Help = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    let loggedIn = window.localStorage.getItem("user_token");

    const dispatch = useDispatch();
    useEffect(() => {
        if (loggedIn != null && loggedIn != '')
            dispatch(getPlayerProfileDetail());
    }, []);
    
    return (
        <>
            {!loggedIn &&
                <HelpInner />
            }
            {loggedIn &&
                <HelpInner />
            }
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === 'right_vertical' && <FloatingComponents />}
        </>
    )
}

export default Help