import { GrFormClose } from "react-icons/gr";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
} from "../../../helpers/global_helper";
import { useNavigate } from "react-router-dom";
// import

const DepositWithdrawMessage = (props) => {
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));

  const [custComplaint, setCustComplaint] = useState(false);
  const btnRefLogin = useRef();
  const navigate = useNavigate();

  const handleNotificationPopup = () => {
    props.handlePopup();
  };

  const messageRead = (id) => {
    let payload = {
      _id: id,
    };
    makeAjax(
      url.DPWD_API.depositWithdrawReadMessage,
      payload,
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      props.handlePoup();
      navigate("/memo");
    });
  };


  return (
    <div
      className={`popup-modal ${custComplaint ? "customerComplaintsClass" : ""
        }`}
      id="popup-model-id"
    >
      <div className="modal-cus" ref={btnRefLogin}>
        <span className="close" onClick={() => handleNotificationPopup()}>
          <GrFormClose />
        </span>
        <div className="popup-box">

          {props.data != undefined && props.data[0].popup_status == false && (
            <>
              <h4 className="title">{props.t("informations")}</h4>
              <p style={{ wordWrap: "break-word" }}>
                {" "}
                {props.t(props.data[0].popup_message)}{" "}
              </p>
              <button onClick={() => handleNotificationPopup()}>
                {props.t("ok")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

DepositWithdrawMessage.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(DepositWithdrawMessage);
