
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const FooterMenu = (props) => {
    const { playerMenu } = useSelector((state) => ({
        playerMenu: state.menuListR.data
    }));
    const [loggedIn, setLoggedIn] = useState('');
    const [footerMenu, setFooterMenu] = useState([]);
    let token = window.localStorage.getItem("user_token");



    useEffect(() => {
        setLoggedIn(token);
    }, [token]);
    useEffect(() => {
        if (playerMenu)
            if (!loggedIn) {
                setFooterMenu(playerMenu['before']);
            } else {
                setFooterMenu(playerMenu["after"]);
            }

    }, [loggedIn, playerMenu])

    return (
        <>
            <div className="content">
                <h2 className="title">{props.t('info')}</h2>
                <div className="info">
                    <navbar>
                        <ul>
                            {Object.values(footerMenu).map((v, i) => {
                                return (v.footer_menu == true) &&
                                    <li>
                                        <Link to={"/" + v.code}>
                                            {v.code == 'blogs' ? (
                                                <>
                                                    {props.t('blog')}
                                                </>
                                            ) : (
                                                <>
                                                    {props.t(`${v.code}`)}
                                                </>
                                            )}
                                        </Link>
                                    </li>
                            })}
                        </ul>
                    </navbar>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(FooterMenu);