import { call, put, takeEvery } from "redux-saga/effects";
import { LIST_LANGUAGE_DATA } from "./actionTypes";
import { listLanguageDataSuccess } from "./actions";
import { makeAjax, url, showMessage } from "../../helpers/global_helper";
function* listLanguageData({ payload: { selected_language_code } }) {
  try {
    
    const response = yield call(
      makeAjax,
      url.USER_API.listLanguageUserData,
      {
        lang: selected_language_code
      }
    );
    if (response && response.status == 'SUCCESS') {
      yield put(listLanguageDataSuccess({ response: response.data, selected_language_code: selected_language_code }));
    }
    else {
      showMessage(response);
      // alert
    }
  } catch (error) {
    
    // alert
  }
}
function* myLangSaga() {
  yield takeEvery(LIST_LANGUAGE_DATA, listLanguageData);
}

export default myLangSaga;
