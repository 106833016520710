import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import {
  makeAjax,
  showMessage,
  url,
  respStatus,
} from "../../../../helpers/global_helper";
import Jackpot from "../../../games/jackpot/Jackpot";

const PaymentSlider = (props) => {
  const [bankDD, setBankDD] = useState([]);
  const bankList = () => {
    makeAjax(url.USER_API.listTenantBank, [], url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response.messages);
            return;
          }
          showMessage(response);
          return;
        }
        setBankDD(response.data);
      }
    );
  };
  useEffect(() => {
    bankList();
  }, []);

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="payment-slide">
        <h2 className="title">{props.t("payment_methods")}</h2>
        <div className="payment-items">
          <div className="bank-slide">
            <Slider {...settings}>
              {bankDD.length > 0 &&
                bankDD.map((v, i) => {
                  {
                    return (
                      <>
                        {v.status == "Offline" && (
                          <div className="bank-item">
                            <div className="bank-status offline">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}

                        {v.status == "Problem" && (
                          <div className="bank-item">
                            <div className="bank-status problem">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}

                        {v.status == "Coming_Soon" && (
                          <div className="bank-item">
                            <div className="bank-status comings">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}

                        {v.status == "Online" && (
                          <div className="bank-item">
                            <div className="bank-status online">
                              <img alt="Bank"
                                src={
                                  "/assets/images/bank/" +
                                  v.tenant_bank_detail.bank_name +
                                  ".png"
                                }
                              />
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }
                })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

PaymentSlider.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PaymentSlider);