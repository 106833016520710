import {
  FETCH_REFERAL_INFO,
  FETCH_REFERAL_INFO_SUCCESS
} from "./actionTypes"


export const fetchReferalInfo = (param = '') => {
  return {
    type: FETCH_REFERAL_INFO,
    payload: {'device': param, code:'referral'},
  }
}

export const fetchReferalInfoSuccess = (response) => {
  return {
    type: FETCH_REFERAL_INFO_SUCCESS,
    payload: response,
  }
}