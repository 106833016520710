import { useSelector } from "react-redux"
import RulesOfPlayInner from "../../components/authentication/rulesOfPlay/RulesOfPlayInner"
import FloatingComponents from "../../components/floatingComponents/FloatingComponents"
// import { componentSetting } from "../../template-settings"

const RulesOfPlay = () => {
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  return (
    <>
      <RulesOfPlayInner />
      {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
    </>
  )
}

export default RulesOfPlay