import { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import GuideHowToPlay from './guideHowToPlay/GuideHowToPlay';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const HowToPlay = (props) => {
  const accordianGeneral = [
    { id: 1, title: 'Registration', content: 'Steps to create an account or userID at Wigobet.com:' },
    { id: 2, title: 'Forgot Password', content: 'Please click the "Forgot Password" menu if you forget your password. There are 2 Forgot password guides as follows:' }
  ]

  const accordianBalance = [
    { id: 1, title: 'Deposits', content: 'Deposit is topping up the balance into your account in the form of chips that are used to make bets. The minimum deposit at Wigobet.com is IDR 10,000' },
    { id: 2, title: 'Withdraw', content: 'Withdraw is a way to withdraw funds from your chips to the bank account that you registered for the first time.' }
  ]

  const [active, setActive] = useState(1)
  const [showAnimate, setShowAnimate] = useState(false);
  const handleAccordian = (id) => {
    setTimeout(() => {
      setShowAnimate(true);
    }, 1000)
    setActive(id);
    if (id === active) {
      setActive(0)
    }
  }

  return (
    <div className='how-to-play'>
      <h3 className="title-tab">{props.t('how_to_play')}</h3>
      <Tabs>
        <TabList>
          <Tab>{props.t('general_guide')}</Tab>
          <Tab>{props.t('balance_fill_guide')}</Tab>
          <Tab>{props.t('guide_how_to_play')}</Tab>
        </TabList>
        <TabPanel>
          <h3 className="title-tab-sub">{props.t('general_guide')}</h3>
          <div className="custom-accordian">
            {accordianGeneral.map((v, i) =>
              <div className="accordian-item" key={i}>
                <div className="title" onClick={() => handleAccordian(v.id)}>
                  <span className='text'>{v.id}. {v.title}</span>
                  <span className='icon'> {active === v.id ? <BsChevronUp /> : <BsChevronDown />}</span>
                </div>
                {active === v.id &&
                  <div className={"content " + (showAnimate ? 'animate' : '')}>
                    {v.content}
                  </div>
                }
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <h3 className="title-tab-sub">{props.t('balance_fill_guide')}</h3>
          <div className="custom-accordian">
            {accordianBalance.map((v, i) =>
              <div className="accordian-item" key={i}>
                <div className="title" onClick={() => handleAccordian(v.id)}>
                  <span className='text'>{v.id}. {v.title}</span>
                  <span className='icon'> {active === v.id ? <BsChevronUp /> : <BsChevronDown />}</span>
                </div>
                {active === v.id &&
                  <div className={"content " + (showAnimate ? 'animate' : '')}>
                    {v.content}
                  </div>
                }
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <h3 className="title-tab-sub">{props.t('guide_how_to_play')}</h3>
          <GuideHowToPlay/>
        </TabPanel>
      </Tabs>
    </div>
  )
}

HowToPlay.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(HowToPlay);