import React from 'react'
import BlogsInner from '../components/blogs/BlogsInner'
import CommonSec from '../components/commonSection/CommonSec'
import FloatingComponents from '../components/floatingComponents/FloatingComponents'
//import { componentSetting } from '../template-settings'
import { useSelector } from 'react-redux'

const Blog = () => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));
    return (
        <>
            <BlogsInner />
            <CommonSec />
            {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === 'right_vertical' && <FloatingComponents />}
        </>
    )
}

export default Blog