import { useEffect, useState } from "react";
import { BsFillCalendarFill, BsDot } from "react-icons/bs"
import { FaTag, FaUserAlt } from "react-icons/fa"
import { useParams } from "react-router-dom"
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";
import { Helmet } from "react-helmet";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonSec from "../../commonSection/CommonSec";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const BlogInnerPage = (props) => {
    const { slug } = useParams();
    const [deafultTitle, setDefaultTitle] = useState('Gaming APP');
    const [blog, setBlog] = useState({ post_categories: [], post_date: '', seo_title: '', seo_description: '', seo_keywords: '' });
    const navigate = useNavigate();
    const getBlog = () => {
        makeAjax(url.USER_API.getBlog, { slug: slug })
            .then(response => {
                if (response.status !== respStatus['SUCCESS']) {
                    if (response.code !== 'validation_error') {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                    return;
                }
                if (response.data.seo_title)
                    document.title = response.data.seo_title;
                setBlog(response.data);
            })
    }

    const { metaInfo } = useSelector(state => ({
        metaInfo: state.metaInformationListR.data
    }));

    const backToBlog = () => {
        navigate('/blogs');
    }

    useEffect(() => {
        if (slug) {
            getBlog();
        }
        for (let element of metaInfo) {
            if (element.menu != null && element.menu.code == 'blogs') {
                setDefaultTitle(element.title);
            }
        }

    }, [slug])

    return (
        <>
            <div className='blogs blog-inner-page'>

                {
                    blog.seo_title != '' ?
                        <>
                            <Helmet>
                                <title>{blog.seo_title}</title>
                                <meta name="description" content={blog.seo_description} />
                                <meta name="keywords" content={blog.seo_keywords} />
                            </Helmet>
                        </> : <>
                            <Helmet>
                                <title>{deafultTitle}</title>
                                <meta name="description" content={'description'} />
                                <meta name="keywords" content={'keywords'} />
                            </Helmet>
                        </>
                }


                <div className="container">
                    <div className="blog-items blog-inner-items">
                        <div className="blog-item">
                            <img src={blog.file_path + blog.system_file_name} alt='Blog' className='img-fluid' />
                            <div className="blog-data">
                                <h4 className="blog-title">{blog.title}</h4>
                                <div className="posted-on">
                                    <span className="date"> {blog.post_date.slice(0, 10)}</span>
                                    <span className="author"> <BsDot /> {blog.author}</span>
                                    {blog.post_tags != null && <span className="tag"> # {blog.post_tags} </span>}

                                </div>
                                <h6 className="short-desc" dangerouslySetInnerHTML={{ __html: blog.content }}></h6>
                                <span className="btn-back" onClick={backToBlog}> <IoIosArrowRoundBack /> {props.t('back')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CommonSec />
        </>
    )
}

BlogInnerPage.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(BlogInnerPage);