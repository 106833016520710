import React from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

const Error404 = () => {
    return (
        <div className='error-404'>
            <div className="container">
                <div className="error-sub">
                    <div className="error-sub-left">
                        <h1>404</h1>
                        <h2>Something Went</h2>
                        <h2><strong>Wrong!</strong></h2>
                        <NavLink to={'/'} className={'back-homepage'}>Back to Homepage</NavLink>
                    </div>
                    <div className="error-sub-right">
                        <img alt='Error' className='img-fluid' src='/assets/images/error404.png'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Error404);