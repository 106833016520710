import { useState, useEffect } from "react";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currencyFormat,
  dateFormat,
} from "../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function ReferralsList(props) {
  const [referralPlayerList, setReferralPlayerList] = useState([]);
  const [referralPlayerCount, setReferralPlayerCount] = useState(0);

  // Get referral list
  const getReferralPlayer = () => {
    makeAjax(url.USER_API.listReferralPlayer, [], url.USER_MS_EXT).then(
      (response) => {
        
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setReferralPlayerList(response.data);
      }
    );
  };

  
  // Get referral list
  const getReferralPlayerCount = () => {
    makeAjax(url.USER_API.listReferralCount, [], url.USER_MS_EXT).then(
      (response) => {
        
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        setReferralPlayerCount(response.data);
      }
    );
  };

  // Effect List
  useEffect(() => {
    getReferralPlayer();
    getReferralPlayerCount();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="table-div ">
        {windowWidth < 768 ? (
          <>
            <div className="member-form-box">
              {referralPlayerList &&
                typeof referralPlayerList.referrals != "undefined" &&
                referralPlayerList.referrals.length > 0 &&
                referralPlayerList.referrals.map((v, i) => (
                  <div className="referral-table-data">
                    <table className="referral-table">
                      <tbody className="referral-bg">
                        <tr>
                          <td className="sr-no" colspan="2">
                            <div className="td-flex">
                              <div>
                                <span className="user-one">#</span>
                                <span className="user-amount"> {i + 1}</span>
                              </div>
                              <div>
                                <span className="user-one">
                                  {props.t("registration_date")}:
                                </span>
                                <span className="user-date">
                                  {dateFormat(v.created_at)}
                                </span>
                              </div>
                            </div>
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                            <span className="user-one">
                              {props.t("user_id")}:
                            </span>
                          </td>
                          <td>
                            <span className="user-name">
                              {v.username
                                .slice(0, 6)
                                .replace(/^.{2}/g, "XX")
                                .slice(0, -2) + "XX"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="user-one">
                              {" "}
                              {props.t("interim_commission")}:
                            </span>
                          </td>
                          <td>
                            <span className="user-amount">
                              {" "}
                              {v.pending_turnover_amount != null
                                ? currencyFormat(v.pending_turnover_amount)
                                : 0}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="user-one">
                              {" "}
                              {props.t("pending_turnover_amount")}:
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span className="user-amount">
                              {v.pending_referee_bonus != null
                                ? currencyFormat(v.pending_referee_bonus)
                                : 0}{" "}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="user-one">
                              {props.t("pending_referral_bonus_amount")}:
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span className="user-amount">
                              {" "}
                              {v.referee_bonus != null
                                ? currencyFormat(v.referee_bonus)
                                : 0}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            <div className="member-form-box">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>#</th>
                    <th>{props.t("username")}</th>
                    <th>{props.t("registration_date")}</th>
                    <th>{props.t("pending_turnover_amount")}</th>
                    <th>{props.t("pending_referral_bonus_amount")}</th>
                    <th>{props.t("pending_commission")}</th>
                  </tr>
                </thead>
                <tbody>
                  {referralPlayerList &&
                    typeof referralPlayerList.referrals != "undefined" &&
                    referralPlayerList.referrals.length > 0 &&
                    referralPlayerList.referrals.map((v, i) => (
                      <tr key={i}>
                        <td>{i + 1} &nbsp;&nbsp;</td>
                        <td className="user-id">
                          {v.username
                            .slice(0, 6)
                            .replace(/^.{2}/g, "XX")
                            .slice(0, -2) + "XX"}
                        </td>
                        <td>{dateFormat(v.created_at)}</td>
                        <td>
                          {v.pending_turnover_amount != null
                            ? currencyFormat(v.pending_turnover_amount)
                            : 0}
                        </td>
                        <td>
                          {v.pending_referee_bonus != null
                            ? currencyFormat(v.pending_referee_bonus)
                            : 0}
                        </td>
                        <td>
                          {v.referee_bonus != null
                            ? currencyFormat(v.referee_bonus)
                            : 0}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}

ReferralsList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReferralsList);
