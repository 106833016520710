import { all, fork } from "redux-saga/effects"

import menuListSaga from "./menu-list/saga"
import menuComponentSettingSaga from "./component-setting/saga"
import metaInfomationSaga from "./meta-information/saga"
import textInfomationSaga from "./text-information/saga"
import logoutSaga from "./logout/saga"
import footerInfomationSaga from "./footer-infomation/saga"
import submenuListSaga from "./submenu-list/saga"
import playerProfileSaga from "./player-profile/saga"
import referalInfomationSaga from "./referal-device/saga"
import othermediaInfomationSaga from "./other-media/saga"
import cssInfomationSaga from "./template-css/saga"
import checkPinStatusSaga from "./check-pin-status/saga"
import dropdownSaga from "./dropdown/saga"
import myLangSaga from "./my-lang/saga"
import getMemoCountSaga from "./memo-unread-count/saga"
import getPlayerBalanceSaga from "./player-balance/saga"
import fetchDepositPendingSaga from "./deposit-pending/saga"
export default function* rootSaga() {
  yield all([
    fork(menuListSaga),
    fork(menuComponentSettingSaga),
    fork(metaInfomationSaga),
    fork(textInfomationSaga),
    fork(logoutSaga),
    fork(footerInfomationSaga),
    fork(submenuListSaga),
    fork(playerProfileSaga),
    fork(referalInfomationSaga),
    fork(othermediaInfomationSaga),
    fork(cssInfomationSaga),
    fork(checkPinStatusSaga),
    fork(dropdownSaga),
    fork(myLangSaga),
    fork(getMemoCountSaga),
    fork(getPlayerBalanceSaga),
    fork(fetchDepositPendingSaga),
  ])
}
