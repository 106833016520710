import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useState, useEffect } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ActionLoader from "../../../pages/ActionLoader";
const ChangePassword = (props) => {
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [loaderStatus, setLoader] = useState(false);
  const [valuesOld, setValuesOld] = useState({
    password: "",
    showOldPassword: false,
  });
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [valuesConfirm, setValuesConfirm] = useState({
    password: "",
    showConfirmPassword: false,
  });

  const [onhoverConfirmPassword, setOnhoverConfirmPassword] = useState(false);
  const [onhoverOldPassword, setOnhoverOldPassword] = useState(false);
  const [onhoverNewPassword, setOnhoverNewPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    makeAjax(url.USER_API.changePassword, formData, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          setLoader(false);
          if (response.code != "validation_error") showMessage(response);
          setFormError(response.messages);
          return;
        }
        setLoader(false);
        setFormError({});
        setFormData({
          ...formData,
          ...{ opassword: "", password: "", password_confirmation: "" },
        });
        showMessage(response);
        logout();
      }
    );
  };
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
    opassword: "",
  });

  const logout = () => {
    makeAjax(url.USER_API.logout, [], url.USER_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
    });
    window.localStorage.removeItem("user_token");
    navigate("/");
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // const handleClickShowOldPassword = () => {
  //     setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
  // };
  // const handleClickShowPassword = () => {
  //     setValues({ ...values, showPassword: !values.showPassword });
  // };

  // const handleClickShowConfirmPassword = () => {
  //     setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
  // };

  //#wl2-5252
  const onHoverHandel = (event) => {
    if (event.target.name == "password") {
      setOnhoverNewPassword(true);
    }
    if (event.target.name == "opassword") {
      setOnhoverOldPassword(true);
    }
    if (event.target.name == "password_confirmation") {
      setOnhoverConfirmPassword(true);
    }
  };

  const onLeaveHandel = () => {
    setOnhoverNewPassword(false);
    setOnhoverOldPassword(false);
    setOnhoverConfirmPassword(false);
  };

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobile = window.innerWidth <= 768;
      setIsDesktop(!isMobile);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const handleMouseOverOld = () => {
    if (isDesktop) {
      setValuesOld({
        ...valuesOld,
        showOldPassword: !valuesOld.showOldPassword,
      });
    }
  };

  const handleClickOld = () => {
    if (!isDesktop) {
      setValuesOld({
        ...valuesOld,
        showOldPassword: !valuesOld.showOldPassword,
      });
    }
  };

  const handleMouseOver = () => {
    if (isDesktop) {
      setValues({ ...values, showPassword: !values.showPassword });
    }
  };

  const handleClick = () => {
    if (!isDesktop) {
      setValues({ ...values, showPassword: !values.showPassword });
    }
  };

  const handleMouseOverConf = () => {
    if (isDesktop) {
      setValuesConfirm({
        ...valuesConfirm,
        showConfirmPassword: !valuesConfirm.showConfirmPassword,
      });
    }
  };

  const handleClickConf = () => {
    if (!isDesktop) {
      setValuesConfirm({
        ...valuesConfirm,
        showConfirmPassword: !valuesConfirm.showConfirmPassword,
      });
    }
  };

  return (
    <div className="popup-box change-password-popup">
      <h4 className="title">{props.t("change_password")}</h4>
      <form onSubmit={handleSubmit} className="reset-p">
        <div className="input-control">
          <div className="password-icon">
            <label className="form-label">{props.t("old_password")}</label>
            <input
              type={valuesOld.showOldPassword ? "text" : "password"}
              placeholder={props.t("min_8_one_uppercase_one_number_and_special_characters")}
              name="opassword"
              onChange={handleChange}
              onMouseEnter={onHoverHandel}
              onMouseLeave={onLeaveHandel}
            />
            {formError.opassword ? (
              <span className="text-danger change-span error-msg">
                {formError.opassword[0]}
              </span>
            ) : null}
            {valuesOld.showOldPassword == false ? (
              <AiOutlineEyeInvisible
                onMouseEnter={handleMouseOverOld}
                onClick={handleClickOld}
              />
            ) : (
              <AiOutlineEye
                onMouseLeave={handleMouseOverOld}
                onClick={handleClickOld}
              />
            )}
          </div>
        </div>
        <div className="input-control">
          <div className="password-icon">
            <label className="form-label">{props.t("new_password")}</label>
            <input
              type={values.showPassword ? "text" : "password"}
              placeholder={props.t("min_8_one_uppercase_one_number_and_special_characters")}
              name="password"
              onChange={handleChange}
              onMouseEnter={onHoverHandel}
              onMouseLeave={onLeaveHandel}
            />
            {formError.password ? (
              <span className="text-danger change-span error-msg">
                {formError.password[0]}
              </span>
            ) : null}
            {values.showPassword == false ? (
              <AiOutlineEyeInvisible
                onMouseEnter={handleMouseOver}
                onClick={handleClick}
              />
            ) : (
              <AiOutlineEye
                onMouseLeave={handleMouseOver}
                onClick={handleClick}
              />
            )}
          </div>
        </div>
        <div className="input-control">
          <div className="password-icon">
            <label className="form-label">{props.t("confirm_new_password")}</label>
            <input
              type={valuesConfirm.showConfirmPassword ? "text" : "password"}
              placeholder={props.t("min_8_one_uppercase_one_number_and_special_characters")}
              name="password_confirmation"
              onChange={handleChange}
              onMouseEnter={onHoverHandel}
              onMouseLeave={onLeaveHandel}
            />
            {formError.password_confirmation ? (
              <span className="text-danger change-span error-msg">
                {formError.password_confirmation[0]}
              </span>
            ) : null}
            {valuesConfirm.showConfirmPassword == false ? (
              <AiOutlineEyeInvisible
                onMouseEnter={handleMouseOverConf}
                onClick={handleClickConf}
              />
            ) : (
              <AiOutlineEye
                onMouseLeave={handleMouseOverConf}
                onClick={handleClickConf}
              />
            )}
          </div>
        </div>
        <div className="text-center pt-2">
          <ActionLoader
            label={"process"}
            loaderClass={"change-password-loader"}
            showLoader={loaderStatus}
          />
        </div>
      </form>
    </div>
  );
};

ChangePassword.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ChangePassword);
