import React from 'react'
import ForgotPasswordInner from '../../components/authentication/forgotPassword/ForgotPasswordInner'
import CommonSec from '../../components/commonSection/CommonSec'
import FloatingComponents from '../../components/floatingComponents/FloatingComponents'
//import { componentSetting } from '../../template-settings'
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));
  return (
    <>
      <ForgotPasswordInner />
      <CommonSec />
      {typeof componentSetting.floatingComponents != 'undefined' && componentSetting.floatingComponents.defaultValue === "right_vertical" && <FloatingComponents />}
    </>
  )
}

ForgotPassword.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ForgotPassword);