import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const initSetting = {
  category_menu: null,
  default_class: null,
};

const GameCategoryCarousel = (props) => {
  const [fnSetting, setFnSetting] = useState(initSetting);

  const settings = {
    // centerMode: true,
    // centerPadding: '40px',
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    // vertical: true,
    // verticalScrolling: true,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  useEffect(() => {
    let temp = fnSetting;
    if (typeof componentSetting?.["category-horizonatal-nav-bar"] != 'undefined') {
      let classDeafault = componentSetting?.["category-horizonatal-nav-bar"]?.["default-value"];
      setFnSetting({
        ...fnSetting,
        ['category_menu']: componentSetting?.["category-horizonatal-nav-bar"]?.["default-value"],
      })
    }
  }, [componentSetting?.["category-horizonatal-nav-bar"]]);

  const hoverGames = [
    { code: "slots", totalGame: 99, link: '/slots', imgpath: "../assets/images/carouselimg/Slot-Machine-108x108.gif" },
    { code: "arcades", totalGame: 99, link: '/arcades', imgpath: "../assets/images/carouselimg/Arcade-108x108.gif" },
    { code: "live_casino", totalGame: 99, link: '/live_casino', imgpath: "../assets/images/carouselimg/Casino-108x108.gif" },
    { code: "sportsbook", totalGame: 99, link: '/sportsbook', imgpath: "../assets/images/carouselimg/Sportbook-108x108.gif" },
    { code: "card_games", totalGame: 99, link: '/card_games', imgpath: "../assets/images/carouselimg/Card-Games-108x108.gif" },
    { code: "lottery", totalGame: 99, link: '/lottery', imgpath: "../assets/images/carouselimg/Lottery-108x108.gif" },
    { code: "dingdong", totalGame: 99, link: '/dingdong', imgpath: "../assets/images/carouselimg/Ding-Dong-108x108.gif" },
  ]

  const type = 'category'

  const { playerMenu } = useSelector(state => ({
    playerMenu: state.menuListR.data
  }));
  return (
    <div className="game-carousel">
      <div className="container">
        <div className="title">{props.t('categories')}</div>
        <div className="games-list">
          <div>
            <Slider {...settings}>
              {hoverGames.map((data, index) => {
                return (playerMenu['before'][data.code] && playerMenu['before'][data.code]['status']) &&
                  <div key={index}>
                    {componentSetting?.["category-horizonatal-nav-bar"]?.["default-value"] == 'label-icon' &&
                      <Link to={{ pathname: data.link, state: type }} >
                        <div className="games-item">
                          <div className="category-text">
                            <p className="inner-title">{props.t(`${playerMenu['before'][data.code]['name']}`)}</p>
                            <p className="sub-title">{playerMenu['before'][data.code] && playerMenu['before'][data.code]['game_count']} {props.t('games')}</p>
                          </div>

                          <div className="category-gif">
                            <img src={data.imgpath} alt="Category" />
                          </div>
                        </div>
                      </Link>
                    }

                    {componentSetting?.["category-horizonatal-nav-bar"]?.["default-value"] == 'only-lable' &&
                      <Link to={{ pathname: data.link, state: type }}>
                        <div className="games-item">
                          <div className="category-text">
                            <p className="inner-title">{props.t(`${playerMenu['before'][data.code]['name']}`)}</p>
                            <p className="sub-title">{playerMenu['before'][data.code] && playerMenu['before'][data.code]['game_count']} {props.t('games')}</p>
                          </div>
                        </div>
                      </Link>
                    }

                  </div>
              })}
            </Slider>

          </div>
        </div>
      </div>
    </div>
  );
};

GameCategoryCarousel.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(GameCategoryCarousel);

