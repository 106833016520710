import { useEffect, useState } from "react";
import { CSS_CLASSES } from "../../../css-classes";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Popup from "../../popup/Popup";

const initSetting = {
  read_more_m: null,
  class: null,
  default_class: null,
};

const Readmore = (props) => {
  const [fnSetting, setFnSetting] = useState(initSetting);

  const { componentSetting } = useSelector(state => ({
    componentSetting: state.componentSettingListR.data
  }));

  // Set & get state
  const [readmore, setReadmore] = useState(false);
  const [isMessageSet, setIsMessageSet] = useState(false);
  const handlePopup = () => {
    setIsMessageSet(false);
  }
  const popUpMessage = (v) => {
    setIsMessageSet(v);
  }

  // Get code from url
  let params = window.location.pathname.substr(1);

  // Fetch footer info
  const { footerInfo } = useSelector((state) => ({
    footerInfo: state.footerInformationListR.data,
  }));

  useEffect(() => {
    let temp = { ...fnSetting };
    temp['read_more_m'] = componentSetting?.["read-more"]?.["default-value"];
    temp['default_class'] = componentSetting?.["read-more"]?.["default-value"];
    setFnSetting(temp);
    // if (typeof componentSetting?.["read-more"] != 'undefined') {
    //   let classDeafault = componentSetting?.["read-more"]?.["default-value"];
    //   setFnSetting({
    //     ...fnSetting,
    //     ['read_more_m']: componentSetting?.["read-more"]?.["default-value"],
    //     ['default_class']: componentSetting?.["read-more"]?.["default-value"],
    //   })
    // }
  }, [componentSetting?.["read-more"]]);

  return (
    footerInfo &&
    footerInfo.length > 0 &&
    footerInfo.map((v, i) => {
      return (
        v.menu != null &&
        (v.menu.code == params ||
          ((params == "referrals" || params == "calculation") &&
            ["referral", "my_referral"].includes(v.menu.code)) ||
          (params == "games" && ["hot_games"].includes(v.menu.code)) ||
          (params == "" && ["home"].includes(v.menu.code))) && (
          <>
            {
              typeof componentSetting?.["read-more"] != 'undefined' && componentSetting?.["read-more"]?.["default-value"] &&
              <div className="read-more">
                <div className="container">
                  <div key={i} className="read-more-inner">
                    <h1 className="title" dangerouslySetInnerHTML={{ __html: v?.title }}></h1>
                    <div className={`description ${readmore && "overflow-hide"}`}>
                      <p
                        className="desc"
                        dangerouslySetInnerHTML={{ __html: v.description }}

                      >

                      </p>

                    </div>

                    {componentSetting?.["read-more"]?.["default-value"] == 'fixed' &&
                      <a
                        className="read-more-btn"
                        onClick={() => setReadmore((current) => !current)}
                      >
                        {readmore == false
                          ? `${props.t("read_more")}`
                          : `${props.t("closed")}`}
                      </a>}

                    {componentSetting?.["read-more"]?.["default-value"] == 'popup' &&
                      <a
                        className="read-more-btn"
                        onClick={() => popUpMessage(v)}
                      >
                        {readmore == false
                          ? `${props.t("read_more")}`
                          : `${props.t("closed")}`}
                      </a>}
                  </div>
                  {isMessageSet &&
                    <Popup handlePoup={handlePopup} readData />
                  }
                </div>
              </div>
            }
          </>

        )
      );
    })
  );
};

Readmore.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Readmore);
