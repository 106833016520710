import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ReferralMessage = (props) => {
  
  return (
    <div className='popup-box'>
        <h4 className="title">{props.t('information')}</h4>
        <p>{props.t('your_referral_link_has_been_copied')}.</p>
    </div>
  )
}

ReferralMessage.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ReferralMessage);