import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { showMessage, makeAjax, url, respStatus } from "../../../helpers/global_helper";
import { getPlayerProfileDetail } from '../../../store/actions';
import { useDispatch } from 'react-redux';

const VerifyToken = () => {
    const InitData = {
        username: '',
        email_token: '',
    }


    const { emailToken } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const verifyEmailToken = () => {
        makeAjax(
            url.USER_API.verifyEmailToken, { email_token: emailToken }, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != "validation_error") {
                        navigate("/");
                        showMessage(response);
                        return;
                    }

                }
                showMessage(response);
                dispatch(getPlayerProfileDetail());
                navigate("/profile");
                // navigate(0);
            });
    }



    let isLoaded = false;
    useEffect(() => {
        if (!isLoaded)
            verifyEmailToken();
        isLoaded = true;
    }, []);

    return (
        <div className='new-auth'>
            <div className='container'>
                {/* <img src='/assets/images/logo.png' alt='logo' /> */}
            </div>
        </div>
    )
}

export default VerifyToken