
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"

// Redux fetch
import { fetchMenu, getPlayerProfileDetail, fetchOtherMediaInfo, fetchCssInfo } from "./store/actions"
import { fetchComponentSetting } from "./store/actions"
import { fetchMetaInfo } from "./store/actions"
import { fetchFooterInfo } from "./store/actions"
import { fetchSubMenu } from "./store/actions"
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getMemoCount } from "./store/actions";
import { fetchDepositPending } from "./store/actions";

// Ajax import
import { makeAjax, url, respStatus, prevetHeaderLoad } from "./helpers/global_helper"

// Mobile & desktop header , footer
import HeaderMobile from "./layout/header-mobile/HeaderMobile"
import FooterMobile from "./layout/footer-mobile/FooterMobile"
import Header from "./layout/header/Header"
import Footer from './layout/footer/Footer';
import { useSelector } from "react-redux";
import VerticalNav from "./layout/header/navbar/VerticalNav";
// Import Routes all
import { authProtectedRoutes, publicRoutes, bothRoutes, specialRoutes } from './routes/index';

// Default css import
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
// import './jayabet.css'
// import './custom.css';
// import './wigobet.css';
// import './template7.css';


const App = props => {

    const noHeadFoot = ['/new-password', '/login-pin', '/security-question', '/rules-of-play', '/security-validation', '/pin-security-validation', '/reminder-password', '/update_token', '/unauthorised401', '/error404', '/accessdenied', '/livechat'];
    const specialRestrictedRoute = ['/access-denied'];
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();


    const restrictedUrl = window.location.pathname;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    const afterADC = () => {
        let user_token = window.localStorage.getItem("user_token");
        if (user_token != null && user_token != '') {
            dispatch(getPlayerProfileDetail());
            dispatch(getMemoCount());
            dispatch(fetchDepositPending());
        }
        if (!prevetHeaderLoad.includes(restrictedUrl)) {
            getPinStatus();
            dispatch(fetchCssInfo());
            dispatch(fetchMenu());
            dispatch(fetchComponentSetting());
            dispatch(fetchMetaInfo());
            dispatch(fetchFooterInfo());
            dispatch(fetchSubMenu());
            dispatch(fetchOtherMediaInfo());
        }

        // Public route
        let publicRouteArr = [];
        for (const iterator of publicRoutes) {
            if (typeof iterator.path.split('/')[1] != 'undefined')
                publicRouteArr.push(iterator.path.split('/')[1]);
        }

        // Both route
        let bothRouteArr = [];
        for (const iterator of bothRoutes) {
            if (typeof iterator.path.split('/')[1] != 'undefined')
                bothRouteArr.push(iterator.path.split('/')[1]);
        }

        // Protected route
        let protectedRouteArr = [];
        for (const iterator of authProtectedRoutes) {
            if (typeof iterator.path.split('/')[1] != 'undefined')
                protectedRouteArr.push(iterator.path.split('/')[1]);
        }
    }
    useEffect(() => {
        setDevice();
        if (!specialRestrictedRoute.includes(restrictedUrl))
            isLoggedIn();
        const handleWindowWidth = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleWindowWidth);
        return () => { window.removeEventListener('resize', handleWindowWidth) }
    }, []);

    const { cssObj } = useSelector(state => ({
        cssObj: state.cssInformationListR.data
    }));

    useEffect(() => {
        getPinStatus();
    }, [])

    const [enablePage, setEnablePage] = useState('N');
    let redirectArr = ['/login-pin', '/security-question', '/security-validation', '/reminder-password'];
    const getPinStatus = () => {
        makeAjax(
            url.USER_API.checkIfPinAlreadySet, [], url.USER_MS_EXT).then(response => {
                let resp = response.data;
                if (response.status != respStatus['SUCCESS']) {
                    setEnablePage('Y');
                    return;
                }
                if (resp) {
                    let config_status = resp.get_tenant_config?.value;
                    if (config_status === true && resp.enabled_active_pin === true && resp.active_pin === false && !redirectArr.includes(location.pathname)) {
                        navigate("/login-pin");
                        setEnablePage('Y');
                        return;
                    } else if (config_status === true && resp.enabled_active_pin === true && resp.is_pin_verified === false && !redirectArr.includes(location.pathname)) {
                        navigate("/security-validation");
                        setEnablePage('Y');
                        return;
                    }
                    else if ((resp.is_security_question_set === false || resp.is_security_question_set === null) && !redirectArr.includes(location.pathname)) {
                        navigate("/security-question");
                        setEnablePage('Y');
                        return;
                    }
                    else if (resp.is_password_active === false && !redirectArr.includes(location.pathname)) {
                        navigate("/reminder-password");
                        setEnablePage('Y');
                        return;
                    }
                    setEnablePage('Y');
                } else if (redirectArr.includes(location.pathname)) {
                    navigate("/");
                    setEnablePage('Y');
                    return;
                }
                setEnablePage('Y');
            }).catch(ex => {
                if (redirectArr.includes(location.pathname)) {
                    navigate("/");
                    setEnablePage('Y');
                    return;
                }
                setEnablePage('Y');
            });
    }

    const isLoggedIn = () => {
        makeAjax(url.USER_API.isLoggedIn, {}, url.USER_MS_EXT)
            .then(response => {
                if (response.code != 'validation.access_denied') {
                    afterADC();
                    setEnablePage('Y');
                } else {
                    setEnablePage('N');
                    navigate("/access-denied");
                }
            });
    }

    const [sideMenuClickPropState, setSideMenuClickPropState] = useState(false)
    const sideMenuClickProp = () => {
        setSideMenuClickPropState(curr => !curr)
    }


    const setDevice = () => {
        if (!prevetHeaderLoad.includes(restrictedUrl) && !noHeadFoot.includes(location.pathname)) {
            if (windowWidth > 991) {
                localStorage.setItem('device_type', 'desktop');
            } else {
                localStorage.setItem("device_type", "mobile");
            }
        }
    }

    return (
        <>
            {<style>
                {cssObj}
            </style>}
            {enablePage == 'Y' ?
                <>
                    <div className={componentSetting?.["vertical-nav-bar"]?.["default-value"] == 'left' && componentSetting?.["nav-bar"]["default-value"] == 'vertical' ? 'vertical-nav-main' : componentSetting?.["vertical-nav-bar"]?.["default-value"] == 'right' && componentSetting?.["nav-bar"]["default-value"] == 'vertical' ? 'vertical-nav-main vertical-nav-main-right' : ''}>
                        {componentSetting?.["nav-bar"]?.["default-value"] == 'vertical' &&
                            <VerticalNav sideMenuClickPropState={sideMenuClickPropState} />
                        }
                        <div className="main">

                            {!specialRestrictedRoute.includes(restrictedUrl) &&
                                <>
                                    {windowWidth > 991 ? <>{!prevetHeaderLoad.includes(restrictedUrl) && !noHeadFoot.includes(location.pathname) && <Header sideMenuClickProp={sideMenuClickProp} />} </> : <>{!prevetHeaderLoad.includes(restrictedUrl) && !noHeadFoot.includes(location.pathname) && <HeaderMobile />}</>}
                                </>
                            }
                            <React.Fragment>

                                <div id="ajax-loader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                                <Routes>
                                    {publicRoutes.map((route, id1) => (
                                        <React.Fragment key={id1}>
                                            {
                                                <Route

                                                    exact path={route.path}
                                                    element={route.component}
                                                    key={id1}
                                                />
                                            }
                                        </React.Fragment>
                                    ))}
                                    {!prevetHeaderLoad.includes(restrictedUrl) && authProtectedRoutes.map((route, id2) => (
                                        <React.Fragment key={id2}>
                                            <Route
                                                exact path={route.path}
                                                element={route.component}
                                                key={id2}
                                            />
                                            {
                                                localStorage.getItem("user_token") == null && route.path == location.pathname && <Route
                                                    path={navigate('/')}
                                                    element={route.component}
                                                />
                                            }
                                        </React.Fragment>
                                    ))}
                                    {!prevetHeaderLoad.includes(restrictedUrl) && bothRoutes.map((route, idx) => (
                                        <React.Fragment key={idx}>
                                            <Route
                                                index
                                                exact path={route.path}
                                                element={route.component}
                                                key={idx}
                                            />
                                        </React.Fragment>
                                    ))}

                                </Routes>
                            </React.Fragment>
                            {!specialRestrictedRoute.includes(restrictedUrl) &&
                                <>
                                    {windowWidth > 991 ? <>{!prevetHeaderLoad.includes(restrictedUrl) && !noHeadFoot.includes(location.pathname) && <Footer />} </> : <>{!noHeadFoot.includes(location.pathname) && <FooterMobile />} </>}
                                </>
                            }

                        </div>
                    </div>
                </> :
                <>
                    <React.Fragment>
                        <div id="ajax-loader">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <Routes>
                            {specialRoutes.map((route, id1) => (
                                <React.Fragment key={id1}>
                                    {
                                        <Route
                                            path={route.path}
                                            element={route.component}
                                            key={id1}
                                        />
                                    }
                                </React.Fragment>
                            ))}
                        </Routes>
                    </React.Fragment>
                </>
            }
        </>
    )
};


export default App;
