import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
  currentDate,
} from "../../../helpers/global_helper";
import WLPagination from "../../../pages/utility/WLPagination";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineChevronLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineChevronRight,
} from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading from "../../games/Loading/Loading";

const OutstandingBetInner = (props) => {
  const initForm = {
    start_date: currentDate(),
    end_date: currentDate(),
    provider_id: "",
    game_id: "",
  };
  const [tenantLotteryProviderDD, settenantLotteryProviderDD] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [formError, setFormError] = useState({});
  const [selectedGame, setSelectedGame] = useState(null);
  const [outstandingBetHistory, setOutstandingBetHistory] = useState(undefined);
  const [gameList, setGameList] = useState([]);
  const [formData, setFormData] = useState(initForm);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [betList, setBetList] = useState([]);

  // Componet setting
  const { componentSetting } = useSelector((state) => ({
    componentSetting: state.componentSettingListR.data,
  }));

  const gettenantLotteryProviderDD = () => {
    makeAjax(url.GP_API.tenantLotteryProviderDD, [], url.GP_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        settenantLotteryProviderDD(response.data);
        if (componentSetting?.custom_loading?.options?.outstanding_bet_handle) {
          if (response.data.length > 0) {
            setSelectedProvider(response?.data[0]?._id);
            formData["provider_id"] = response?.data[0]?._id;
            getGame(response?.data[0]?._id);
            setFormData({ ...formData });
          }
        }
      }
    );
  };

  const fetchOutStandingBet = (nextPage) => {
    return new Promise((resolve, reject) => {
      setFormError({});
      if (nextPage == 1) {
        setOutstandingBetHistory(undefined);
        setBetList([]);
      }
      formData["page"] = nextPage;
      formData["per_page"] = 15;
      setLoading(true);
      makeAjax(
        url.REPORT_API.getOutStandingBetDetail,
        formData,
        url.REPORT_MS_EXT
      )
        .then((response) => {
          setLoading(false);
          if (response.status != respStatus["SUCCESS"]) {
            if (response.code == "validation_error") {
              setFormError(response.messages);
              return;
            }
            showMessage(response);
            return;
          }
          if (response?.data) {
            resolve({
              data: response?.data,
              lastPage: response.data?.data?.last_page,
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const outStandingBetList = async (nextPage) => {
    try {
      let response = "";
      response = await fetchOutStandingBet(nextPage);
      setOutstandingBetHistory(response.data);
      setBetList((prevRecords) => [...prevRecords, ...response.data.data.data]);
      setLastPage(response.lastPage);
      setPage(nextPage + 1);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      //
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    outStandingBetList(1);
  };

  const onChangeProvider = (event) => {
    formError[event.target.name] = "";
    setFormError({ ...formError });
    let init = formData;
    setSelectedProvider(event.target.value);
    setOutstandingBetHistory(undefined);
    setFormData({ ...formData, ["game_id"]: "" });
    setSelectedGame(null);
    setGameList([]);
    init["provider_id"] = event.target.value;
    setFormData(init);
    getGame(event.target.value);
  };

  const getGame = (id) => {
    let payLoad = {
      provider_id: id,
    };
    makeAjax(
      url.GP_API.lotteryCategoryProviderGameDD,
      payLoad,
      url.GP_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setGameList(response.data);
    });
  };

  const onChange = (event) => {
    formError[event.target.name] = "";
    setFormError({ ...formError });
    setSelectedGame(event.target.value);
    let init = formData;
    setOutstandingBetHistory(undefined);
    init[event.target.name] = event.target.value;
    setFormData(init);
  };

  const TableHeader = ({ columnLabels }) => {
    return (
      <thead>
        <tr>
          {columnLabels?.map(
            (columnName, id) =>
              columnName !== undefined && (
                <th key={id}>{props.t(`${columnName}`)}</th>
              )
          )}
        </tr>
      </thead>
    );
  };

  const renderTableCell = (item, columnLabel) => {
    if (item?.columnLabel || typeof item[columnLabel] !== "object") {
      return <>{item[columnLabel]}</>;
    } else {
      return <>{recursiveBody(item[columnLabel], columnLabel)}</>;
    }
  };

  const recursiveBody = (newData, columnLabel) => {
    let arr = [];
    if (newData)
      Object.keys(newData).forEach((key) => {
        if (typeof newData[key] === "object") {
          arr = arr.concat(recursiveBody(newData[key], columnLabel));
        } else {
          // let newKey = props.t(`${key}`);

          if (key == "status") {
            arr.push(`${newData[key]} `, <br />, <br />);
          } else {
            arr.push(`${newData[key]} `, <br />);
          }
        }
      });
    return arr;
  };

  useEffect(() => {
    gettenantLotteryProviderDD();
    const currentPath = window.location.pathname;
    const parts = currentPath.split("/");
    const lastPart = parts[parts.length - 1];
    if (!componentSetting?.custom_loading?.options?.outstanding_bet_handle) {
      if (lastPart) {
        getGame(lastPart);
        setSelectedProvider(lastPart);
        formData["provider_id"] = lastPart;
        setFormData({ ...formData });
      }
    }
  }, []);

  useEffect(() => {
    // Function to update window width state on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="trans-history">
      <div className="container">
        <div className="trans-history-div">
          <h4 className="title">{props.t("outstanding_bet")}</h4>
          <div className="trans-history-box">
            <div className="outstandingbet-form">
              <form onSubmit={handleSubmit}>
                <div className="outstanding-container">
                  <div className="tr-s">
                    <div className="txt-two">
                      <select
                        name="provider_id"
                        onChange={onChangeProvider}
                        value={selectedProvider}
                      >
                        {tenantLotteryProviderDD?.map((data, index) => {
                          return (
                            <option key={index} value={data?._id}>
                              {data?.name}
                            </option>
                          );
                        })}
                      </select>
                      {formError.provider_id ? (
                        <span className="text-danger error-msg">
                          {formError.provider_id}
                        </span>
                      ) : null}
                    </div>
                    <div className="txt-three">
                      {" "}
                      <select
                        name="game_id"
                        onChange={onChange}
                        value={selectedGame}
                      >
                        <option value="">{props.t("select_game")}</option>
                        {gameList?.map((data, index) => {
                          return (
                            <option key={index} value={data?._id}>
                              {data?.name}
                            </option>
                          );
                        })}
                      </select>
                      {formError.game_id ? (
                        <span className="text-danger">{formError.game_id}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="tr-m">
                    <div className="txt-four">
                      <input
                        type="date"
                        name="start_date"
                        value={formData.start_date}
                        max={formData.end_date}
                        onChange={onChange}
                      />
                      {formError.start_date ? (
                        <>
                          <span className="text-danger">
                            {formError.start_date}
                          </span>
                        </>
                      ) : null}
                    </div>
                    <div className="txt-five ">
                      {" "}
                      <input
                        type="date"
                        name="end_date"
                        min={formData.start_date}
                        value={formData.end_date}
                        onChange={onChange}
                      />
                      {formError.end_date ? (
                        <>
                          <span className="text-danger">
                            {formError.end_date}
                          </span>
                        </>
                      ) : null}{" "}
                    </div>
                    <div className="txt-six ">
                      <button>{props.t("search")}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div>
              {windowWidth >= 991 ? (
                // Desktop div
                <>
                  {outstandingBetHistory && (
                    <>
                      <div className="outstanding-bet">
                        <table className="outstanding-bet-table">
                          <TableHeader
                            columns={outstandingBetHistory?.columns}
                            columnLabels={outstandingBetHistory?.column_labels}
                          />
                          {betList?.length > 0 ? (
                            <tbody>
                              {betList?.map((item, index) => (
                                <tr key={index}>
                                  {outstandingBetHistory?.column_labels.map(
                                    (columnLabel, id) => {
                                      let newLable =
                                        outstandingBetHistory.keys[columnLabel];
                                      return (
                                        <React.Fragment key={id}>
                                          <td>
                                            {renderTableCell(item, newLable)}
                                          </td>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan={8} className="table-no-reacord">
                                  {props.t("no_record_found")}
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>

                      {lastPage != 1 && (
                        <div className="show-btn">
                          <button
                            onClick={() => {
                              lastPage > page - 1
                                ? outStandingBetList(page)
                                : outStandingBetList(1);
                            }}
                          >
                            {lastPage > page - 1 ? (
                              <>{props.t("show_more")}</>
                            ) : (
                              <>{props.t("show_less")}</>
                            )}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                // Mobile div
                <div>
                  {outstandingBetHistory && (
                    <>
                      {betList?.length > 0 ? (
                        <>
                          <div className="outstanding-bet">
                            <div className="trans-histry-data">
                              {betList?.map((item) => (
                                <div className="histry-card">
                                  <div className="table-data">
                                    <div className=" d-flex">
                                      {" "}
                                      <div className="data-one">
                                        {props.t("transaction_id")}:
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="data-two">
                                        {item.betting_date}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="table-data d-flex">
                                    <div className="data-three">
                                      {item.transaction_id}
                                    </div>
                                    {/* <div className="data-four">{item.status}</div> */}
                                    {item.status == "Win All" ? (
                                      <div className="data-four text-success">
                                        {item.status}
                                      </div>
                                    ) : item.status == "Lose All" ? (
                                      <div className="data-four text-danger">
                                        {item.status}
                                      </div>
                                    ) : item.status == "Lose Half" ? (
                                      <div className="data-four text-warning">
                                        {item.status}
                                      </div>
                                    ) : (
                                      <div className="data-four text-info">
                                        {item.status}
                                      </div>
                                    )}
                                  </div>
                                  <div className="table-info">
                                    <div className="data-txtdt">
                                      {props.t("betting_position")}:
                                    </div>
                                    <div className="data-position">
                                      {outstandingBetHistory?.column_labels.map(
                                        (columnLabel, id) => {
                                          let newLable =
                                            outstandingBetHistory.keys[
                                              columnLabel
                                            ];
                                          return (
                                            <React.Fragment key={id}>
                                              {renderTableCell(item, newLable)}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="table-data d-flex">
                                    <div className="data-one">
                                      {props.t("betting_value")}:
                                    </div>
                                    <div className="data-one">
                                      {props.t("odds")}:
                                    </div>
                                    <div className="data-one">
                                      {props.t("win")} / {props.t("loss")}:
                                    </div>
                                  </div>
                                  <div className="table-data d-flex">
                                    <div className="data-count">
                                      {item.betting_value}
                                    </div>
                                    <div className="data-count">
                                      {item.odds}
                                    </div>
                                    <div className="data-count">
                                      {item.win_loss}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {lastPage != 1 && (
                            <div className="show-btn">
                              <button
                                onClick={() => {
                                  lastPage > page - 1
                                    ? outStandingBetList(page)
                                    : outStandingBetList(1);
                                }}
                              >
                                {lastPage > page - 1 ? (
                                  <>{props.t("show_more")}</>
                                ) : (
                                  <>{props.t("show_less")}</>
                                )}
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="text-center pt-2">
                          <span className="table-no-reacord">
                            {props.t("no_record_found")}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

OutstandingBetInner.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(OutstandingBetInner);
