import { GrFormClose } from 'react-icons/gr';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

// import 

const HomeScreenPopup = (props) => {
    const { componentSetting } = useSelector(state => ({
        componentSetting: state.componentSettingListR.data
    }));

    const [custComplaint, setCustComplaint] = useState(false);
    const btnRefLogin = useRef();
    const navigate = useNavigate();

    const handleNotificationPopup = () => {
        props.handlePopup();
    }

    const handelSubmitOK = () => {
        props.handlePopup();
        // navigate('/memo');
    }


    return (
        <div className={`popup-modal ${custComplaint ? 'customerComplaintsClass' : ''}`} id='popup-model-id'>
            <div className="modal-cus" ref={btnRefLogin}>
                <span className="close" onClick={() => handleNotificationPopup()} ><GrFormClose /></span>
                <div className='popup-box'>

                    {props.data != undefined && props.data[0].popup_status == false &&
                        <>
                            <h4 className="title">{props.t('information')}</h4>
                            {props.data?.[0]?.popup_message != '' && props.data?.[0]?.popup_message && <p style={{ wordWrap: 'break-word' }}> {props.t(props.data?.[0]?.popup_message)} </p>}
                            <div className='text-center depo-okbtn'>
                                <button onClick={() => handelSubmitOK()}>{props.t('okay')}</button>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    )
}

HomeScreenPopup.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(HomeScreenPopup);